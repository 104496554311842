import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { Box, Grid, IconButton } from '@mui/material';
import { LabeledOutline, SideDrawer } from 'components/Common';
import { FieldTooltip } from 'components/Fields';
import { ContactsTable } from 'components/Fields/Contact/components/ContactsTable';
import { CONTACT_TYPES } from 'consts';
import { useSortableFieldArray } from 'hooks';
import React, { useState } from 'react';
import { ReactSortable } from 'react-sortablejs';
import { RoleField } from '../../RolesPanel/components/RoleField';
import { RolesWrapper } from '../../RolesPanel/components/RolesField/styled';

export const TeamMembers = () => {
  const [showModalList, setShowModalList] = useState(false);
  let teamFieldArray = useSortableFieldArray('team');

  const handleAddRoleClick = () => {
    setShowModalList(true);
  };

  const addRoleButton = (
    <IconButton size="small" onClick={handleAddRoleClick}>
      <FieldTooltip
        right={3}
        top={-8}
        tooltip={{
          title: `Add a Team Member`,
          icon: faPlus,
        }}
      />
    </IconButton>
  );

  const removeRole = (role) => {
    teamFieldArray.form.mutators.removeTeamMember(role.order);
  };

  const handleTableRowClick = (contact) => {
    const newTeamMember = {
      teamMember: contact,
      percent: 0,
      order: teamFieldArray.value.length + 1,
    };
    teamFieldArray.form.mutators.push('team', newTeamMember);
    setShowModalList(false);
  };

  return (
    <RolesWrapper>
      <Grid item xs={12}>
        <Box style={{ cursor: 'pointer' }}>
          <LabeledOutline
            large
            // error={!!getError(meta)}
            label={
              <>
                Team Members
                {/*{required ? <Required /> : ''}*/}
              </>
            }
            toolbar={addRoleButton}
          >
            {(showModalList ||
              Object.keys(teamFieldArray.value).length > 0) && (
              <div>
                {teamFieldArray.value && (
                  <ReactSortable
                    list={teamFieldArray.value}
                    setList={teamFieldArray.update}
                    filter={'.clear-button'}
                  >
                    {teamFieldArray.value.map((roleOption, index) => {
                      return (
                        <React.Fragment key={`teamMember${index}`}>
                          <RoleField
                            teamMember
                            index={index}
                            roleOption={roleOption}
                            removeRole={removeRole}
                            hideModalList={() => {
                              setShowModalList(false);
                            }}
                          />
                        </React.Fragment>
                      );
                    })}
                  </ReactSortable>
                )}
              </div>
            )}
            {/*{getErrorMessage(meta)}*/}
          </LabeledOutline>
        </Box>
      </Grid>
      <SideDrawer
        closeOnClickAway={() => {
          setShowModalList(!showModalList);
        }}
        width={'40%'}
        isOpen={showModalList}
      >
        <ContactsTable
          onRowClick={handleTableRowClick}
          title={'Team Member'}
          type={CONTACT_TYPES.COMPANY_AGENT.value}
        />
      </SideDrawer>
    </RolesWrapper>
  );
};
