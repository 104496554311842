import { createContext, useContext } from 'react';

const FormValidationContext = createContext(
  {
    validate: async () => {},
    processFormData: () => {},
  }
);

export function useFormValidation() {
  return useContext(FormValidationContext);
}

export const FormValidationProvider = FormValidationContext.Provider;
