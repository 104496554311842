import { Button } from '@mui/material';
import { StyledBadge } from 'components/Styled';
import PropTypes from 'prop-types';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetTransactionInvoicesQuery } from 'redux/rtk-query';

export const StatusCounterButton = ({ status, onClick, type }) => {
  const { transactionDetailsId } = useParams();
  const { data: transactionInvoiceData } = useGetTransactionInvoicesQuery(
    {
      id: transactionDetailsId,
      status,
      type,
      limit: 100,
    },
    { skip: !transactionDetailsId || status === 'Current' },
  );

  const totalCount = transactionInvoiceData?.data?.meta?.total || 0;

  return (
    <Button size="small" variant="text" onClick={onClick}>
      {status}
      <StyledBadge badgeContent={totalCount} color="primary" />
    </Button>
  );
};

StatusCounterButton.propTypes = {
  status: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.array,
};
