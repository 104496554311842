import {RIGHT_OPTIONS} from "consts/rightsPermissions";
import {TASK_STATUSES} from "consts/tasks";

export const defaultValues = {
    name: "",
    description: "",
    status: TASK_STATUSES.NEW,
    checklistItems: [],
    companyStructureUnits: [],
    shareWithOption: RIGHT_OPTIONS.agentOnly.value,
    modelAccess: {
        shareWithOption: RIGHT_OPTIONS.agentOnly.value,
        sharedWithUsers: [],
        sharedWithUnits: [],
        owner: null,
        everyone: false,
    }
}