import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";

import GoogleLogoImg from "images/GoogleLogo.svg";
import AbeLogoImg from "images/abe-logo.png";
import { HOME_ROUTE } from "consts";
import { isAuthenticatedSelector } from "redux/selectors";
import { gapiLibInit, signInGoogle, isAuthRedirect } from "utils/authService";
import { useGoogleLogin } from '@react-oauth/google';

import {
  MainContainerWrapper,
  AbeLogo,
  GoogleButton,
  GoogleLogo,
  AlterLoginText,
  AlterTextWrapper,
  DividerStyled,
  CredsButton, LogoBox,
} from "./styled";

export const SignInWithGoogle = ({ signInWith }) => {
  const isAuthenticated = useSelector(state => isAuthenticatedSelector(state));

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const targetUrl = location.state?.targetUrl;

  useEffect(() => {
    isAuthRedirect(isAuthenticated, navigate, targetUrl, HOME_ROUTE);
  }, [targetUrl, isAuthenticated]);

  useEffect(() => {
    gapiLibInit();
  }, []);

  const signInHandler = useGoogleLogin({
    onSuccess: tokenResponse =>  signInGoogle(dispatch, tokenResponse.access_token),
  });

  return (
    <MainContainerWrapper disableGutters>
      <LogoBox>
        <AbeLogo component="img" alt="Abe logo" src={AbeLogoImg} />
        <div style={{color: "#8a8a8a"}}>AUTOMATED BACK-END</div>
      </LogoBox>
      <GoogleButton component="div" onClick={signInHandler}>
        <GoogleLogo component="img" src={GoogleLogoImg} alt="Google Logo" />
        <Box component="span">Sign in with Google</Box>
      </GoogleButton>
      <AlterTextWrapper component="div">
        <DividerStyled orientation="horizontal" />
        <AlterLoginText variant="p">Or choose another way</AlterLoginText>
        <DividerStyled orientation="horizontal" />
      </AlterTextWrapper>
      <CredsButton component="div" onClick={() => signInWith("Email")}>
        Sign In with email & password
      </CredsButton>
    </MainContainerWrapper>
  );
};

SignInWithGoogle.propTypes = {
  signInWith: PropTypes.func.isRequired,
};
