import { faEdit, faPaperclip } from '@fortawesome/pro-light-svg-icons';
import { IconButton } from '@mui/material';
import { FileChip, FontAwesomeIconComp } from 'components/Common';
import { RowButtonWrapper } from 'components/Styled';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { LeftInfo, MainContent, MainSummary, RightInfo } from './styled';

export const EmailSummary = ({ email }) => {
  // eslint-disable-next-line no-unused-vars
  const [emailFiles, setEmailFiles] = useState([]);
  const [showFiles, setShowFiles] = useState(false);

  const handleEdit = () => {};

  return (
    <MainContent>
      <MainSummary>{email.description}</MainSummary>
      <RowButtonWrapper className={'showOnHover'}>
        <IconButton onClick={handleEdit} size={'small'}>
          <FontAwesomeIconComp icon={faEdit} size="sm" />
        </IconButton>
      </RowButtonWrapper>
      <LeftInfo>
        <strong>TO:</strong>
        <span style={{ color: '#545454', marginLeft: 5 }}>{email.to}</span>
        {email.filesCount > 0 && (
          <IconButton
            style={{ marginRight: 6 }}
            onClick={() => {
              setShowFiles(!showFiles);
            }}
            size={'x-small'}
          >
            <FontAwesomeIconComp icon={faPaperclip} fontSize={12} />
          </IconButton>
        )}
        {showFiles &&
          emailFiles.map((emailFile) => {
            return <FileChip key={emailFile.id} file={emailFile} />;
          })}
      </LeftInfo>
      <RightInfo>{new Date(email.createdAt).toLocaleDateString()}</RightInfo>
    </MainContent>
  );
};

EmailSummary.propTypes = {
  email: PropTypes.object,
};
