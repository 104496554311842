export const SettingType = {
    OFFICE: 'Office',
    BROKER: 'Broker',
    INDIVIDUAL_AGENT: 'Individual Agent',
    TEAM_LEADER: 'Team Leader',
    TEAM_MEMBER: 'Team Member',
    TEAM: 'Team',
    COMPANY_ADMIN: 'Company Admin',
    REFERRAL_AGENT: 'Referral Agent',
};

export const AgentStatus = {
    PRE_HIRE: 'Pre-Hire',
    HIRED: 'Hired',
    DEHIRED: 'Dehired',
}

export const SiteBranding = {
    HEADSHOT: 'Headshot',
    NO_HEADSHOT: 'No Headshot',
    ICON: "Icon",
}

export const Pronouns = {
    HE_HIM_HIS: 'He / Him / His',
    SHE_HER_HERS: 'She / Her / Hers',
    THEY_THEM_THEIRS: 'They / Them / Theirs',
}

export const Languages = {
    ENGLISH: 'English',
    SPANISH: 'Spanish',
    PORTUGUESE: 'Portuguese',
    MANDARIN_CHINESE: 'Mandarin Chinese',
    HINDI: 'Hindi',
    BENGALI: 'Bengali',
    RUSSIAN: 'Russian',
    JAPANESE: 'Japanese',
    WESTERN_PUNJABI: 'Western Punjabi',
    MARATHI: 'Marathi',
    TELUGU: 'Telugu',
    TURKISH: 'Turkish',
    KOREAN: 'Korean',
    FRENCH: 'French',
    GERMAN: 'German',
    VIETNAMESE: 'Vietnamese',
    TAMIL: 'Tamil',
    URDU: 'Urdu',
    JAVANESE: 'Javanese',
    ITALIAN: 'Italian',
}

export const ResidentialServices = {
    BUYER: 'Buyer',
    SELLER: 'Seller',
    INVESTOR: 'Investor',
    TENANT: 'Tenant',
    LANDLORD_LEASE_ONLY: 'Landlord (lease only)',
    LANDLORD_MONTHLY_MANAGEMENT: 'Landlord (monthly management)',
}

export const CommercialServices = {
    BUYER: 'Buyer',
    SELLER: 'Seller',
    TENANT: 'Tenant',
    LANDLORD_LEASE_ONLY: 'Landlord (lease only)',
    LANDLORD_MONTHLY_MANAGEMENT: 'Landlord (monthly management)',
}