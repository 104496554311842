import React from "react";
import { Form } from "react-final-form";
import PropTypes from "prop-types";

import { useChangePasswordMutation } from "redux/rtk-query";


import PasswordForm from "./PasswordForm";

export const Password = ({ userId }) => {
  const [changePassword, { isLoading }] = useChangePasswordMutation();

  const onSubmit = values => {
    changePassword({
      userId,
      ...values,
    });
  };

  return (
    <Form
      onSubmit={onSubmit}

      initialValues={{
        password: "",
        confirm: "",
      }}
      component={props => <PasswordForm {...props} isLoading={isLoading} />}
    />
  );
};

Password.propTypes = {
  userId: PropTypes.string,
};
