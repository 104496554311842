import {createApi} from "@reduxjs/toolkit/query/react";

import {mlsUrlBuilder} from "./urlBuilder/mls";
import axiosBaseQuery from "../../utils/baseQuery";

export const mlsAPI = createApi({
  reducerPath: "mlsAPI",
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  tagTypes: ["MlsListings","Parcels"],
  endpoints: build => ({
    getParcels: build.query({
      query: args => ({
        url: mlsUrlBuilder.getParcels(args),
        method: "GET",
      }),
      providesTags: ["Parcels"],
    }),
    getParcel: build.query({
      query: args => ({
        url: mlsUrlBuilder.getParcel(args),
        method: "GET",
      }),
      providesTags: ["Parcels"],
    }),
    getMlsListing: build.query({
      query: args => ({
        url: mlsUrlBuilder.getMlsListing(args),
        method: "GET",
      }),
      providesTags: ["MlsListing"],
    }),
    getMlsListings: build.query({
      query: args => ({
        url: mlsUrlBuilder.getMlsListings(args),
        method: "GET",
      }),
      providesTags: ["MlsListing"],
    }),
  }),
});

export const {
  useGetParcelsQuery,
  useGetParcelQuery,
  useGetMlsListingQuery,
  useGetMlsListingsQuery,
} = mlsAPI;
