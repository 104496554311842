import { Box, Button, Card } from '@mui/material';
import { DetailsPanels } from 'components/Common';
import { DetailsLayout } from 'components/Layouts';
import { DetailsBox, DetailsWrapper } from 'components/Styled';
import { UPDATE_CAPPING } from 'consts';
import React from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { useGetDocumentQuery } from 'redux/rtk-query';

export const DetailsDocumentPage = () => {
  let { documentDetailsId } = useParams();
  const navigate = useNavigate();
  const { data: documentData } = useGetDocumentQuery({ id: documentDetailsId });

  return (
    <>
      {documentData && (
        <>
          <DetailsLayout
            header={
              <>
                <span>{documentData.data.name}</span>
                <Button
                  size={'small'}
                  onClick={() => {
                    navigate(UPDATE_CAPPING(documentDetailsId));
                  }}
                >
                  Edit
                </Button>
              </>
            }
            leftContent={
              <>
                <DetailsWrapper>
                  <Card>
                    <DetailsBox>
                      <Box width={'100%'}>
                        <DetailsPanels
                          data={documentData?.data}
                          panels={{
                            Overview: [
                              'name',
                              'calculationType',
                              'calculationPeriod',
                              'documentLevels',
                            ],
                          }}
                        />
                      </Box>
                    </DetailsBox>
                  </Card>
                </DetailsWrapper>
              </>
            }
            rightContent={<></>}
          />
          <Outlet />
        </>
      )}
    </>
  );
};
