import { Grid } from '@mui/material';
import { DetailsLayout } from 'components/Layouts';
import { DetailsWrapper } from 'components/Styled';
import { conversion } from 'helpers';
import React, { useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { useGetSettingQuery } from 'redux/rtk-query';
import {
  HeadshotCard,
  SettingCapping,
  SettingProfile,
  SettingsOverview,
} from './components';

export const DetailsSettingPage = () => {
  let { settingDetailsId } = useParams();
  const { data: settingData } = useGetSettingQuery(
    { id: settingDetailsId },
    { refetchOnMountOrArgChange: true },
  );

  useEffect(() => {
    if (settingData) {
      document.title = `${conversion.getName(settingData?.data)} - Settings`;
    }
  }, [settingData]);

  return (
    <>
      {settingData && (
        <>
          <DetailsLayout
            header={
              <>
                <span>{settingData.data.name}</span>
              </>
            }
            leftContent={
              <>
                <HeadshotCard />
                <SettingsOverview />
              </>
            }
            rightContent={
              <DetailsWrapper>
                <Grid container spacing={2}>
                  <SettingProfile />
                  <SettingCapping />
                </Grid>
              </DetailsWrapper>
            }
          />
          <Outlet />
        </>
      )}
    </>
  );
};
