import { useEffect, useState } from 'react';
import { useGetContactQuery, useGetTransactionQuery } from 'redux/rtk-query';
import { useSelector } from 'react-redux';
import {
  currentDocumentSelector,
  isTemplateViewSelector,
} from "redux/selectors";
import { getPossibleRoles } from '../utils/roles';

export const getCombinedRoles = () => {
  const [combinedRoles, setCombinedRoles] = useState(null);
  const documentData = useSelector(currentDocumentSelector);
  const isTemplateView = useSelector(isTemplateViewSelector);
  const { data: transactionData } = useGetTransactionQuery(
    { id: documentData?.transaction?.id },
    { skip: !documentData?.transaction?.id },
  );
  const { data: contactData } = useGetContactQuery(
    { id: documentData?.contact?.id },
    { skip: !documentData?.contact?.id },
  );

  useEffect(() => {
    let allRoles = [...documentData.roles];

    if (isTemplateView) {
      if (documentData.moduleClassName === 'Transaction') {
        let possibleRoles = getPossibleRoles(documentData);
        allRoles = allRoles.concat(possibleRoles);
      }
    } else if (transactionData?.data?.roles) {
      transactionData.data.roles.map((role) => {
        if (!documentData.roles.find((r) => r.role === role.role && r.order === role.order)) {
          allRoles.push({
            ...role,
            role: role.role,
            type: 'inactive',
          });
        }
      });
    }

    setCombinedRoles(allRoles);

    //TODO implement mismatched roles warning
    // Object.keys(roles.account).map(
    //   (key, index) => {
    //     let accountRole = roles.account[key]
    //     if(!roles.document[key]){
    //       allRoles[key] = accountRole
    //       if(key.includes("seller") || key.includes("buyer") || key.includes("landlord") || key.includes("tenant")){
    //         mismatchedRoles.push(key)
    //       }
    //     }
    //     else if(roles.document[key].id !== roles.account[key].id){
    //       mismatchedRoles.push(key)
    //     }
    //   }
    // );
  }, [transactionData, contactData, documentData]);

  return combinedRoles;
};
