import { IconButton } from '@mui/material'
import { FontAwesomeIconComp } from "components/Common"
import { faTrash } from '@fortawesome/pro-regular-svg-icons'
import React from 'react'

export function DeleteDocumentButton () {
  return (
    <IconButton size="small" sx={{ ml: 1, p: 0 }}>
      <FontAwesomeIconComp fontSize={14} icon={faTrash}/>
    </IconButton>
  )
}