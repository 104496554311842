import { Button } from '@mui/material';
import { CreateButton } from 'components/Common';
import { LayoutHeader } from 'components/Layouts';
import { PageMainContent } from 'components/Styled';
import { LIST_DOCUMENT, TEMPLATE_MANAGER } from 'consts';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { DocumentTemplatesTable } from './components';
import { CategoriesTable } from './components/CategoriesTable/CategoriesTable';

export const DocumentTemplatesPage = () => {
  const navigate = useNavigate();
  const { tab } = useParams();

  const activeTab = tab || 'documentTemplates';

  const handleTabChange = (tab) => {
    const parentId = tab === 'documentTemplates' ? 'home' : '';
    navigate(`/${TEMPLATE_MANAGER(tab, parentId)}`);
  };

  return (
    <>
      <LayoutHeader
        title={
          <>
            <Button
              variant={activeTab === 'documentTemplates' ? 'outlined' : ''}
              onClick={() => handleTabChange('documentTemplates')}
            >
              Document Templates
            </Button>
            <Button
              variant={activeTab === 'clickMarketing' ? 'outlined' : ''}
              onClick={() => handleTabChange('clickMarketing')}
            >
              Click Marketing
            </Button>
            <Button
              variant={activeTab === 'categories' ? 'outlined' : ''}
              onClick={() => handleTabChange('categories')}
            >
              Categories
            </Button>
          </>
        }
      >
        <CreateButton
          path={`/${LIST_DOCUMENT}`}
          title={'Documents List'}
          icon={null}
        />
      </LayoutHeader>
      <PageMainContent maxWidth={false} component="main" disableGutters>
        {activeTab === 'documentTemplates' && (
          <DocumentTemplatesTable key="documentTemplates" />
        )}
        {activeTab === 'clickMarketing' && (
          <DocumentTemplatesTable
            key="clickMarketing"
            parentId={'CLICK_MARKETING'}
          />
        )}
        {activeTab === 'categories' && <CategoriesTable key="categories" />}
      </PageMainContent>
    </>
  );
};
