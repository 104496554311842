import { Grid } from '@mui/material';
import { FormPanel } from 'components/Common';
import { CurrencyInput, NumberInput, Select } from 'components/Fields';
import { FormCondition } from 'components/Forms';
import { STATUS_OPTIONS } from 'consts';
import { useTransactionOptions } from 'hooks';
import React from 'react';
import { Field, useForm } from 'react-final-form';

export const MyCommissionPanel = () => {
  const form = useForm();
  const { paidByTypeOptions } = useTransactionOptions(form);

  return (
    <FormPanel label={'My Commission'}>
      <Grid container>
        <FormCondition
          when="status"
          operator="doesNotEqual"
          is={STATUS_OPTIONS.REFERRAL.value}
        >
          <Grid item xs={6}>
            <Field
              label="Commission"
              name={'commission'}
              iconEnd={<>%</>}
              textAlign={'right'}
              component={NumberInput}
              numberType={'decimal'}
              min={0}
              max={100}
              tooltip={{
                title:
                  'Enter the commission percentage for only your side of this file.',
              }}
            />
          </Grid>
        </FormCondition>
        <Grid item xs={6}>
          <Field
            label="Bonus Or Flat Rate"
            name={'bonusOrFlatRate'}
            component={CurrencyInput}
            tooltip={{
              title:
                'Enter a flat dollar amount for the commission or any bonuses/deductions that affect the percentage commission to the left.',
            }}
          />
        </Grid>
        <FormCondition
          when="status"
          operator="oneOf"
          is={['Listed', 'Pending', 'Closed Sale']}
        >
          <Grid item xs={12}>
            <Field
              label="Transaction Fee Amount"
              name={'transactionFeeAmount'}
              component={CurrencyInput}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              label="Paid By"
              name={'transactionFeePaidBy'}
              component={Select}
              options={paidByTypeOptions}
            />
          </Grid>
        </FormCondition>
      </Grid>

    </FormPanel>
  );
};
