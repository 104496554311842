// Value.js
import React from 'react';
import PropTypes from 'prop-types';
import { Field, useField } from 'react-final-form';
import {
  TextField,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  InputLabel,
  FormControl,
} from '@mui/material';

export const Value = ({ name }) => {
  // Access the 'condition' field value
  const {
    input: { value: condition },
  } = useField(`${name}.condition`, { subscription: { value: true } });

  // Access the 'enum' field value
  const {
    input: { value: fieldEnum },
  } = useField(`${name}.enum`, { subscription: { value: true } });

  const isOneOfCondition = condition === 'ONE_OF';
  const hasEnumValues = Array.isArray(fieldEnum) && fieldEnum.length > 0;

  if (hasEnumValues) {
    // Use Select component with enum values
    return (
      <Field
        name={`${name}.value`}
        key={`${name}.value-${isOneOfCondition ? 'multi' : 'single'}`}
      >
        {({ input }) => (
          <FormControl variant="outlined" sx={{ margin: 0, minWidth: 150 }}>
            <InputLabel>{isOneOfCondition ? 'Values' : 'Value'}</InputLabel>
            <Select
              sx={{ margin: 0 }}
              value={
                isOneOfCondition
                  ? Array.isArray(input.value)
                    ? input.value
                    : input.value
                      ? [input.value]
                      : []
                  : input.value || ''
              }
              onChange={(event) => {
                input.onChange(event.target.value);
              }}
              multiple={isOneOfCondition}
              label={isOneOfCondition ? 'Values' : 'Value'}
              renderValue={(selected) =>
                isOneOfCondition && Array.isArray(selected)
                  ? selected.join(', ')
                  : selected
              }
            >
              {fieldEnum.map((option) => (
                <MenuItem key={option} value={option}>
                  {isOneOfCondition ? (
                    <>
                      <Checkbox
                        checked={
                          input.value ? input.value.indexOf(option) > -1 : false
                        }
                      />
                      <ListItemText primary={option} />
                    </>
                  ) : (
                    option
                  )}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </Field>
    );
  } else {
    // Use TextField for other cases
    return (
      <Field name={`${name}.value`} key={`${name}.value-text`}>
        {({ input, meta }) => (
          <TextField
            {...input}
            label="Value"
            variant="outlined"
            sx={{ marginRight: 2, minWidth: 150 }}
            error={meta.touched && Boolean(meta.error)}
            helperText={meta.touched && meta.error ? meta.error : ''}
          />
        )}
      </Field>
    );
  }
};

Value.propTypes = {
  name: PropTypes.string.isRequired,
};

export default Value;
