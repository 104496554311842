import PropTypes from 'prop-types';
import React, { createContext, useContext, useState } from 'react';

const ItemCountContext = createContext({
  itemCount: 0, // Default value
  setItemCount: () => {}, // Default value
});

export const useItemCount = () => useContext(ItemCountContext);

export const ItemCountProvider = ({ children }) => {
  const [itemCount, setItemCount] = useState(0);

  const handleSetItemCount = (count) => {
    setItemCount(count);
  };

  return (
    <ItemCountContext.Provider
      value={{ itemCount, setItemCount: handleSetItemCount }}
    >
      {children}
    </ItemCountContext.Provider>
  );
};

ItemCountProvider.propTypes = {
  children: PropTypes.node,
};
