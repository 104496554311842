import { faCirclePlus } from '@fortawesome/pro-regular-svg-icons';
import { Button, Divider } from '@mui/material';
import { Box } from '@mui/system';
import {
  AbeCard,
  FontAwesomeIconComp,
  ModuleIcon,
  SideDrawer,
} from 'components/Common';
import { InvoiceTemplateSelection } from 'components/ModelViews/InvoiceTemplates/InvoiceTemplateSelection/InvoiceTemplateSelection';
import { CREATE_INVOICE } from 'consts';
import { useDrawer } from 'hooks';
import { StatusCounterButton } from 'pages/TransactionsPages/DetailsTransactionPage/components/TransactionDashboard/components/PropertyMgmtInvoicesCards/components/components';
import React, { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useGetTransactionInvoicesQuery } from 'redux/rtk-query';
import { PropertyMgmtInvoiceRow } from './components/PropertyMgmtInvoiceRow';

const type = ['Tenant Charge'];
export const TenantInvoicesCard = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { transactionDetailsId } = useParams();
  const { isOpen, openDrawer, closeDrawer } = useDrawer(false);

  const [status, setStatus] = useState('Current');

  const { data: transactionInvoiceData } = useGetTransactionInvoicesQuery(
    {
      id: transactionDetailsId,
      type,
      status: status,
      limit: 100,
    },
    { skip: !transactionDetailsId },
  );

  const transactionInvoices = transactionInvoiceData?.data?.items || [];

  const onRowClick = (invoiceTemplate) => {
    navigate(`${pathname}${CREATE_INVOICE(invoiceTemplate.id)}`);
    closeDrawer();
  };

  return (
    <>
      <AbeCard
        icon={<ModuleIcon module={'Invoices'} />}
        title={'Tenant Charges'}
      >
        <Divider />
        <Box
          fontSize={14}
          display={'flex'}
          justifyContent={'space-between'}
          alignContent="center"
        >
          <Box alignItems={'center'} display={'inline-flex'}>
            <Button
              sx={{ fontWeight: status === 'Current' ? 'bold' : 'normal' }}
              size="x-small"
              onClick={() => {
                setStatus('Current');
              }}
            >
              Current
            </Button>
            <StatusCounterButton
              type={type}
              status={'Future'}
              size="small"
              variant="text"
              onClick={() => setStatus('Future')}
            />
          </Box>
          <Box display={'inline-flex'}>
            <Button
              size="small"
              variant="text"
              onClick={() => {
                openDrawer();
              }}
              endIcon={<FontAwesomeIconComp icon={faCirclePlus} />}
            >
              New
            </Button>
          </Box>
        </Box>
        <Divider />
        <Box px={1.5} py={1}>
          {transactionInvoices.length > 0 &&
            transactionInvoices.map((invoice) => {
              return (
                <Box key={invoice.id} py={.5}>
                  <PropertyMgmtInvoiceRow invoice={invoice} />
                </Box>
              );
            })}
        </Box>
      </AbeCard>
      <SideDrawer width={'40%'} isOpen={isOpen} closeDrawer={closeDrawer}>
        <InvoiceTemplateSelection
          type={'Tenant Charge'}
          onRowClick={onRowClick}
          closeDrawer={closeDrawer}
        />
      </SideDrawer>
    </>
  );
};
