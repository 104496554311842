import {
  faAlignCenter,
  faAlignJustify,
  faAlignLeft,
  faAlignRight,
} from '@fortawesome/pro-light-svg-icons';
import { Button, ButtonGroup } from '@mui/material';
import { Box } from '@mui/system';
import { CollapsableMenuGroup, FontAwesomeIconComp } from 'components/Common';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { activeFieldsSelector } from 'redux/selectors';
import { updateField } from 'redux/slices';

export function TextAlign() {
  const dispatch = useDispatch();
  const activeFields = useSelector(activeFieldsSelector);
  const textAlign = activeFields[0].textAlign;
  const alignIcon =
    textAlign === 'center'
      ? faAlignCenter
      : textAlign === 'right'
      ? faAlignRight
      : textAlign === 'left'
      ? faAlignLeft
      : faAlignJustify;

  const handleTextAlign = (align) => {
    dispatch(
      updateField({
        properties: { textAlign: align },
      }),
    );
  };

  return (
    <CollapsableMenuGroup
      label={'Text Align'}
      icon={<FontAwesomeIconComp icon={alignIcon} />}
    >
      <Box p={1} display="flex" justifyContent="center">
        <ButtonGroup fullWidth color="primary" size="large">
          <Button
            variant={textAlign === 'left' ? 'contained' : 'outlined'}
            onClick={() => handleTextAlign('left')}
            sx={{ width: '33%' }}
          >
            <FontAwesomeIconComp icon={faAlignLeft} />
          </Button>
          <Button
            variant={textAlign === 'center' ? 'contained' : 'outlined'}
            onClick={() => handleTextAlign('center')}
            sx={{ width: '33%' }}
          >
            <FontAwesomeIconComp icon={faAlignCenter} />
          </Button>
          <Button
            variant={textAlign === 'right' ? 'contained' : 'outlined'}
            onClick={() => handleTextAlign('right')}
            sx={{ width: '33%' }}
          >
            <FontAwesomeIconComp icon={faAlignRight} />
          </Button>
        </ButtonGroup>
      </Box>
    </CollapsableMenuGroup>
  );
}
