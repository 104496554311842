import { endpoints } from 'consts';
import queryString from 'query-string';

export const invoiceUrlBuilder = {
  validateInvoice: function () {
    return `${endpoints.invoices}/validate`;
  },
  createInvoice: function () {
    return endpoints.invoices;
  },
  updateInvoice: function () {
    return endpoints.invoices;
  },
  getInvoices: function (params) {
    const urlParams = queryString.stringify(params, { skipNull: true });
    return `${endpoints.invoices}?${urlParams}`;
  },
  getInvoice: function ({ id }) {
    return `${endpoints.invoices}/${id}`;
  },
  getInvoiceMedia: function (id) {
    return `${endpoints.invoices}/${id}/media`;
  },
  getAvailablePeriods: function (transactionId) {
    return `${endpoints.invoices}/available-periods/${transactionId}`;
  }
};
