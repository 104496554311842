import React from "react";
import PropTypes from "prop-types";
import { PageLink } from "components/Styled";
import { UPDATE_TASK } from "consts";
import { TaskRelatedModel } from "components/CellComponents";
import { conversion } from "helpers";
import {DateTimeCell} from "components/CellComponents";

const FullName = () => {
  return <>Gigi Cardano</>;
};

FullName.propTypes = {
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

const TaskName = ({ value }) => {
  return value ? (
    <PageLink to={`${UPDATE_TASK(value.id)}`}>
      <div> {value.description} </div>
    </PageLink>
  ) : (
    <></>
  );
};
TaskName.propTypes = {
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

// function that take a var 'value' that has key of roles. return the first two roles contacts names that CONTAIN Buyer or Seller or Landlord or Tenant
const TransactionCustomers = ({ value }) => {
  const customersString = conversion.getCustomersString(value);
  return <div>{customersString}</div>;
};

TransactionCustomers.propTypes = {
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

export const Cells = [
  {
    id: "createdAt",
    value: "createdAt",
    label: "Date",
    Wrapper: DateTimeCell,
  },
  {
    id: "description",
    value: ["description", "id"],
    numeric: false,
    disablePadding: true,
    label: "Description",
    Wrapper: TaskName,
  },
  {
    id: "linkedTo",
    value: ["contact", "transaction", "id"],
    label: "Related Item",
    Wrapper: TaskRelatedModel,
  },
  {
    id: "agent",
    value: "agent",
    label: "Assigned To",
    Wrapper: FullName,
  },
];
