import { createApi } from '@reduxjs/toolkit/query/react';
import { transformResponse } from 'helpers';
import { axiosBaseQuery } from 'utils/baseQuery';
import { reportUrlBuilder } from './urlBuilder/reports';

export const reportsAPI = createApi({
  reducerPath: 'reportsAPI',
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  tagTypes: ['Reports'],
  endpoints: (build) => ({
    getReportModules: build.query({
      query: () => ({
        url: reportUrlBuilder.getReportModules(),
        method: 'GET',
      }),
    }),
    createReport: build.mutation({
      query: (data) => ({
        url: reportUrlBuilder.createReport(),
        method: 'POST',
        data: {
          data: data,
        },
      }),
    }),
    createFilters: build.mutation({
      query: (data) => ({
        url: reportUrlBuilder.createFilters(data),
        method: 'POST',
        data: {
          data: data,
        },
      }),
    }),
    getFilters: build.query({
      query: (id) => ({
        url: reportUrlBuilder.getFilters(id),
        method: 'GET',
      }),
    }),
    updateReport: build.mutation({
      query: (data) => ({
        url: reportUrlBuilder.updateReport(data),
        method: 'PUT',
        data: {
          data: data,
        },
      }),
    }),
    getReport: build.query({
      query: (id) => ({
        url: reportUrlBuilder.getReport(id),
        method: 'GET',
      }),
    }),
    getReportsList: build.query({
      query: (args) => ({
        url: reportUrlBuilder.getList(args),
        method: 'GET',
      }),
      transformResponse,
      providesTags: ['Reports'],
    }),
  }),
});

export const {
  useGetReportModulesQuery,
  useCreateReportMutation,
  useUpdateReportMutation,
  useCreateFiltersMutation,
  useGetFiltersQuery,
  useGetReportQuery,
  useGetReportsListQuery,
} = reportsAPI;
