import {
  Button,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { TransactionFolderIcon } from 'components/Icons';
import { ListPagination } from 'components/ListPagination';
import { DocumentProgress } from 'components/ModelViews';
import { transactionColors } from 'consts';
import hexRgb from 'hex-rgb';
import PropTypes from 'prop-types';
import React from 'react';
import { NumericFormat } from 'react-number-format';

export const SalesPipelineSection = ({
  title,
  status,
  extraText,
  data,
  hideTable,
}) => {
  const [showTable, setShowTable] = React.useState(hideTable ? false : true);
  const [page, setPage] = React.useState(1);
  const pageSize = 5;

  const getBgColor = (alpha) => {
    const color = transactionColors[status];
    let rgb = hexRgb(color);
    return `rgba(${rgb.red},${rgb.green},${rgb.blue},${alpha})`;
  };

  const getExtraText = () => {
    if (extraText) {
      return (
        <span
          style={{
            fontStyle: 'italic',
            fontWeight: 100,
            fontSize: 12,
          }}
        >
          {extraText}
        </span>
      );
    }
  };

  const getSecondCell = (row) => {
    if (status === 'Property Mgmt') {
      return <TableCell align="left">{row.pmStatus}</TableCell>;
    } else {
      return (
        <TableCell align="right">
          <DocumentProgress documentProgress={row.documentProgress} />
        </TableCell>
      );
    }
  };

  const getThirdCell = (row) => {
    if (status === 'Property Mgmt') {
      return <TableCell align="right">{row.period}</TableCell>;
    } else if (status.includes('Lease')) {
      return <TableCell align="right">{row.status}</TableCell>;
    } else {
      return (
        <TableCell align="right">
          {row.netCommission ? (
            <NumericFormat
              decimalScale={0}
              fixedDecimalScale={true}
              value={row.netCommission}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
            />
          ) : (
            row.agent
          )}
        </TableCell>
      );
    }
  };

  const getFourthCell = (row) => {
    if (status === 'Property Mgmt') {
      return (
        <TableCell align="right">
          {row.netCommission ? (
            <NumericFormat
              decimalScale={0}
              fixedDecimalScale={true}
              value={row.netCommission}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
            />
          ) : (
            row.agent
          )}
        </TableCell>
      );
    }
  };

  const getTable = () => {
    return (
      <>
        {data.items.map((row) => (
          <TableRow
            style={{ borderLeft: `4px solid ${getBgColor(1)}` }}
            className={'data-rows'}
            key={row.name}
          >
            <TableCell>
              <Typography variant="body2" style={{ fontWeight: 500 }}>
                {row.name}
              </Typography>
            </TableCell>
            {getSecondCell(row)}
            {getThirdCell(row)}
            {getFourthCell(row)}
          </TableRow>
        ))}
        {data && data.totalItemCount && data.totalItemCount / pageSize > 1 ? (
          <TableRow>
            <TableCell
              style={{
                textAlign: 'center',
                padding: 3,
                borderLeft: `4px solid ${getBgColor(1)}`,
              }}
              colSpan={3}
            >
              <ListPagination
                totalCount={data.totalItemCount}
                page={page}
                rowsPerPage={pageSize}
                handleChangePage={(pageNo) => {
                  setPage(pageNo);
                }}
                rowsPerPageOptions={[5]}
              />
            </TableCell>
          </TableRow>
        ) : (
          <></>
        )}
      </>
    );
  };

  return (
    <TableBody style={{ borderBottom: '1px solid lightgrey' }}>
      <TableRow className={'status-header'}>
        <TableCell
          onClick={() => {
            setShowTable(!showTable);
          }}
          style={{
            borderLeft: `4px solid ${getBgColor(1)}`,
            borderBottom: 0,
            fontWeight: 600,
          }}
          colSpan={1}
        >
          <span style={{ top: -1, position: 'relative', marginRight: 10 }}>
            <Button style={{ minWidth: 0, padding: '2px 5px' }}>
              <TransactionFolderIcon
                model={{ status: status }}
                opened={showTable}
              />
            </Button>
          </span>
          {title ? title : status} {getExtraText()}
        </TableCell>
        <TableCell
          align={'right'}
          style={{
            fontWeight: 600,
            borderBottom: 0,
          }}
          colSpan={status === 'Property Mgmt' ? 3 : 2}
        >
          {data && data.total && parseInt(data.total) > 0 ? (
            <>
              Total:{' '}
              <NumericFormat
                decimalScale={0}
                fixedDecimalScale={true}
                value={data && data.total ? data.total : 0}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'$'}
              />
            </>
          ) : (
            <></>
          )}
        </TableCell>
      </TableRow>
      {showTable ? getTable() : <></>}
    </TableBody>
  );
};

SalesPipelineSection.propTypes = {
  title: PropTypes.string,
  status: PropTypes.string.isRequired,
  extraText: PropTypes.string,
  data: PropTypes.object.isRequired,
  hideTable: PropTypes.bool,
};
