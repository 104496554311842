import { ModuleIcon, Table } from 'components/Common';
import { ToolbarTitle } from "components/Common/Table/TableToolbar/styled";
import React, { useEffect } from 'react';
import { Cells } from './config';

export const EmailsActivityTable = () => {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('');
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [search, setSearch] = React.useState('');

  const data = {
    success: true,
    message: 'Transaction notes.',
    data: {
      items: [
        {
          id: 5,
          uuid: '2177c57c-ff55-43d6-ad9e-c53b04552959',
          description: 'Congratulations on the Closing of Your New Home!',
          createdAt: '2023-01-19T17:42:40.365Z',
          updatedAt: '2023-01-19T17:43:39.364Z',
          everyone: false,
          to: 'Harold Maudey',
          agent: {
            id: 2,
            uuid: '22a5a65c-acec-4d6d-8fc5-5e0cb491ee79',
            firstName: null,
            lastName: 'ABE',
          },
          sharedWith: null,
          companyStructureUnits: null,
          filesCount: 1,
        },
        {
          id: 7,
          uuid: '30483c4e-e835-468e-b4fd-cbf7cbc949c7',
          description: 'Reminder: Final Walk-Through Tomorrow at 10am',
          createdAt: '2023-01-19T17:42:50.881Z',
          updatedAt: '2023-01-19T17:42:50.881Z',
          everyone: false,
          to: 'Harold Maudey',
          agent: {
            id: 2,
            uuid: '22a5a65c-acec-4d6d-8fc5-5e0cb491ee79',
            firstName: null,
            lastName: 'ABE',
          },
          sharedWith: null,
          companyStructureUnits: null,
          filesCount: 0,
        },
        {
          id: 6,
          uuid: 'f31c9a2c-6c7c-475a-a652-f01433d4c7cc',
          description: 'Important Documents for Signing - Deadline Approaching',
          createdAt: '2023-01-19T17:42:47.062Z',
          updatedAt: '2023-01-19T17:42:47.062Z',
          everyone: false,
          to: 'Harold Maudey',
          agent: {
            id: 2,
            uuid: '22a5a65c-acec-4d6d-8fc5-5e0cb491ee79',
            firstName: null,
            lastName: 'ABE',
          },
          sharedWith: null,
          companyStructureUnits: null,
          filesCount: 0,
        },
        {
          id: 4,
          uuid: '3c2aad00-83ef-4c80-810b-fcb790cd0e12',
          description: 'Escrow Instructions and Timeline',
          createdAt: '2023-01-19T17:42:34.749Z',
          updatedAt: '2023-01-19T17:42:34.749Z',
          everyone: false,
          to: 'Harold Maudey',
          agent: {
            id: 2,
            uuid: '22a5a65c-acec-4d6d-8fc5-5e0cb491ee79',
            firstName: null,
            lastName: 'ABE',
          },
          sharedWith: null,
          companyStructureUnits: null,
          filesCount: 0,
        },
        {
          id: 3,
          uuid: 'c9dd1a96-c7c0-47ae-be99-451482e87cf7',
          description: 'Home Inspection Results - Next Steps',
          createdAt: '2023-01-19T17:42:26.337Z',
          updatedAt: '2023-01-19T17:42:26.337Z',
          everyone: false,
          to: 'Harold Maudey',
          agent: {
            id: 2,
            uuid: '22a5a65c-acec-4d6d-8fc5-5e0cb491ee79',
            firstName: null,
            lastName: 'ABE',
          },
          sharedWith: null,
          companyStructureUnits: null,
          filesCount: 0,
        },
      ],
      meta: {
        page: 1,
        limit: 5,
        total: 6,
        totalPages: 2,
        otherData: {},
      },
    },
  };
  const [loading, setLoading] = React.useState(true);
  const totalPages = data?.data?.meta.totalPages;
  const totalCount = data?.data?.meta.total;
  const rows = data?.data?.items;

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [0]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <>
      <Table
        title={
          <>
            <ToolbarTitle>
              <ModuleIcon marginRight={10} module={'Emails'} />
              Emails
            </ToolbarTitle>
          </>
        }
        handleSearch={setSearch}
        search={search}
        isLoading={loading}
        rows={rows}
        totalPages={totalPages}
        totalCount={totalCount}
        order={order}
        cells={Cells}
        orderBy={orderBy}
        page={page}
        rowsPerPage={rowsPerPage}
        handleRequestSort={handleRequestSort}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
      />
    </>
  );
};
