import { SideDrawer } from 'components/Common';
import { conversion, omitNull } from 'helpers';
import { usePrevious } from 'hooks';
import PropTypes from 'prop-types';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useGetContactQuery, useUpdateContactMutation } from 'redux/rtk-query';
import ContactForm from '../ContactForm/ContactForm';
import { defaultValues } from '../ContactForm/defaultValues';
import { processFormData } from '../ContactForm/formData';

export const EditContactPage = ({ params, path }) => {
  const paramName = Object.keys(params)[0];

  const [initialValues, setInitialValues] = useState({});
  const [isOpen, setIsOpen] = useState(false);

  const [updateContact] = useUpdateContactMutation();
  const { data: contactData, isSuccess } = useGetContactQuery({
    id: params[paramName],
  });

  const location = useLocation();
  const navigate = useNavigate();
  const prevIsOpen = usePrevious(isOpen);

  const afterSave = () => {
    setIsOpen(false);
  };

  useLayoutEffect(() => {
    if (prevIsOpen && !isOpen) {
      const pathArray = location.pathname.split('/');
      const pathIndex = pathArray.indexOf(path.split('/')[0]);
      const newPath = pathArray.slice(0, pathIndex).join('/');
      setTimeout(() => {
        navigate(newPath + (newPath[newPath.length - 1] === '/' ? '' : '/'));
      }, 500);
    }
  }, [isOpen]);

  useEffect(() => {
    if (isSuccess) {
      let data = { ...contactData.data };
      // PARSE COMMUNICATION AND CLASSIFICATION
      data.tags = data.tags.map((tag) => ({
        value: tag.tag.id,
        label: tag.tag.name,
      }));
      data.abeUser = data.abeUser?.id
        ? { value: data.abeUser?.id, label: conversion.getName(data.abeUser) }
        : null;
      data.classification = conversion.dbEnumToArray(data.classification);
      data.communication = conversion.dbEnumToArray(data.communication);
      data.vendorTypeId = data.vendorType?.id;
      data.vendor1099 = data.vendor1099 ? 'Yes' : 'No';
      setInitialValues({
        ...omitNull({ ...defaultValues }),
        ...omitNull(data),
      });
      setIsOpen(true);
    }
  }, [isSuccess]);

  const handleSubmitFunc = async (values, validating) => {
    let formData = processFormData(values);
    const contact = await updateContact({ ...formData, validating });
    return contact;
  };

  return (
    <SideDrawer width={'80%'} isOpen={isOpen}>
      <ContactForm
        title={'Edit Contact'}
        initialValues={initialValues}
        isLoading={!isSuccess}
        afterSave={afterSave}
        handleSubmitFunc={handleSubmitFunc}
      />
    </SideDrawer>
  );
};

EditContactPage.propTypes = {
  params: PropTypes.object,
  path: PropTypes.string,
};
