import { createApi } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from '../../utils/baseQuery';

export const authAPI = createApi({
  reducerPath: 'authAPI',
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  endpoints: (build) => ({
    getTokenGoogle: build.mutation({
      mutation: () => 'auth/google',
    }),

    getTokenCreds: build.mutation({
      mutation: () => 'auth/sign-in',
    }),
    switchUser: build.mutation({
      query: (body) => {
        return {
          url: 'auth/switch',
          method: 'POST',
          data: body,
        };
      },
    }),
    switchBack: build.mutation({
      query: (body) => {
        return {
          url: 'auth/switch/back',
          method: 'POST',
          data: body,
        };
      },
    }),
  }),
});

export const {
  useGetTokenCredsMutation,
  useGetTokenGoogleMutation,
  useSwitchUserMutation,
  useSwitchBackMutation,
} = authAPI;
