import React from "react";
import PropTypes from "prop-types";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";

import { ErrorMsg, BoxInput, TextInputField } from "components/Styled";
import {DateTimePicker as MuiDateTimePicker} from "@mui/x-date-pickers";

export const DateTimePicker = ({
  label,
  size,
  inputFormat,
  input,
  meta,
}) => {

  return <BoxInput>
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <MuiDateTimePicker
          label={label}
          error={meta.error && meta.touched}
          fullWidth
          inputFormat={inputFormat}
          {...input}
          value={input.value || null}
          renderInput={(params) => <TextInputField size={size} {...params} />}
      />
    </LocalizationProvider>
    {meta.error && meta.touched && (
        <ErrorMsg component={"span"}>{meta.error}</ErrorMsg>
    )}
  </BoxInput>
};

DateTimePicker.defaultProps = {
  size: "small",
  inputFormat: "MM/dd/yyyy hh:mm a",
};

DateTimePicker.propTypes = {
  label: PropTypes.string,
  size: PropTypes.string,
  maxDate: PropTypes.instanceOf(Date),
  inputFormat: PropTypes.string,
  input: PropTypes.object,
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
  }),
};
