// HoverReveal.js
import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

const HoverReveal = ({ children, hoverContent, sx = {}, ...props }) => {
  return (
    <Box
      {...props}
      sx={{
        position: 'relative',
        display: 'flex',
        ...sx,
        '&:hover > .hover-content': {
          opacity: 1,
          pointerEvents: 'auto',
        },
      }}
    >
      {/* Main Content */}
      {children}

      {/* Hover Content */}
      <Box
        className="hover-content"
        sx={{
          opacity: 0,
          transition: 'opacity 0.3s',
          pointerEvents: 'none',
          position: 'absolute',
          top: '50%',
          right: 10,
          transform: 'translateY(-50%)',
        }}
      >
        {hoverContent}
      </Box>
    </Box>
  );
};

HoverReveal.propTypes = {
  children: PropTypes.node.isRequired,
  hoverContent: PropTypes.node.isRequired,
  sx: PropTypes.object,
};

export default HoverReveal;
