import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fieldTemplateSelector,
  placingItemSelector,
  scaleSelector,
} from 'redux/selectors';
import { dropPlacingItem } from 'redux/slices';
import { Strikeout } from '../DocumentItem/components/fields/Strikeout';

export const PlacingField = () => {
  const fieldTemplates = useSelector(fieldTemplateSelector);
  const placingItem = useSelector(placingItemSelector);
  const scale = useSelector(scaleSelector);
  const dispatch = useDispatch();
  const initialBoxSize = fieldTemplates[placingItem];

  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [boxSize, setBoxSize] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const handleMouseMove = (event) => {
      if (!isDragging) {
        setMousePosition({
          x: event.clientX,
          y: event.clientY,
        });
      } else {
        const pageElement = event.target.closest('.react-pdf__Page');
        if (pageElement) {
          const canvasRect = pageElement.getBoundingClientRect();
          const relativeX = event.clientX - canvasRect.left;
          const relativeY = event.clientY - canvasRect.top;

          let newWidth = relativeX - dragStart.x;
          let newHeight = relativeY - dragStart.y;

          // Check if the current placing item requires maintaining aspect ratio
          if (
            placingItem === 'signatureField' ||
            placingItem === 'signatureInitialField'
          ) {
            const aspectRatio = initialBoxSize.width / initialBoxSize.height;
            const deltaWidth = relativeX - dragStart.x;
            const deltaHeight = relativeY - dragStart.y;
            const deltaAspect = deltaWidth / deltaHeight;

            if (deltaAspect > aspectRatio) {
              newHeight = newWidth / aspectRatio;
            } else {
              newWidth = newHeight * aspectRatio;
            }
          }

          newWidth += initialBoxSize.width;
          newHeight += initialBoxSize.height;

          if (newWidth < 10 || newHeight < 10) {
            return;
          }

          setBoxSize({ width: newWidth, height: newHeight });
        }
      }
    };

    const handleMouseDown = (event) => {
      setIsDragging(true);
      setBoxSize({
        width: initialBoxSize.width,
        height: initialBoxSize.height,
      });
      const pageElement = event.target.closest('.page-wrapper');
      if (pageElement) {
        const canvasRect = pageElement.getBoundingClientRect();
        const relativeX = event.clientX - canvasRect.left;
        const relativeY = event.clientY - canvasRect.top;

        setDragStart({ x: relativeX, y: relativeY });
      }
    };

    const handleMouseUp = (event) => {
      setIsDragging(false);
      const pageElement = event.target.closest('.page-wrapper');
      if (pageElement) {
        const pageNumber = pageElement.getAttribute('data-page-number');

        const relativeX = dragStart.x - initialBoxSize.width;
        const relativeY = dragStart.y - initialBoxSize.height;

        const scaledX = relativeX / scale;
        const scaledY = relativeY / scale;

        dispatch(
          dropPlacingItem({
            pageNumber,
            x: scaledX,
            y: scaledY,
            width: boxSize.width,
            height: boxSize.height,
          }),
        );
      }
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mousedown', handleMouseDown);
    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mousedown', handleMouseDown);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [
    boxSize,
    scale,
    dragStart,
    isDragging,
    dispatch,
    initialBoxSize.width,
    initialBoxSize.height,
  ]);

  return (
    <>
      {placingItem && (
        <Box
          sx={{
            transform: `scale(${scale})`,
            transformOrigin: 'top left',
            position: 'fixed',
            left: `${mousePosition.x - initialBoxSize.width}px`,
            top: `${mousePosition.y - initialBoxSize.height}px`,
            backgroundColor:
              placingItem === 'strikethrough' ? 'transparent' : '#7dccff',
            width: isDragging ? boxSize.width : initialBoxSize.width,
            height: isDragging ? boxSize.height : initialBoxSize.height,
            pointerEvents: 'none',
            zIndex: 9999,
          }}
        >
          {placingItem === 'strikethrough' && (
            <Strikeout
              field={{
                width: isDragging ? boxSize.width : initialBoxSize.width,
                height: isDragging ? boxSize.height : initialBoxSize.height,
              }}
            />
          )}
        </Box>
      )}
    </>
  );
};
