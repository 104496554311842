import React from 'react';
import { faAlignCenter } from '@fortawesome/pro-light-svg-icons';
import { Divider } from '@mui/material';
import { MenuItemButton, FontAwesomeIconComp } from 'components/Common';
import { FileMenuGroup } from '../menuItems';

export const PrimaryMenu = () => {
  return (
    <>
      <FileMenuGroup />
      <Divider />
      <MenuItemButton
        icon={<FontAwesomeIconComp fontSize={18} icon={faAlignCenter} />}
        label={'View'}
        onClick={() => {}}
      />
    </>
  );
};
