import React from "react";
import PropTypes from "prop-types";
import {useLocation, useMatch} from "react-router-dom";

export const MatchPath = ({ path, Comp, componentProps }) => {
    let location = useLocation();
    let basePath = path.split("/");
    let baseUrl = location.pathname.split(basePath[0])
    let match = useMatch(baseUrl[0]+path);

    return match ? <Comp {...match} {...componentProps} path={path}/> : null;
}

MatchPath.propTypes = {
    path: PropTypes.string,
    Comp: PropTypes.elementType,
    componentProps: PropTypes.object
};


