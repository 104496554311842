import { faTimesCircle } from '@fortawesome/pro-light-svg-icons';
import { Box, Button, IconButton, Paper } from '@mui/material';
import {
  DetailsPanels,
  FontAwesomeIconComp,
  SideDrawer,
} from 'components/Common';
import {
  ButtonSection,
  DetailsBox,
  Header,
  HeadingSection,
} from 'components/Styled';
import { useDrawer } from 'hooks';
import { InvoiceTemplateEditPage } from 'pages/InvoiceTemplatePages/InvoiceTemplateEditPage/InvoiceTemplateEditPage';
import PropTypes from 'prop-types';
import React from 'react';
import { useGetInvoiceTemplateQuery } from 'redux/rtk-query';

export function InvoiceTemplateDetailsDrawer({ params, path }) {
  console.log('InvoiceTemplateDetailsDrawer');
  const [activeView, setActiveView] = React.useState('details');
  const { isOpen, closeDrawer } = useDrawer(true, path);

  const paramName = Object.keys(params)[0];
  const { data: invoiceTemplateData } = useGetInvoiceTemplateQuery({
    id: params[paramName],
  });

  return (
    <>
      <SideDrawer width={'40%'} isOpen={isOpen && activeView === 'details'}>
        <Paper
          sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}
        >
          <HeadingSection backgroundColor={'#F7F7F7'}>
            <Box>
              <Header variant="h4">{invoiceTemplateData?.data?.name}</Header>
              <Button
                onClick={() => {
                  setActiveView('edit');
                }}
              >
                Edit
              </Button>
            </Box>
            <ButtonSection>
              <IconButton onClick={closeDrawer}>
                <FontAwesomeIconComp icon={faTimesCircle} />
              </IconButton>
            </ButtonSection>
          </HeadingSection>
          <DetailsBox
            style={{
              alignItems: 'flex-start',
              padding: 16,
              paddingBottom: 8,
              width: '100%',
            }}
          >
            {activeView === 'details' && invoiceTemplateData?.data && (
              <DetailsPanels
                data={invoiceTemplateData?.data}
                panels={{
                  '': [
                    'name',
                    'publicDescription',
                    'systemDescription',
                    'status',
                    'minQuantity',
                    'priceFrequency',
                    'cost',
                    'sellPrice',
                    'type',
                    'paymentType',
                    'paidTo',
                    'website',
                    'categories',
                    'attachments',
                  ],
                }}
                formatTypes={{
                  cost: 'currency',
                  sellPrice: 'currency',
                  paidTo: 'model',
                  categories: 'modelArray',
                  attachments: 'attachmentArray',
                }}
              />
            )}
          </DetailsBox>
        </Paper>
      </SideDrawer>
      <InvoiceTemplateEditPage
        open={isOpen && activeView === 'edit'}
        path={path}
        params={params}
      />
    </>
  );
}

InvoiceTemplateDetailsDrawer.propTypes = {
  params: PropTypes.object.isRequired,
  path: PropTypes.string,
};
