import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { Select, MenuItem, FormControl } from '@mui/material';

export const Condition = ({ name }) => (
  <FormControl>
    <Field name={`${name}.condition`} defaultValue="Equals">
      {({ input }) => (
        <Select {...input} sx={{ marginRight: 2, minWidth: 120 }}>
          <MenuItem value="One of">One of</MenuItem>
          <MenuItem value="Equals">Equals</MenuItem>
          <MenuItem value="Not Equals">Not Equals</MenuItem>
        </Select>
      )}
    </Field>
  </FormControl>
);

Condition.propTypes = {
  name: PropTypes.string.isRequired,
};
