import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { Box, Button, FormControl } from '@mui/material';
import { FontAwesomeIconComp } from 'components/Common';
import { NoResults } from 'components/Styled';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { ReactSortable } from 'react-sortablejs';
import { AddCheckListItem, CheckListItem } from '../';

export const CheckListItems = ({ input }) => {
  const [checklistItems, setChecklistItems] = useState(input.value || []);

  const addNewItem = () => {
    let itemsCopy = [...checklistItems];
    let newItem = { name: '', id: `temp-${Date.now()}` }; // Generate a temporary ID with a "temp-" prefix
    itemsCopy.push(newItem);
    setChecklistItems(itemsCopy);
  };

  const handleInputChange = (newValues) => {
    setChecklistItems(newValues);
    input.onChange(newValues);
  };

  return (
    <Box>
      <FormControl fullWidth>
        <div>
          {checklistItems.length ? (
            <>
              <ReactSortable
                list={checklistItems}
                setList={(sortableState) => {
                  handleInputChange(sortableState);
                }}
                filter={'.clear-button'}
              >
                {checklistItems.map(
                  (item) =>
                    !item.removed && (
                      <CheckListItem
                        key={`item-${item.id}`}
                        setItems={handleInputChange}
                        items={checklistItems}
                        item={item}
                      />
                    ),
                )}
              </ReactSortable>
              <AddCheckListItem addNewItem={addNewItem} />
            </>
          ) : (
            <NoResults>
              <Button
                size={'small'}
                endIcon={<FontAwesomeIconComp icon={faPlus} />}
                onClick={addNewItem}
              >
                Add New
              </Button>
            </NoResults>
          )}
        </div>
      </FormControl>
    </Box>
  );
};

CheckListItems.propTypes = {
  input: PropTypes.object,
};
