import { Box } from '@mui/material';
import { FormPanel } from 'components/Common';
import { TextInput } from 'components/Fields';
import { AbeForm } from 'components/Forms';
import { useFileUpload } from 'hooks/useFileUpload';
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';
import {
  useGetNoteMediaQuery,
  useNoteFileDeleteMutation,
  useNoteFileUploadMutation,
} from 'redux/rtk-query';
import { ButtonFileInput } from '../../../Fields/ButtonFileInput';

export function NoteForm(props) {
  const { handleFileUpload } = useFileUpload(
    useNoteFileUploadMutation,
    useNoteFileDeleteMutation,
  );

  const renderFields = () => (
    <Box p={2}>
      <FormPanel startOpen>
        <>
          <Field
            name="description"
            label={'Description'}
            component={TextInput}
            multiline
            autoSize
          />
          <Field
            name="files"
            component={(props) => (
              <ButtonFileInput
                {...props}
                useGetMediaQuery={useGetNoteMediaQuery}
              />
            )}
            dropZoneId={'note-form'}
          />
        </>
      </FormPanel>
    </Box>
  );

  const onSubmit = async (values) => {
    let response = await props.handleSubmitFunc(values);
    if (!response.error) {
      handleFileUpload(response.data.data.uuid, values.files);
    }
    return response;
  };

  return (
    <AbeForm {...props} formId={'note-form'} handleSubmitFunc={onSubmit}>
      {renderFields}
    </AbeForm>
  );
}

NoteForm.propTypes = {
  afterSave: PropTypes.func,
  handleClose: PropTypes.func,
  title: PropTypes.string,
  subTitle: PropTypes.string || PropTypes.object,
  handleSubmitFunc: PropTypes.func,
  initialValues: PropTypes.object,
};
