import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { EmptyBox } from './styled';

export const EmptyResults = ({ label = 'results' }) => {
  return (
    <EmptyBox>
      <Typography component={'span'}>No {label} found</Typography>
    </EmptyBox>
  );
};

EmptyResults.propTypes = {
  label: PropTypes.string,
};
