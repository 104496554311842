import { Box } from "@mui/system";
import React from "react";
import {useSelector} from "react-redux";
import {selectedContactsSelector} from "redux/selectors";
import {RemoveFromList} from "./RemoveFromList";
import {AddExistingContact} from "./AddExistingContact";
import {useParams} from "react-router-dom";

export const TableToolbar = () => {
  const {listId : selectedListId} = useParams();
  const selectedContacts = useSelector(selectedContactsSelector);
  const numSelected = selectedContacts ? selectedContacts.length : 0;
  return (
    <Box display="inline-block">
      {!!parseInt(selectedListId) && numSelected > 0 && (
        <RemoveFromList/>
      )}
      {!!parseInt(selectedListId) && !numSelected && (
        <AddExistingContact/>
      )}
    </Box>
  );
}
