import { faFolder, faFolderOpen } from '@fortawesome/pro-solid-svg-icons';
import { Box, Button, Checkbox, Collapse, List, ListItem } from '@mui/material';
import { FontAwesomeIconComp, SkeletonSummary } from 'components/Common';
import { SearchInput } from 'components/SearchInput';
import {
  ButtonSection,
  Header,
  HeaderSubtitle,
  HeadingSection,
} from 'components/Styled';
import { addModelAccessValues } from 'helpers';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  useCreateMultipleDocumentsMutation,
  useGetDocumentTemplatesListQuery,
} from 'redux/rtk-query';
import { setSnackbarOpen } from 'redux/slices';

const AddDocumentItem = ({ doc, handleToggle, isSelected, selectedDocs }) => {
  const [open, setOpen] = useState(false);
  const handleOpenToggle = () => {
    setOpen(!open);
  };
  return (
    <>
      <ListItem key={doc.id} dense>
        {!doc.children?.length ? (
          <Checkbox
            sx={{ p: 0.5, pr: 1 }}
            onChange={() => handleToggle(doc)}
            checked={isSelected}
          />
        ) : (
          <Button
            sx={{ p: 1, pr: 1.5 }}
            size={'x-small'}
            onClick={handleOpenToggle}
          >
            {open ? (
              <FontAwesomeIconComp fontSize={18} icon={faFolderOpen} />
            ) : (
              <FontAwesomeIconComp fontSize={18} icon={faFolder} />
            )}
          </Button>
        )}
        {doc.name}
      </ListItem>
      <Collapse in={open}>
        <Box pl={2}>
          {doc.children?.length > 0 && (
            <List>
              {doc.children.map((child) => (
                <AddDocumentItem
                  key={child.id}
                  doc={child}
                  handleToggle={handleToggle}
                  isSelected={selectedDocs.indexOf(child) !== -1}
                />
              ))}
            </List>
          )}
        </Box>
      </Collapse>
    </>
  );
};

AddDocumentItem.propTypes = {
  doc: PropTypes.object,
  handleToggle: PropTypes.func,
  isSelected: PropTypes.bool,
  selectedDocs: PropTypes.array,
};

function AddDocumentList({ item, handleOpenToggle }) {
  const [search, setSearch] = useState('');
  const [selectedDocs, setSelectedDocs] = useState([]);
  const routeParams = useParams();
  const dispatch = useDispatch();

  const { data, isFetching } = useGetDocumentTemplatesListQuery({
    page: 1,
    limit: 100,
    search: search,
    order: 'ASC',
    orderBy: 'name',
    column: ['name'],
    parentId: item.documentTemplate.id,
    status: ["Completed"],
  });

  const [createDocuments] = useCreateMultipleDocumentsMutation();

  const handleToggle = (doc) => {
    const currentIndex = selectedDocs.indexOf(doc);
    const newChecked = [...selectedDocs];

    if (currentIndex === -1) {
      newChecked.push(doc);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setSelectedDocs(newChecked);
  };

  const handleSave = async () => {
    const data = {
      name: 'Document Name',
      transactionId: parseInt(routeParams?.transactionDetailsId),
      contactId: parseInt(routeParams?.contactDetailsId),
      documentTemplateIds: selectedDocs.map((doc) => doc.id),
      ...addModelAccessValues(item.modelAccess),
    };
    await createDocuments(data).then(() => {
      handleOpenToggle();
      dispatch(
        setSnackbarOpen({
          message: `${selectedDocs.length} document${
            selectedDocs.length > 1 ? `s` : ``
          } created!`,
          severity: 'success',
        }),
      );
    });
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Box>
        <HeadingSection>
          <Box>
            <Header variant="h4">Add Document</Header>
            <HeaderSubtitle>
              to {item.documentTemplate.name} folder
            </HeaderSubtitle>
          </Box>
          <ButtonSection>
            <Button variant="outlined" onClick={handleOpenToggle}>
              Cancel
            </Button>
            <Button
              disabled={selectedDocs.length === 0}
              type="submit"
              variant="contained"
              onClick={handleSave}
            >
              {selectedDocs.length > 0
                ? `Add ${selectedDocs.length} Documents`
                : 'Select Docs to Add'}
            </Button>
          </ButtonSection>
        </HeadingSection>
        <Box
          sx={{
            margin: '0px 2px',
            padding: '6px 18px',
            backgroundColor: 'white',
          }}
        >
          <SearchInput changeHandler={setSearch} />
        </Box>
      </Box>
      <Box sx={{ flexGrow: 1, overflowY: 'auto' }}>
        {isFetching ? (
          <SkeletonSummary />
        ) : (
          <List>
            {data?.data?.map((doc) => (
              <AddDocumentItem
                key={doc.id}
                doc={doc}
                handleToggle={handleToggle}
                isSelected={selectedDocs.indexOf(doc) !== -1}
                selectedDocs={selectedDocs}
              />
            ))}
          </List>
        )}
      </Box>
      <Box sx={{ bgcolor: 'background.paper' }}>
        <List>
          <HeadingSection>
            <Box>
              <Header variant="h4">{`${selectedDocs.length} Document${
                selectedDocs.length > 1 ? 's' : ''
              } Selected`}</Header>
            </Box>
          </HeadingSection>
          {selectedDocs.map((doc) => (
            <AddDocumentItem
              key={doc.id}
              doc={doc}
              handleToggle={handleToggle}
              isSelected={selectedDocs.indexOf(doc) !== -1}
            />
          ))}
        </List>
      </Box>
    </Box>
  );
}

export default AddDocumentList;

AddDocumentList.propTypes = {
  item: PropTypes.object,
  handleOpenToggle: PropTypes.func,
};
