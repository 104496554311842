import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import AbeFileImage from 'components/Common/AbeFileImage/AbeFileImage';
import { SvgImageComponent } from 'components/ModelViews/Documents/ClickMarketingViewer/components/DocumentItem/components/fields/components/SvgImageComponent';
import { useAutofillData } from 'components/ModelViews/Documents/ClickMarketingViewer/hooks/useAutofillData';
import { CM_ITEM_TYPES } from 'consts';
import useGetMediaUrl from 'hooks/useGetMediaUrl';
import { htmlTemplateSelectorCM as htmlTemplateSelector } from 'redux/selectors';

// Utility to convert hex to rgb
const hexToRgb = (hex) => {
  const rgb = hex
    .replace(/^#/, '')
    .match(/.{2}/g)
    .map((x) => parseInt(x, 16));
  return rgb.join(', ');
};

export const Image = React.memo(({ itemId }) => {
  const { items: designItems } = useSelector(htmlTemplateSelector);
  const currentItem = designItems.find((item) => item.id === itemId);
  const autoFillData = useAutofillData();

  if (!currentItem) return null;

  const SvgComponent = autoFillData.logos[currentItem.type];
  const { data: fileData } = useGetMediaUrl(
    autoFillData.agentIcon,
    'id',
    autoFillData.agentSettingId,
  );

  // Compute background style
  let backgroundStyle = currentItem?.style?.background;
  if (currentItem.style?.backgroundThemeColor) {
    const themeColor =
      autoFillData.customColor === 'mainframeTheme'
        ? '#FFFFFF'
        : autoFillData.customColor;
    const rgbString = hexToRgb(themeColor);
    const rgba = currentItem.style?.background.match(
      /rgba?\((\d+),\s*(\d+),\s*(\d+),\s*(\d*\.?\d+)\)/,
    );
    if (rgbString && rgba) {
      backgroundStyle = `rgba(${rgbString}, ${rgba[4]})`;
    }
  }

  // Memoize the rendering of the image component itself
  const imageComponent = useMemo(() => {
    if (SvgComponent) {
      return (
        <SvgImageComponent
          SvgComponent={SvgComponent}
          fillColor={
            currentItem?.style?.svgColor === 'customColor'
              ? currentItem?.style?.customColor
              : currentItem?.style?.svgColor
          }
        />
      );
    }

    if (currentItem.type === CM_ITEM_TYPES.image.attachment) {
      return (
        <div
          style={{
            height: '100%',
            maxWidth: '100%',
            maxHeight: '100%',
            padding: 10,
            backgroundColor: 'grey',
            zIndex: -2,
            position: 'relative',
          }}
        ></div>
      );
    }

    if (currentItem.type === CM_ITEM_TYPES.image.agentIcon) {
      return (
        <>
          {fileData?.data?.url && (
            <SvgImageComponent
              SvgComponent={fileData?.data?.url}
              fillColor={
                currentItem?.style?.svgColor === 'customColor'
                  ? currentItem?.style?.customColor
                  : currentItem?.style?.svgColor
              }
            />
          )}
        </>
      );
    }

    if (currentItem.type === CM_ITEM_TYPES.image.agent) {
      return (
        <>
          {autoFillData.agentHeadshot && (
            <AbeFileImage
              file={autoFillData.agentHeadshot}
              identifierValue={autoFillData.agentSettingId}
              identifier="id"
            />
          )}
        </>
      );
    }

    if (currentItem.type === CM_ITEM_TYPES.image.agent2) {
      return (
        <>
          {autoFillData.agent2Headshot && (
            <AbeFileImage
              file={autoFillData.agent2Headshot}
              identifierValue={autoFillData.agent2SettingId}
              identifier="id"
            />
          )}
        </>
      );
    }

    // New case for propertyPhoto
    if (currentItem.type === CM_ITEM_TYPES.image.property) {
      const imageNumber = Number(currentItem.imageNumber);
      const index = imageNumber; // Adjust for zero-based indexing

      // Validate imageNumber and propertyPhotos array
      const propertyPhotos = autoFillData.propertyPhotos || [];
      const imageSrc = propertyPhotos[index];

      if (imageSrc) {
        return (
          <img
            src={imageSrc}
            alt={`Property Photo ${imageNumber}`}
            style={{ width: '100%', height: '100%' }}
          />
        );
      } else {
        // Render a placeholder or null if image not found
        return <div>No Image Available</div>;
      }
    }

    return <img src={currentItem.src} alt={currentItem.alt} />;
  }, [SvgComponent, currentItem, fileData, autoFillData]);

  return (
    <>
      {currentItem.style?.background && (
        <div
          style={{
            background: backgroundStyle,
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: -1,
          }}
        />
      )}
      {imageComponent}
    </>
  );
});

Image.propTypes = {
  itemId: PropTypes.string.isRequired,
};

Image.displayName = 'Image';
