import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import {
  authAPI,
  cappingsAPI,
  commissionsAPI,
  companiesAPI,
  companyUnitsAPI,
  contactsAPI,
  documentsAPI,
  documentTemplatesAPI,
  groupsAPI,
  invoicesAPI,
  invoiceTemplatesAPI,
  mlsAPI,
  notesAPI,
  profileAPI,
  rolesAPI,
  searchAPI,
  settingsAPI,
  tagsAPI,
  tasksAPI,
  transactionsAPI,
  usersAPI,
  vendorsAPI,
} from 'redux/rtk-query';

import {
  activitiesSliceReducer as activitiesReducer,
  companiesSliceReducer as companiesReducer,
  companyUnitsSliceReducer as companyUnitsReducer,
  contactsSliceReducer as contactsReducer,
  documentEditorSliceReducer as documentEditorReducer,
  notesSliceReducer as notesReducer,
  profileSliceReducer as profileReducer,
  resetSliceReducer as resetSliceReducer,
  resetState,
  rolesSliceReducer as rolesReducer,
  snackbarSliceReducer as snackbarReducer,
  tagsSliceReducer as tagsReducer,
  tasksSliceReducer as tasksReducer,
  transactionsSliceReducer as transactionsReducer,
  userLoginSliceReducer,
  usersSliceReducer as usersReducer,
  vendorsSliceReducer as vendorsReducer,
} from './redux/slices';

export const apiSlices = [
  authAPI,
  contactsAPI,
  tasksAPI,
  transactionsAPI,
  groupsAPI,
  notesAPI,
  tagsAPI,
  mlsAPI,
  companiesAPI,
  companyUnitsAPI,
  profileAPI,
  usersAPI,
  searchAPI,
  vendorsAPI,
  rolesAPI,
  settingsAPI,
  cappingsAPI,
  commissionsAPI,
  documentsAPI,
  documentTemplatesAPI,
  invoicesAPI,
  invoiceTemplatesAPI,
];

const loginPersistConfig = {
  key: 'auth',
  storage,
};

const appReducer = combineReducers({
  loginReducer: persistReducer(loginPersistConfig, userLoginSliceReducer),
  reset: resetSliceReducer,
  snackbarReducer,
  contactsReducer,
  activitiesReducer,
  notesReducer,
  tasksReducer,
  tagsReducer,
  transactionsReducer,
  companiesReducer,
  companyUnitsReducer,
  profileReducer,
  usersReducer,
  vendorsReducer,
  rolesReducer,
  documentEditorReducer,
  [authAPI.reducerPath]: authAPI.reducer,
  [contactsAPI.reducerPath]: contactsAPI.reducer,
  [tasksAPI.reducerPath]: tasksAPI.reducer,
  [transactionsAPI.reducerPath]: transactionsAPI.reducer,
  [groupsAPI.reducerPath]: groupsAPI.reducer,
  [notesAPI.reducerPath]: notesAPI.reducer,
  [tagsAPI.reducerPath]: tagsAPI.reducer,
  [mlsAPI.reducerPath]: mlsAPI.reducer,
  [companiesAPI.reducerPath]: companiesAPI.reducer,
  [companyUnitsAPI.reducerPath]: companyUnitsAPI.reducer,
  [profileAPI.reducerPath]: profileAPI.reducer,
  [usersAPI.reducerPath]: usersAPI.reducer,
  [searchAPI.reducerPath]: searchAPI.reducer,
  [vendorsAPI.reducerPath]: vendorsAPI.reducer,
  [rolesAPI.reducerPath]: rolesAPI.reducer,
  [settingsAPI.reducerPath]: settingsAPI.reducer,
  [cappingsAPI.reducerPath]: cappingsAPI.reducer,
  [commissionsAPI.reducerPath]: commissionsAPI.reducer,
  [documentsAPI.reducerPath]: documentsAPI.reducer,
  [documentTemplatesAPI.reducerPath]: documentTemplatesAPI.reducer,
  [invoicesAPI.reducerPath]: invoicesAPI.reducer,
  [invoiceTemplatesAPI.reducerPath]: invoiceTemplatesAPI.reducer,
});

const rootReducer = (state, action) => {
  if (action.type === resetState.type) {
    state = undefined;
  }
  return appReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST'],
      },
    }).concat(
      authAPI.middleware,
      contactsAPI.middleware,
      searchAPI.middleware,
      groupsAPI.middleware,
      notesAPI.middleware,
      tasksAPI.middleware,
      transactionsAPI.middleware,
      tagsAPI.middleware,
      companiesAPI.middleware,
      companyUnitsAPI.middleware,
      profileAPI.middleware,
      usersAPI.middleware,
      vendorsAPI.middleware,
      rolesAPI.middleware,
      mlsAPI.middleware,
      settingsAPI.middleware,
      cappingsAPI.middleware,
      commissionsAPI.middleware,
      documentsAPI.middleware,
      documentTemplatesAPI.middleware,
      invoicesAPI.middleware,
      invoiceTemplatesAPI.middleware,
      thunk,
    ),
});

export const persistor = persistStore(store);

export default store;
