import React from "react";
import { styled, Button } from "@mui/material";
import {
  faMinus,
  faPlus,
  faX,
  faColumns3,
  faFilter,
} from "@fortawesome/pro-regular-svg-icons";

import { FontAwesomeIconComp } from "components/Common";
import PropTypes from "prop-types";



export const ToolbarAction = styled("div")({
  flex: "0 0 fit-content",
});

export const AutocompleteBox = styled("div")({
  width: 250
});

export const ToolbarButton = styled(Button)({
  lineHeight: "20px",
  fontSize: "13px",
  textTransform: "none",
  marginRight: -9
});
ToolbarButton.defaultProps = {
  variant: "text",
  size: "small",
};


export const XIcon = () => (
  <FontAwesomeIconComp icon={faX} style={{ width: "10px", height: "9px" }} />
);

export const PlusIcon = ({marginRight}) => (
  <FontAwesomeIconComp
    icon={faPlus}
    style={{ width: 13, height: 13, marginRight: marginRight ? marginRight : 0 }}
  />
);

PlusIcon.propTypes = {
  marginRight: PropTypes.number,
}

export const MinusIcon = ({marginRight}) => (
    <FontAwesomeIconComp
        icon={faMinus}
        style={{ width: 13, height: 13, marginRight: marginRight ? marginRight : 0 }}
    />
);

MinusIcon.propTypes = {
  marginRight: PropTypes.number,
}

export const ColumnsIcon = () => (
  <FontAwesomeIconComp
    icon={faColumns3}
    style={{ width: "16px", height: "16px" }}
  />
);

export const FilterIcon = () => (
  <FontAwesomeIconComp
    icon={faFilter}
    style={{ width: "14px", height: "14px" }}
  />
);
