import { styled } from "@mui/system";
import { Box, Tab as TabMUI, Tabs as TabsMUI } from "@mui/material";

export const InfoBox = styled(Box)({
  height: "fit-content",
  minHeight: "320px",
  minWidth: "320px",
  background: "#FFFFFF",
  borderRadius: "16px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  flex: 1,
});

export const Tabs = styled(TabsMUI)({
  ".MuiTabs-indicator": {
    backgroundColor: "#0085FF",
  },
});

export const Tab = styled(TabMUI)({
  fontWeight: "500",
  fontSize: "16px",
  lineHeight: "24px",
  textAlign: "center",
  color: "#565656",
  textTransform: "none",
  marginTop: "16px",
  padding: "0 24px",
  ".Mui-selected": {
    color: "#0085FF",
  },
});
