import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import InputAdornment from '@mui/material/InputAdornment';
import { Required } from 'components/Common';
import { FieldTooltip } from 'components/Fields';
import { BoxInput, ErrorMsg, TextInputField } from 'components/Styled';
import { getError, hasValue } from 'helpers';
import { useFormValidationProcess, useIsRequired } from 'hooks';

export const TextInput = ({
  label,
  type,
  size,
  variant,
  rows,
  multiline,
  disabled,
  input,
  meta,
  iconStart,
  iconEnd,
  autoFocus,
  tooltip,
  readOnly,
  textAlign,
  shrinkLabel,
  required,
  fullWidth = true,
  disableUnderline,
  hideError = false,
  min,
  max,
}) => {
  const [shrink, setShrink] = useState(!!shrinkLabel);
  const error = getError(meta);
  const isFilled = hasValue(input.value);
  const isRequired = (useIsRequired(input.name) && !isFilled) || required;
  const validateFormData = useFormValidationProcess();

  useEffect(() => {
    if (isFilled) {
      setShrink(true);
    }
  }, [input.value]);

  // const handleKeyDown = useCallback(
  //   (event) => {
  //     const { code, keyCode, key } = event;
  //     const isNavigationKey =
  //       code === 'Backspace' || keyCode === 37 || keyCode === 39;
  //     const isFloatInput = inputType === 'float' && !FLOAT_REGEX.test(key);
  //
  //     if (isNavigationKey || isFloatInput) {
  //       event.preventDefault();
  //     }
  //   },
  //   [input.value],
  // );

  const handleBlur = async (event, input) => {
    await validateFormData();
    input.onBlur(event);
  };

  return (
    <BoxInput>
      <TextInputField
        variant={variant}
        className={isFilled ? 'filled' : ''}
        sx={
          (iconStart && {
            '& .MuiInputLabel-root:not(.MuiInputLabel-shrink)': {
              transform: 'translate(41px, 9px)',
            },
          },
          disableUnderline && {
            '& .MuiInputBase-input': {
              padding: '0',
            },
          })
        }
        label={
          label && (
            <>
              {label}
              {isRequired ? <Required /> : ''}
            </>
          )
        }
        type={type}
        size={size}
        min={min}
        max={max}
        multiline={multiline || !!rows}
        minRows={rows || 1} // minimum number of rows if multiline
        maxRows={rows || 10} // maximum number of rows if multiline
        error={!!error}
        rows={rows}
        disabled={disabled}
        fullWidth={fullWidth}
        autoFocus={autoFocus}
        {...input}
        onFocus={() => setShrink(true)}
        onBlur={(e) => {
          !e.target.value && setShrink(false);
          handleBlur(e, input, meta);
        }}
        // onKeyDown={(event) => {
        //   if (inputType) {
        //     handleKeyDown(event);
        //   }
        // }}
        InputLabelProps={{
          shrink: shrink,
          style: {
            right: !shrink && !!iconEnd ? 46 : 0,
          },
        }}
        InputProps={{
          startAdornment: iconStart ? (
            <InputAdornment position="start">{iconStart}</InputAdornment>
          ) : null,
          endAdornment: iconEnd ? (
            <InputAdornment position="end">{iconEnd}</InputAdornment>
          ) : null,
          readOnly: readOnly,
          autoComplete: 'off',
          'data-lpignore': 'true',
          inputProps: { style: { textAlign: textAlign } },
        }}
      />
      {tooltip && <FieldTooltip tooltip={tooltip} />}
      {error && !hideError && (
        <ErrorMsg align={textAlign} component={'span'}>
          {error}
        </ErrorMsg>
      )}
    </BoxInput>
  );
};

TextInput.defaultProps = {
  size: 'small',
  disabled: false,
};

TextInput.propTypes = {
  required: PropTypes.bool,
  inputType: PropTypes.string,
  readOnly: PropTypes.bool,
  textAlign: PropTypes.string,
  shrinkLabel: PropTypes.bool,
  tooltip: PropTypes.object,
  variant: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.string,
  type: PropTypes.string,
  rows: PropTypes.number,
  multiline: PropTypes.bool,
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
  input: PropTypes.object,
  iconStart: PropTypes.node,
  iconEnd: PropTypes.object,
  fullWidth: PropTypes.bool,
  disableUnderline: PropTypes.bool,
  hideError: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
    submitError: PropTypes.string,
    dirtySinceLastSubmit: PropTypes.bool,
  }),
};
