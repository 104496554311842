import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  Shape,
  Image,
  Text,
} from 'components/ModelViews/Documents/ClickMarketingViewer/components/DocumentItem/components/fields';
import ItemLabelText from 'components/ModelViews/Documents/ClickMarketingViewer/components/DocumentItem/components/ItemLabelText';
import { ResizableDraggable } from 'components/ModelViews/Documents/ClickMarketingViewer/components/DocumentItem/components/ResizableDraggable';
import { CM_ITEM_TYPES } from 'consts/documentTemplate';
import {
  htmlTemplateSelectorCM as htmlTemplateSelector,
  activePageCM,
} from 'redux/selectors';

const imageTypes = [
  CM_ITEM_TYPES.image.property,
  CM_ITEM_TYPES.image.logo.svgLogo,
  CM_ITEM_TYPES.image.logo.svgIcon,
  CM_ITEM_TYPES.image.svgImage,
  CM_ITEM_TYPES.image.agentIcon,
  CM_ITEM_TYPES.image.logo.lightlogo,
  CM_ITEM_TYPES.image.logo.darklogo,
  CM_ITEM_TYPES.image.logo.lighticonlogo,
  CM_ITEM_TYPES.image.logo.whiteiconlogo,
  CM_ITEM_TYPES.image.logo.whitelogo,
  CM_ITEM_TYPES.image.logo.darkiconlogo,
  CM_ITEM_TYPES.image.attachment,
  CM_ITEM_TYPES.image.agent,
  CM_ITEM_TYPES.image.agent2,
];

const shapeTypes = [
  CM_ITEM_TYPES.shapes.rectangle,
  CM_ITEM_TYPES.shapes.circle,
  CM_ITEM_TYPES.shapes.line,
];

const textTypes = [
  CM_ITEM_TYPES.text.list,
  CM_ITEM_TYPES.text.autofill,
  CM_ITEM_TYPES.text.fillnow,
];

const getItemComponent = (item, preview) => {
  if (imageTypes.includes(item.type)) {
    return <Image itemId={item.id} />;
  } else if (shapeTypes.includes(item.type)) {
    return <Shape itemId={item.id} />;
  } else if (textTypes.includes(item.type)) {
    return <Text itemId={item.id} preview={preview} />;
  }
  return null;
};

const DesignItems = React.memo(({ pageId, preview = false, scale = null }) => {
  const htmlTemplate = useSelector(htmlTemplateSelector);
  const activePage = pageId || useSelector(activePageCM);
  const page = htmlTemplate?.options?.pages?.[activePage];

  const elements = page?.elements || [];
  const designItems = htmlTemplate?.items || [];

  const designItemsById = useMemo(
    () =>
      designItems.reduce((acc, item) => {
        acc[item.id] = item;
        return acc;
      }, {}),
    [designItems],
  );

  const currentDesignItems = useMemo(
    () => elements.map((id) => designItemsById[id]).filter(Boolean),
    [elements, designItemsById],
  );

  if (!page) return null;

  return (
    <div className={`page-${page.id}`}>
      {currentDesignItems.map((item) => {
        const { id, type, textLabel } = item;
        const component = getItemComponent(item, preview);
        const isImage = imageTypes.includes(type);
        const isText = textTypes.includes(type);
        const isImageOrText = isImage || isText;

        return (
          <ResizableDraggable
            field={item}
            pageId={activePage}
            scale={scale}
            preview={preview}
            key={id}
          >
            {!preview && (
              <>
                {isImageOrText && textLabel && (
                  <ItemLabelText textLabel={textLabel} insideLabel={isImage} />
                )}
              </>
            )}
            {component}
          </ResizableDraggable>
        );
      })}
    </div>
  );
});

DesignItems.displayName = 'DesignItems';

DesignItems.propTypes = {
  pageId: PropTypes.string,
  preview: PropTypes.bool,
  scale: PropTypes.number,
};

export default DesignItems;
