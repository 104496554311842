import { SideDrawer } from 'components/Common';
import { omitNull } from 'helpers';

import { usePrevious } from 'hooks';
import PropTypes from 'prop-types';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  useGetCategoryQuery,
  useUpdateCategoryMutation,
} from 'redux/rtk-query';
import { DocumentCategoryForm } from '../DocumentCategoryForm/DocumentCategoryForm';

export const EditDocumentCategoryPage = ({ params, path }) => {
  const paramName = Object.keys(params)[0];

  const [initialValues, setInitialValues] = useState({});
  const [isOpen, setIsOpen] = useState(false);

  const [updateCategory] = useUpdateCategoryMutation();
  const { data: categoryData, isSuccess } = useGetCategoryQuery({
    id: params[paramName],
  });

  const location = useLocation();
  const navigate = useNavigate();
  const prevIsOpen = usePrevious(isOpen);

  useEffect(() => {
    if (isSuccess) {
      let data = { ...categoryData.data };
      setInitialValues({
        ...omitNull(data),
      });
      setIsOpen(true);
    }
  }, [isSuccess]);

  const handleSubmitFunc = async (values) => {
    return await updateCategory({
      parentCategoryId: values?.parentCategory?.value,
      ...values,
    });
  };

  const afterSave = () => {
    setIsOpen(false);
  };

  useLayoutEffect(() => {
    if (prevIsOpen && !isOpen) {
      const pathArray = location.pathname.split('/');
      const pathIndex = pathArray.indexOf(path.split('/')[0]);
      const newPath = pathArray.slice(0, pathIndex).join('/');
      setTimeout(() => {
        navigate(newPath + (newPath[newPath.length - 1] === '/' ? '' : '/'));
      }, 500);
    }
  }, [isOpen]);

  return (
    <SideDrawer width={'40%'} isOpen={isOpen}>
      <DocumentCategoryForm
        title={'Edit Category'}
        initialValues={initialValues}
        afterSave={afterSave}
        handleSubmitFunc={handleSubmitFunc}
      />
    </SideDrawer>
  );
};

EditDocumentCategoryPage.propTypes = {
  params: PropTypes.object,
  path: PropTypes.string,
};
