import { endpoints } from 'consts';
import queryString from 'query-string';

export const taskUrlBuilder = {
  validate: function () {
    return `${endpoints.tasks}/validate`;
  },
  createTask: function () {
    return endpoints.tasks;
  },
  updateTask: function () {
    return endpoints.tasks;
  },
  getTask: function ({ id }) {
    return `${endpoints.tasks}/${id}`;
  },
  getTasks: function (params) {
    const urlParams = queryString.stringify(params, { skipNull: true });
    return `${endpoints.tasks}/tasks?${urlParams}`;
  },
  getChecklistForTask: function ({ uuid }) {
    return `${endpoints.tasks}/${uuid}/checklist`;
  },
  saveChecklistItems: function () {
    return `${endpoints.tasks}/subTasks`;
  },
  getMyTasks: function (params) {
    const urlParams = queryString.stringify(params, { skipNull: true });
    return `${endpoints.tasks}/myTasks?${urlParams}`;
  },
  getTaskComments: function ({ uuid }) {
    return `${endpoints.tasks}/${uuid}/comments`;
  },
};
