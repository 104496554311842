// ConditionGroup.js
import React from 'react';
import PropTypes from 'prop-types';
import { faCircleXmark, faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, useForm } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { Box, Button, MenuItem, Select, IconButton } from '@mui/material';
import { SideDrawer, FontAwesomeIconComp } from 'components/Common';
import HoverReveal from 'components/Common/HoverReveal/HoverReveal';
import { Header, HeadingSection } from 'components/Styled';
import ReportFiltersTree from './../../ReportFiltersTree';
import Trigger from './Trigger/Trigger';

const ConditionGroup = ({ name, showGroupCondition, onRemoveGroup }) => {
  const form = useForm();
  const [showFilterDrawer, setShowFilterDrawer] = React.useState(false);

  const handleFilterSelect = (selectedFilter) => {
    // Add the selected filter to the group's filters
    console.log(selectedFilter);
    form.mutators.push(`${name}.filters`, {
      fieldName: selectedFilter.name,
      path: selectedFilter.path,
      condition: 'Equals',
      value: '',
      enum: selectedFilter.enum,
      label: selectedFilter.label,
    });
    setShowFilterDrawer(false);
  };

  return (
    <>
      <HoverReveal
        hoverContent={
          <IconButton color="error" onClick={onRemoveGroup}>
            <FontAwesomeIcon icon={faTimes} />
          </IconButton>
        }
        sx={{
          marginBottom: 2,
          flexDirection: 'column',
        }}
      >
        <Box
          className="condition-group"
          sx={{
            marginBottom: 2,
            padding: 2,
            border: '1px solid #ddd',
            borderRadius: 2,
            backgroundColor: '#f5f5f5',
            position: 'relative',
            display: 'flex',
          }}
        >
          {/* AND/OR between Groups */}
          {showGroupCondition && (
            <Field name={`${name}.groupingCondition`} defaultValue="AND">
              {({ input }) => (
                <Select
                  {...input}
                  IconComponent={() => null}
                  sx={{
                    position: 'absolute',
                    left: 33,
                    top: -40,
                    padding: '11px 20px',
                    background: 'white',
                    borderRadius: 8,
                    borderWidth: 2,
                    mx: -1,
                    zIndex: 7,
                    minWidth: '56px',
                    textAlign: 'center',
                    '& .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input':
                      {
                        padding: '2px',
                        paddingRight: '2px',
                      },
                  }}
                >
                  <MenuItem value="AND">AND</MenuItem>
                  <MenuItem value="OR">OR</MenuItem>
                </Select>
              )}
            </Field>
          )}

          {/* Filters */}
          <FieldArray name={`${name}.filters`}>
            {({ fields }) => (
              <>
                {fields.map((filterName, index) => (
                  <Trigger
                    key={filterName}
                    name={filterName}
                    index={index}
                    showCondition={index > 0}
                    onRemove={() => fields.remove(index)}
                  />
                ))}
              </>
            )}
          </FieldArray>
          <Box>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setShowFilterDrawer(true)}
              sx={{ ml: 2 }}
            >
              Add Filter
            </Button>
          </Box>
        </Box>
      </HoverReveal>

      {/* SideDrawer for selecting filters */}
      <SideDrawer
        width={'400px'}
        isOpen={showFilterDrawer}
        closeDrawer={() => setShowFilterDrawer(false)}
        closeOnClickAway={() => setShowFilterDrawer(false)}
      >
        <HeadingSection>
          <Header variant="h4">Select Filter</Header>
          <FontAwesomeIconComp
            icon={faCircleXmark}
            style={{ height: '28px', color: '#969696', cursor: 'pointer' }}
            onClick={() => setShowFilterDrawer(false)}
          />
        </HeadingSection>
        <ReportFiltersTree
          onSelect={(selectedFilter) => {
            handleFilterSelect(selectedFilter);
          }}
        />
      </SideDrawer>
    </>
  );
};

ConditionGroup.propTypes = {
  name: PropTypes.string.isRequired,
  showGroupCondition: PropTypes.bool.isRequired,
  onRemoveGroup: PropTypes.func,
};

export default ConditionGroup;
