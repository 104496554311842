import { styled } from "@mui/system";
import {Box, Button} from "@mui/material";

export const CornerButtonWrapper = styled("div")({
  right: 0,
  top: 0,
  position: "absolute",
  margin: "5px",
})

export const RowButtonWrapper = styled("div")({
  right: 0,
  top: 0,
  position: "absolute",
  margin: "10px 15px",
  display: "none"
})

export const TextBtn = styled(Button)({
  color: "#0085FF",
  fontWeight: "500",
  fontSize: "16px",
  lineHeight: "24px",
  textAlign: "center",
  textTransform: "none",
});


export const ButtonSection = styled(Box)(()=>({
  display: "flex",
  gap: 15
}));

const FormButton = styled(Button)({
  height: "40px",
  width: "168px",
  fontWeight: "500",
  fontSize: "16px",
  lineHeight: "24px",
  textTransform: "none",
  borderRadius: "8px",
});

export const CancelButton = styled(FormButton)({
  border: "1px solid #E8E8E8",
});

export const ConfirmButton = styled(FormButton)({
  color: "#FFFFFF",
  background: "#0085FF",
});

export const NewButtonBox = styled(Box)({
  right: 10,
});