import {createSlice} from "@reduxjs/toolkit";

const initialState = {
  selectedActivity: null,
  activityFilters: {
  },
};

export const activitiesSlice = createSlice({
  name: "activities",
  initialState,
  reducers: {
    setSelectedActivity: (state, action) => {
      state.selectedActivity = action.payload;
    },
    setActivityFilters: (state, action) => {
      state.activityFilters = action.payload;
    },
  },
});

export const { setSelectedActivity, setActivityFilters } = activitiesSlice.actions;
export const activitiesSliceReducer = activitiesSlice.reducer;
