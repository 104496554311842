import { Divider } from '@mui/material';
import { AbeCard } from 'components/Common';
import { EmailsList } from 'components/ModelViews/Emails';
import React from 'react';

export const EmailCard = (props) => {
  return (
    <AbeCard title={'Emails'} {...props}>
      <Divider />
      <EmailsList />
    </AbeCard>
  );
};

EmailCard.defaultProps = {};
