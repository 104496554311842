import { faUndo } from '@fortawesome/pro-light-svg-icons';
import {
  faCheckCircle,
  faExclamationCircle,
  faEye,
  faFilePdf,
  faMinusCircle,
  faPen,
  faTimesCircle,
} from '@fortawesome/pro-regular-svg-icons';
import { PopperButton } from 'components/Common';
import {
  EDIT_DOCUMENT,
  PREVIEW_DOCUMENT,
  SELECT_DOCUMENT,
  STATUSES,
} from 'consts';
import { getStatus } from 'helpers';
import { useOpenDrawer } from 'hooks';
import PropTypes from 'prop-types';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  CancelEsignComponent,
  CloseApproveComponent,
  DeleteComponent,
  ReviseComponent,
  UnApproveComponent,
  UnVoidComponent,
  UploadCompletedPdfComponent,
  UploadEditPdfComponent,
  VoidComponent,
} from './menuItemContent';

export const MenuItems = (item) => {
  let navigate = useNavigate();
  const openDrawer = useOpenDrawer();
  const routeParams = useParams();

  if (item.documentTemplate?.hasChildren) {
    return [
      {
        name: 'Choose Template',
        icon: faEye,
        closeOnClick: true,
        onClick: ({ id }) => {
          navigate(SELECT_DOCUMENT(id, item.documentTemplate.id));
        },
      },
    ];
  }

  if (item.status === STATUSES.VOIDED) {
    return [
      {
        name: 'Un-Void',
        icon: faTimesCircle,
        content: UnVoidComponent,
      },
    ];
  }

  return [
    {
      name: 'Preview',
      icon: faEye,
      divider: true,
      closeOnClick: true,
      onClick: ({ id }) => {
        openDrawer(PREVIEW_DOCUMENT(id));
      },
      // shouldRender: (item) => {
      //   console.log(item)
      // }
    },
    {
      name: 'Continue Editing',
      closeOnClick: true,
      icon: faPen,
      color: 'orange',
      shouldRender: (item) => {
        let statusValue = getStatus(item);
        return statusValue !== 'Approved' && statusValue !== 'In Progress';
      },
      onClick: ({ id }) => {
        openDrawer(EDIT_DOCUMENT(id), routeParams['*']);
      },
    },
    {
      name: 'Upload PDF to Edit',
      icon: faFilePdf,
      color: 'orange',
      divider: true,
      content: UploadEditPdfComponent,
      shouldRender: (item) => {
        let statusValue = getStatus(item);
        return statusValue !== 'Approved' && statusValue !== 'In Progress';
      },
    },
    {
      name: 'Cancel E-sign',
      icon: faUndo,
      color: 'orange',
      content: CancelEsignComponent,
      shouldRender: (item) => {
        let statusValue = getStatus(item);
        return statusValue === 'In Progress';
      },
    },
    {
      name: 'Upload Completed PDF',
      icon: faCheckCircle,
      color: 'green',
      content: UploadCompletedPdfComponent,
      shouldRender: (item) => {
        let statusValue = getStatus(item);
        return statusValue !== 'Approved';
      },
    },
    {
      name: 'Void',
      icon: faTimesCircle,
      content: VoidComponent,
      divider: true,
      shouldRender: (item) => {
        let statusValue = getStatus(item);
        return statusValue !== 'Approved' && statusValue !== 'In Progress';
      },
    },
    {
      name: 'Mark as Revise',
      icon: faExclamationCircle,
      color: '#c9232d',
      content: ReviseComponent,
      shouldRender: (item) => {
        let statusValue = getStatus(item);
        return statusValue !== 'Approved' && statusValue !== 'In Progress';
      },
    },
    {
      name: 'Close Approve',
      icon: faCheckCircle,
      color: 'green',
      content: CloseApproveComponent,
      shouldRender: (item) => {
        let statusValue = getStatus(item);
        return statusValue !== 'Approved' && statusValue !== 'In Progress';
      },
    },
    {
      name: 'Un Approve',
      icon: faCheckCircle,
      color: 'green',
      content: UnApproveComponent,
      shouldRender: (item) => {
        let statusValue = getStatus(item);
        return statusValue === 'Approved' && statusValue !== 'In Progress';
      },
    },
    {
      name: 'Delete',
      icon: faMinusCircle,
      color: '#c9232d',
      content: DeleteComponent,
      shouldRender: (item) => {
        let statusValue = getStatus(item);
        return statusValue !== 'Approved' && statusValue !== 'In Progress';
      },
    },
  ];
};

export function EditDocumentButton({ item, setSelected }) {
  const menuItems = MenuItems(item);
  return (
    <span
      onClick={() => {
        setSelected(`document-${item.id}`);
      }}
    >
      <PopperButton size={'x-small'} menuItems={menuItems} item={item} />
    </span>
  );
}

EditDocumentButton.propTypes = {
  item: PropTypes.object.isRequired,
  setSelected: PropTypes.func.isRequired,
};
