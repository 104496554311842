import { SideDrawer } from 'components/Common';
import { omitNull } from 'helpers';

import { usePrevious } from 'hooks';
import PropTypes from 'prop-types';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGetSettingQuery, useUpdateSettingMutation } from 'redux/rtk-query';
import { defaultValues } from '../SettingForm/defaultValues';
import { processFormData } from '../SettingForm/formData';
import SettingForm from '../SettingForm/SettingForm';

export const EditSettingPage = ({ params, path }) => {
  const paramName = Object.keys(params)[0];

  const [initialValues, setInitialValues] = useState({});
  const [isOpen, setIsOpen] = useState(false);

  const [updateSetting] = useUpdateSettingMutation();
  const { data: settingData, isSuccess } = useGetSettingQuery({
    id: params[paramName],
  });

  const location = useLocation();
  const navigate = useNavigate();
  const prevIsOpen = usePrevious(isOpen);

  useEffect(() => {
    if (isSuccess) {
      let data = { ...settingData.data };
      data.agent = data.modelAccess?.owner;
      data.shareWithOption;
      data.shareWith = data.modelAccess?.sharedWithUsers;
      data.companyStructureUnits = data.modelAccess?.sharedWithUnits;
      setInitialValues({
        ...omitNull({ ...defaultValues }),
        ...omitNull(data),
      });
      setIsOpen(true);
    }
  }, [isSuccess]);

  const handleSubmitFunc = async (values) => {
    let formData = processFormData(values);
    const setting = await updateSetting(formData);
    return setting;
  };

  const afterSave = () => {
    setIsOpen(false);
  };

  useLayoutEffect(() => {
    if (prevIsOpen && !isOpen) {
      const pathArray = location.pathname.split('/');
      const pathIndex = pathArray.indexOf(path.split('/')[0]);
      const newPath = pathArray.slice(0, pathIndex).join('/');
      setTimeout(() => {
        navigate(newPath + (newPath[newPath.length - 1] === '/' ? '' : '/'));
      }, 500);
    }
  }, [isOpen]);

  return (
    <SideDrawer width={'65%'} isOpen={isOpen}>
      <SettingForm
        panelEdit={params['panelEdit']}
        title={'Edit Setting'}
        initialValues={initialValues}
        afterSave={afterSave}
        handleSubmitFunc={handleSubmitFunc}
      />
    </SideDrawer>
  );
};

EditSettingPage.propTypes = {
  params: PropTypes.object,
  path: PropTypes.string,
};
