import { Grid } from '@mui/material';

import { FormPanel } from 'components/Common';
import { Contact, PhoneNumber, Select, TextInput } from 'components/Fields';
import { FormCondition } from 'components/Forms';
import {
  CONTACT_TYPES,
  INTERNET_SOURCES,
  PREFERRED_COMMUNICATIONS,
  SOURCES,
} from 'consts/contacts';
import { getSelectOptions } from 'helpers/form';
import { useContactOptions } from 'hooks';
import PropTypes from 'prop-types';
import React from 'react';
import { Field, useForm } from 'react-final-form';
import { NameBox } from '../NameBox';
import { VendorField } from '../VendorField';

export const OverviewBox = ({ showAllFields }) => {
  const form = useForm();
  const getState = form.getState;

  const { typeOptions } = useContactOptions(form);

  return (
    <FormPanel label={'Overview'}>
      <Field
        name="type"
        label="Contact Type"
        options={typeOptions}
        component={Select}
      />
      <VendorField showAllFields={showAllFields} />
      {showAllFields || getState().values.type ? (
        <>
          <NameBox showAllFields={showAllFields} />
          <Field
            name="mobilePhone"
            label="Mobile Phone"
            component={PhoneNumber}
          />
          <Field
            name="email"
            label="Email"
            component={TextInput}
            // disabled={[CONTACT_TYPES.COMPANY_AGENT.value, CONTACT_TYPES.COMPANY_ADMIN.value, CONTACT_TYPES.COMPANY_REFERRAL_AGENT].includes(getState().values.type)}
          />
          <Field
            name="secondaryEmail"
            label="Secondary Email"
            component={TextInput}
          />
          <Grid container>
            <Grid item xs={6}>
              <Field
                name="source"
                label="Source"
                options={getSelectOptions(SOURCES)}
                component={Select}
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                name="communication"
                label="Preferred Communication"
                options={PREFERRED_COMMUNICATIONS}
                component={Select}
                multiple
              />
            </Grid>
            <Grid item xs={6}>
              <FormCondition
                when="source"
                operator="equals"
                is={[SOURCES.REFERRAL, SOURCES.OFFICE_REFERRAL]}
              >
                <Field
                  name="referralContact"
                  label="Referral Contact"
                  component={Contact}
                  contactType={[
                    CONTACT_TYPES.COMPANY_AGENT.value,
                    CONTACT_TYPES.COOPERATING_AGENT.value,
                    CONTACT_TYPES.COMPANY_REFERRAL_AGENT.value,
                  ]}
                />
              </FormCondition>
              <FormCondition when="source" operator="equals" is={'Internet'}>
                <Field
                  name="sourceWebsite"
                  label="Source Website"
                  component={Select}
                  options={INTERNET_SOURCES}
                />
              </FormCondition>
            </Grid>
            <Grid item xs={6}></Grid>
          </Grid>
        </>
      ) : null}
    </FormPanel>
  );
};

OverviewBox.propTypes = {
  showAllFields: PropTypes.bool,
};
