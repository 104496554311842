import { DocumentTreeItem } from "./DocumentTreeItem";
import React from "react";
import PropTypes from "prop-types";

export function FileTree({
  documentData,
  setSelected,
  onIconClick,
  expanded,
  skipTopLevel = false,
}) {
  function createTree(data, skipTopLevelCheck = false) {
    return (
      <span>
        {data.map(item => {
          if (skipTopLevelCheck) {
            if (item.children) {
              return (
                <React.Fragment key={`document-${item.id}`}>
                  {createTree(item.children)}
                </React.Fragment>
              );
            }
          } else {
            return (
              <DocumentTreeItem
                expanded={expanded.includes(`document-${item.id}`)}
                onIconClick={onIconClick}
                setSelected={setSelected}
                nodeId={`document-${item.id}`}
                key={`document-${item.id}`}
                item={item}
              >
                {item.children && createTree(item.children)}
              </DocumentTreeItem>
            );
          }
        })}
      </span>
    );
  }

  return createTree(documentData, skipTopLevel);
}

FileTree.propTypes = {
  setSelected: PropTypes.func.isRequired,
  onIconClick: PropTypes.func.isRequired,
  expanded: PropTypes.arrayOf(PropTypes.string).isRequired,
};
