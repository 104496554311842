import { Button } from '@mui/material';
import { useDrawerContext } from 'components/Common/SideDrawer/context/DrawerContext';
import { FieldAlert } from 'components/ModelViews/Documents/DocumentPdfViewer/components/FieldAlert/FieldAlert';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  activeMenuSelector,
  hasErrorSelector,
  signedStatusSelector,
} from 'redux/selectors';
import { saveRolesAndFinalize, saveRolesAndSync, setMenu } from 'redux/slices';

export function UpdateRolesButton() {
  const activeMenu = useSelector(activeMenuSelector);
  const signedStatus = useSelector(signedStatusSelector);

  const hasError = useSelector(hasErrorSelector);
  const dispatch = useDispatch();
  const { closeDrawer } = useDrawerContext();

  const disabledButton =
    (!signedStatus || hasError) && activeMenu === 'finalizeDocument';

  const title = !signedStatus
    ? 'Select if this document will be Partially or Fully signed'
    : hasError?.bannerMessage;
  return (
    <FieldAlert
      disableHoverListener={!disabledButton}
      placement={'top'}
      arrow
      title={title}
    >
      <Button
        size="medium"
        variant="outlined"
        style={{ marginRight: '3px' }}
        disabled={disabledButton}
        onClick={async () => {
          if (activeMenu === 'finalizeDocument') {
            dispatch(saveRolesAndFinalize());
            closeDrawer();
          } else {
            dispatch(saveRolesAndSync());
          }
          dispatch(setMenu('primary'));
        }}
      >
        <>
          {activeMenu === 'finalizeDocument'
            ? 'Add to Dashboard'
            : 'Update Roles'}
        </>
      </Button>
    </FieldAlert>
  );
}
