import { Stack } from '@mui/material';
import { Avatar } from 'components/Styled';
import { contactColors } from 'consts';
import GigiImage from 'images/gigi.png';
import PropTypes from 'prop-types';
import React from 'react';

export const ContactAvatar = ({ contactInfo }) => {
  if (contactInfo.firstName === 'Gigi') {
    return (
      <Avatar
        alt="User Image"
        sx={{
          width: 130,
          height: 130,
          backgroundColor: 'white',
          borderRadius: '100% 100% 100% 100%',
          border: '1px solid #d1d1d1',
        }}
        src={GigiImage}
      />
    );
  } else {
    let firstInitial = '';
    let lastInitial = '';
    if (contactInfo.lastName) {
      firstInitial = contactInfo.firstName?.charAt(0) || '';
      lastInitial = contactInfo.lastName.charAt(0);
    } else if (contactInfo.companyName) {
      firstInitial = contactInfo.companyName.charAt(0);
    }
    return (
      <Stack p={2} direction="row" spacing={2} justifyContent={'center'}>
        <Avatar sx={{ bgcolor: contactColors[contactInfo.type] }}>
          {firstInitial + lastInitial}
        </Avatar>
      </Stack>
    );
  }
};

ContactAvatar.propTypes = {
  contactInfo: PropTypes.object,
};
