import { faColumns } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MenuItem, Switch, Tooltip } from '@mui/material';
import { MenuButton } from 'components/Common';
import PropTypes from 'prop-types';
import React from 'react';

// eslint-disable-next-line no-unused-vars
export const ColumnManager = ({ columns, setColumns, resetColumns }) => {
  const handleToggleColumn = (columnName) => {
    setColumns(columnName);
  };

  return (
    <MenuButton
      size={'x-small'}
      title={
        <Tooltip title={'Columns'}>
          <FontAwesomeIcon size={'lg'} icon={faColumns} />
        </Tooltip>
      }
    >
      {columns.map((col) => (
        <MenuItem key={col.id} disabled={col.static}>
          <Switch
            size="small"
            edge="start"
            checked={col.isVisible}
            onChange={() => handleToggleColumn(col.id)}
            name={col.label}
          />
          {col.label}
        </MenuItem>
      ))}
    </MenuButton>
  );
};

ColumnManager.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      isVisible: PropTypes.bool.isRequired,
    }),
  ).isRequired,
  setColumns: PropTypes.func.isRequired,
  resetColumns: PropTypes.func.isRequired,
};
