import queryString from 'query-string';
import { endpoints } from 'consts';

export const reportUrlBuilder = {
  createReport: function () {
    return `${endpoints.reports}`;
  },
  updateReport: function ({ id }) {
    return `${endpoints.reports}/${id}`;
  },
  createFilters: function ({ reportId }) {
    return `${endpoints.reports}/${reportId}/filters`;
  },
  getFilters: function (id) {
    return `${endpoints.reports}/${id}/filters`;
  },
  getReportModules: function () {
    return `${endpoints.reports}/modules-info`;
  },
  getReport: function (id) {
    return `${endpoints.reports}/${id}`;
  },
  getList: function (params) {
    const urlParams = queryString.stringify(params, { skipNull: true });
    return `${endpoints.reports}?${urlParams}`;
  },
};
