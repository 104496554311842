import { ADMIN_ROUTES } from 'consts';
import { omitNull } from 'helpers';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useEditUserMutation, useGetUserQuery } from 'redux/rtk-query';

import UserForm, { defaultValues } from '../UserForm';

export const EditUserPage = () => {
  const [initialValues, setInitialValues] = useState(defaultValues);
  let { userId } = useParams();
  const navigate = useNavigate();
  const { data } = useGetUserQuery(userId);
  const user = useMemo(() => data?.data ?? {}, [data]);
  const [editUser, { isSuccess, isLoading }] = useEditUserMutation();

  useEffect(() => {
    if (user?.data) {
      let userData = { ...user.data };
      userData.roles = userData.roles.map((role) => ({
        id: role.id,
        value: role.id,
        label: role.name,
      }));
      userData.groups = userData.groups.map((group) => ({
        id: group.id,
        value: group.id,
        label: group.name,
      }));
      userData.companyStructureUnits = userData.companyStructureUnits.map(
        (unit) => ({ id: unit.id, value: unit.id, label: unit.name }),
      );
      setInitialValues({
        ...omitNull({ ...defaultValues }),
        ...omitNull(userData),
      });
    }
  }, [user]);

  const onSubmit = ({
    contact,
    setting,
    roles,
    groups,
    companyStructureUnits,
    language,
    locale,
    timezone,
    currency,
    ...values
  }) => {
    editUser({
      ...values,
      userId: values.id,
      roleIds: roles.map((role) => (role.value ? role.value : role.id)),
      groupIds: groups.map((group) => (group.value ? group.value : group.id)),
      companyStructureUnitsIds: companyStructureUnits.map((unit) =>
        unit.value ? unit.value : unit.id,
      ),
      contactId: contact ? contact.id : null,
      settingId: setting ? setting.id : null,
      languageId: language ? language.id : null,
      localeId: locale ? locale.id : null,
      timezoneId: timezone ? timezone.id : null,
      currencyId: currency ? currency.id : null,
    });
  };

  const afterSave = () => {
    navigate(ADMIN_ROUTES.USERS.path);
  };

  return (
    <UserForm
      initialValues={initialValues}
      handleSubmit={onSubmit}
      title={'Edit User'}
      isLoading={isLoading}
      isSuccess={isSuccess}
      afterSave={afterSave}
    />
  );
};
