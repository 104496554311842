import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { Typography, Box } from '@mui/material';

export const Path = ({ name }) => (
  <Box display={'block'}>
    <Field name={`${name}.label`}>
      {({ input }) => (
        <Typography sx={{ marginRight: 2, minWidth: 150 }}>
          {Array.isArray(input.value) ? input.value.join(' -> ') : input.value}
        </Typography>
      )}
    </Field>
  </Box>
);

Path.propTypes = {
  name: PropTypes.string.isRequired,
};
