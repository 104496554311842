import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { Box, Button, FormControl } from '@mui/material';
import { FontAwesomeIconComp, LabeledOutline } from 'components/Common';
import { BoxInput, NoResults } from 'components/Styled';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { ReactSortable } from 'react-sortablejs';
import { ProfileBulletListItem } from './ProfileBulletListItem';

export const ProfileBulletList = ({ input, label }) => {
  const [profileBulletLists, setProfileBulletLists] = useState(
    input.value || [],
  );

  const addNewProfileBulletList = () => {
    let profileBulletListsCopy = [...profileBulletLists];
    let newProfileBulletList = { label: '', id: `temp-${Date.now()}` };
    profileBulletListsCopy.push(newProfileBulletList);
    setProfileBulletLists(profileBulletListsCopy);
  };

  const handleInputChange = (newValues) => {
    setProfileBulletLists(newValues);
    input.onChange(newValues);
  };

  return (
    <BoxInput>
      <LabeledOutline label={label}>
        <Box px={2}>
          <FormControl fullWidth>
            <div>
              {profileBulletLists.length ? (
                <>
                  <ReactSortable
                    list={profileBulletLists.map((profileBulletList) =>
                      JSON.parse(JSON.stringify(profileBulletList)),
                    )}
                    setList={(sortableState) => {
                      handleInputChange(sortableState);
                    }}
                  >
                    {profileBulletLists.map((profileBulletList) => (
                      <ProfileBulletListItem
                        key={`item-${profileBulletList.id}`}
                        setProfileBulletLists={handleInputChange}
                        profileBulletLists={profileBulletLists}
                        profileBulletList={profileBulletList}
                      />
                    ))}
                  </ReactSortable>
                  <Button
                    size={'small'}
                    endIcon={<FontAwesomeIconComp icon={faPlus} />}
                    onClick={addNewProfileBulletList}
                  >
                    Add {label}
                  </Button>
                </>
              ) : (
                <NoResults>
                  <Button
                    size={'small'}
                    endIcon={<FontAwesomeIconComp icon={faPlus} />}
                    onClick={addNewProfileBulletList}
                  >
                    Add New
                  </Button>
                </NoResults>
              )}
            </div>
          </FormControl>
        </Box>
      </LabeledOutline>
    </BoxInput>
  );
};

ProfileBulletList.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
};
