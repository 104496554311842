export const TASK_STATUSES = {
  NEW: "New",
  IN_PROGRESS: "In Progress",
  REVISE: "Revise",
  COMPLETED: "Completed",
  CANCELLED: "Cancelled",

  // {
  //   value: "New",
  //   label: "New",
  // },
  // {
  //   value: "In Progress",
  //   label: "In Progress",
  // },
  // {
  //   value: "Revise",
  //   label: "Revise",
  // },
  // {
  //   value: "Completed",
  //   label: "Completed",
  // },
  // {
  //   value: "Cancelled",
  //   label: "Cancelled",
  // },
};

export const TIMEFRAME_TYPE_OPTIONS = {
  CUSTOM: "*Custom",
  EXPECTED_CLOSING: "Expected Closing",
  LISTING_EXPIRATION: "Listing Expiration",
  ESCROW: "Escrow Deadline",
  ADDITIONAL_ESCROW: "Additional Escrow Deadline",
  LOAN_APPLICATION: "Loan Application Deadline",
  LOAN_APPROVAL: "Loan Approval Deadline",
  APPRAISAL: "Appraisal Deadline",
  ATTORNEY_APPROVAL: "Attorney Approval Deadline",
  SALE_OF_BUYERS_PROPERTY: "Sale of Buyer’s Property Deadline",
  INSPECTION: "Inspection Deadline",
  ADDITIONAL_INSPECTION: "Additional Inspection Deadline",
  LEAD_BASED_PAINT: "Lead-Based Paint Deadline",
  TITLE_EVIDENCE: "Title Evidence Deadline",
  TITLE_EXAMINATION: "Title Examination Deadline",
  SURVEY: "Survey Deadline",
  FLOOD_ZONE: "Flood Zone Deadline",
  WALKTHROUGH: "Walkthrough",
};

export const timeframeOptionsArray = Object.entries(TIMEFRAME_TYPE_OPTIONS).map(
  ([, value]) => ({
    value: value,
    label: value,
  }),
);
