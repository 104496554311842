import React from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";

export const ConfirmDialog = ({
  open,
  setOpen,
  onConfirm,
  title,
  message,
  cancelButton,
  confirmButton,
}) => {
  const handleClose = () => setOpen(false);
  const handleConfirm = () => {
    handleClose();
    onConfirm();
  };

  return (
    <Dialog open={open} maxWidth="sm" fullWidth onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Typography>{message}</Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          {cancelButton}
        </Button>
        <Button color="error" variant="contained" onClick={handleConfirm}>
          {confirmButton}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmDialog.defaultProps = {
  title: "Confirm the action",
  message: "Are you sure?",
  cancelButton: "Cancel",
  confirmButton: "Confirm",
};

ConfirmDialog.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  message: PropTypes.string,
  cancelButton: PropTypes.string,
  confirmButton: PropTypes.string,
  setOpen: PropTypes.func,
  onConfirm: PropTypes.func,
};
