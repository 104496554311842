import {
  faExclamationCircle,
  faQuestionCircle,
} from '@fortawesome/pro-regular-svg-icons';
import { Grid, Tooltip } from '@mui/material';
import { FontAwesomeIconComp } from 'components/Common';
import {
  AttributeValue,
  PanelLabel,
} from 'components/Common/DetailsPanels/styled';
import { colors } from 'consts';
import { conversion } from 'helpers';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useGetMlsListingQuery, useGetParcelQuery } from 'redux/rtk-query';

export const BedBathSqftYearBuilt = ({ transactionInfo }) => {
  const [mlsData, setMlsData] = useState(null);
  const [parcelData, setParcelData] = useState(null);

  const mlsQuery = useGetMlsListingQuery(
    { id: transactionInfo.mlsListing },
    { skip: typeof transactionInfo.mlsListing !== 'string' },
  );
  const parcelQuery = useGetParcelQuery(
    { hjid: transactionInfo.parcel },
    { skip: typeof transactionInfo.parcel !== 'string' },
  );

  useEffect(() => {
    if (mlsQuery.isSuccess) setMlsData(mlsQuery.data.data);
    if (parcelQuery.isSuccess) setParcelData(parcelQuery.data.data);
  }, [
    mlsQuery.isSuccess,
    parcelQuery.isSuccess,
    mlsQuery.data,
    parcelQuery.data,
  ]);

  const calculateBaths = (bathFull, bathHalf) =>
    parseInt(bathFull) + parseInt(bathHalf) * 0.5;

  const renderAttributeValue = (mlsValue, parcelValue) => {
    if (!mlsValue && !parcelValue)
      return (
        <Tooltip title={'No data available'}>
          <AttributeValue>
            <FontAwesomeIconComp icon={faQuestionCircle} color={colors.RED} />
          </AttributeValue>
        </Tooltip>
      );
    if (mlsValue && parcelValue && mlsValue !== parcelValue) {
      return (
        <Tooltip
          title={
            <div>
              <strong>MLS: {mlsValue}</strong>
              <br />
              <strong>Parcel: {parcelValue}</strong>
              <br />
              Perform due diligence to ensure that you understand the reason for
              this inconsistency.
            </div>
          }
        >
          <AttributeValue>
            <FontAwesomeIconComp
              icon={faExclamationCircle}
              color={colors.RED}
            />
          </AttributeValue>
        </Tooltip>
      );
    }
    return <AttributeValue>{mlsValue || parcelValue}</AttributeValue>;
  };

  return (
    <Grid px={2} py={1} container>
      <Grid item xs={3}>
        <PanelLabel>Beds</PanelLabel>
        {renderAttributeValue(mlsData?.beds, parcelData?.beds)}
      </Grid>
      <Grid item xs={3}>
        <PanelLabel>Baths</PanelLabel>
        {renderAttributeValue(
          mlsData ? calculateBaths(mlsData.bathFull, mlsData.bathHalf) : null,
          parcelData
            ? calculateBaths(parcelData.baths?.full, parcelData.baths?.half)
            : null,
        )}
      </Grid>
      <Grid item xs={3} textAlign="right">
        <PanelLabel>Built</PanelLabel>
        {renderAttributeValue(mlsData?.yearBuilt, parcelData?.yearBuilt)}
      </Grid>
      <Grid item xs={3} textAlign="right">
        <PanelLabel>Sqft</PanelLabel>
        {renderAttributeValue(
          conversion.formatNumberWithCommas(mlsData?.size),
          conversion.formatNumberWithCommas(parcelData?.size),
        )}
      </Grid>
    </Grid>
  );
};

BedBathSqftYearBuilt.propTypes = {
  transactionInfo: PropTypes.object.isRequired,
};

export default BedBathSqftYearBuilt;
