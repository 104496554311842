import React from 'react';
import PropTypes from 'prop-types';
import { ConfirmMenuItem } from "components/Common";
import { useUpdateDocumentMutation } from "redux/rtk-query";
import { STATUSES } from "consts";
import { TextField } from "@mui/material";

export const ReviseComponent = ({ onBackToMenu, handleCloseMenu, item }) => {
  const [message, setMessage] = React.useState('');
  const [updateDocument] = useUpdateDocumentMutation();

  const onConfirm = () => {
    updateDocument({ id: item.id, status: STATUSES.REVISE });
    handleCloseMenu(true);
  };

  return (
    <ConfirmMenuItem
      title="Mark as Revise"
      message={
        <>
          Are you sure you want to mark this document as Revise?
          <br />
          <br />
          <TextField
            autoFocus
            label="Message"
            fullWidth
            multiline
            rows={4}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </>
      }
      onConfirm={onConfirm}
      onCancel={onBackToMenu}
      confirmText={'Confirm'}
      confirmColor={'error'}
      cancelText={'Go Back'}
    />
  );
};

ReviseComponent.propTypes = {
  onBackToMenu: PropTypes.func,
  handleCloseMenu: PropTypes.func,
  item: PropTypes.object,
};
