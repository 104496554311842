import React from 'react';
import PropTypes from 'prop-types';
import { faCircleInfo, faSidebar } from '@fortawesome/pro-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/system';
import { DETAILS_DOCUMENT_TEMPLATE, EDIT_DOCUMENT_TEMPLATE } from 'consts';
import { columnTypes } from 'consts/columnTypes';
import { CM_ITEM_TYPES, ASPECT_RATIO } from 'consts/documentTemplate';
import { getSelectOptions } from 'helpers/form';

const DocumentTemplateName = ({ value }) => {
  return value ? (
    <Box>
      <Box component={'span'} pr={1}>
        {value.name}
      </Box>
    </Box>
  ) : (
    <></>
  );
};

DocumentTemplateName.propTypes = {
  value: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    children: PropTypes.array,
    type: PropTypes.string,
    aliasDocument: PropTypes.object,
  }),
};

const AspectRatio = ({ value }) => {
  return value ? (
    <Box>
      <Box component={'span'} pr={1}>
        {ASPECT_RATIO.find((ar) => ar.value === value.aspectRatio)?.label}
      </Box>
    </Box>
  ) : (
    <></>
  );
};

AspectRatio.propTypes = {
  value: PropTypes.shape({
    aspectRatio: PropTypes.string,
  }),
};

export const Cells = [
  {
    id: 'name',
    label: 'Name',
    value: ['name'],
    numeric: false,
    disablePadding: true,
    static: true,
    Wrapper: DocumentTemplateName,
  },
  {
    id: 'cmBlockType',
    value: ['cmBlockType'],
    label: 'cmBlockType',
    filterable: true,
    filterType: columnTypes.DROP_DOWN,
    filterOptions: getSelectOptions(CM_ITEM_TYPES.modular),
    disablePadding: true,
  },
  {
    id: 'aspectRatio',
    value: ['aspectRatio'],
    label: 'aspectRatio',
    filterable: true,
    filterType: columnTypes.DROP_DOWN,
    filterOptions: ASPECT_RATIO,
    disablePadding: true,
    Wrapper: AspectRatio,
  },
];

export const MenuItems = () => {
  let navigate = useNavigate();

  return [
    {
      name: 'Edit',
      icon: faSidebar,
      closeOnClick: true,
      onClick: (row) => {
        navigate(EDIT_DOCUMENT_TEMPLATE(row.id));
      },
    },
    {
      name: 'Details',
      icon: faCircleInfo,
      closeOnClick: true,
      onClick: (row) => {
        navigate(DETAILS_DOCUMENT_TEMPLATE(row.id));
      },
    },
  ];
};
