import React, {useEffect, useState} from 'react';
import {Tooltip} from "@mui/material";
import PropTypes from "prop-types";
import {colors} from "consts";

export const DocumentProgress = ({documentProgress}) => {

    const [totalDocuments, setTotalDocuments] = useState(0);

    useEffect(() => {
        let statuses = Object.keys(documentProgress)
        let totalDocuments = 0;
        for (let i = 0; i < statuses.length; i++) {
            totalDocuments += parseInt(documentProgress[statuses[i]])
        }
        setTotalDocuments(totalDocuments)
    }, [0]);

    const getBgGradient = () => {
        let draftPercent = (documentProgress['Draft']/totalDocuments) * 100;
        draftPercent = Math.round(draftPercent);

        let revisePercent = (documentProgress['Revise']/totalDocuments) * 100;
        revisePercent = Math.round(revisePercent);

        let inProgressPercent = (documentProgress['In Progress']/totalDocuments) * 100;
        inProgressPercent = Math.round(inProgressPercent);

        let completedPercent = (documentProgress['Completed']/totalDocuments) * 100;
        completedPercent = Math.round(completedPercent);

        let totalPercent = draftPercent + revisePercent + inProgressPercent + completedPercent;
        if(totalPercent !== 100){
            let diff = totalPercent - 100;
            completedPercent = completedPercent - diff;
        }

        let draftDiv = <Tooltip placement={"top"} arrow title={documentProgress['Draft'] + " Draft"}>
            <div style={{display: "inline-block", height: "100%", width:`${draftPercent}%`, backgroundColor: colors.LIGHT_GREY}} />
        </Tooltip>
        let reviseDiv = <Tooltip placement={"top"} arrow title={documentProgress['Revise'] + " Revise"}>
            <div style={{display: "inline-block", height: "100%", width:`${revisePercent}%`, backgroundColor: colors.RED}} />
        </Tooltip>
        let inProgressDiv = <Tooltip placement={"top"} arrow title={documentProgress['In Progress'] + " In Progress"}>
            <div style={{display: "inline-block",height: "100%", width:`${inProgressPercent}%`, backgroundColor: colors.LIGHT_ORANGE}} />
        </Tooltip>
        let completedDiv = <Tooltip placement={"top"} arrow title={documentProgress['Completed'] + " Completed"}>
            <div style={{display: "inline-block",height: "100%", width:`${completedPercent}%`, backgroundColor: colors.GREEN}} />
        </Tooltip>

        return <>
            {completedDiv}
            {inProgressDiv}
            {reviseDiv}
            {draftDiv}
        </>
    }


    return (
        <>
            {totalDocuments > 0 && <div
                style={{
                    borderRadius: 10,
                    height: 6,
                    width: "100%",
                    overflow: "hidden",
                    display: "flex"
                }}
            >
                {getBgGradient()}
            </div>}
        </>
    );
}

DocumentProgress.propTypes = {
    documentProgress: PropTypes.object.isRequired,
}
