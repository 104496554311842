import {
  useGetInvoiceTemplateMediaUrlQuery,
  useGetSettingMediaUrlQuery,
} from 'redux/rtk-query';

const useGetMediaUrl = (file, identifier, identifierValue) => {
  if(!file) {
    return { data: null };
  }
  const destination = file.destination;

  if (destination === 'invoiceTemplate') {
    return useGetInvoiceTemplateMediaUrlQuery({
      [identifier]: identifierValue,
      uuid: file?.uuid,
    });
  } else if (destination === 'setting') {
    return useGetSettingMediaUrlQuery({
      [identifier]: identifierValue,
      uuid: file?.uuid,
    });
  }

  return { data: null };
};

export default useGetMediaUrl;
