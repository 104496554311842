import { Autocomplete } from 'components/Fields/Autocomplete';
import { useLoadMoreOptions } from 'hooks';
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';
import { useGetTagsQuery } from 'redux/rtk-query';

export const TagsAutocomplete = (props) => {
  const tags = useLoadMoreOptions(useGetTagsQuery);

  return (
    <Field
      {...props}
      size={'small'}
      creatable
      freeSolo
      options={tags.options}
      component={Autocomplete}
      onOpen={tags.handleOpen}
      loadMoreResults={tags.handleMore}
      isLoading={tags.isFetching}
      handleSearch={tags.handleSearch}
    />
  );
};

TagsAutocomplete.defaultProps = {
  freeSolo: false,
  onOpen: null,
  isLoading: false,
};

TagsAutocomplete.propTypes = {
  label: PropTypes.string,
  size: PropTypes.string,
  freeSolo: PropTypes.bool,
  isLoading: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    }),
  ),
  iconStart: PropTypes.node,
  input: PropTypes.object,
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
  }),
  loadMoreResults: PropTypes.func,
  onOpen: PropTypes.func,
  handleSearch: PropTypes.func,
};
