import { Box } from '@mui/material';
import { Checkbox, EmptyResults, SkeletonSummary } from 'components/Common';
import { ToolbarRow } from 'components/Common/Table/TableToolbar/styled';
import { SearchInput } from 'components/SearchInput';
import { useDebounce } from 'hooks';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useGetListsQuery } from 'redux/rtk-query';

import { Lists, ListsItems, ListsItemsText, PrimaryTextStyles } from './styled';

export const ListAndFilter = ({ checkListHandler, checkedLists }) => {
  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(true);
  const [allLists, setAllLists] = useState([]);
  const debouncedValue = useDebounce(searchValue, 500);
  const { data, isSuccess, isFetching } = useGetListsQuery({
    page: 1,
    limit: 15,
    search: debouncedValue,
    justFavorite: 0,
    skipFavorite: 0,
  });

  useEffect(() => {
    if (isFetching) {
      setLoading(true);
    }
  }, [isFetching]);

  useEffect(() => {
    if (isSuccess) {
      setAllLists(data?.data?.items);
      setLoading(false);
    }
  }, [isSuccess]);

  return (
    <>
      <ToolbarRow>
        <SearchInput changeHandler={setSearchValue} />
      </ToolbarRow>
      <Lists>
        {loading ? (
          <SkeletonSummary />
        ) : (
          <>
            {allLists.length > 0 ? (
              allLists.map((list) => (
                <ListsItems disablePadding key={list.id}>
                  <Box>
                    <Checkbox
                      onClick={checkListHandler(list.id)}
                      checked={checkedLists.includes(list.id)}
                    />
                  </Box>
                  <ListsItemsText
                    primary={list.name}
                    primaryTypographyProps={PrimaryTextStyles}
                  />
                </ListsItems>
              ))
            ) : (
              <EmptyResults label={'lists'} />
            )}
          </>
        )}
      </Lists>
    </>
  );
};

ListAndFilter.propTypes = {
  AllLists: PropTypes.array,
  checkListHandler: PropTypes.func,
  checkedLists: PropTypes.array,
};
