import { endpoints } from "consts";

export const commissionUrlBuilder = {
  getList: function ({ page = 1, limit = 15, search } = {}) {
    return `${endpoints.commissions}?page=${page}&limit=${limit}${
      search ? `&search=${search}` : ""
    }`;
  },
  createCommission: function () {
    return endpoints.commissions;
  },
  getCommission: ({ id}) => {
    return `${endpoints.commissions}/${id}/details`;
  },
};
