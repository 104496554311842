import React from "react";
import PropTypes from "prop-types";
import { Button, Typography } from "@mui/material";
import { FontAwesomeIconComp } from "../Common";
import {
  faChevronDoubleLeft,
  faChevronDoubleRight,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/pro-light-svg-icons";

export const ListPagination = ({
  totalCount,
  page,
  rowsPerPage,
  handleChangePage,
  hideText = false,
}) => {
  const lastPage = () => {
    let number = totalCount / rowsPerPage;
    if (totalCount % rowsPerPage === 0) {
      return number;
    }
    return Math.ceil(totalCount / rowsPerPage);
  };

  const getText = () => {
    let pageNumber = page;
    let firstItem = pageNumber > 1 ? (pageNumber - 1) * rowsPerPage : 1;
    let secondItem = pageNumber * rowsPerPage;
    secondItem = secondItem > totalCount ? totalCount : secondItem;

    return `${firstItem}-${secondItem} of ${totalCount}`;
  };
  return (
    <>
      {totalCount / rowsPerPage > 1 && (
        <>
          <Button
            size="x-small"
            disabled={page === 1}
            onClick={() => {
              handleChangePage(1);
            }}
          >
            <FontAwesomeIconComp icon={faChevronDoubleLeft} />
          </Button>
          <Button
            size="x-small"
            disabled={page === 1}
            onClick={() => {
              let nextPage = page - 1;
              handleChangePage(nextPage);
            }}
          >
            <FontAwesomeIconComp icon={faChevronLeft} />
          </Button>
          {!hideText &&
            <div style={{ display: 'inline-block', margin: '0 20px' }}>
              <Typography fontSize={12}>{getText()}</Typography>
            </div>
          }
          <Button
            disabled={page === lastPage()}
            size="x-small"
            onClick={() => {
              let nextPage = page + 1;
              handleChangePage(nextPage);
            }}
          >
            <FontAwesomeIconComp icon={faChevronRight} />
          </Button>
          <Button
            size="x-small"
            disabled={page === lastPage()}
            onClick={() => {
              handleChangePage(lastPage());
            }}
          >
            <FontAwesomeIconComp icon={faChevronDoubleRight} />
          </Button>
        </>
      )}
    </>
  );
};

ListPagination.propTypes = {
  totalCount: PropTypes.number,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  handleChangePage: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
  hideText: PropTypes.bool,
};
