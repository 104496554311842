import {RIGHT_OPTIONS} from "consts/rightsPermissions";

export const defaultValues = {
    description: "",

    files: [],
    companyStructureUnits: [],
    shareWithOption: RIGHT_OPTIONS.agentOnly.value,
    modelAccess: {
        shareWithOption: RIGHT_OPTIONS.agentOnly.value,
        sharedWithUsers: [],
        sharedWithUnits: [],
        owner: null,
        everyone: false,
    }
}