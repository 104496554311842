import { Grid } from '@mui/material';
import { FormPanel } from 'components/Common';
import { CurrencyInput, NumberInput, TextInput } from 'components/Fields';
import { FormCondition } from 'components/Forms';
import { STATUS_OPTIONS } from 'consts';
import React from 'react';
import { Field, useForm } from 'react-final-form';

export const BuyerAgentCommissionPanel = () => {
  const form = useForm();

  return (
    <FormPanel
      label={`${
        form.getState().values.representing === 'Seller' ? 'Buyer' : 'Tenant'
      } Agent Commission`}
    >
      <Grid container>
        <FormCondition
          when="status"
          operator="doesNotEqual"
          is={STATUS_OPTIONS.REFERRAL.value}
        >
          <Grid item xs={6} style={{ paddingRight: 20 }}>
            <Field
              label="Commission"
              name={'buyerCommission'}
              iconEnd={<>%</>}
              textAlign={'right'}
              component={NumberInput}
              numberType={'decimal'}
              min={0}
              max={100}
              tooltip={{
                title:
                  'Enter the commission percentage for the other side of this file.',
              }}
            />
          </Grid>
        </FormCondition>
        <Grid item xs={6} style={{ paddingLeft: 20 }}>
          <Field
            label="Bonus Or Flat Rate"
            name={'buyerBonusOrFlatRate'}
            component={CurrencyInput}
            tooltip={{
              title:
                'Enter a flat dollar amount for the commission or any bonuses/deductions that affect the percentage commission to the left.',
            }}
          />
        </Grid>
      </Grid>
      <Grid spacing={1} container>
        <Field
          label="Commission Details"
          name={'buyerCommissionDescription'}
          component={TextInput}
          tooltip={{
            title: 'Enter the description or terms of the commission.',
          }}
        />
      </Grid>
    </FormPanel>
  );
};
