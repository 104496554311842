/* eslint-disable no-dupe-keys */
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';

export const HeadingSection = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== 'shadow' && prop !== 'backgroundColor' && prop !== 'noborder',
})(({ noborder = 'false', backgroundColor = null, shadow = false }) => ({
  padding: '12px 16px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderBottom: noborder === 'false' ? '1px solid #E8E8E8' : 'none',
  gap: 20,
  backgroundColor: backgroundColor,
  boxShadow: shadow ? 'rgba(0, 0, 0, 0.9) 3px 2px 10px' : 'none',
  zIndex: 1,
}));

export const HeadingSectionStack = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
});

export const HeaderSubtitle = styled('div')({
  fontSize: 13,
  fontWeight: 400,
  color: '#565656',
  padding: '6px 0',
  paddingBottom: 0,
});

export const Header = styled(Typography)({
  fontWeight: '500',
  fontSize: '20px',
  lineHeight: '28px',
  textAlign: 'center',
  display: 'inline',
});
