import { Box } from "@mui/system";
import React from "react";
import PropTypes from "prop-types";

import {ButtonSection, LayoutHeaderWrapper, PageName,} from "components/Styled";
import {Button} from "@mui/material";

export const LayoutHeader = ({
  title,
  afterTitle,
  nameButton = "Save",
  afterSave,
  isLoading,
  children,
}) => (
  <LayoutHeaderWrapper>
    <Box display={"flex"}>
      <PageName variant="h3">{title}</PageName>
      {afterTitle}
    </Box>
    <ButtonSection>
      {children}
      {
          afterSave && (<>
            <Button variant="outlined" onClick={afterSave}>
              Cancel
            </Button>
            <Button variant="contained" type="submit" disabled={isLoading}>
              {nameButton}
            </Button>
          </>)
      }
    </ButtonSection>
  </LayoutHeaderWrapper>
);

LayoutHeader.defaultProps = {
  nameButton: "Save",
};

LayoutHeader.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  afterTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  nameButton: PropTypes.string,
  afterSave: PropTypes.func,
  dirty: PropTypes.bool,
  isLoading: PropTypes.bool,
  isSuccess: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
