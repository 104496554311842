import { styled } from "@mui/system";
import { Container, Box, Link, Typography } from "@mui/material";

export const Main = styled(Container)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  backgroundColor: "#F7F7F7",
});

export const Footer = styled(Container)({
  display: "flex",
  justifyContent: "center",
});

export const FooterContent = styled(Box)({
  padding: "8px",
  backgroundColor: "#fff",
  textAlign: "center",
  width: "100%",
  position: "fixed",
  bottom: "0",
});

export const FooterText = styled(Box)({
  fontSize: "16px",
  fontWeight: "400",
  lineHeight: "24px",
});

export const FooterLink = styled(Link)({
  fontSize: "16px",
  "&:hover": {
    color: "#0c7bc7",
  },
});

export const ImageBox = styled(Box)({
  height: "472px",
  width: "551px",
});

export const HeaderBox = styled(Typography)({
  fontWeight: "500",
  fontSize: "48px",
  lineHeight: "60px",
  textAlign: "center",
  marginBottom: "20px",
});

export const HeaderText = styled(Typography)({
  fontWeight: "500",
  fontSize: "20px",
  lineHeight: "28px",
  textAlign: "center",
  marginBottom: "16px",
});

export const ContentWrapper = styled(Box)({
  display: "flex",
  width: "100%",
  justifyContent: "center",
  alignItems: "flex-end",
});

export const LogoAndFormWrapper = styled(Box)({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  marginRight: "121px",
});
