import { Box } from '@mui/system';
import { partyTypeCount } from 'components/ModelViews/Documents/DocumentPdfViewer/utils/roles';
import React from 'react';
import { useSelector } from 'react-redux';
import { activeMenuSelector, roleSelector } from 'redux/selectors';
import { PartiesList } from './';

export function RolesSections() {
  const documentRoles = useSelector(roleSelector);
  const activeMenu = useSelector(activeMenuSelector);

  console.log(documentRoles);
  const showUploadList = partyTypeCount('signer', documentRoles) === 0;
  const showSignerList =
    partyTypeCount('uploader', documentRoles) === 0 &&
    activeMenu !== 'finalizeDocument';

  return (
    <Box
      sx={{
        flexGrow: 1,
        overflowY: 'auto',
        height: 'calc(100% - 110px)',
      }}
    >
      {showSignerList && <PartiesList type={'signer'} />}
      <PartiesList type={'cc'} />
      <PartiesList
        type={'inactive'}
        collapseList={activeMenu === 'finalizeDocument'}
      />
      {showUploadList && <PartiesList type={'uploader'} />}
    </Box>
  );
}
