import { createSelector } from '@reduxjs/toolkit';
import { getPixelSize } from 'helpers/clickMarketing';

export const currentDocumentSelectorCM = (state) =>
  state.clickMarketingReducer.currentDocument;
export const pixelSizeSelector = createSelector(
  [currentDocumentSelectorCM],
  (currentDocument) => getPixelSize(currentDocument),
);
export const isTemplateViewSelectorCM = (state) =>
  state.clickMarketingReducer.templateView;
export const htmlTemplateSelectorCM = (state) =>
  state.clickMarketingReducer.currentDocument.htmlTemplate;
export const activePageCM = (state) => state.clickMarketingReducer.activePage;
export const signedStatusSelectorCM = (state) =>
  state.clickMarketingReducer.signedStatus;
export const autofillDataSelectorCM = (state) =>
  state.clickMarketingReducer.autofillData;
export const activeFieldIdsSelectorCM = (state) =>
  state.clickMarketingReducer.activeFieldIds;
export const scaleSelectorCM = (state) => state.clickMarketingReducer.scale;
export const roleSelectorCM = (state) =>
  state.clickMarketingReducer.currentDocument.roles;
export const fieldTemplateSelectorCM = (state) =>
  state.clickMarketingReducer.fieldsTemplates;
export const placingItemSelectorCM = (state) =>
  state.clickMarketingReducer.placingItem;
export const activeMenuSelectorCM = (state) =>
  state.clickMarketingReducer.activeMenu;
export const hasErrorSelectorCM = (state) => {
  return state.clickMarketingReducer.bannerMessages.find(
    (message) => message.severity === 'error',
  );
};
export const bannerMessagesSelectorCM = (state) =>
  state.clickMarketingReducer.bannerMessages;
export const isPreviewModeSelectorCM = (state) => {
  return state.clickMarketingReducer.bannerMessages.find(
    (message) =>
      message.severity === 'info' && message.bannerMessage === 'Preview Mode',
  );
};
export const hasUndoHistorySelectorCM = (state) =>
  state.clickMarketingReducer.undoStack.length > 0;
export const hasRedoHistorySelectorCM = (state) =>
  state.clickMarketingReducer.redoStack.length > 0;
