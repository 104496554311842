import * as React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';

export function MenuButton({
  title,
  size = 'small',
  children,
  closeOnClick,
  ...props
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const childrenWithHandleClose = React.Children.map(children, (child) => {
    return React.cloneElement(child, { onBackToMenu: handleClose });
  });

  return (
    <Box sx={{ display: 'inline-block' }}>
      <Button
        size={size}
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        {...props}
      >
        {title}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={closeOnClick ? handleClose : undefined}
        sx={{
          '& .MuiPaper-root': { padding: 1, mt: 1 },
          '& .MuiPaper-root .MuiList-root': { padding: 0 },
        }}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        PaperProps={{
          elevation: 4,
          sx: {
            borderRadius: '10px',
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {childrenWithHandleClose}
      </Menu>
    </Box>
  );
}

MenuButton.propTypes = {
  size: PropTypes.oneOf(['x-small', 'small', 'medium', 'large']),
  title: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  closeOnClick: PropTypes.bool,
};
