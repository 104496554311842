import { faCircleXmark } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIconComp, SideDrawer } from 'components/Common';

import {
  Header,
  HeaderSubtitle,
  HeadingSection,
  HeadingSectionStack,
} from 'components/Styled';
import { conversion } from 'helpers';
import PropTypes from 'prop-types';
import React from 'react';
import { NotesList } from '../NotesList';

export const NotesListModal = ({ closeHandler, open, relatedModel }) => {
  const subTitle = conversion.getName(relatedModel);

  return (
    <SideDrawer width={'40%'} isOpen={open}>
      <HeadingSection>
        <HeadingSectionStack>
          <Header variant="h4">Notes</Header>
          <HeaderSubtitle>{subTitle}</HeaderSubtitle>
        </HeadingSectionStack>
        <FontAwesomeIconComp
          icon={faCircleXmark}
          style={{ height: '28px', color: '#969696', cursor: 'pointer' }}
          onClick={() => closeHandler(false)}
        />
      </HeadingSection>
      <>
        <NotesList
          relatedModelType={'contact'}
          relatedModel={relatedModel}
          infiniteScroll
        />
      </>
    </SideDrawer>
  );
};

NotesListModal.propTypes = {
  closeHandler: PropTypes.func,
  open: PropTypes.bool,
  contactId: PropTypes.number,
  relatedModel: PropTypes.object,
};
