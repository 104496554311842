import { SideDrawer } from 'components/Common';

import { usePrevious } from 'hooks';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCreateSettingMutation } from 'redux/rtk-query';
import { defaultValues } from '../SettingForm/defaultValues';
import { processFormData } from '../SettingForm/formData';
import SettingForm from '../SettingForm/SettingForm';

export const CreateSettingPage = () => {
  const [initialValues, setInitialValues] = useState({});
  const [isOpen, setIsOpen] = useState(false);

  const [createSetting] = useCreateSettingMutation();

  const navigate = useNavigate();
  const prevIsOpen = usePrevious(isOpen);

  useEffect(() => {
    setInitialValues(defaultValues);
    setIsOpen(true);
  }, [0]);

  const handleSubmitFunc = async (values) => {
    let formData = processFormData(values);
    const setting = await createSetting(formData);
    return setting;
  };

  const afterSave = () => {
    setIsOpen(false);
  };

  useLayoutEffect(() => {
    if (prevIsOpen && !isOpen) {
      setTimeout(function () {
        navigate(-1);
      }, 500);
    }
  }, [isOpen]);

  return (
    <SideDrawer width={'95%'} isOpen={isOpen}>
      <SettingForm
        title={'Create Setting'}
        initialValues={initialValues}
        afterSave={afterSave}
        handleSubmitFunc={handleSubmitFunc}
      />
    </SideDrawer>
  );
};
