import React from "react";

import { ADMIN_ROUTES } from "consts/routes";
import {LayoutHeader} from "components/Layouts";
import { CreateButton } from "components/Common";
import {ButtonSection, PageMainContent} from "components/Styled";

import { GroupsTable } from "./GroupsTable";

export const GroupsPage = () => (
  <>
      <LayoutHeader
          title={"Groups"}
      >
          <ButtonSection>
              <CreateButton path={ADMIN_ROUTES.CREATE_GROUP.path} title="New Group" />
          </ButtonSection>
      </LayoutHeader>
      <PageMainContent maxWidth={false} component="main" disableGutters>
      <GroupsTable />
    </PageMainContent>
  </>
);
