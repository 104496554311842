import { faContactCard } from '@fortawesome/pro-solid-svg-icons';
import { AgentName, TaskRelatedModel } from 'components/CellComponents';
import { PageLink } from 'components/Styled';
import { UPDATE_INVOICE } from 'consts';
import { columnTypes } from 'consts/columnTypes';
import { STATUSES } from 'consts/documentTemplate';
import { getSelectOptions } from 'helpers/form';
import PropTypes from 'prop-types';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const InvoiceName = ({ value }) => {
  return value ? (
    <PageLink to={`${UPDATE_INVOICE(value.id)}`}>
      <div>{value.name} </div>
    </PageLink>
  ) : (
    <></>
  );
};

InvoiceName.propTypes = {
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

export const Cells = [
  {
    id: 'name',
    value: ['name', 'id'],
    numeric: false,
    disablePadding: true,
    label: 'Name',
    isVisible: true,
    Wrapper: InvoiceName,
  },
  {
    id: 'type',
    value: ['type'],
    filterable: true,
    filterType: columnTypes.DROP_DOWN,
    filterOptions: getSelectOptions(STATUSES),
    numeric: false,
    disablePadding: true,
    isVisible: true,
    label: 'Type',
  },
  {
    id: 'transaction',
    value: ['transaction', 'contact', 'id'],
    numeric: false,
    disablePadding: true,
    isVisible: true,
    label: 'Transaction',
    Wrapper: TaskRelatedModel,
  },
  {
    id: 'modelAccess',
    value: 'modelAccess',
    label: 'Agent',
    isVisible: true,
    disableSort: true,
    Wrapper: AgentName,
  },
];

export const MenuItems = () => {
  let navigate = useNavigate();

  return [
    {
      name: 'Edit',
      icon: faContactCard,
      onClick: (item) => {
        navigate(`${UPDATE_INVOICE(item.id)}`);
      },
      closeOnClick: true,
    },
  ];
};
