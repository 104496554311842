import { FormPanel } from "components/Common";
import { ListsAutocomplete, TagsAutocomplete, TextInput } from "components/Fields";

import { CONTACT_TYPES } from "consts/contacts";
import PropTypes from "prop-types";
import React from "react";
import { Field, useForm } from "react-final-form";

export const CategorizationBox = ({ showAllFields }) => {
  const { getState } = useForm();

  return (
    <FormPanel label={'Categorization'}>
      {showAllFields ||
      [CONTACT_TYPES.LEAD.value, CONTACT_TYPES.CUSTOMER.value].includes(
        getState().values.type,
      ) ? (
        <TagsAutocomplete name="tags" label={'Tags'} multiple />
      ) : null}
      <ListsAutocomplete name="lists" label={'Lists'} multiple />
      <Field
        name="description"
        label="Description"
        rows={3}
        component={TextInput}
      />
    </FormPanel>
  );
};

CategorizationBox.propTypes = {
  showAllFields: PropTypes.bool,
};
