import React, { useEffect, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { SideDrawer } from 'components/Common';
import { STATUSES } from 'consts';
import { addModelAccessValues } from 'helpers';
import { usePrevious } from 'hooks';
import {
  useCreateDocumentTemplateMutation,
  useGetDocumentTemplateQuery,
} from 'redux/rtk-query';
import { ClickMarketingForm } from '../ClickMarketingForm/ClickMarketingForm';
import { DocumentTemplateForm } from '../DocumentTemplateForm/DocumentTemplateForm';

export const DocumentTemplateCreatePage = ({ params }) => {
  const [initialValues, setInitialValues] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const paramName = Object.keys(params)[0];

  // const { data } = useGetProfileQuery();

  const navigate = useNavigate();
  const prevIsOpen = usePrevious(isOpen);
  const parentId = params[paramName];
  const type = params.type;

  const [createDocumentTemplate] = useCreateDocumentTemplateMutation();

  //useGetDocumentTemplateQuery to get the parent template
  const { data, isSuccess } = useGetDocumentTemplateQuery(
    { id: parentId },
    { skip: !parentId },
  );

  useEffect(() => {
    const parentTemplate = data?.data?.aliasDocument
      ? data.data.aliasDocument
      : (data?.data ?? null);
    setInitialValues({
      parentTemplate: parentTemplate,
      status: STATUSES.DRAFT,
      fileType: type !== 'Click Marketing' ? 'E-sign' : 'Click Marketing',
      type: type,
      categories: [],
      aspectRatio: '',
    });
    setIsOpen(true);
  }, [0, isSuccess]);

  const handleSubmit = async (values) => {
    return await createDocumentTemplate({
      ...values,
      parentTemplateId: values?.parentTemplate?.id,
      ...addModelAccessValues(values.modelAccess),
    });
  };

  const afterSave = () => {
    setIsOpen(false);
  };

  useLayoutEffect(() => {
    if (prevIsOpen && !isOpen) {
      setTimeout(function () {
        navigate(-1);
      }, 500);
    }
  }, [isOpen]);

  return (
    <SideDrawer width={'40%'} isOpen={isOpen}>
      {type === 'Click Marketing' ? (
        <ClickMarketingForm
          title={'Create Click Marketing Template'}
          initialValues={initialValues}
          handleSubmitFunc={handleSubmit}
          afterSave={afterSave}
        />
      ) : (
        <DocumentTemplateForm
          title={'Create E-sign Template'}
          initialValues={initialValues}
          handleSubmitFunc={handleSubmit}
          afterSave={afterSave}
        />
      )}
    </SideDrawer>
  );
};

DocumentTemplateCreatePage.propTypes = {
  params: PropTypes.object,
  type: PropTypes.string,
};
