import { useField, useForm } from 'react-final-form';

export function useSortableFieldArray(name) {
  const form = useForm();

  const fieldArray = useField(name);

  if (!fieldArray) {
    throw new Error(`Field not found: ${name}`);
  }

  const updateFieldArray = (newList) => {
    let values = [...fieldArray.input.value]; // Create a shallow copy to trigger re-render
    values = values.map((value) => {
      const index = newList.findIndex((item) => item.order === value.order);
      return { ...value, order: index + 1 };
    });

    // Ensure the final array is sorted by order before updating the form state
    const sortedValues = values.sort((a, b) => a.order - b.order);
    form.change(name, sortedValues);
  };

  return {
    value: JSON.parse(JSON.stringify(fieldArray.input.value)).sort(
      (a, b) => a.order - b.order,
    ),
    update: updateFieldArray,
    form,
  };
}
