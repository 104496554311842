import { styled } from "@mui/system";
import {
  Typography,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export const DividerStyled = styled(Divider)({
  height: "1px",
  color: "#E8E8E8",
  margin: "auto 5px",
});

export const ListAccordion = styled(Accordion)({
  margin: "10.5px 0",
  minHeight: 20,
  marginBottom: 0,
  background: "transparent",
  boxShadow: "none",
  border: "none",
  "&.Mui-expanded": {
    margin: "10px 0 0px 0px",
  },
  "&::before": {
    content: "none",
  },
});

export const ListAccordionSum = styled(AccordionSummary)({
  border: "none",
  padding: "0 5px",
  paddingRight: 12,
  minHeight: 20,
  marginTop: -2,
  alignItems: "flex-start",
  "& .MuiSvgIcon-root":{

  },
  "> .MuiAccordionSummary-content":{
    margin: 0,
  },
  "> .MuiAccordionSummary-content.Mui-expanded":{
    margin: 0,
  },
  "&.Mui-expanded": {
    minHeight: 20,
  },
});

export const ListAccordionDetails = styled(AccordionDetails)({
  padding: 0,
});

export const ListAccordionDetHeader = styled(Typography)({
  fontWeight: "700",
  fontSize: "10px",
  lineHeight: "16px",
  textAlign: "center",
  letterSpacing: "0.04em",
  textTransform: "uppercase",
  color: "#5e5e5e",
  marginBottom: 10
});

export const ExpandIcon = styled(ExpandMoreIcon)({
  height: "20px",
  width: "20px",
  color: "#969696",
});
