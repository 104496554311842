import { Select } from 'components/Fields';

import { CONTACT_TYPES } from 'consts';
import { useLoadMoreOptions } from 'hooks';
import PropTypes from 'prop-types';
import React from 'react';
import { Field, useForm } from 'react-final-form';
import { useGetVendorsTypeQuery } from 'redux/rtk-query';

const getVendorOption = ({ id, name, child }) => ({
  label: name,
  key: id,
  options: child.map(({ id, name }) => ({
    value: id,
    label: name,
    key: id,
  })),
});

export const VendorField = ({ showAllFields }) => {
  const { getState } = useForm();
  const vendors = useLoadMoreOptions(useGetVendorsTypeQuery, getVendorOption, {
    selectField: true,
  });
  const isOptionsLoaded = vendors.options && vendors.options.length > 0;

  return showAllFields ||
    (CONTACT_TYPES.VENDOR.value === getState().values.type &&
      isOptionsLoaded) ? (
    <Field
      name="vendorTypeId"
      label="Vendor Type"
      component={Select}
      isGrouped
      options={vendors.options}
    />
  ) : null;
};

VendorField.propTypes = {
  showAllFields: PropTypes.bool,
};
