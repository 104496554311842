import { Link, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

export const ModelLink = ({ href, label }) => {
  return (
    <Typography
      variant="subtitle2"
      component={Link}
      href={href}
      underline={'hover'}
      target="_blank"
    >
      {label}
    </Typography>
  );
};

ModelLink.propTypes = {
  href: PropTypes.string,
  label: PropTypes.string,
};
