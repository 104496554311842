import { faCircleXmark } from '@fortawesome/pro-light-svg-icons';
import { Box } from '@mui/material';

import { FontAwesomeIconComp } from 'components/Common';
import { BaseDialogWrapper } from 'components/ModelViews/styled';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import {
  ActiveUser,
  Content,
  Header,
  HeaderBox,
  HeadingSection,
  InactiveUser,
  ListBox,
  SubHeader,
} from './styled';

export const UsersModal = ({ currentItem, setCurrentItem, useGetList }) => {
  const { data, isLoading } = useGetList(
    currentItem.uuid ? currentItem.uuid : currentItem.id,
  );
  const userList = useMemo(
    () => (data?.data?.items ? data?.data?.items : data?.data || []),
    [data?.data],
  );
  const activeUsers = useMemo(
    () => userList.filter(({ status }) => status === 'Active'),
    [userList],
  );
  const inactiveUsers = useMemo(
    () => userList.filter(({ status }) => status !== 'Active'),
    [userList],
  );

  const handleUsersClose = () => setCurrentItem(false);

  return (
    <BaseDialogWrapper open={!!currentItem} onBackdropClick={handleUsersClose}>
      <Content>
        <HeadingSection>
          <HeaderBox>
            <Header variant="h4">Total Users</Header>
            <SubHeader>{currentItem.name}</SubHeader>
          </HeaderBox>
          <FontAwesomeIconComp
            icon={faCircleXmark}
            style={{ height: '28px', color: '#969696', cursor: 'pointer' }}
            onClick={handleUsersClose}
          />
        </HeadingSection>
        <ListBox>
          {isLoading ? (
            <Box>Loading...</Box>
          ) : userList.length ? (
            <>
              {activeUsers.map(({ id, firstName, lastName, username }) => (
                <ActiveUser key={id}>{`${firstName || ''} ${lastName || ''} ${
                  username || ''
                }`}</ActiveUser>
              ))}
              {inactiveUsers.map(({ id, firstName, lastName, username }) => (
                <InactiveUser key={id}>{`${firstName || ''} ${lastName || ''} ${
                  username || ''
                }`}</InactiveUser>
              ))}
            </>
          ) : (
            <Box>Empty list</Box>
          )}
        </ListBox>
      </Content>
    </BaseDialogWrapper>
  );
};

UsersModal.propTypes = {
  currentItem: PropTypes.shape({
    id: PropTypes.number,
    uuid: PropTypes.string,
    name: PropTypes.string,
  }),
  setCurrentItem: PropTypes.func,
  onClose: PropTypes.func,
  useGetList: PropTypes.func,
};
