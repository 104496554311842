import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import { BoxInput } from 'components/Styled';

export const ChipSelect = ({ input, options }) => {
  const handleClick = (value) => () => {
    input.onChange(value);
  };

  return (
    <BoxInput>
      {options.map((option) => {
        return (
          <Button
            key={option.value}
            size={'small'}
            variant={option.value === input.value ? 'contained' : 'outlined'}
            onClick={handleClick(option.value)}
          >
            {option.label}
          </Button>
        );
      })}
    </BoxInput>
  );
};

ChipSelect.defaultProps = {
  size: 'small',
  disabled: false,
};

ChipSelect.propTypes = {
  options: PropTypes.array,
  input: PropTypes.object,
};
