import React from "react";
import { Divider } from "@mui/material";
import { AbeCard } from "components/Common";
import { MyTaskList } from "./components/MyTaskList";
import PropTypes from "prop-types";

export const MyTaskCard = ({ type }) => {
  const moduleName = `${type}s`;

  return (
    <AbeCard title={moduleName}>
      <Divider />
      <MyTaskList type={type} />
    </AbeCard>
  );
};

MyTaskCard.defaultProps = {};

MyTaskCard.propTypes = {
    type: PropTypes.string.isRequired
};
