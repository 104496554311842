import { LayoutHeader } from 'components/Layouts';
import { PageMainContent } from 'components/Styled';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { CommissionsTable } from './components/CommissionsTable';

export const CommissionsPage = () => {
  return (
    <>
      <LayoutHeader title={'Commissions'}></LayoutHeader>
      <PageMainContent maxWidth={false} component="main" disableGutters>
        <CommissionsTable />
      </PageMainContent>
      <Outlet />
    </>
  );
};
