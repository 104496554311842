import {
  faContactCard,
  faPen,
  faTrash,
  faUp,
} from '@fortawesome/pro-solid-svg-icons';
import { ListCount, NoteCount, TaskCount } from 'components/CellComponents';
import { PageLink } from 'components/Styled';
import {
  columnTypes,
  CONTACT_TYPES,
  DETAILS_CONTACT,
  UPDATE_CONTACT,
} from 'consts';
import { conversion } from 'helpers';
import { getSelectOptionsAlt } from 'helpers/form';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setEditingList } from 'redux/slices';
import { ContactTypeChip } from '../../styled';

const FullName = ({ value }) => {
  return value ? (
    <PageLink to={`/${DETAILS_CONTACT(value.id)}`}>
      <div> {conversion.getName(value)} </div>
    </PageLink>
  ) : (
    <></>
  );
};

FullName.propTypes = {
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

const ContactType = ({ value }) => {
  return <ContactTypeChip>{value}</ContactTypeChip>;
};

ContactType.propTypes = {
  value: PropTypes.string,
};

export const Cells = [
  {
    id: 'firstName',
    value: ['firstName', 'middleName', 'lastName', 'companyName', 'id'],
    numeric: false,
    disablePadding: true,
    label: 'Name',
    isVisible: true,
    static: true,
    Wrapper: FullName,
  },
  {
    id: 'type',
    value: 'type',
    label: 'Type',
    filterable: true,
    filterType: columnTypes.DROP_DOWN,
    filterOptions: getSelectOptionsAlt(CONTACT_TYPES),
    disablePadding: true,
    isVisible: true,
    Wrapper: ContactType,
  },
  {
    id: 'lists',
    value: ['lists', 'id', 'uuid'],
    numeric: true,
    disablePadding: true,
    disableSort: true,
    isVisible: true,
    cellAlign: 'center',
    label: 'Lists',
    Wrapper: ListCount,
  },
  {
    id: 'notes',
    value: [
      'notes',
      'id',
      'uuid',
      'firstName',
      'middleName',
      'lastName',
      'companyName',
    ],
    numeric: true,
    disablePadding: true,
    disableSort: true,
    isVisible: true,
    cellAlign: 'center',
    label: 'Notes',
    Wrapper: NoteCount,
  },
  {
    id: 'tasks',
    value: [
      'tasks',
      'id',
      'uuid',
      'firstName',
      'middleName',
      'lastName',
      'companyName',
    ],
    numeric: true,
    disablePadding: true,
    disableSort: true,
    isVisible: true,
    cellAlign: 'center',
    label: 'Tasks',
    Wrapper: TaskCount,
  },
  {
    id: 'agent',
    value: 'agent',
    numeric: false,
    isVisible: true,
    disablePadding: true,
    label: 'Agent',
    Wrapper: FullName,
  },
];

export const MenuItems = () => {
  let dispatch = useDispatch();
  let navigate = useNavigate();

  return [
    {
      name: 'Convert to Customer',
      icon: faUp,
      shouldRender: ({ type }) => {
        return type === 'Lead';
      },
      onClick: (item) => {
        dispatch(setEditingList(item.uuid));
      },
    },
    {
      name: 'Edit',
      icon: faPen,
      onClick: (item) => {
        navigate(UPDATE_CONTACT(item.id));
      },
    },
    {
      name: 'Details',
      icon: faContactCard,
      onClick: (item) => {
        navigate(`/${DETAILS_CONTACT(item.id)}`);
      },
    },
    { name: 'Delete', icon: faTrash },
  ];
};
