import { PageLink } from 'components/Styled';
import { DETAILS_CONTACT, DETAILS_TRANSACTION } from 'consts';
import { conversion } from 'helpers';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

export const TaskRelatedModel = ({ value }) => {
  const relatedModelString = useMemo(() => {
    if (value.contact) {
      return (
        <PageLink to={`/${DETAILS_CONTACT(value.contact.id)}`}>
          <div> {conversion.getName(value.contact)} </div>
        </PageLink>
      );
    } else if (value.transaction) {
      return (
        <PageLink to={`/${DETAILS_TRANSACTION(value.transaction.id)}`}>
          <div> {conversion.getName(value.transaction)} </div>
        </PageLink>
      );
    }
    return <></>;
  }, [value]);
  return <>{relatedModelString}</>;
};

TaskRelatedModel.propTypes = {
  value: PropTypes.shape({
    id: PropTypes.number,
    contact: PropTypes.object,
    transaction: PropTypes.object,
  }),
};
