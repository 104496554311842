import { styled } from "@mui/system";
import {Box} from "@mui/material";

export const TaskBox = styled(Box)(({ iscompleted }) => ({
  display: "inline-flex",
  width: "100%",
  justifyContent: "space-between",
  "& span":{
    textDecoration: iscompleted === "true" ? "line-through" : ""
  }
}));



