import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  removeBannerMessage,
  setBannerMessage,
} from "redux/slices";

export const usePreviewMode = () => {
  const dispatch = useDispatch();
  const bannerMessage = { severity: 'info', bannerMessage: 'Preview Mode' };

  useEffect(() => {
    dispatch(setBannerMessage(bannerMessage));

    return () => {
      dispatch(removeBannerMessage(bannerMessage));
    };
  }, []);
};

export default usePreviewMode;