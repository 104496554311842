import React from 'react';
import PropTypes from 'prop-types';
import '../../../css/styles.css';
import { useDispatch, useSelector } from 'react-redux';
import { Rnd } from 'react-rnd';
import { Box } from '@mui/system';
import { getComputedBorderRadius } from 'components/ModelViews/Documents/ClickMarketingViewer/utils/viewer';
import {
  activeFieldIdsSelectorCM as activeFieldIdsSelector,
  htmlTemplateSelectorCM as htmlTemplateSelector,
  scaleSelectorCM as scaleSelector,
} from 'redux/selectors';
import { setActiveFieldCM as setActiveField, updateField } from 'redux/slices';

export const ResizableDraggable = ({
  field,
  pageId,
  children,
  scale = null,
  preview = false,
}) => {
  const dispatch = useDispatch();
  const activeFieldIds = useSelector(activeFieldIdsSelector);
  const htmlTemplate = useSelector(htmlTemplateSelector);
  const selectorScale = useSelector(scaleSelector);
  const activeScale = scale || selectorScale;
  const isActive = activeFieldIds.includes(field.id);
  const computedBorderRadius = getComputedBorderRadius(field);

  // Get the elements array (ordered list of item IDs) for the active page
  const page = htmlTemplate?.options?.pages?.[pageId];
  const elements = page?.elements || [];

  // Calculate zIndex based on the position of the field.id in the elements array
  const zIndex = elements.indexOf(field.id);

  console.log('field.page:', pageId);

  let left = field.x;
  let top = field.y;
  let width = parseFloat(field.w);
  let height = parseFloat(field.h);

  const handleResizeStop = (e, direction, ref, delta) => {
    const newWidth = parseFloat(field.w) + delta.width;
    const newHeight = parseFloat(field.h) + delta.height;
    dispatch(
      updateField({
        fieldId: field.id,
        properties: { width: newWidth + 'px', height: newHeight + 'px' },
      }),
    );
  };

  const handleDrag = () => {
    if (field.groupId || activeFieldIds.length > 1) {
      const currentElement = document.querySelector(
        `[data-field-id="${field.id}"]`,
      );
      const transform = currentElement.style.transform;

      // Check if transform is set and matches expected patterns
      const transformValues = transform.match(/-?\d+(\.\d+)?/g) || [0, 0];
      const x = parseFloat(transformValues[0]) - parseFloat(field.left);
      const y = parseFloat(transformValues[1]) - parseFloat(field.top);

      // Make an array with both the fields with the same groupId and the active fields
      const fields = htmlTemplate.filter(
        (possField) =>
          (field.groupId && possField.groupId === field.groupId) ||
          activeFieldIds.includes(possField.id),
      );
      fields.forEach((fieldVar) => {
        if (fieldVar.id !== field.id) {
          const fieldObject = fieldVar;
          // left and top must be greater than 0
          let left = Math.max(parseFloat(fieldObject.left) + x, 0);
          let top = Math.max(parseFloat(fieldObject.top) + y, 0);
          const groupElement = document.querySelector(
            `[data-field-id="${fieldVar.id}"]`,
          );
          groupElement.style.transform = `translate(${left}px, ${top}px)`;
        }
      });
    }
  };

  const handleDragStop = (e, data) => {
    if (field.type === 'checkbox') {
      const newLeft = data.x + 3;
      const newTop = data.y + 3;
      dispatch(
        updateField({
          fieldId: field.id,
          properties: { left: newLeft + 'px', top: newTop + 'px' },
        }),
      );
    } else {
      dispatch(
        updateField({
          fieldId: field.id,
          properties: { left: data.x + 'px', top: data.y + 'px' },
        }),
      );
    }
  };

  const onFieldClick = (e) => {
    if (e.shiftKey) {
      // If Shift is pressed, add the field to the active fields
      dispatch(setActiveField([...activeFieldIds, field.id]));
      e.preventDefault();
    } else {
      dispatch(setActiveField(field.id));
    }
  };

  return (
    <Rnd
      data-field-id={preview ? `preview-${field.id}` : field.id}
      data-group-id={field.groupId}
      bounds={'parent'}
      scale={activeScale}
      resizeHandleComponent={{
        bottomRight: (
          <Box
            onClick={(e) => {
              e.stopPropagation();
            }}
            style={{
              display: isActive ? 'block' : null,
            }}
          />
        ),
      }}
      style={{
        position: 'absolute',
        textAlign: field.textAlign,
        borderRadius: computedBorderRadius,
        zIndex: zIndex,
      }}
      size={{ width, height }}
      position={{ x: left, y: top }}
      onResizeStop={handleResizeStop}
      onDrag={handleDrag}
      onDragStop={handleDragStop}
      onClick={onFieldClick}
      lockAspectRatio={
        field.type === 'signatureField' ||
        field.type === 'signatureInitialField'
      }
      resizeHandleStyles={{
        bottomRight: {
          width: 20,
          height: 20,
          right: -5,
          bottom: -5,
          zIndex: 100,
        },
      }}
      resizeHandleWrapperClass={'resizeHandleWrapper'}
      enableResizing={{
        top: false,
        right: false,
        bottom: false,
        left: false,
        topRight: preview ? false : field.type === 'strikethrough',
        bottomRight: preview ? false : field.type !== 'checkbox' && isActive,
        bottomLeft: preview ? false : field.type === 'strikethrough',
        topLeft: preview ? false : field.type === 'strikethrough',
      }}
      disableDragging={preview}
      minHeight={field.type === 'strikethrough' ? 20 : null}
    >
      {children}
    </Rnd>
  );
};

ResizableDraggable.propTypes = {
  field: PropTypes.object.isRequired,
  pageId: PropTypes.string.isRequired,
  children: PropTypes.node,
  onFieldClick: PropTypes.func,
  scale: PropTypes.number,
  preview: PropTypes.bool,
};
