import { faSearchMinus, faSearchPlus } from '@fortawesome/pro-light-svg-icons';
import { Box, IconButton, Paper } from '@mui/material';
import { FontAwesomeIconComp } from 'components/Common';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { scaleSelector } from 'redux/selectors';
import { setScale } from 'redux/slices';

export const ZoomControls = () => {
  const dispatch = useDispatch();
  const scale = useSelector(scaleSelector);
  const [isVisible, setIsVisible] = useState(false);

  const handleZoomIn = () => {
    dispatch(setScale(scale + 0.1));
  };

  const handleZoomOut = () => {
    dispatch(setScale(scale - 0.1));
  };

  return (
    <Box
      position="absolute"
      top={16}
      right={20}
      zIndex={100}
      onMouseEnter={() => setIsVisible(true)}
      onMouseLeave={() => setIsVisible(false)}
      style={{
        opacity: isVisible ? 1 : 0,
        transition: 'opacity 0.3s ease-in-out',
      }}
    >
      <Paper
        elevation={3}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: 1,
        }}
      >
        <IconButton
          onClick={handleZoomIn}
          size="large"
          sx={{ marginBottom: 1 }}
        >
          <FontAwesomeIconComp icon={faSearchPlus} />
        </IconButton>
        <IconButton onClick={handleZoomOut} size="large">
          <FontAwesomeIconComp icon={faSearchMinus} />
        </IconButton>
      </Paper>
    </Box>
  );
};
