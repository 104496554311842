import { faPaperPlane, faUser } from '@fortawesome/pro-light-svg-icons';
import { Button, ButtonGroup } from '@mui/material';
import { FontAwesomeIconComp, MenuItemButton } from 'components/Common';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { activeMenuSelector } from 'redux/selectors';
import { cancelRoleUpdate, setMenu } from 'redux/slices';
import { BottomMenuButtons } from '../BottomMenuButtons';
import { RolesSections, SignedStatusSelection, UpdateRolesButton } from './components';

export const RolesMenu = () => {
  const dispatch = useDispatch();
  const activeMenu = useSelector(activeMenuSelector);
  const icon = activeMenu === 'roles' ? faUser : faPaperPlane;
  const label = activeMenu === 'roles' ? 'Roles' : 'Finalize Document';

  return (
    <>
      <MenuItemButton
        icon={<FontAwesomeIconComp fontSize={18} icon={icon} />}
        label={label}
        isSelected
        onClick={() => {}}
      />
      <RolesSections />
      <BottomMenuButtons>
        {activeMenu === 'finalizeDocument' && <SignedStatusSelection />}
        <ButtonGroup>
          <UpdateRolesButton />
          <Button
            size="medium"
            variant="outlined"
            style={{ marginRight: '3px' }}
            onClick={() => {
              dispatch(cancelRoleUpdate());
              dispatch(setMenu('primary'));
            }}
          >
            Cancel
          </Button>
        </ButtonGroup>
      </BottomMenuButtons>
    </>
  );
};
