import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

export const companyUnitsSlice = createSlice({
  name: "companyUnits",
  initialState,
  reducers: {},
});

export const companyUnitsSliceReducer = companyUnitsSlice.reducer;
