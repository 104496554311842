import * as yup from "yup";

import { validateFormValues } from "helpers/validation";

const option = yup.object().shape({
  value: yup.number(),
  label: yup.string(),
  key: yup.number(),
});

const validationSchema = yup.object().shape({
  username: yup
    .string()
    .trim()
    .min(3)
    .max(64)
    .nullable()
    .matches(/^[^A-Z]+$/, "Must be lowercase")
    .required("Required"),
  contact: option.nullable().required("Required"),
  setting: option.nullable(),
  firstName: yup
    .string()
    .trim()
    .max(64)
    .nullable()
    .test(
      "is-lower-or-upper-case",
      "This name is not properly capitalized and will display as typed on contracts, emails, and more.",
      (value = "") =>
        value
          ? ![value.toLowerCase(), value.toUpperCase()].includes(value)
          : true,
    ),
  middleName: yup
    .string()
    .trim()
    .max(64)
    .nullable()
    .test(
      "is-lower-or-upper-case",
      "This name is not properly capitalized and will display as typed on contracts, emails, and more.",
      (value = "") =>
        value
          ? ![value.toLowerCase(), value.toUpperCase()].includes(value)
          : true,
    ),
  lastName: yup
    .string()
    .trim()
    .max(64)
    .nullable()
    .test(
      "is-lower-or-upper-case",
      "This name is not properly capitalized and will display as typed on contracts, emails, and more.",
      value =>
        value
          ? ![value.toLowerCase(), value.toUpperCase()].includes(value)
          : true,
    )
    .required("Required"),
  nickname: yup.string().trim().max(64).nullable(),
  email: yup
    .string()
    .nullable()
    .email("Email Address is not a valid email address."),
  roles: yup.array().of(option).min(1, "Required"),
  groups: yup.array().of(option),
  companyStructureUnits: yup.array().of(option),
  language: option.nullable(),
  locale: option.nullable(),
  timezone: option.nullable(),
  currency: option.nullable(),
  status: yup.string(),
  password: yup
    .string()
    .trim()
    .min(8, "Password must be at least 8 characters")
    .max(64)
    .matches(/^(?=.*[a-z])/, "Password must contain a lowercase letter")
    .matches(/^(?=.*[A-Z])/, "Password must contain an uppercase letter")
    .matches(/^(?=.*[0-9])/, "Password must contain a number")
    .nullable()
    .required("Required"),
  confirm: yup
    .string()
    .trim()
    .min(8, "Password must be at least 8 characters")
    .max(64)
    .matches(/^(?=.*[a-z])/, "Password must contain a lowercase letter")
    .matches(/^(?=.*[A-Z])/, "Password must contain an uppercase letter")
    .matches(/^(?=.*[0-9])/, "Password must contain a number")
    .oneOf([yup.ref("password"), null], "Password must be repeated exactly.")
    .nullable()
    .required("Required"),
});

export const validate = validateFormValues(validationSchema);
