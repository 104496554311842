import React, { useState } from "react";
import { Box, Grid, IconButton, TextField, Typography } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import PropTypes from "prop-types";
import { faPencil, faTrash } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIconComp } from "components/Common";

export const Testimonial = ({ testimonial, onSave, onDelete }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [customerName, setCustomerName] = useState(testimonial.customerName);
  const [description, setDescription] = useState(testimonial.description);

  const handleSave = () => {
    onSave(testimonial.id, customerName, description);
    setIsEditing(false);
  };

  return (
    <Box
      sx={{
        border: "1px solid #ccc",
        borderRadius: "12px",
        padding: "1rem",
        marginBottom: "1rem",
        position: "relative",
        "&:hover .actionButtons": {
          visibility: "visible",
        },
      }}
    >
      {isEditing ? (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              value={description}
              onChange={e => setDescription(e.target.value)}
              label="Testimonial"
              multiline
              rows={4}
              fullWidth
            />
          </Grid>
            <Grid item xs={12}>
                <TextField
                    size={"small"}
                    value={customerName}
                    onChange={e => setCustomerName(e.target.value)}
                    label="Customer Name"
                    fullWidth
                />
            </Grid>
          <Grid item xs={12}>
            <IconButton onClick={handleSave} color="primary">
              <SaveIcon />
            </IconButton>
          </Grid>
        </Grid>
      ) : (
        <>
          <Typography variant="subtitle1" component="p" gutterBottom>
            {description}
          </Typography>
          <Typography variant="caption" component="p" color="text.secondary">
            - {customerName}
          </Typography>
          <Box
            className="actionButtons"
            sx={{
              position: "absolute",
              bottom: 8,
              right: 8,
              visibility: "hidden",
            }}
          >
            <IconButton onClick={() => setIsEditing(true)} color="primary">
              <FontAwesomeIconComp fontSize={12} icon={faPencil} />
            </IconButton>
            <IconButton
              onClick={() => onDelete(testimonial.id)}
              color="secondary"
              size="small"
            >
              <FontAwesomeIconComp fontSize={12} icon={faTrash} />
            </IconButton>
          </Box>
        </>
      )}
    </Box>
  );
};

Testimonial.propTypes = {
  testimonial: PropTypes.shape({
    id: PropTypes.string.isRequired,
    customerName: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }).isRequired,
  onSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};
