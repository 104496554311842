import { DateTime } from 'luxon-business-days';

export const isValidDateString = (dateString) => {
  const regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;
  if (!regex.test(dateString)) {
    return false;
  }

  const parsedDate = new Date(dateString);
  return !isNaN(parsedDate.getTime());
};

export const formatDate = (value) => {
    return value
      ? DateTime.fromISO(value).toFormat('M/d/yy')
      : '';
}

export const formatDateTime = (value) => {
    return value
      ? DateTime.fromISO(value).toFormat('M/d/yyyy h:mm a')
      : '';
}

export const setDateToEndOfDay = (date) => {
    let dateObj = new Date(date)
    dateObj.getUTCDate()
    dateObj.setUTCHours(23,59,59,999);
    return dateObj;
}

function getNthTargetDayOfMonth({ n, day, month, year }) {
    const firstDayOfMonth = DateTime.fromObject({
        day: 1,
        month,
        year,
    });

    // is target day before or after first day
    const offsetThreshold = firstDayOfMonth.weekday - day;
    let offsetFromTargetDay = null;
    if (offsetThreshold > 0) {
        // get to target day if target is after first day
        offsetFromTargetDay = 7 - offsetThreshold;
    } else {
        // reverse threshold to get to target from first day
        offsetFromTargetDay = offsetThreshold * -1;
    }

    const firstOccurenceOfTargetDay = firstDayOfMonth.plus({
        days: offsetFromTargetDay,
    });
    const nthDay = firstOccurenceOfTargetDay.plus({
        days: (n - 1) * 7,
    });

    return nthDay;
}

const isJuneteenth = function(inst) {
    const juneteenth = DateTime.fromObject({ month: 6, day: 19 });
    return +inst === +juneteenth;
};
const isVeteransDay = function(inst) {
    const veteransDay = DateTime.fromObject({ month: 11, day: 11 });
    return +inst === +veteransDay;
};
const isWashingtonsBDay = function(inst) {
    if (inst.month !== 2) {
        return false;
    }
    const washingtonBday = getNthTargetDayOfMonth({
        n: 3,
        day: 1,
        month: 2,
        year: inst.year,
    });

    const matchesMonth = inst.month === washingtonBday.month;
    const matchesDay = inst.day === washingtonBday.day;

    return matchesMonth && matchesDay;
};
const isVeteransDayObserved = function(inst) {
    const nov1023 = DateTime.fromObject({ month: 1, day: 10, year: 2023 });
    return +inst === +nov1023;
};
const isIndependenceDayObserved = function(inst) {
    const july0326 = DateTime.fromObject({ month: 7, day: 3, year: 2026 });
    return +inst === +july0326;
};
const isChristmasDayObserved = function(inst) {
    const dec2622 = DateTime.fromObject({ month: 12, day: 26, year: 2022 });
    return +inst === +dec2622;
};
const isNewYearsDayObserved = function(inst) {
    const jan0223 = DateTime.fromObject({ month: 1, day: 2, year: 2023 });
    return +inst === +jan0223;
};
const isMLKDayObserved = function(inst) {
    const jan2025 = DateTime.fromObject({ month: 1, day: 20, year: 2025 });
    return +inst === +jan2025;
};

const getHolidayName = (dt) => {
    if(dt.availableHolidayMatchers.isNewYearsDay(dt)){
        return "New Year's Day"
    }
    if(isWashingtonsBDay(dt)){
        return "Washington's Birthday";
    }
    if(dt.availableHolidayMatchers.isMLKDay(dt)){
        return "Martin Luther King Jr. Day";
    }
    if(dt.availableHolidayMatchers.isMemorialDay(dt)){
        return "Memorial Day";
    }
    if(isJuneteenth(dt)){
        return "Juneteenth";
    }
    if(dt.availableHolidayMatchers.isIndependanceDay(dt)){
        return "Independence Day";
    }
    if(dt.availableHolidayMatchers.isLaborDay(dt)){
        return "Labor Day";
    }
    if(dt.availableHolidayMatchers.isColumbusDay(dt)){
        return "Columbus Day";
    }
    if(isVeteransDay(dt)){
        return "Veterans Day";
    }
    if(dt.availableHolidayMatchers.isThanksgivingDay(dt)){
        return "Thanksgiving Day";
    }
    if(dt.availableHolidayMatchers.isChristmasDay(dt)){
        return "Christmas Day";
    }
    if(isVeteransDayObserved(dt)){
        return "Veterans Day (Observed)";
    }
    if(isIndependenceDayObserved(dt)){
        return "Independence Day (Observed)";
    }
    if(isChristmasDayObserved(dt)){
        return "Christmas Day (Observed)";
    }
    if(isNewYearsDayObserved(dt)){
        return "New Year's Day (Observed)";
    }
    if(isMLKDayObserved(dt)){
        return "Martin Luther King Jr. Day (Observed)";
    }
}

export function checkForBusinessDay(date) {
    let replacedDate = date.split(" ");
    replacedDate = replacedDate[0].replaceAll("-", "/")
    let timeframeDateTime = new Date(replacedDate)
    const year = timeframeDateTime.getFullYear();
    const month = String(timeframeDateTime.getMonth() + 1);
    const day = String(timeframeDateTime.getDate());
    let dt = DateTime.local(parseInt(year), parseInt(month), parseInt(day));
    const myHolidaysWithObserved = [
        dt.availableHolidayMatchers.isNewYearsDay,
        dt.availableHolidayMatchers.isMLKDay,
        isWashingtonsBDay,
        dt.availableHolidayMatchers.isMemorialDay,
        isJuneteenth,
        dt.availableHolidayMatchers.isIndependanceDay,
        dt.availableHolidayMatchers.isLaborDay,
        dt.availableHolidayMatchers.isColumbusDay,
        isVeteransDay,
        dt.availableHolidayMatchers.isThanksgivingDay,
        dt.availableHolidayMatchers.isChristmasDay,
        isVeteransDayObserved,
        isIndependenceDayObserved,
        isChristmasDayObserved,
        isNewYearsDayObserved,
        isMLKDayObserved
    ];

    dt.setupBusiness({ holidayMatchers: myHolidaysWithObserved });
    let skippedDays = []
    if(!dt.isBusinessDay() || dt.isHoliday()){
        if(!dt.isBusinessDay()) {
            skippedDays.push(`${dt.month}/${dt.day} is a ${dt.weekdayLong}`);
        }
        else {
            let holidayName = getHolidayName(dt)
            skippedDays.push(`${dt.month}/${dt.day} is ${holidayName}`);
        }
    }

    return skippedDays;
}

export function addDays(p1,p2, timeframeCalculation) {

    p1 = p1.split("/")
    let dt = DateTime.local(parseInt(p1[2]), parseInt(p1[0]), parseInt(p1[1]));


    const myHolidaysWithObserved = [
        dt.availableHolidayMatchers.isNewYearsDay,
        dt.availableHolidayMatchers.isMLKDay,
        isWashingtonsBDay,
        dt.availableHolidayMatchers.isMemorialDay,
        isJuneteenth,
        dt.availableHolidayMatchers.isIndependanceDay,
        dt.availableHolidayMatchers.isLaborDay,
        dt.availableHolidayMatchers.isColumbusDay,
        isVeteransDay,
        dt.availableHolidayMatchers.isThanksgivingDay,
        dt.availableHolidayMatchers.isChristmasDay,
        isVeteransDayObserved,
        isIndependenceDayObserved,
        isChristmasDayObserved,
        isNewYearsDayObserved,
        isMLKDayObserved
    ];
    const myHolidays = [
        dt.availableHolidayMatchers.isNewYearsDay,
        dt.availableHolidayMatchers.isMLKDay,
        isWashingtonsBDay,
        dt.availableHolidayMatchers.isMemorialDay,
        isJuneteenth,
        dt.availableHolidayMatchers.isIndependanceDay,
        dt.availableHolidayMatchers.isLaborDay,
        dt.availableHolidayMatchers.isColumbusDay,
        isVeteransDay,
        dt.availableHolidayMatchers.isThanksgivingDay,
        dt.availableHolidayMatchers.isChristmasDay,
    ];


    if(timeframeCalculation === "Calendar Days, Ends at Midnight, Skip if ends Weekends, Holidays and Observed Holidays and ends at 11:59pm"){
        dt.setupBusiness({ holidayMatchers: myHolidaysWithObserved });
        dt = dt.plus({ days: parseInt(p2) });
        let skippedDays = []
        while(!dt.isBusinessDay() || dt.isHoliday()){
            if(!dt.isBusinessDay()) {
                skippedDays.push(`${dt.month}/${dt.day} is a ${dt.weekdayLong}`);
            }
            else {
                let holidayName = getHolidayName(dt)
                skippedDays.push(`${dt.month}/${dt.day} is ${holidayName}`);
            }
            dt = dt.plus({ days: 1 })
        }
        let newDate = new Date(dt.ts)
        newDate.getUTCDate();
        newDate.setUTCHours(23,59,59,999);
        return {newDate, skippedDays};
    }
    else if(timeframeCalculation === "Calendar Days, Skip Weekends if 5 days or less, Skip if ends on Weekend or Holidays and roll to 5pm next business day"){
        let skippedWeekendOrHoliday = false;
        let skippedDays = []
        if(parseInt(p2) <= 5){
            dt.setupBusiness({ holidayMatchers: [] });
            let withoutSkip = dt.plus({ days: parseInt(p2) });
            dt = dt.plusBusiness({ days: parseInt(p2) })
            if(withoutSkip.ts !== dt.ts){
                skippedWeekendOrHoliday = true
                skippedDays.push(`Business days skipped`);
            }
        }
        else{
            dt = dt.plus({ days: parseInt(p2) });
        }
        // DONT SKIP OBSERVED
        dt.setupBusiness({ holidayMatchers: myHolidays });
        while(!dt.isBusinessDay() || dt.isHoliday()){
            skippedWeekendOrHoliday = true
            if(!dt.isBusinessDay()) {
                skippedDays.push(`${dt.month}/${dt.day} is a ${dt.weekdayLong}`);
            }
            else {
                let holidayName = getHolidayName(dt)
                skippedDays.push(`${dt.month}/${dt.day} is ${holidayName}`);
            }
            dt = dt.plus({ days: 1 })
        }
        let newDate = new Date(dt.ts);
        newDate.getUTCDate()
        if(skippedWeekendOrHoliday) {
            newDate.setUTCHours(17, 0, 0, 0);
        }
        else{
            newDate.setUTCHours(23,59,59,999);
        }
        return {newDate, skippedDays};
    }
}