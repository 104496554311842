import { conversion } from 'helpers';
import PropTypes from 'prop-types';
import React from 'react';

export const AgentName = ({ value }) => {
  return value ? conversion.getName(value.owner) : <></>;
};

AgentName.propTypes = {
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};
