import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";

import { TabPanel, getTabProps } from "./TabPanel";
import { Password } from "./Password";
import { AuditLog } from "./AuditLog";
import { InfoBox, Tabs, Tab } from "./styled";
import {SecurityOverview} from "./SecurityOverview";

export const UserInfo = ({ userId }) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <InfoBox>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Audit Log" {...getTabProps(0)} />
            <Tab label="Password" {...getTabProps(1)} />
            <Tab label="Security Overview" {...getTabProps(2)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <AuditLog userId={userId} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Password userId={userId} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <SecurityOverview groupId={userId} />
        </TabPanel>
      </Box>
    </InfoBox>
  );
};

UserInfo.propTypes = {
  userId: PropTypes.string,
};
