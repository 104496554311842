import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { checkForRequiredFields } from "redux/slices/documentEditor/helpers";
import { rolesReducer } from './reducers/roles';
import { historyReducer } from './reducers/history';
import { fieldsReducer } from './reducers/role';
import { documentsAPI, documentTemplatesAPI } from '../../rtk-query';
import { STATUSES } from "consts";
import { setSnackbarOpen } from "../snackbar";

const initialState = {
  undoStack: [],
  redoStack: [],
  needsSaving: false,
  numPages: 0,
  activeMenu: 'primary',
  bannerMessages: [],
  signedStatus: null,
  templateView: false,
  currentDocument: {
    htmlTemplate: [],
  },
  previousRoles: [],
  activeFieldIds: [],
  autofillData: {},
  scale: 1,
  placingItem: false,
  fieldsTemplates: {
    signatureField: {
      width: 200,
      height: 40,
      role: null,
    },
    signatureInitialField: {
      width: 30,
      height: 30,
      role: null,
    },
    signDate: {
      width: 110,
      height: 15,
      role: null,
    },
    datePicker: {
      width: 100,
      height: 15,
    },
    printName: {
      width: 100,
      height: 20,
      role: null,
    },
    text: {
      width: 100,
      height: 20,
    },
    checkbox: {
      width: 15,
      height: 15,
    },
    strikethrough: {
      width: 200,
      height: 20,
    },
  },
};

export const saveRolesAndFinalize = createAsyncThunk(
  'document/saveRolesAndFinalize',
  async (args, { dispatch, getState }) => {
    // Local state update
    dispatch(saveRoles());
    // Get the updated document from state
    const updatedDocument = getState().documentEditorReducer.currentDocument;
    // Call the mutation using RTK Query
    const { updateDocument } = documentsAPI.endpoints;
    return await dispatch(
      updateDocument.initiate({
        id: updatedDocument.id,
        roles: updatedDocument.roles,
        signedStatus: updatedDocument.signedStatus,
        status: STATUSES.IN_PROGRESS,
        htmlTemplate: updatedDocument.htmlTemplate,
      }),
    ).unwrap();
  },
);

export const saveHtmlTemplate = createAsyncThunk(
  'document/saveHtmlTemplate',
  async (args, { dispatch, getState }) => {
    const updatedDocument = getState().documentEditorReducer.currentDocument;

    try {
      let response;
      if (getState().documentEditorReducer.templateView) {
        const { updateDocumentTemplate } = documentTemplatesAPI.endpoints;
        response = await dispatch(
          updateDocumentTemplate.initiate({
            id: updatedDocument.id,
            htmlTemplate: updatedDocument.htmlTemplate,
          }),
        ).unwrap();
      } else {
        const { updateDocument } = documentsAPI.endpoints;
        response = await dispatch(
          updateDocument.initiate({
            id: updatedDocument.id,
            htmlTemplate: updatedDocument.htmlTemplate,
          }),
        ).unwrap();
      }

      // Dispatch success snackbar action
      dispatch(setSnackbarOpen({ message: 'Fields saved successfully', severity: 'success' }));

      return response;
    } catch (error) {
      // Dispatch error snackbar action
      dispatch(setSnackbarOpen({ message: 'Failed to save fields', severity: 'error' }));

      throw error;
    }
  },
);

export const saveRolesAndSync = createAsyncThunk(
  'document/saveRolesAndSync',
  async (args, { dispatch, getState }) => {
    try {
      // Local state update
      dispatch(saveRoles());

      // Get the updated document from state
      const updatedDocument = getState().documentEditorReducer.currentDocument;

      // Call the mutation using RTK Query
      const { updateDocument } = documentsAPI.endpoints;
      const response = await dispatch(
        updateDocument.initiate({
          id: updatedDocument.id,
          roles: updatedDocument.roles,
        }),
      ).unwrap();

      // Dispatch success snackbar action
      dispatch(
        setSnackbarOpen({
          message: 'Document roles updated!',
          severity: 'success',
        }),
      );

      return response;
    } catch (error) {
      // Dispatch error snackbar action
      dispatch(
        setSnackbarOpen({
          message: 'Failed to update document',
          severity: 'error',
        }),
      );

      throw error;
    }
  },
);

export const documentEditorSlice = createSlice({
  name: 'documentEditor',
  initialState,
  reducers: {
    ...historyReducer,
    ...rolesReducer,
    ...fieldsReducer,
    setNumberOfPages: (state, action) => {
      state.numPages = action.payload;
    },
    setMenu: (state, action) => {
      state.activeMenu = action.payload;
      checkForRequiredFields(state);
    },
    setBannerMessage: (state, action) => {
      state.bannerMessages.push(action.payload);
    },
    removeBannerMessage: (state, action) => {
      state.bannerMessages = state.bannerMessages.filter(
        (message) => message.bannerMessage !== action.payload.bannerMessage,
      );
    },
    setDocumentData: (state, action) => {
      state.currentDocument = action.payload;
      if (state.currentDocument?.parentTemplate) {
        state.templateView = true;
      }
      if (!state.currentDocument?.htmlTemplate) {
        state.currentDocument = {
          ...state.currentDocument,
          htmlTemplate: [],
        };
      }
    },
    setAutofillData: (state, action) => {
      state.autofillData = action.payload;
    },
    setScale: (state, action) => {
      state.scale = action.payload;
    },
    setSignedStatus: (state, action) => {
      state.signedStatus = action.payload;
    },
  },
});

export const {
  setMenu,
  setNumberOfPages,
  setDocumentData,
  updateField,
  deleteSelectedFields,
  setActiveField,
  setAutofillData,
  setScale,
  setPlacingItem,
  dropPlacingItem,
  duplicateOnAllPages,
  manageTemplate,
  updateRoleParty,
  cancelRoleUpdate,
  saveRoles,
  setBannerMessage,
  removeBannerMessage,
  arrangeGroup,
  updateRoleFiller,
  setSignedStatus,
  groupFields,
  unGroupFields,
  undo,
  redo,
} = documentEditorSlice.actions;
export const documentEditorSliceReducer = documentEditorSlice.reducer;
