import { faWandSparkles } from '@fortawesome/pro-regular-svg-icons';
import { List } from '@mui/material';
import { Box } from '@mui/system';
import {
  CollapsableMenuGroup,
  FontAwesomeIconComp,
  MenuItemButton,
} from 'components/Common';
import { RoleHeadIcon } from 'components/Icons';
import { getRoleAndOrderFromRoleName } from 'components/ModelViews/Documents/DocumentPdfViewer/utils/roles';
import { colors } from 'consts';
import { conversion } from 'helpers';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { activeFieldsSelector } from 'redux/selectors';
import { updateField } from 'redux/slices';
import { AutofillAssignment, RoleAssignment } from './';

export function FieldAssign() {
  const activeFields = useSelector(activeFieldsSelector);
  const dispatch = useDispatch();

  const getAssignedLabel = () => {
    if (activeFields[0].role) {
      return `Assigned to: ${conversion.camelCaseToWords(
        activeFields[0].role,
      )}`;
    } else if (activeFields[0].autoFill) {
      return conversion.camelCaseToWords(activeFields[0].autoFill);
    } else {
      return 'Fill Now';
    }
  };

  const getAssignedIcon = () => {
    if (activeFields[0].role) {
      const { role, order } = getRoleAndOrderFromRoleName(activeFields[0].role);
      return (
        <RoleHeadIcon
          solid
          role={{
            role: role,
            order: order,
          }}
        />
      );
    } else {
      return <FontAwesomeIconComp fontSize={18} icon={faWandSparkles} />;
    }
  };

  const isFillNowSelected = useMemo(
    () => !activeFields[0]?.role && !activeFields[0]?.autoFill,
    [activeFields],
  );

  // Determine if Auto Fill is selected
  const isAutoFillSelected = useMemo(
    () => !!activeFields[0]?.autoFill,
    [activeFields],
  );

  // Determine if a role is assigned
  const isRoleAssigned = useMemo(() => !!activeFields[0]?.role, [activeFields]);

  const handleSetAsFillNow = () => {
    dispatch(updateField({ properties: { autoFill: null, role: null } }));
  };

  return (
    <List>
      <CollapsableMenuGroup icon={getAssignedIcon()} label={getAssignedLabel()}>
        <Box p={2}>
          <MenuItemButton
            isSelected={isFillNowSelected}
            onClick={handleSetAsFillNow}
            dense
            label={'Fill Now'}
          />
          {activeFields.length === 1 && activeFields[0].type !== 'checkbox' && (
            <CollapsableMenuGroup
              dense
              selectedColor={colors.DARK_BLUE_1}
              label={'Auto Fill'}
              isSelected={isAutoFillSelected}
            >
              <AutofillAssignment />
            </CollapsableMenuGroup>
          )}
          <CollapsableMenuGroup
            dense
            selectedColor={colors.DARK_BLUE_1}
            label={'Assign Role'}
            isSelected={isRoleAssigned}
          >
            <RoleAssignment />
          </CollapsableMenuGroup>
        </Box>
      </CollapsableMenuGroup>
    </List>
  );
}
