import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ReactSortable } from 'react-sortablejs';
import { Grid } from '@mui/material';
import { htmlTemplateSelectorCM as htmlTemplateSelector } from 'redux/selectors';
import { sortPagesCM as sortPages } from 'redux/slices';
import { PageTab } from './PageTab';

const PagesNavigation = () => {
  const dispatch = useDispatch();
  const htmlTemplate = useSelector(htmlTemplateSelector);
  const pageOrder = htmlTemplate?.options?.pageOrder || [];
  const pageItems = useMemo(() => {
    return pageOrder.map((pageId) => {
      return {
        id: pageId,
        chosen: false,
        selected: false,
      };
    });
  }, [pageOrder]);

  return (
    <ReactSortable
      style={{ height: '100%' }}
      list={pageItems}
      setList={(sortableState) => {
        let pageIds = sortableState.map((item) => item.id);
        if (pageIds.length > 0) dispatch(sortPages(pageIds));
      }}
    >
      <Grid
        container
        flexDirection={'column'}
        sx={{ background: '#ababab', minHeight: '100%' }}
      >
        {pageItems.map((item) => {
          let key = `page-tab-${item.id}`;
          return (
            <React.Fragment key={key}>
              <PageTab pageId={item.id} />
            </React.Fragment>
          );
        })}
      </Grid>
    </ReactSortable>
  );
};
export default PagesNavigation;
