import { removeFieldById, updateOrAddField } from './fields';

export const trackFieldChange = (state, changes) => {
  // Filter out changes where before and after states are the same

  const meaningfulChanges = changes.filter(
    (change) => {
      return JSON.stringify(change.before) !== JSON.stringify(change.after);

    },
  );

  if (meaningfulChanges.length > 0) {
    state.undoStack.push(meaningfulChanges);
    state.redoStack = [];
  }
};

export const applyAndTrackChanges = (state, modifyFunction) => {
  let changes = []; // Initialize an array to capture changes

  state.currentDocument.htmlTemplate = state.currentDocument.htmlTemplate.map(
    (field) => {
      if (state.activeFieldIds.includes(field.id)) {
        const before = { ...field }; // Capture the state before modification

        modifyFunction(field); // Apply the modification passed as modifyFunction

        const after = { ...field }; // Capture the state after modification
        changes.push({ fieldId: field.id, before, after }); // Track the change

        return field; // Return the modified field
      }
      return field; // Return unmodified fields as-is
    },
  );

  // If there are changes, track them
  if (changes.length > 0) {
    trackFieldChange(state, changes);
  }
};

export const applyUndoRedo = (state, changes, actionType) => {
  changes.forEach((change) => {
    if (actionType === 'undo') {
      if (change.before === null) {
        // If before is null, the field was added, so remove it
        removeFieldById(state, change.fieldId);
      } else {
        // Otherwise, revert to the before state
        updateOrAddField(state, change.fieldId, change.before);
      }
    } else if (actionType === 'redo') {
      if (change.before === null) {
        // If after is null, the field was deleted, so remove it
        removeFieldById(state, change.fieldId);
      } else {
        // Otherwise, apply the after state
        updateOrAddField(state, change.fieldId, change.before);
      }
    }
  });
};
