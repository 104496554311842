import { createApi } from "@reduxjs/toolkit/query/react";

import { axiosBaseQuery } from "utils/baseQuery";
import { transformResponse } from "helpers";

import { tagUrlBuilder } from "./urlBuilder/tags";

export const tagsAPI = createApi({
  reducerPath: "tagsAPI",
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  tagTypes: ["Tags"],
  endpoints: build => ({
    getTags: build.query({
      query: args => ({
        url: tagUrlBuilder.getList(args),
        method: "GET",
      }),
      transformResponse,
      providesTags: ["Tags"],
    }),
  }),
});

export const { useGetTagsQuery } = tagsAPI;
