import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';

import { BoxName, Name } from 'components/Styled';

export const Username = styled(Name)({
  fontSize: '16px',
  lineHeight: '24px',
  color: '#565656',
  marginBottom: '24px',
});

export const FieldBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
});

export const FieldName = styled(Typography)({
  fontWeight: '400',
  fontSize: '13px',
  lineHeight: '20px',
  color: '#969696',
});

export const FieldValue = styled(FieldName)({
  color: '#1C1C1C',
});

export const Divider = styled(BoxName)({
  width: '100%',
  margin: '9px 0',
});
