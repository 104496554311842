import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {
  Box,
  ClickAwayListener,
  SwipeableDrawer,
  useMediaQuery,
} from '@mui/material';
import { styled } from '@mui/system';
import { SmallDrawer } from 'components/Layouts/common/SmallDrawer';
import React, { useState } from 'react';
import { ModuleMenu } from './components/ModuleMenu';
import { RecentlyViewed } from './components/RecentlyViewed';
import { DividerStyled } from './styled';

const Tab = styled(Box)(({ theme, leftPosition }) => ({
  position: 'fixed',
  left: leftPosition,
  top: '50%',
  transform: 'translateY(-50%)',
  zIndex: 1201, // Above the drawer
  backgroundColor: theme.palette.background.paper,
  borderRadius: '0 50% 50% 0',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  padding: '8px',
  transition: 'left 200ms ease', // Match drawer's transition speed and type
  border: '1px solid #ddd',
  borderLeft: 'none',
}));

export const LeftMenuDrawer = () => {
  const [open, setOpen] = useState(false);
  const [fullMenu, setFullMenu] = useState(false);
  const [showText, setShowText] = useState(false);
  const [drawerWidth, setDrawerWidth] = useState(0); // State to store drawer width during drag
  const isSm = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const handleDrawerOpen = () => {
    setOpen(true);
    setShowText(true);
    setDrawerWidth(220); // Full width when open
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setTimeout(() => {
      setShowText(false);
    }, 200);
    setDrawerWidth(0); // Reset width when closed
  };

  const handleDrawerMove = (event) => {
    if (event.touches && event.touches.length) {
      const touch = event.touches[0];
      setDrawerWidth(Math.min(Math.max(touch.clientX, 0), 220)); // Update width during drag
    }
  };

  const handleCloseClick = () => {
    if (open) {
      handleDrawerClose();
    } else {
      handleDrawerOpen();
    }
  };

  return (
    <>
      {isSm ? (
        <>
          <ClickAwayListener onClickAway={handleDrawerClose}>
            <Box>
              <Tab leftPosition={drawerWidth} onClick={handleCloseClick}>
                {open ? <ArrowBackIcon /> : <ArrowForwardIcon />}
              </Tab>
              <SwipeableDrawer
                transitionDuration={200}
                PaperProps={{
                  style: {
                    top: 48,
                    maxWidth: 220,
                    boxShadow: open ? '11px 4px 8px -5px rgb(0 0 0 / 4%)' : '',
                  },
                }}
                variant="temporary"
                slotProps={{
                  backdrop: {
                    style: {
                      backgroundColor: 'transparent',
                    },
                  },
                }}
                open={open}
                onOpen={handleDrawerOpen}
                onClose={handleDrawerClose}
                onTouchMove={handleDrawerMove} // Update position on drag
              >
                <ModuleMenu
                  open={open}
                  fullMenu={fullMenu}
                  setFullMenu={setFullMenu}
                />
                <DividerStyled sx={{ width: '85%', margin: '0 auto' }} />
                <RecentlyViewed
                  open={showText}
                  fullMenu={fullMenu}
                  setFullMenu={setFullMenu}
                />
              </SwipeableDrawer>
            </Box>
          </ClickAwayListener>
        </>
      ) : (
        <SmallDrawer
          variant="permanent"
          anchor="left"
          open={open}
          onClose={handleDrawerClose}
          onMouseEnter={handleDrawerOpen}
          onMouseLeave={handleDrawerClose}
        >
          <ModuleMenu
            open={open}
            fullMenu={fullMenu}
            setFullMenu={setFullMenu}
          />
          <DividerStyled sx={{ width: '85%', margin: '0 auto' }} />
          <RecentlyViewed
            open={showText}
            fullMenu={fullMenu}
            setFullMenu={setFullMenu}
          />
        </SmallDrawer>
      )}
    </>
  );
};
