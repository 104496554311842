import { CreateButton, Table } from 'components/Common';
import { CREATE_INVOICE_TEMPLATE_CATEGORY } from 'consts';
import React, { useEffect } from 'react';
import {
  useGetInvoiceCategoriesListQuery,
  useLazyGetInvoiceCategoriesListQuery,
} from 'redux/rtk-query';
import { Cells, MenuItems } from './config';

export const CategoriesTable = () => {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('name');
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [search, setSearch] = React.useState('');
  const [expandedRows, setExpandedRows] = React.useState({});
  const orderByKey = orderBy
    .split(/\.?(?=[A-Z])/)
    .join('_')
    .toLowerCase();

  const visibleColumnIds = Cells.filter((col) => col.isVisible).map(
    (col) => col.id,
  );
  const { data, isFetching } = useGetInvoiceCategoriesListQuery({
    page: page,
    limit: rowsPerPage,
    search: search,
    order: order.toUpperCase(),
    orderBy: orderByKey,
    column: visibleColumnIds,
    parentId: "NULL",
  });
  const [fetchChildCategories] = useLazyGetInvoiceCategoriesListQuery();
  const [loading, setLoading] = React.useState(true);
  const totalPages = data?.meta?.totalPages || 1;
  const totalCount = data?.data.length;
  const rows = data?.data;

  useEffect(() => {
    setLoading(isFetching);
  }, [isFetching]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleRowExpand = async (rowId) => {
    if (expandedRows[rowId]) {
      setExpandedRows((prev) => ({
        ...prev,
        [rowId]: null,
      }));
    } else {
      const result = await fetchChildCategories({ parentId: rowId });
      setExpandedRows((prev) => ({
        ...prev,
        [rowId]: result.data.data,
      }));
    }
  };

  return (
    <>
      <Table
        handleSearch={setSearch}
        search={search}
        afterSearch={
          <CreateButton
            path={CREATE_INVOICE_TEMPLATE_CATEGORY}
            title="Add New"
          />
        }
        isLoading={loading}
        rows={rows}
        totalPages={totalPages}
        totalCount={totalCount}
        order={order}
        menuItems={MenuItems()}
        cells={Cells}
        orderBy={orderBy}
        page={page}
        rowsPerPage={rowsPerPage}
        handleRequestSort={handleRequestSort}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
        isCollapsible
        handleRowExpand={handleRowExpand}
        expandedRows={expandedRows}
      />
    </>
  );
};
