import { nanoid } from 'nanoid';
import { conversion } from "helpers";
import { isFillNow } from "components/ModelViews/Documents/DocumentPdfViewer/utils/field";
import { getRoleNameFromWords } from "components/ModelViews/Documents/DocumentPdfViewer/utils/roles";

export const updateProperties = (properties) => {
  if (properties?.role) {
    properties.autoFill = null;
  } else if (properties?.autoFill) {
    properties.role = null;
  }
  return properties;
};

//setActiveField helpers
export const reflectPosition = (state, fieldId, fieldIds, properties) => {
  if (fieldId && properties?.left && properties?.top) {
    const currentField = state.currentDocument.htmlTemplate.find(
      (field) => field.id === fieldId,
    );
    if (currentField.groupId || state.activeFieldIds.length > 1) {
      const fields = state.currentDocument.htmlTemplate.filter(
        (field) =>
          state.activeFieldIds.includes(field.id) ||
          (field.groupId && field.groupId === currentField.groupId),
      );

      const leftMove = parseInt(properties.left) - parseInt(currentField.left);
      const topMove = parseInt(properties.top) - parseInt(currentField.top);
      fields.forEach((field) => {
        //left and top must be greater than 0
        field.left = `${Math.max(parseInt(field.left) + leftMove, 0)}px`;
        field.top = `${Math.max(parseInt(field.top) + topMove, 0)}px`;
      });
    }
  }
};

export const parseActiveFieldIds = (payload) =>
  Array.isArray(payload) ? payload : [payload];

export const createInitialsForSigners = (
  state,
  x,
  y,
  width,
  height,
  pageNumber,
) => {
  const customerRoles = conversion.getCustomerRoles(
    state.currentDocument.roles,
  );
  let createdIds = [];
  const itemHeight = state.fieldsTemplates.signatureInitialField.height;
  const itemWidth = state.fieldsTemplates.signatureInitialField.width;
  const itemSpacing = 10;
  const signerCount = customerRoles.filter((role) => role.type === 'signer');

  signerCount.forEach((role, index) => {
    const col = Math.floor(index / 2);
    const row = index % 2;

    // Calculate the total height based on the number of items in a column
    // If only one signer, we use just one itemHeight; otherwise, calculate for two plus spacing
    const itemsInColumn = signerCount.length === 1 ? 1 : 2;
    const totalHeight = itemsInColumn * itemHeight + (itemsInColumn - 1) * itemSpacing;

    // Calculate the starting y coordinate to center align vertically within the column
    // Adjust startY based on whether there is one or multiple signers
    const startY = y + (height - totalHeight) / 2;

    // Calculate the x coordinate to align to the right side
    // If only one signer, center it; otherwise, place them as originally planned
    const startX = x + width + 10 + col * (itemWidth + itemSpacing);

    const customerInitial = createNewItem({
      x: startX,
      y: startY + row * (itemHeight + itemSpacing),
      width: itemWidth,
      height: itemHeight,
      pageNumber,
      placingItem: 'signatureInitialField',
      role: getRoleNameFromWords(role),
    });
    customerInitial.required = 'yes';
    state.currentDocument.htmlTemplate.push(customerInitial);
    createdIds.push(customerInitial.id);
  });
  return createdIds;

};

export const collectActiveFields = (template, activeFieldIds) =>
  template.filter((field) => activeFieldIds.includes(field.id));

export const extractGroupIds = (activeFields) => [
  ...new Set(
    activeFields.filter((field) => field.groupId).map((field) => field.groupId),
  ),
];

export const collectGroupFieldIds = (template, groupIds) =>
  template
    .filter((field) => groupIds.includes(field.groupId))
    .map((field) => field.id);

const sanitizePosition = (position) => Math.max(position, 0);

export const createNewItem = ({
  x,
  y,
  width,
  height,
  pageNumber,
  placingItem,
  role,
}) => ({
  id: nanoid(),
  pageContainer: `pageContainer${pageNumber}`,
  type: placingItem,
  width: `${width}px`,
  height: `${height}px`,
  left: `${sanitizePosition(x)}px`,
  top: `${sanitizePosition(y)}px`,
  value: '',
  role,
});

export const assignRoleToNewItem = (placingItem, roles, fieldTemplate) => {
  if (
    [
      'signatureField',
      'signatureInitialField',
      'signDate',
      'printName',
    ].includes(placingItem)
  ) {
    const signerRoles = roles.filter((role) => role.type === 'signer');
    if (signerRoles.length === 1) {
      return `${conversion.wordsToCamelCase(signerRoles[0].role)}${
        signerRoles[0].order
      }`;
    }
  }
  return fieldTemplate.role; // Default role from field template
};

export const updateFieldTemplates = (
  state,
  placingItem,
  width,
  height,
  role,
) => {
  state.fieldsTemplates[placingItem] = { width, height, role };
  if (
    placingItem === 'signatureField' ||
    placingItem === 'signatureInitialField'
  ) {
    state.fieldsTemplates['signDate'] = {
      ...state.fieldsTemplates['signDate'],
      role,
    };
    state.fieldsTemplates['printName'] = {
      ...state.fieldsTemplates['printName'],
      role,
    };
  }
};

export const alignItems = (field, extremePosition, direction) => {
  switch (direction) {
    case 'left':
      field.left = `${extremePosition}px`;
      break;
    case 'right':
      field.left = `${extremePosition - parseFloat(field.width)}px`;
      break;
    case 'top':
      field.top = `${extremePosition}px`;
      break;
    case 'bottom':
      field.top = `${extremePosition - parseFloat(field.height)}px`;
      break;
    default:
      console.warn('Invalid direction specified for alignment');
  }
};

// Helper function to find extreme positions based on direction
export const findExtremePosition = (itemsToArrange, direction) => {
  const positions = itemsToArrange.map((item) => {
    if (direction === 'left' || direction === 'right') {
      return (
        parseFloat(item.left) +
        (direction === 'right' ? parseFloat(item.width) : 0)
      );
    } else {
      return (
        parseFloat(item.top) +
        (direction === 'bottom' ? parseFloat(item.height) : 0)
      );
    }
  });
  return direction === 'left' || direction === 'top'
    ? Math.min(...positions)
    : Math.max(...positions);
};

export const adjustGroupCheckboxFieldsOnUpdate = (
  state,
  fieldId,
  properties,
) => {
  const currentItem = state.currentDocument.htmlTemplate.find(
    (field) => field.id === fieldId,
  )?.type;
  const currentItemType = currentItem?.type;
  const currentItemGroup = currentItem?.groupId;
  if (currentItemType === 'checkbox' && currentItemGroup && properties?.value) {
    const groupItems = state.currentDocument.htmlTemplate.filter(
      (field) =>
        field.groupId ===
        state.currentDocument.htmlTemplate.find((field) => field.id === fieldId)
          .groupId,
    );
    groupItems.forEach((item) => {
      item.value = properties.value;
    });
  }
};

export const adjustFieldTemplatesOnUpdate = (state, fieldId, properties) => {
  if (!fieldId) return;
  const currentField = state.currentDocument.htmlTemplate.find(
    (field) => field.id === fieldId,
  );
  if (properties?.height && properties?.width) {
    const height = parseInt(properties.height);
    const width = parseInt(properties.width);
    if (height && width) {
      if (height > 10 && width > 10) {
        state.fieldsTemplates[currentField.type] = {
          width: width,
          height: height,
          // role: currentField.role,
        };
      }
    }
  }
  if (properties?.role) {
    if (
      currentField.type === 'signatureField' ||
      currentField.type === 'signatureInitialField'
    ) {
      state.fieldsTemplates['signDate'] = {
        ...state.fieldsTemplates['signDate'],
        role: properties.role,
      };
      state.fieldsTemplates['printName'] = {
        ...state.fieldsTemplates['printName'],
        role: properties.role,
      };
      state.fieldsTemplates['signatureField'] = {
        ...state.fieldsTemplates['signatureField'],
        role: properties.role,
      };
      state.fieldsTemplates['signatureInitialField'] = {
        ...state.fieldsTemplates['signatureInitialField'],
        role: properties.role,
      };
    }
  }
};

export const checkForRequiredFields = (state) => {
  const message = {
    severity: 'error',
    bannerMessage:
      'There are required fields that must be filled before signing.',
  };
  if (state.activeMenu === 'finalizeDocument') {
    const fieldToReturn = state.currentDocument.htmlTemplate.find((field) => {
      if (
        isFillNow(field) &&
        (field.required === true || field.required === 'yes') &&
        !field.value
      ) {
        if (field.type === 'checkbox') {
          if (field.groupId) {
            const groupItems = state.currentDocument.htmlTemplate.filter(
              (item) => item.groupId === field.groupId,
            );
            const groupHasValue = groupItems.some(
              (groupItem) => groupItem.value,
            );
            return !groupHasValue;
          } else {
            return true; // Checkbox without a group and no value
          }
        }
        // Check for non-checkbox fields or checkboxes that are not part of a group
        return (
          field.value === null ||
          (typeof field.value === 'string' &&
            (!field.value || field.value.trim() === ''))
        );
      }
      return false;
    });

    if (fieldToReturn) {
      state.bannerMessages.push(message);
      // return fieldToReturn;
    }
  } else {
    state.bannerMessages = state.bannerMessages.filter(
      (bannerMessage) => bannerMessage.bannerMessage !== message.bannerMessage,
    );
  }
};

export function removeFieldById(state, fieldId) {
  state.currentDocument.htmlTemplate =
    state.currentDocument.htmlTemplate.filter((field) => field.id !== fieldId);
}

export function updateOrAddField(state, fieldId, newFieldData) {
  const fieldIndex = state.currentDocument.htmlTemplate.findIndex(
    (field) => field.id === fieldId,
  );

  if (fieldIndex !== -1) {
    // Field exists, update it
    state.currentDocument.htmlTemplate[fieldIndex] = newFieldData;
  } else {
    // Field does not exist, add it
    state.currentDocument.htmlTemplate.push(newFieldData);
  }
}
