import { DetailsLayout } from 'components/Layouts';
import { conversion } from 'helpers';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useGetUserQuery } from 'redux/rtk-query';

import { UserDetails } from './UserDetails';
import { UserInfo } from './UserInfo';

export const ViewUserPage = () => {
  const [user, setUser] = useState(null);
  let { userId } = useParams();
  const { data, isSuccess } = useGetUserQuery(userId);
  useEffect(() => {
    if (isSuccess) {
      setUser({
        ...data.data.data,
        contact: { id: 1, name: 'Gigi Cardano' },
        setting: { id: 0, name: 'Gigi Cardano' },
        name: conversion.getName(data.data.data),
      });
    }
  }, [isSuccess]);
  // const user = useMemo(
  //   () =>
  //     data?.data ?? {
  //       // delete after getting real user data from BE
  //       uuid: userId,
  //       avatar: "",
  //       name: "Juan Carlos Perez",
  //       username: "Username",
  //       contactName: "Juan Carlos Perez",
  //       settingName: "Mainframe Real Estate - Central Florida",
  //     },
  // );

  return (
    <>
      {user && (
        <DetailsLayout
          leftContent={<UserDetails user={user} />}
          rightContent={<UserInfo userId={userId} />}
        />
      )}
    </>
  );
};
