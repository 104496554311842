import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Box } from '@mui/system';
import { activeFieldIdsSelector } from 'redux/selectors';
import { ActiveFieldMenu, PrimaryMenu } from './components';

export const ClickMarketingMenu = ({ headerHeight }) => {
  const activeFieldIds = useSelector(activeFieldIdsSelector);

  return (
    <Box
      sx={{
        background: 'white', // background color of the menu
        height: `calc(100vh - ${headerHeight}px)`,
        overflowY: 'scroll',
      }}
    >
      {activeFieldIds.length === 0 ? <PrimaryMenu /> : <ActiveFieldMenu />}
    </Box>
  );
};

ClickMarketingMenu.propTypes = {
  headerHeight: PropTypes.number,
};
