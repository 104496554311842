import { faCircle } from '@fortawesome/pro-light-svg-icons';
import { faQuestionCircle } from '@fortawesome/pro-regular-svg-icons';
import { Tooltip } from '@mui/material';
import { FontAwesomeIconComp } from 'components/Common';
import PropTypes from 'prop-types';
import React from 'react';

export const FieldTooltip = ({ tooltip, top, right }) => {
  return (
    <Tooltip title={tooltip.title}>
      <span
        style={{
          position: 'absolute',
          right: right ? right : -4,
          top: top ? top : 1,
        }}
      >
        {tooltip.icon ? (
          <span
            className="fa-layers fa-fw"
            style={{
              verticalAlign: 'top',
              background: 'white',
              borderRadius: '100%',
            }}
          >
            <FontAwesomeIconComp
              color={tooltip.color ? tooltip.color : '#45aaf2'}
              icon={tooltip.icon}
              fontSize={10}
            />
            <FontAwesomeIconComp
              color={tooltip.color ? tooltip.color : '#45aaf2'}
              icon={faCircle}
              size="lg"
            />
          </span>
        ) : (
          <FontAwesomeIconComp
            icon={faQuestionCircle}
            color={tooltip.color ? tooltip.color : '#45aaf2'}
            style={{
              fontSize: tooltip.fontSize ? tooltip.fontSize : 16,
              zIndex: 9,
              backgroundColor: 'white',
              borderRadius: '100%',
            }}
          />
        )}
      </span>
    </Tooltip>
  );
};

FieldTooltip.propTypes = {
  tooltip: PropTypes.object,
  top: PropTypes.number,
  right: PropTypes.number,
};
