import React, { useEffect } from 'react';
import { useForm } from 'react-final-form';
import { Grid } from '@mui/material';
import { ReportFilters } from 'pages/ReportPages/CreateReportPage/ReportFiltersPage/ReportFilters';
import { useGetFiltersQuery } from 'redux/rtk-query';

export const ReportFiltersPage = () => {
  const form = useForm();
  const id = form.getState().values.id;

  console.log(form.getState().values.id);
  const { data: filters } = useGetFiltersQuery(id, { skip: !id });

  const mapFiltersData = (filterGroup) => ({
    name: filterGroup.name || '',
    groupingCondition: filterGroup.groupingCondition || 'AND',
    isEditable: filterGroup.isEditable || false,
    childGroups: filterGroup.childGroups
      ? filterGroup.childGroups.map(mapFiltersData)
      : [],
    filters: filterGroup.filters
      ? filterGroup.filters.map((filter) => ({
          name: filter.name || '',
          path: filter.path || [],
          condition: filter.condition || 'EQUALS',
          value: filter.value || '',
          isEditable: filter.isEditable || false,
        }))
      : [],
  });

  useEffect(() => {
    if (filters?.data) {
      // Map the filters data
      const mappedFilters = mapFiltersData(filters?.data);
      // Set the 'filters' field in the form state
      form.change('filters', [mappedFilters]);
    }
  }, [filters, form]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <ReportFilters form={form} />
      </Grid>
    </Grid>
  );
};

ReportFiltersPage.propTypes = {};
