import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { hexToRgb } from '@mui/system';
import { useAutofillData } from 'components/ModelViews/Documents/ClickMarketingViewer/hooks/useAutofillData';
import { useCurrentItem } from 'components/ModelViews/Documents/ClickMarketingViewer/hooks/useCurrentItem';
import { getComputedBorderRadius } from 'components/ModelViews/Documents/ClickMarketingViewer/utils/viewer';

export const Shape = React.memo(({ itemId }) => {
  const currentItem = useCurrentItem(itemId);
  const autoFillData = useAutofillData();

  const getColor = useCallback(
    (color) => {
      if (color === 'customColor') {
        if (autoFillData.customColor === 'mainframeTheme') {
          return currentItem.style?.mainframeThemeColor || '#FFFFFF';
        }
        return autoFillData.customColor || '#48484A';
      }
      return color;
    },
    [
      autoFillData.customColor,
      currentItem.style?.mainframeThemeColor,
      currentItem.type,
    ],
  );

  // Memoize the style object to avoid recalculating on every render
  const style = useMemo(() => {
    const computedBorderRadius = getComputedBorderRadius(currentItem);

    const divStyle = {
      position: 'relative',
      zIndex: -1,
      backgroundColor: getColor(currentItem.style?.backgroundColor),
      height: '100%',
      width: '100%',
      opacity: currentItem?.style?.transparency,
      borderRadius: currentItem.roundedLine
        ? currentItem.h + 'px'
        : computedBorderRadius,
      overflow:
        currentItem.roundedLine ||
        (currentItem.style?.borderRadius &&
          parseInt(currentItem.style?.borderRadius?.replace('%', '')) > 0)
          ? 'hidden'
          : 'visible',
      borderStyle: 'solid',
      borderWidth: currentItem?.style?.borderWidth
        ? currentItem?.style?.borderWidth + 'px'
        : '0',
      borderColor: currentItem?.style?.borderColor
        ? getColor(currentItem?.style?.borderColor)
        : '#000',
    };

    // Check if background property exists
    if (currentItem?.style?.background) {
      divStyle.background = getColor(currentItem?.style?.background);

      // If backgroundThemeColor is present, blend the theme color with background
      if (currentItem.style?.backgroundThemeColor) {
        const themeColor =
          autoFillData.customColor === 'mainframeTheme'
            ? '#FFFFFF'
            : autoFillData.customColor;
        const rgbString = hexToRgb(themeColor).match(/(\d+),\s*(\d+),\s*(\d+)/);
        const rgba = currentItem.style?.background.match(
          /rgba?\((\d+),\s*(\d+),\s*(\d+),\s*(\d*\.?\d+)\)/,
        );

        if (rgbString && rgba) {
          divStyle.background = `rgba(${rgbString[1]}, ${rgbString[2]}, ${rgbString[3]}, ${rgba[4]})`;
        }
      }
    }

    return divStyle;
  }, [
    currentItem.style?.backgroundColor,
    currentItem.style?.background,
    currentItem.style?.backgroundThemeColor,
    currentItem.style?.borderColor,
    currentItem.style?.borderRadius,
    currentItem.style?.borderWidth,
    currentItem.style?.transparency,
    currentItem.roundedLine,
    currentItem.w,
    currentItem.h,
    getColor,
    autoFillData.customColor,
  ]);

  return <div style={style}></div>;
});

Shape.displayName = 'Shape';

Shape.propTypes = {
  itemId: PropTypes.string.isRequired,
};
