export const INVOICE_STATUS = {
    PAST: 'Past',
    CURRENT: 'Current',
    FUTURE: 'Future',
};

export const PRICE_FREQUENCY = {
    ONE_TIME: 'One Time',
    MONTHLY: 'Monthly',
    ANNUALLY: 'Annually',
    QUARTERLY: 'Quarterly',
}