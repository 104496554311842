import { Typography } from '@mui/material';
import { DateTimeCell, TaskRelatedModel } from 'components/CellComponents';
import { PageLink } from 'components/Styled';
import { UPDATE_NOTE } from 'consts';
import { conversion } from 'helpers';
import PropTypes from 'prop-types';
import React from 'react';

const FullName = ({ value }) => {
  return conversion.getName(value);
};

FullName.propTypes = {
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

const NoteName = ({ value }) => {
  return value ? (
    <div
      style={{
        maxWidth: '500px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      }}
    >
      <Typography
        variant="subtitle2"
        component={PageLink}
        to={`${UPDATE_NOTE(value.id)}`}
        noWrap
      >
        {value.description}
      </Typography>
    </div>
  ) : (
    <></>
  );
};

NoteName.propTypes = {
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

// function that take a var 'value' that has key of roles. return the first two roles contacts names that CONTAIN Buyer or Seller or Landlord or Tenant
const TransactionCustomers = ({ value }) => {
  const customersString = conversion.getCustomersString(value);
  return <div>{customersString}</div>;
};

TransactionCustomers.propTypes = {
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

export const Cells = [
  {
    id: 'createdAt',
    value: 'createdAt',
    label: 'Date',
    Wrapper: DateTimeCell,
    disableSort: true,
  },
  {
    id: 'description',
    value: ['description', 'id'],
    numeric: false,
    disablePadding: true,
    label: 'Description',
    Wrapper: NoteName,
    disableSort: true,
  },
  {
    id: 'linkedTo',
    value: ['contact', 'transaction', 'id'],
    label: 'Related Item',
    Wrapper: TaskRelatedModel,
    disableSort: true,
  },
  {
    id: 'createdBy',
    value: 'createdBy',
    label: 'Created By',
    Wrapper: FullName,
    disableSort: true,
  },
];
