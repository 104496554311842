import { styled } from '@mui/material/styles';
import { Box, Toolbar } from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';

export const AppBar = styled(MuiAppBar)({
  backgroundColor: '#FFFFFF',
  boxShadow: 'none',
  borderLeft: 'none',
});

export const NavbarWrapper = styled(Toolbar)({
  justifyContent: 'space-between',
  minHeight: '48px !important',
});

export const UserMenuWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  marginRight: '20px',
  alignItems: 'center',

  [theme.breakpoints.down('sm')]: {
    marginRight: 0,
  },
}));

export const CreateTransactionButton = styled(Box)({
  alignItems: 'center',
  border: '1px solid #E8E8E8',
  background: 'transparent',
  padding: '4px 8px',
  width: '78px',
  height: '28px',
  borderRadius: '8px',
  marginRight: '24px',
  cursor: 'pointer',
  fontSize: '13px',
  lineHeight: '20px',
  boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.06)',
});

export const UserMenuButtonAndAvatar = styled(Box)({
  padding: 0,
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
});
