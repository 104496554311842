import { omitNull } from 'helpers';
import { defaultValues } from './defaultValues';

export const processFormData = (values) => {
  let formData = values.values ? values.values : values;
  let allValues = { ...defaultValues, ...omitNull(formData) };

  const { agent, companyStructureUnits, shareWith, ...filteredAllValues } =
    allValues;

  return {
    ...filteredAllValues,
    invoiceTemplateId: values.invoiceTemplate?.id,
    transactionId: values.transaction?.id,
    contactId: values.contact?.id,
    paidToContactId: values.paidTo?.id,
    paidFromContactId: values.paidFrom?.id,
    agentId: agent?.id ? agent.id : agent?.value,
    shareWithIds: shareWith?.map(({ value }) => value),
    companyStructureUnitsIds: companyStructureUnits?.map(({ value }) => value),
  };
};
