import { sortRoles } from 'components/ModelViews/Documents/DocumentPdfViewer/utils/roles';
import React from 'react';
import { useSelector } from 'react-redux';
import { roleSelector } from 'redux/selectors';
import RoleAssignmentOption from './components/RoleAssignmentOption';

export function RoleAssignment() {
  const roles = useSelector(roleSelector);
  const signerRoles = roles.filter((role) => role.type === 'signer');
  const sortedRoles = sortRoles(signerRoles);

  return (
    <>
      {sortedRoles.map((role, index) => (
        <RoleAssignmentOption key={index} role={role} />
      ))}
    </>
  );
}
