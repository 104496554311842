import { createApi } from "@reduxjs/toolkit/query/react";

import { axiosBaseQuery } from "utils/baseQuery";
import {noteUrlBuilder} from "./urlBuilder/notes";
import {contactUrlBuilder} from "./urlBuilder/contacts";
import {transactionUrlBuilder} from "./urlBuilder/transactions";

export const notesAPI = createApi({
  reducerPath: "notesAPI",
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  tagTypes: ["Notes"],
  endpoints: build => ({
    createNote: build.mutation({
      query: body => ({
        url: noteUrlBuilder.createNote(),
        method: "POST",
        data: body,
      }),
      invalidatesTags: ["Notes"],
    }),
    updateNote: build.mutation({
      query: body => ({
        url: noteUrlBuilder.updateNote(),
        method: "PUT",
        data: body,
      }),
      invalidatesTags: ["Notes"],
    }),
    getNote: build.query({
      query: args => ({
        url: noteUrlBuilder.getNote(args),
        method: "GET",
      }),
      providesTags: ["Notes"],
    }),
    getNoteMedia: build.query({
      query: args => ({
        url: noteUrlBuilder.getNoteMedia(args),
        method: "GET",
      }),
      providesTags: ["Notes"],
    }),
    noteFileUpload: build.mutation({
      query: ({uuid, files}) => ({
        url: noteUrlBuilder.noteFileUpload(uuid),
        method: "POST",
        data: files,
      }),
      invalidatesTags: ["Notes"],
    }),
    noteFileDelete: build.mutation({
      query: ({uuid, files}) => ({
        url: noteUrlBuilder.noteFileDelete(uuid),
        method: "DELETE",
        data: files,
      }),
      invalidatesTags: ["Notes"],
    }),
    getNotes: build.query({
      query: args => ({
        url: noteUrlBuilder.getNotes(args),
        method: "GET",
      }),
      providesTags: ["Notes"],
    }),
    getContactNotes: build.query({
      query: args => ({
        url: contactUrlBuilder.getNotes(args),
        method: "GET",
      }),
      providesTags: ["Notes"],
    }),
    getTransactionNotes: build.query({
        query: args => ({
            url: transactionUrlBuilder.getNotes(args),
            method: "GET",
        }),
        providesTags: ["Notes"],
    }),
  }),
});

export const {
  useCreateNoteMutation,
  useUpdateNoteMutation,
  useNoteFileDeleteMutation,
  useNoteFileUploadMutation,
  useGetContactNotesQuery,
  useGetTransactionNotesQuery,
  useGetNoteQuery,
  useGetNotesQuery,
  useGetNoteMediaQuery
} = notesAPI;
