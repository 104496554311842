import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import {
  ListItemButton,
  Toolbar,
  ListItemIcon,
  ListItemText,
  Divider,
  Box,
} from "@mui/material";

const drawerWidthOpen = 220;
const drawerWidthClosed = 80;

export const BoxDrawer = styled(Box)({
  position: "relative",
  width: "85px",
  display: "flex",
  transition: 'all 200ms ease', // Ensure this matches the Drawer transition
});

const openedMixin = theme => ({
  borderRight: "none",
  width: drawerWidthOpen,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = theme => ({
  borderRight: "none",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: drawerWidthClosed,
});

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: prop => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidthOpen,
  flexShrink: 0,
  padding: 10,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  position: "absolute",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export const ToolbarWithLogo = styled(Toolbar)({
  justifyContent: "flex-start",
});

export const UpperListItemButton = styled(ListItemButton)({
  justifyContent: "center",
  minHeight: 48,
  padding: 6,
  borderRadius: "24px",
  margin: "0 5px",
  "&:hover": {
    background: "rgba(0, 133, 255, 0.1)",
  },
});

export const UpperListItemIcon = styled(ListItemIcon)({
  minWidth: 0,
  justifyContent: "center",
  alignItems: "center",
  width: "24px",
  height: "24px",
});

export const LowerListItemButton = styled(ListItemButton)({
  height: "28px",
  px: 2.5,
  width: "100%",
  borderRadius: "24px",
  "&:hover": {
    background: "rgba(0, 133, 255, 0.1)",
  },
});

export const LowerListItemIcon = styled(ListItemIcon)({
  minWidth: 0,
  justifyContent: "center",
  alignItems: "center",
  width: "16px",
  height: "20px",
});

export const FullMenuButton = styled(ListItemButton)({
  justifyContent: "center",
  "&:hover": {
    background: "rgba(0, 133, 255, 0.1)",
  },
});

export const FullMenuBtnText = styled(ListItemText)({
  color: "#0085FF",
  width: "100px",
});

export const RecentlyViewedBtn = styled(ListItemButton)({
  minHeight: 48,
  px: 2.5,
  "&:hover": {
    background: "rgba(0, 133, 255, 0.1)",
  },
});

export const RecentlyViewedBtnTxt = styled(ListItemText)({
  color: "#969696",
  fontSize: "12px",
});

export const DividerStyled = styled(Divider)({
  width: "85%",
  margin: "0 auto",
});
