import React, {useEffect, useMemo, useState} from "react";
import {faEye} from "@fortawesome/pro-solid-svg-icons";
import {useNavigate} from "react-router-dom";
import {Table} from 'components/Common';

import {Cells} from "./config";
import {conversion} from "helpers";
import {useField} from "react-final-form";
import PropTypes from "prop-types";
import {useGetParcelsQuery} from "redux/rtk-query";

const RowsPerPageOptions = [15, 25, 50];

export const ParcelsTable = ({onRowClick}) => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(15);
  const [search, setSearch] = useState("");
  const [data, setData] = useState(null);

  const navigate = useNavigate();
  let addressValue = useField("address").input.value;


  const rows = useMemo(() => data?.items || [], [data]);
  const totalPages = useMemo(() => data?.meta?.totalPages, [data]);
  const MenuItems = useMemo(
    () => [
      {
        name: "View",
        icon: faEye,
        onClick: ({ uuid }) => navigate(`/admin/users/${uuid}`),
      },
    ],
    [navigate],
  );

  const {data: parcelData, isSuccess} = useGetParcelsQuery({
      limit: 10,
      page: 1,
      address: conversion.addressToParcelAddress(addressValue.street1),
      state: addressValue.state,
      zip: addressValue.zipCode,
      secondaryNumber: addressValue.street2
  })


    useEffect(() => {
        if(parcelData) {
            setData(parcelData.data)
        }
  }, [isSuccess]);


  return (
    <Table
      title="Parcels"
      onRowClick={onRowClick}
      handleSearch={setSearch}
      search={search}
      isLoading={data === null}
      rows={rows}
      totalPages={totalPages}
      menuItems={MenuItems}
      cells={Cells}
      page={page}
      rowsPerPage={limit}
      rowsPerPageOptions={RowsPerPageOptions}
      setPage={setPage}
      setRowsPerPage={setLimit}
    />
  );
};

ParcelsTable.propTypes = {
    onRowClick: PropTypes.func
};
