import { createApi } from "@reduxjs/toolkit/query/react";

import { axiosBaseQuery } from "utils/baseQuery";

import { roleUrlBuilder } from "./urlBuilder/roles";

export const rolesAPI = createApi({
  reducerPath: "rolesAPI",
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  tagTypes: ["Roles"],
  endpoints: build => ({
    getRoles: build.query({
      query: args => ({
        url: roleUrlBuilder.getList(args),
        method: "GET",
      }),
      providesTags: ["Roles"],
    }),
    getRole: build.query({
      query: args => ({
        url: roleUrlBuilder.getRole(args),
        method: "GET",
      }),
      providesTags: ["Roles"],
    }),
    getRoleUsers: build.query({
      query: args => ({
        url: roleUrlBuilder.getRoleUsers(args),
        method: "GET",
      }),
      invalidatesTags: ["RoleUsers"],
    }),
    createRole: build.mutation({
      query: body => ({
        url: roleUrlBuilder.createRole(),
        method: "POST",
        data: body,
      }),
      invalidatesTags: ["Roles"],
    }),
    editRole: build.mutation({
      query: body => ({
        url: roleUrlBuilder.editRole(),
        method: "PUT",
        data: body,
      }),
      invalidatesTags: ["Roles"],
    }),
    deleteRole: build.mutation({
      query: args => ({
        url: roleUrlBuilder.deleteRole(args),
        method: "DELETE",
      }),
      invalidatesTags: ["Roles"],
    }),
  }),
});

export const {
  useGetRolesQuery,
  useGetRoleQuery,
  useGetRoleUsersQuery,
  useCreateRoleMutation,
  useEditRoleMutation,
  useDeleteRoleMutation,
} = rolesAPI;
