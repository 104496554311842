import { faCirclePlus } from '@fortawesome/pro-regular-svg-icons';
import { Box, Button, Divider } from '@mui/material';
import {
  EmptyResults,
  FontAwesomeIconComp,
  SkeletonSummary,
} from 'components/Common';
import { ToolbarRow } from 'components/Common/Table/TableToolbar/styled';
import { ListPagination } from 'components/ListPagination';
import { SearchInput } from 'components/SearchInput';
import { ListBox, NewButtonBox } from 'components/Styled';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { EmailSummary } from './components/EmailSummary';

// eslint-disable-next-line no-unused-vars
export const EmailsList = ({ relatedModel, relatedModelType }) => {
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  // eslint-disable-next-line no-unused-vars
  const [searchValue, setSearchValue] = useState('');

  const data = {
    success: true,
    message: 'Transaction notes.',
    data: {
      items: [
        {
          id: 5,
          uuid: '2177c57c-ff55-43d6-ad9e-c53b04552959',
          description: 'Congratulations on the Closing of Your New Home!',
          createdAt: '2023-01-19T17:42:40.365Z',
          updatedAt: '2023-01-19T17:43:39.364Z',
          everyone: false,
          to: 'Harold Maudey',
          agent: {
            id: 2,
            uuid: '22a5a65c-acec-4d6d-8fc5-5e0cb491ee79',
            firstName: null,
            lastName: 'ABE',
          },
          sharedWith: null,
          companyStructureUnits: null,
          filesCount: 1,
        },
        {
          id: 7,
          uuid: '30483c4e-e835-468e-b4fd-cbf7cbc949c7',
          description: 'Reminder: Final Walk-Through Tomorrow at 10am',
          createdAt: '2023-01-19T17:42:50.881Z',
          updatedAt: '2023-01-19T17:42:50.881Z',
          everyone: false,
          to: 'Harold Maudey',
          agent: {
            id: 2,
            uuid: '22a5a65c-acec-4d6d-8fc5-5e0cb491ee79',
            firstName: null,
            lastName: 'ABE',
          },
          sharedWith: null,
          companyStructureUnits: null,
          filesCount: 0,
        },
        {
          id: 6,
          uuid: 'f31c9a2c-6c7c-475a-a652-f01433d4c7cc',
          description: 'Important Documents for Signing - Deadline Approaching',
          createdAt: '2023-01-19T17:42:47.062Z',
          updatedAt: '2023-01-19T17:42:47.062Z',
          everyone: false,
          to: 'Harold Maudey',
          agent: {
            id: 2,
            uuid: '22a5a65c-acec-4d6d-8fc5-5e0cb491ee79',
            firstName: null,
            lastName: 'ABE',
          },
          sharedWith: null,
          companyStructureUnits: null,
          filesCount: 0,
        },
        {
          id: 4,
          uuid: '3c2aad00-83ef-4c80-810b-fcb790cd0e12',
          description: 'Escrow Instructions and Timeline',
          createdAt: '2023-01-19T17:42:34.749Z',
          updatedAt: '2023-01-19T17:42:34.749Z',
          everyone: false,
          to: 'Harold Maudey',
          agent: {
            id: 2,
            uuid: '22a5a65c-acec-4d6d-8fc5-5e0cb491ee79',
            firstName: null,
            lastName: 'ABE',
          },
          sharedWith: null,
          companyStructureUnits: null,
          filesCount: 0,
        },
        {
          id: 3,
          uuid: 'c9dd1a96-c7c0-47ae-be99-451482e87cf7',
          description: 'Home Inspection Results - Next Steps',
          createdAt: '2023-01-19T17:42:26.337Z',
          updatedAt: '2023-01-19T17:42:26.337Z',
          everyone: false,
          to: 'Harold Maudey',
          agent: {
            id: 2,
            uuid: '22a5a65c-acec-4d6d-8fc5-5e0cb491ee79',
            firstName: null,
            lastName: 'ABE',
          },
          sharedWith: null,
          companyStructureUnits: null,
          filesCount: 0,
        },
      ],
      meta: {
        page: 1,
        limit: 5,
        total: 6,
        totalPages: 2,
        otherData: {},
      },
    },
  };
  const AllEmails = data?.data?.items;
  const totalPages = data?.data?.meta.totalPages;
  const totalCount = data?.data?.meta.total;

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const createEmail = useCallback(() => {}, [relatedModel]);

  return (
    <>
      <ToolbarRow width="100%" justifyContent="space-between">
        <SearchInput changeHandler={setSearchValue} />
        <NewButtonBox>
          <Button
            size={'small'}
            variant="text"
            onClick={createEmail}
            endIcon={<FontAwesomeIconComp icon={faCirclePlus} />}
          >
            New
          </Button>
        </NewButtonBox>
      </ToolbarRow>
      <Divider/>
      <ListBox>
        {!AllEmails ? (
          <SkeletonSummary />
        ) : AllEmails && AllEmails.length ? (
          AllEmails.map((email, index) => (
            <React.Fragment key={email.id}>
              <EmailSummary email={email} />
              {index + 1 < AllEmails.length && <Divider />}
            </React.Fragment>
          ))
        ) : (
          <EmptyResults />
        )}
        <Box style={{ textAlign: 'center' }}>
          {totalPages > 0 && (
            <ListPagination
              totalCount={totalCount}
              page={page}
              rowsPerPage={rowsPerPage}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              rowsPerPageOptions={[5]}
            />
          )}
        </Box>
      </ListBox>
    </>
  );
};

EmailsList.propTypes = {
  closeHandler: PropTypes.func,
  open: PropTypes.bool,
  relatedModel: PropTypes.object,
  relatedModelType: PropTypes.string,
};
