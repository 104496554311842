import {
  faCheckSquare,
  faEnvelope,
  faFileAlt,
  faFunnelDollar,
  faHistory,
  faMailBulk,
  faStickyNote,
  faUsers,
} from '@fortawesome/pro-light-svg-icons';
import {
  faChartColumn,
  faClipboardListCheck,
  faFolder,
  faGear,
  faHouseBlank,
  faLandmark,
  faReceipt,
  faUsdCircle,
  faUser,
} from '@fortawesome/pro-regular-svg-icons';
import { colors, contactColors, transactionColors } from "consts";

export const getModuleIcon = (moduleName, type) => {
  switch (moduleName) {
    case 'Home':
    case 'home':
      return {
        icon: faHouseBlank,
        color: colors.RED,
      };
    case 'Transactions':
    case 'Transaction':
    case 'transactions':
    case 'transaction':
      return {
        icon: faFolder,
        color: transactionColors[type]
          ? transactionColors[type]
          : colors.LIGHT_BLUE_1,
      };
    case 'Contacts':
    case 'Contact':
    case 'contacts':
    case 'contact':
      return {
        icon: faUser,
        color: contactColors[type] ? contactColors[type] : colors.LIGHT_BLUE_2,
      };
    case 'Activities':
      return {
        icon: faClipboardListCheck,
        color: colors.GREEN,
      };
    case 'Reports':
      return {
        icon: faChartColumn,
        color: colors.LIGHT_PURPLE,
      };
    case 'Commissions':
      return {
        icon: faUsdCircle,
        color: colors.LIGHT_YELLOW,
      };
    case 'Invoices':
      return {
        icon: faReceipt,
        color: colors.LIGHT_ORANGE,
      };
    case 'Roles':
      return {
        icon: faUsers,
      };
    case 'Tasks':
      return {
        icon: faCheckSquare,
        color: '#20bf6b',
      };
    case 'Timeframes':
      return {
        icon: faHistory,
        color: '#f6c244',
      };
    case 'Notes':
      return {
        icon: faStickyNote,
        color: '#f7b731',
      };
    case 'Lists':
      return {
        icon: faStickyNote,
        color: '#f7b731',
      };
    case 'Documents':
      return {
        icon: faFileAlt,
        color: colors.BLUE_2,
      };
    case 'Capping':
      return {
        icon: faLandmark,
        color: colors.YELLOW,
      };
    case 'Settings':
    case 'Setting':
    case 'settings':
    case 'setting':
      return {
        icon: faGear,
        color: colors.LIGHT_BLACK,
      };
    case 'Emails':
      return {
        icon: faEnvelope,
      };
    case 'Marketing':
      return {
        icon: faMailBulk,
        color: colors.ORANGE,
      };
    case 'Sales Pipeline':
      return {
        icon: faFunnelDollar,
        color: '#f6444a',
      };
    default:
      return {
        icon: '',
        color: '',
      };
  }
};
