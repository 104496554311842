import { applyUndoRedo } from "../helpers";

export const historyReducer = {
  undo: (state  ) => {
    if (state.undoStack.length === 0) return; // Nothing to undo

    const changes = state.undoStack.pop();
    applyUndoRedo(state, changes, 'undo');
    state.redoStack.push(changes.map(change => ({ ...change, before: change.after, after: change.before })));
  },
  redo: (state) => {
    if (state.redoStack.length === 0) return; // Nothing to redo
    const changes = state.redoStack.pop();
    applyUndoRedo(state, changes, 'redo');
    state.undoStack.push(changes.map(change => ({ ...change, before: change.after, after: change.before })));
  },
};


export const {
  undo,
  redo,
} = historyReducer;
