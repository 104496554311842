import {
  faUsers,
  faUserTie,
  faPeopleGroup,
  faReceipt,
  faArrowsToCircle,
  faBriefcase,
  faUserGear,
} from "@fortawesome/pro-solid-svg-icons";

import { ADMIN_ROUTES } from "consts/routes";

const ITEM_COLOR = "#565656";

export const LEFT_MENU_ITEM_ADMIN = [
  {
    text: "Users",
    icon: faUsers,
    path: ADMIN_ROUTES.USERS.path,
    color: ITEM_COLOR,
  },
  {
    text: "Roles",
    icon: faUserTie,
    path: ADMIN_ROUTES.ROLES.path,
    color: ITEM_COLOR,
  },
  {
    text: "Groups",
    icon: faPeopleGroup,
    path: ADMIN_ROUTES.GROUPS.path,
    color: ITEM_COLOR,
  },
  {
    text: "Company Units",
    icon: faReceipt,
    path: ADMIN_ROUTES.COMPANY_UNITS.path,
    color: ITEM_COLOR,
  },
  {
    text: "Workflow",
    icon: faArrowsToCircle,
    path: ADMIN_ROUTES.WORKFLOW.path,
    color: ITEM_COLOR,
  },
  {
    text: "Job Manager",
    icon: faBriefcase,
    path: ADMIN_ROUTES.JOB_MANAGER.path,
    color: ITEM_COLOR,
  },
  {
    text: "Administrators",
    icon: faUserGear,
    path: ADMIN_ROUTES.ADMINISTRATORS.path,
    color: ITEM_COLOR,
  },
];
