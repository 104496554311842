import { faFire, faSnowflake } from '@fortawesome/pro-regular-svg-icons';
import { Rating } from '@mui/material';
import { FontAwesomeIconComp } from 'components/Common';
import { colors } from 'consts';
import PropTypes from 'prop-types';
import React from 'react';

export const CustomerRating = ({ customerScore }) => {
  const hotIcons = {
    1: {
      icon: <FontAwesomeIconComp icon={faFire} />,
      label: 'Cold',
    },
    2: {
      icon: <FontAwesomeIconComp icon={faFire} />,
      label: 'Warm',
    },
    3: {
      icon: <FontAwesomeIconComp icon={faFire} />,
      label: 'Hot',
    },
    4: {
      icon: <FontAwesomeIconComp icon={faFire} />,
      label: 'Hott!',
    },
    5: {
      icon: <FontAwesomeIconComp icon={faFire} />,
      label: 'Smokkkin!!',
    },
  };

  function IconContainerHot(props) {
    const { value, ...other } = props;
    return <span {...other}>{hotIcons[value].icon}</span>;
  }

  IconContainerHot.propTypes = {
    value: PropTypes.number.isRequired,
  };

  return (
    <span>
      <Rating
        sx={{ fontSize: 16, '& label > .MuiRating-icon': { paddingRight: 1 } }}
        name="customized-color"
        defaultValue={customerScore}
        precision={1}
        max={1}
        icon={
          <FontAwesomeIconComp icon={faSnowflake} color={colors.LIGHT_BLUE_1} />
        }
        emptyIcon={<FontAwesomeIconComp icon={faSnowflake} />}
      />
      <Rating
        IconContainerComponent={IconContainerHot}
        getLabelText={(value) => hotIcons[value].label}
        sx={{
          fontSize: 16,
          marginRight: -1,
          marginLeft: 1,
          '& label > .MuiRating-icon': { paddingRight: 1 },
        }}
        name="customized-color"
        defaultValue={customerScore}
        precision={1}
        max={5}
      />
    </span>
  );
};

CustomerRating.propTypes = {
  customerScore: PropTypes.string,
};
