import { Autocomplete } from 'components/Fields';
import { useLoadMoreOptions } from 'hooks';
import PropTypes from 'prop-types';
import React from 'react';
import { useGetCappingsListQuery } from 'redux/rtk-query';

const getUserOption = ({ id, name }) => ({
  value: id,
  label: name,
  key: id,
});

export const CappingAutocomplete = (props) => {
  const users = useLoadMoreOptions(useGetCappingsListQuery, getUserOption);
  return (
    <Autocomplete
      {...props}
      options={users.options}
      size={'small'}
      onOpen={users.handleOpen}
      loadMoreResults={users.handleMore}
      isLoading={users.isFetching}
      handleSearch={users.handleSearch}
    />
  );
};

CappingAutocomplete.defaultProps = {
  freeSolo: false,
  onOpen: null,
  isLoading: false,
};

CappingAutocomplete.propTypes = {
  label: PropTypes.string,
  size: PropTypes.string,
  freeSolo: PropTypes.bool,
  isLoading: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    }),
  ),
  iconStart: PropTypes.node,
  input: PropTypes.object,
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
  }),
  loadMoreResults: PropTypes.func,
  onOpen: PropTypes.func,
  handleSearch: PropTypes.func,
};
