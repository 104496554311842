import React from "react";
import { AbeCard } from "components/Common";
import { Divider } from "@mui/material";
import { DocumentsList } from "../DocumentsList";
import DocumentCardToolbar from "./components/DocumentCardToolbar/DocumentCardToolbar";
import { DocumentCardProvider } from "./context/DocumentCardContext";

export const DocumentCard = () => {
  return (
    <DocumentCardProvider>
      <AbeCard elevation={2} title={"Documents"} afterTitle={<DocumentCardToolbar />}>
        <Divider />
        <DocumentsList />
        <Divider />
      </AbeCard>
    </DocumentCardProvider>
  );
};

DocumentCard.propTypes = {};
