import { useLocation, useNavigate } from 'react-router-dom';

export const useOpenDrawer = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (targetUrl, removePath = "") => {
    const currentPath = location.pathname.endsWith('/') ? location.pathname : location.pathname + '/';
    const targetPath = targetUrl.endsWith('/') ? targetUrl : targetUrl + '/';

    // Split paths into segments
    const currentSegments = currentPath.split('/').filter(Boolean);
    const targetSegments = targetPath.split('/').filter(Boolean);

    // Remove specified path segment if it exists
    console.log('Remove Path:', removePath)

    // Find the index in the modified current path where the base path of the target URL starts
    const basePathIndex = currentSegments.findIndex(segment => segment === targetSegments[0]);

    let newPathSegments;
    if (basePathIndex !== -1) {
      // Base path is found, replace segments from this index onwards
      newPathSegments = [
        ...currentSegments.slice(0, basePathIndex), // Take all segments before the base path
        ...targetSegments // Include all target segments
      ];
    } else {
      // Base path not found, append all target segments to the current path
      newPathSegments = [...currentSegments, ...targetSegments];
    }

    // Construct the new path
    let newPath = '/' + newPathSegments.join('/') + '/';
    if(removePath){
      newPath = newPath.replace(removePath, '');
    }

    console.log('Current Path:', currentPath);
    console.log('Target URL:', targetUrl);
    console.log('Remove Path:', removePath);
    console.log('New Path:', newPath);

    if (newPath !== currentPath) {
      navigate(newPath);
    } else {
      console.log("No change in path detected.");
    }
  };
};
