import { Box } from '@mui/material';
import { DetailsPanels } from 'components/Common';
import ModelDetailsCollapse from 'components/ModelDetailsCollapse';
import PropTypes from 'prop-types';
import React from 'react';

const formatTypes = {
  closeDate: 'date',
  listExpirationDate: 'date',
  contractExecutionDate: 'date',
  listExecutionDate: 'date',
  leaseBegins: 'date',
  leaseEnds: 'date',
  pmBegin: 'date',
  period: 'date',
  listPrice: 'currency',
  origListPrice: 'currency',
  escrowAmount: 'currency',
  additionalEscrowAmount: 'currency',
  bonusOrFlatRate: 'currency',
  contractPrice: 'currency',
  rentAmount: 'currency',
  pmDeposit: 'currency',
  pmNonRefundable: 'currency',
  pmRefundable: 'currency',
  lateFeeFlatRate: 'currency',
  nsfFeeFlatRate: 'currency',
  pmRent: 'currency',
  maintenanceLimit: 'currency',
  maintenanceReserve: 'currency',
  newLeaseFlatRate: 'currency',
  leaseRenewalFlatRate: 'currency',
  commission: 'percentage',
  lateFeePercent: 'percentage',
  newLeasePercent: 'percentage',
  leaseRenewalPercent: 'percentage',
  nsfFeeSplit: 'percentage',
  lateFeeSplit: 'percentage',
  outsideReferral: 'boolean',
  referralAgent: 'model',
  referralCommission: 'percentage',
  closedApproved: 'checkbox',
};

const translatedLabels = {
  pmRent: 'Rent',
  pmStatus: 'Mgmt Status',
  pmBegin: 'Agreement Begins',
  nsfFeeSplit: 'NSF Fee Split',
};

export const BottomCard = ({ transactionInfo }) => {
  return (
    <Box width={'100%'}>
      <DetailsPanels
        formatTypes={formatTypes}
        translatedLabels={translatedLabels}
        data={transactionInfo}
        panels={{
          Overview: ['closeDate', 'representing', 'saleType', 'closedApproved'],
          Listing: [
            'listPrice',
            'origListPrice',
            'listExecutionDate',
            'listExpirationDate',
          ],
          Contract: [
            'contractPrice',
            'contractExecutionDate',
            'financingType',
            'escrowAmount',
            'additionalEscrowAmount',
          ],
          'Property Mgmt': [
            'pmStatus',
            'pmRent',
            'period',
            'paymentMethod',
            'notesThisPeriod',
          ],
          'Property Mgmt Agreement': [
            'pmBegin',
            'maintenanceLimit',
            'maintenanceReserve',
            'newLeasePercent',
            'newLeaseFlatRate',
            'leaseRenewalPercent',
            'leaseRenewalFlatRate',
            'lateFeeSplit',
            'nsfFeeSplit',
            'commissionTerms',
          ],
          Lease: [
            'leaseType',
            'rentAmount',
            'pmDeposit',
            'pmNonRefundable',
            'pmRefundable',
            'leaseBegins',
            'leaseEnds',
            'lateFeePercent',
            'lateFeeFlatRate',
            'nsfFeeFlatRate',
            'rentDueOn',
            'rentLateOn',
            'pmDescription',
          ],
          Commission: ['commission', 'bonusOrFlatRate'],
        }}
      />
      <ModelDetailsCollapse data={transactionInfo} />
    </Box>
  );
};

BottomCard.propTypes = {
  transactionInfo: PropTypes.object,
};
