export const transformResponse = response => ({
  ...response,
  hasNextPage: response.data.meta ? response.data.meta.page < response.data.meta.totalPages : false,
});


export const omitNull = obj => {
  if(obj === null || obj === undefined) return {};
  Object.keys(obj).filter(k => obj[k] === null || obj[k] === "{}" ).forEach(k => delete(obj[k]))
  return obj
}

export const omitProperties = (sourceObject, propertiesToOmit) => {
  return Object.keys(sourceObject).reduce((acc, key) => {
    if (!propertiesToOmit.includes(key)) {
      acc[key] = sourceObject[key];
    }
    return acc;
  }, {});
};