import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIconComp } from 'components/Common';
import { getModuleIcon } from 'helpers';
import PropTypes from 'prop-types';
import React from 'react';

export const ModuleIcon = ({
  module,
  type = null,
  closedApproved = false,
  fontSize = 20,
  marginRight = 0,
  maxWidth = '100%',
}) => {
  const iconInfo = getModuleIcon(module, type);
  if (!iconInfo.icon) return null;
  return closedApproved ? (
    <span className="fa-layers">
      <FontAwesomeIconComp
        className="fa-stack-1x"
        transform="left-1"
        icon={iconInfo.icon}
        fontSize={fontSize}
        color={iconInfo.color}
      />
      <FontAwesomeIconComp
        className="fa-stack-1x"
        icon={faCheck}
        fontSize={fontSize}
        transform="shrink-7 down-1 left-1"
        color={iconInfo.color}
      />
    </span>
  ) : (
    <FontAwesomeIconComp
      style={{ marginRight: marginRight, maxWidth: maxWidth }}
      icon={iconInfo.icon}
      fontSize={fontSize}
      color={iconInfo.color}
    />
  );
};

ModuleIcon.propTypes = {
  module: PropTypes.string.isRequired,
  type: PropTypes.string,
  closedApproved: PropTypes.bool,
  fontSize: PropTypes.number,
  marginRight: PropTypes.number,
  maxWidth: PropTypes.string,
};
