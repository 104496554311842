import { Box, Typography } from '@mui/material';
import { CardWithEdit } from 'components/Common';
import { UPDATE_SETTING } from 'consts';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetSettingQuery } from 'redux/rtk-query';
import { ContactAvatar } from './components/ContactAvatar';

export const HeadshotCard = () => {
  let { settingDetailsId } = useParams();
  const { data: settingData } = useGetSettingQuery({ id: settingDetailsId });

  return (
    <Box pb={2}>
      <CardWithEdit link={UPDATE_SETTING(settingDetailsId, 'Headshot')}>
        <Box
          p={1}
          display={'flex'}
          justifyContent={'center'}
          flexDirection={'column'}
          alignItems={'center'}
        >
          <ContactAvatar contactInfo={settingData?.data} />
          <Typography pt={1} variant={'h6'}>
            {settingData?.data?.agentName}
          </Typography>
        </Box>
      </CardWithEdit>
    </Box>
  );
};
