import { useFormValidationProcess, usePrevious } from 'hooks';
import { useEffect } from 'react';
import { useForm } from 'react-final-form';

export const FormValidator = () => {
  const validateFormData = useFormValidationProcess();
  const form = useForm();
  const initialValues = form.getState().initialValues;
  const prevInitialValues = usePrevious(initialValues);

  useEffect(() => {
    if (prevInitialValues !== initialValues) {
      validateFormData();
    }
  }, [initialValues, validateFormData]);

  return null; // This component doesn't render anything
};
