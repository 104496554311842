import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { IconButton } from '@mui/material';
import { FontAwesomeIconComp } from 'components/Common';
import { RoleHeadIcon } from 'components/Icons';
import { conversion } from 'helpers';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { RoleName } from './components/RoleName';
import { TeamMemberField } from './components/TeamMemberField';
import { RoleWrapper } from './styled';

function ClearButton({ removeRole }) {
  return (
    <span className={'clear-button'}>
      <IconButton
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
          removeRole();
        }}
        onMouseDown={(event) => {
          event.preventDefault();
        }}
        edge="end"
      >
        <FontAwesomeIconComp icon={faTimes} color={'#c9232d'} size={'xs'} />
      </IconButton>
    </span>
  );
}

ClearButton.propTypes = {
  removeRole: PropTypes.func,
};
export const RoleField = ({
  roleOption,
  removeRole,
  updateRoleName,
  textOnly,
  teamMember = false,
}) => {
  const roleName = teamMember
    ? conversion.getName(roleOption.teamMember)
    : roleOption.contact
    ? conversion.getName(roleOption.contact)
    : roleOption.contactName;
  const [editingName, setEditingName] = useState(roleName === '');
  const [name, setName] = useState(roleName ? roleName : '');
  const inputRef = useRef(null);

  const handleDivClick = () => {
    if (textOnly) {
      setEditingName(true);
    }
  };

  useEffect(() => {
    setName(roleName);
  }, [roleName]);

  useEffect(() => {
    if (editingName) {
      inputRef.current.focus();
    }
  }, [editingName]);

  return (
    <RoleWrapper onClick={handleDivClick}>
      <RoleHeadIcon role={roleOption} />
      <RoleName
        editingName={editingName}
        inputRef={inputRef}
        value={name}
        onChange={(e) => {
          setName(e.target.value);
        }}
        onBlur={() => {
          updateRoleName(roleOption, name);
          setEditingName(false);
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            updateRoleName(roleOption, name);
            setEditingName(false);
          }
        }}
        roleName={roleName}
      />
      {teamMember && <TeamMemberField order={roleOption.order} />}
      <ClearButton
        removeRole={() => {
          removeRole(roleOption);
        }}
      />
    </RoleWrapper>
  );
};

RoleField.propTypes = {
  roleOption: PropTypes.object,
  removeRole: PropTypes.func,
  updateRoleName: PropTypes.func,
  textOnly: PropTypes.bool,
  addRole: PropTypes.func,
  hideModalList: PropTypes.func,
  teamMember: PropTypes.bool,
};
