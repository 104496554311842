import React from 'react';
import PropTypes from 'prop-types';
import { Box, ListSubheader, MenuItem } from '@mui/material';
import { Required } from 'components/Common';
import { FieldTooltip } from 'components/Fields';
import { ErrorMsg, TextInputField } from 'components/Styled';
import { hasValue } from 'helpers';
import { useFormValidationProcess, useIsRequired } from 'hooks';

const SelectGroup = ({ label, options }) => (
  <>
    <ListSubheader key={label}>{label}</ListSubheader>
    {options.map(({ value, label }) => (
      <MenuItem key={value} value={value}>
        {label}
      </MenuItem>
    ))}
  </>
);

SelectGroup.propTypes = {
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
};

export const Select = ({
  label,
  size,
  options,
  input,
  meta,
  isGrouped,
  onOpen,
  loadMoreResults,
  tooltip,
  isRequired,
}) => {
  const error =
    meta.submitError && !meta.dirtySinceLastSubmit && meta.submitError;
  const isFilled = hasValue(input?.value);
  const required = (useIsRequired(input?.name) || isRequired) && !isFilled;
  const validateFormData = useFormValidationProcess(); // Use the custom hook

  return (
    <Box position="relative">
      <TextInputField
        className={isFilled ? 'filled' : ''}
        label={
          <>
            {label}
            {required && <Required />}
          </>
        }
        SelectProps={{
          multiple: input?.multiple,
          onOpen,
          ...(loadMoreResults && {
            MenuProps: {
              onScroll: ({ currentTarget }) => {
                if (
                  currentTarget.scrollTop + currentTarget.clientHeight >=
                  currentTarget.scrollHeight
                ) {
                  loadMoreResults();
                }
              },
            },
          }),
        }}
        size={size}
        error={!!error}
        fullWidth
        select
        {...input}
        onChange={async (event) => {
          await validateFormData(input.name, event.target.value);
          input.onChange(event);
        }}
      >
        {isGrouped
          ? options.map((group) => [
              // <ListSubheader key={group.label}>{group.label}</ListSubheader>,
              group.options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              )),
            ])
          : options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
      </TextInputField>
      {tooltip && <FieldTooltip tooltip={tooltip} />}
      {error && (
        <ErrorMsg className="error-message" component="span">
          {error}
        </ErrorMsg>
      )}
    </Box>
  );
};

Select.defaultProps = {
  multiple: false,
  size: 'small',
  isGrouped: false,
  onOpen: null,
  loadMoreResults: null,
};

Select.propTypes = {
  tooltip: PropTypes.object,
  label: PropTypes.string,
  multiple: PropTypes.bool,
  size: PropTypes.string,
  isGrouped: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  input: PropTypes.object,
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
    submitError: PropTypes.string,
    dirtySinceLastSubmit: PropTypes.bool,
  }),
  loadMoreResults: PropTypes.func,
  onOpen: PropTypes.func,
  isRequired: PropTypes.bool,
};
