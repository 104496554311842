import { Grid } from '@mui/material';
import { ClickableTypography } from 'components/Styled';
import { UPDATE_INVOICE } from 'consts';
import { conversion } from 'helpers';
import PropTypes from 'prop-types';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export const PropertyMgmtInvoiceRow = ({ invoice, showIndent = false }) => {
  const navigate = useNavigate();

  const onClickHandler = () => {
    navigate(UPDATE_INVOICE(invoice.id));
  };
  return (
    <Grid container fontSize={14} xs={12}>
      <ClickableTypography
        variant="text"
        onClick={onClickHandler}
        container
        component={Grid}
        xs={12}
        display="flex"
        justifyContent="space-between"
      >
        <Grid item textAlign="left">
          {showIndent ? `— ` : ''}
          {invoice.name}
        </Grid>
        <Grid item textAlign="right">
          {conversion.formatCurrency(invoice.price)}
        </Grid>
      </ClickableTypography>
    </Grid>
  );
};

PropertyMgmtInvoiceRow.propTypes = {
  invoice: PropTypes.object,
  showIndent: PropTypes.bool,
};
