import React from "react";
import PropTypes from "prop-types";
import { Card } from "@mui/material";
import { CardButton } from "./components/CardButton";

export const CardWithEdit = ({ children, link }) => {
    return (
        <Card
            sx={{
                padding: 2,
                position: "relative",
                '&:hover .cardButton': { // Add this line
                    display: 'block',
                },
            }}
        >
            <CardButton
                link={link}
                label={"Edit"}
                className="cardButton"
                sx={{
                    display: "none",
                    position: "absolute",
                    top: 0,
                    right: 0,
                }}
            />
            {children}
        </Card>
    );
};

CardWithEdit.propTypes = {
    children: PropTypes.node.isRequired,
    link: PropTypes.string.isRequired,
};
