import {
  faArchive,
  faFile,
  faFolder,
  faFolders,
} from '@fortawesome/pro-light-svg-icons';
import { PopperButton } from 'components/Common';
import { CREATE_DOCUMENT_TEMPLATE, TYPES } from 'consts';
import PropTypes from 'prop-types';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const MenuItems = (viewingId) => {
  const navigate = useNavigate();

  const handleClick = (type) => {
    navigate(
      CREATE_DOCUMENT_TEMPLATE(viewingId === 'home' ? '0' : viewingId, type),
    );
  };
  return [
    {
      name: 'File',
      icon: faFile,
      onClick: () => {
        handleClick(TYPES.FILE);
      },
    },
    {
      name: 'Folder',
      icon: faFolder,
      onClick: () => {
        handleClick(TYPES.FOLDER);
      },
    },
    {
      name: 'Folder Alias',
      icon: faFolders,
      onClick: () => {
        handleClick(TYPES.FOLDER_GROUP);
      },
    },
    {
      name: 'Archive Folder',
      icon: faArchive,
      onClick: () => {
        handleClick(TYPES.ARCHIVE_FOLDER);
      },
    },
  ];
};

function AddDocumentTemplateButton({ viewingId }) {
  return (
    <>
      <PopperButton
        menuItems={MenuItems(viewingId)}
        variant={'outlined'}
        size={'small'}
      >
        + Add New
      </PopperButton>
    </>
  );
}

AddDocumentTemplateButton.propTypes = {
  viewingId: PropTypes.string,
};

export default AddDocumentTemplateButton;
