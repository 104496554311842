import {styled} from "@mui/system";
import {ListItemButton, TextField} from "@mui/material";

export const ChecklistItemInput = styled(TextField)({
    margin: 5,
    "& .MuiInputBase-inputSizeSmall":{
        padding: "5px 10px",
        fontSize: 14,
        backgroundColor: "white"
    },
});

export const ChecklistRow = styled(ListItemButton)({
    "&.Mui-focusVisible":{
        backgroundColor: "rgba(0,0,0,.04)"
    }
});


