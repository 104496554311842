import { SideDrawer } from 'components/Common';
import { omitNull } from 'helpers';

import { useDrawer } from 'hooks';
import { InvoiceCategoryForm } from 'pages/InvoiceTemplatePages/InvoiceCategoryForm/InvoiceCategoryForm';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
  useGetInvoiceCategoryQuery,
  useUpdateInvoiceCategoryMutation,
} from 'redux/rtk-query';

export const EditInvoiceCategoryPage = ({ params, path }) => {
  const paramName = Object.keys(params)[0];

  const [initialValues, setInitialValues] = useState({});
  const { isOpen, closeDrawer } = useDrawer(true, path);

  const [updateCategory] = useUpdateInvoiceCategoryMutation();
  const { data: categoryData, isSuccess } = useGetInvoiceCategoryQuery({
    id: params[paramName],
  });


  useEffect(() => {
    if (isSuccess) {
      let data = { ...categoryData.data };
      setInitialValues({
        ...omitNull(data),
      });
    }
  }, [isSuccess]);

  const handleSubmitFunc = async (values) => {
    return await updateCategory({
      parentCategoryId: values?.parentCategory?.value,
      ...values,
    });
  };

  const afterSave = () => {
    closeDrawer();
  };

  return (
    <SideDrawer width={'40%'} isOpen={isOpen}>
      <InvoiceCategoryForm
        title={'Edit Category'}
        initialValues={initialValues}
        afterSave={afterSave}
        handleSubmitFunc={handleSubmitFunc}
      />
    </SideDrawer>
  );
};

EditInvoiceCategoryPage.propTypes = {
  params: PropTypes.object,
  path: PropTypes.string,
};
