import { setUserLogin, setGoogleUserInfo } from "redux/slices";

import axiosAPI from "./axios";

export const gapiLibInit = () => {
  window.gapi?.load("auth2", function () {
    window.gapi.auth2.init({
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    });
  });
};

const getTokenFromBackEndGoogle = async (accessToken, dispatch) => {
  const backEndToken = await axiosAPI.post("auth/google", {
    token: accessToken,
  });
  dispatch(setUserLogin(backEndToken.data.data));
};

export const getTokenFromBackEndUserCreds = async (userCreds, dispatch) => {
  const backEndToken = await axiosAPI.post("auth/sign-in", {
    username: userCreds.username,
    password: userCreds.password,
  });
  dispatch(setUserLogin(backEndToken.data.data));
};

export const isAuthRedirect = (
  authStatus,
  navigate,
  targetUrl,
  altDirectory,
) => {
  if (authStatus) {
    navigate(targetUrl || altDirectory, {replace: true});
  }
};

export const signInGoogle = (dispatch, access_token) => {
  getTokenFromBackEndGoogle(access_token, dispatch);
  dispatch(setGoogleUserInfo({}));
  // const googleAuth = window.gapi.auth2.getAuthInstance();
  // googleAuth
  //   .signIn({ options: "profile email" })
  //   .then(user => {
  //     getTokenFromBackEndGoogle(user.Cc.access_token, dispatch);
  //     dispatch(setGoogleUserInfo(user));
  //   })
  //   .catch(() => {}); // TODO Handle error
};

