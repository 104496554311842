import { Button } from '@mui/material';
import { StarToggle } from 'components/Common/StarToggle';
import { DetailsLayout } from 'components/Layouts';
import { UPDATE_TRANSACTION } from 'consts';
import { useAccessDeniedHandler } from 'hooks';
import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import {
  useGetTransactionQuery,
  useToggleStarTransactionMutation,
} from 'redux/rtk-query';
import { TransactionDetails } from './components';
import { TransactionDashboard } from './components/TransactionDashboard';

export const DetailsTransactionPage = () => {
  let { transactionDetailsId } = useParams();
  const navigate = useNavigate();

  const [transactionInfo, setTransactionInfo] = useState(null);
  const {
    data: transactionData,
    isSuccess,
    isFetching,
    error,
  } = useGetTransactionQuery(
    { id: transactionDetailsId },
    { refetchOnMountOrArgChange: true },
  );

  const [toggleStar] = useToggleStarTransactionMutation();

  useEffect(() => {
    if (isSuccess) {
      document.title = `${transactionData.data?.name} - Transactions`;
      setTransactionInfo(transactionData.data);
    }
  }, [isFetching, isSuccess, transactionDetailsId]);

  const accessDeniedMessage = useAccessDeniedHandler(error);
  if (accessDeniedMessage) {
    return <div>{accessDeniedMessage}</div>;
  }

  return (
    <>
      {transactionInfo && (
        <>
          <DetailsLayout
            equalWidth
            header={
              <>
                <span>{transactionInfo.name}</span>
                <StarToggle
                  handleToggleStar={() => {
                    toggleStar(transactionInfo?.uuid);
                  }}
                  isStarred={transactionInfo?.favourite}
                />
                <Button
                  size={'small'}
                  onClick={() => {
                    navigate(UPDATE_TRANSACTION(transactionDetailsId));
                  }}
                >
                  Edit
                </Button>
              </>
            }
            leftContent={
              <TransactionDetails transactionInfo={transactionInfo} />
            }
            rightContent={<TransactionDashboard id={transactionDetailsId} />}
          />
          <Outlet />
        </>
      )}
    </>
  );
};
