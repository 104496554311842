import React from 'react';
import PropTypes from 'prop-types';
import {
  faClock,
  faFilter,
  faHeart,
  faBells,
} from '@fortawesome/pro-light-svg-icons';
import { Divider, Grid, Typography, Paper } from '@mui/material';
import { Box } from '@mui/system';
import {
  CollapsableMenuGroup,
  FontAwesomeIconComp,
  MenuItemButton,
} from 'components/Common';
import { SizesDropDown } from 'components/ModelViews/Marketing/MarketingCreateView/components/ClickMarketingGallery/components/ClickMarketingGalleryFilter/components/SizesDropDown';
import { SearchInput } from 'components/SearchInput';
import { colors } from 'consts';
import { CategoriesDropDown } from './components/CategoriesDropDown';

const FilterName = ({ children, ...props }) => {
  return (
    <Typography variant="body1" color={colors.DARK_BLUE_1} {...props}>
      {children}
    </Typography>
  );
};

FilterName.propTypes = {
  children: PropTypes.node,
};

const FilterItem = ({ label, children }) => {
  return (
    <Grid container alignItems="center" py={1} px={2}>
      <Grid item xs={4}>
        <FilterName>{label}</FilterName>
      </Grid>
      <Grid item xs={8}>
        {children}
      </Grid>
    </Grid>
  );
};

FilterItem.propTypes = {
  label: PropTypes.string,
  children: PropTypes.node,
};

export const ClickMarketingGalleryFilter = ({
  setName,
  categories,
  setCategories,
  sizes,
  setSizes,
  featuredType,
  setFeaturedType,
}) => {
  return (
    <Paper sx={{ width: '280px' }} elevation={1}>
      <CollapsableMenuGroup
        isSelected
        label={'Filters'}
        icon={<FontAwesomeIconComp fontSize={18} icon={faFilter} />}
      >
        <Box p={1}>
          <SearchInput changeHandler={(value) => setName(value)} />
        </Box>
        <Divider />
        <FilterItem label="Category">
          <CategoriesDropDown
            categories={categories}
            setCategories={setCategories}
          />
        </FilterItem>
        <Divider />
        <FilterItem label="Sizes">
          <SizesDropDown selectedSizes={sizes} setSizes={setSizes} />
        </FilterItem>
      </CollapsableMenuGroup>
      <Divider />
      <MenuItemButton
        label={'New'}
        icon={<FontAwesomeIconComp icon={faClock} />}
        isSelected={featuredType === 'new'}
        onClick={() => {
          setCategories([]);
          setFeaturedType(featuredType === 'new' ? null : 'new');
        }}
      />
      <Divider />
      <MenuItemButton
        label={'Favorites'}
        icon={<FontAwesomeIconComp icon={faHeart} />}
        isSelected={featuredType === 'favorites'}
        onClick={() => {
          setCategories([]);
          setFeaturedType(featuredType === 'favorites' ? null : 'favorites');
        }}
      />
      <Divider />
      <MenuItemButton
        label={'Holidays'}
        icon={<FontAwesomeIconComp icon={faBells} />}
        isSelected={featuredType === 'holidays'}
        onClick={() => {
          setCategories([]);
          setFeaturedType(featuredType === 'holidays' ? null : 'holidays');
        }}
      />
    </Paper>
  );
};

ClickMarketingGalleryFilter.propTypes = {
  name: PropTypes.string,
  setName: PropTypes.func,
  categories: PropTypes.array,
  setCategories: PropTypes.func,
  sizes: PropTypes.array,
  setSizes: PropTypes.func,
  featuredType: PropTypes.string,
  setFeaturedType: PropTypes.func,
};
