import { Autocomplete } from 'components/Fields/Autocomplete';
import { useLoadHierarchyOptions } from 'hooks';
import PropTypes from 'prop-types';
import React from 'react';
import { useGetCompanyUnitsQuery } from 'redux/rtk-query';

export const CompanyUnitsAutocomplete = (props) => {
  const units = useLoadHierarchyOptions(useGetCompanyUnitsQuery);
  return <Autocomplete {...props} options={units} size={'small'} />;
};

CompanyUnitsAutocomplete.defaultProps = {
  freeSolo: false,
  onOpen: null,
  isLoading: false,
};

CompanyUnitsAutocomplete.propTypes = {
  label: PropTypes.string,
  size: PropTypes.string,
  freeSolo: PropTypes.bool,
  isLoading: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    }),
  ),
  iconStart: PropTypes.node,
  input: PropTypes.object,
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
  }),
  loadMoreResults: PropTypes.func,
  onOpen: PropTypes.func,
  handleSearch: PropTypes.func,
};
