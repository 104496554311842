import { createApi } from '@reduxjs/toolkit/query/react';
import { transformResponse } from 'helpers';

import { axiosBaseQuery } from 'utils/baseQuery';

import { transactionUrlBuilder } from './urlBuilder/transactions';

export const transactionsAPI = createApi({
  reducerPath: 'transactionsAPI',
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  tagTypes: ['Transaction', 'Tasks', 'Commissions', 'Invoices'],
  endpoints: (build) => ({
    validateTransaction: build.mutation({
      query: (body) => ({
        url: transactionUrlBuilder.validateTransaction(),
        method: 'POST',
        data: body,
      }),
      invalidatesTags: [],
    }),
    createTransaction: build.mutation({
      query: (body) => ({
        url: transactionUrlBuilder.createTransaction(),
        method: 'POST',
        data: body,
      }),
      invalidatesTags: ['Transaction', 'Tasks', 'Commissions', 'Invoices'],
    }),
    updateTransaction: build.mutation({
      query: (body) => ({
        url: transactionUrlBuilder.updateTransaction(),
        method: 'PUT',
        data: body,
      }),
      invalidatesTags: ['Transaction', 'Tasks', 'Commissions', 'Invoices'],
    }),
    getTransaction: build.query({
      query: (args) => ({
        url: transactionUrlBuilder.getTransaction(args),
        method: 'GET',
      }),
      providesTags: ['Transaction'],
    }),
    getTransactions: build.query({
      query: (args) => ({
        url: transactionUrlBuilder.getTransactions(args),
        method: 'GET',
      }),
      providesTags: ['Transaction'],
    }),
    getTransactionRoles: build.query({
      query: (args) => ({
        url: transactionUrlBuilder.getTransactionRoles(args),
        method: 'GET',
      }),
      providesTags: ['Contacts'],
    }),
    getTransactionCommissions: build.query({
      query: (args) => ({
        url: transactionUrlBuilder.getCommissions(args),
        method: 'GET',
      }),
      transformResponse,
      providesTags: (result, error, args) => [
        { type: 'Commissions', id: args.transactionId },
      ],
    }),
    getTransactionInvoices: build.query({
      query: (args) => ({
        url: transactionUrlBuilder.getInvoices(args),
        method: 'GET',
      }),
      providesTags: ['Invoices'],
    }),
    getRelatedTransactions: build.query({
      query: (args) => ({
        url: transactionUrlBuilder.getRelatedTransactions(args),
        method: 'GET',
      }),
      providesTags: ['Transaction'],
    }),
    toggleStarTransaction: build.mutation({
      query: (args) => ({
        url: transactionUrlBuilder.toggleStarTransaction(args),
        method: 'PATCH',
      }),
      invalidatesTags: ['Transaction'],
    }),
  }),
});

export const {
  useCreateTransactionMutation,
  useUpdateTransactionMutation,
  useGetTransactionsQuery,
  useGetTransactionQuery,
  useValidateTransactionMutation,
  useGetTransactionCommissionsQuery,
  useGetTransactionInvoicesQuery,
  useGetRelatedTransactionsQuery,
  useToggleStarTransactionMutation,
} = transactionsAPI;
