import React from 'react';
import { Box } from '@mui/system';
import PropTypes from 'prop-types';

export const BottomMenuButtons = ({ children }) => {
  return (
    <Box
      sx={{
        borderTop: '1px solid #c9c9c9',
        bottom: 0,
        position: 'sticky',
        padding: '12px',
        textAlign: 'center',
        marginTop: 'auto',
      }}
    >
      {children}
    </Box>
  );
};

BottomMenuButtons.propTypes = {
  children: PropTypes.node,
};
