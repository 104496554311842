import React from 'react';
import PropTypes from 'prop-types';

import {
  ButtonSection,
  FeatureButtons,
  Header,
  HeaderSubtitle,
} from 'components/Styled';
import { Button } from '@mui/material';
import { Box } from '@mui/system';

export const CreateLayoutHeader = ({
  title,
  subTitle,
  nameButton = 'Save',
  afterSave,
  isLoading,
  children,
}) => (
  <FeatureButtons>
    <Box>
      <Header variant="h4">{title}</Header>
      {subTitle && <HeaderSubtitle>{subTitle}</HeaderSubtitle>}
    </Box>
    <ButtonSection>
      {children}
      {afterSave && (
        <>
          <Button variant="outlined" onClick={afterSave}>
            Cancel
          </Button>
          <Button variant="contained" type="submit" disabled={isLoading}>
            {nameButton}
          </Button>
        </>
      )}
    </ButtonSection>
  </FeatureButtons>
);

CreateLayoutHeader.defaultProps = {
  nameButton: 'Save',
};

CreateLayoutHeader.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  nameButton: PropTypes.string,
  afterSave: PropTypes.func,
  dirty: PropTypes.bool,
  isLoading: PropTypes.bool,
  isSuccess: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
