import { faGauge } from '@fortawesome/pro-solid-svg-icons';
import { Box } from '@mui/material';
import { DetailsPanels, FontAwesomeIconComp } from 'components/Common';
import { CardContentRow } from 'components/Styled';
import { STATUS_OPTIONS } from 'consts';
import { conversion } from 'helpers';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

const formatTypes = {
  contractPrice: 'currency',
  grossCommission: 'currency',
  netToAgent: 'currency',
  dueToOwner: 'currency',
  invoiceTotal: 'currency',
  contractVolume: 'currency',
  grossCommissionAfterReferral: 'currency',
  grossCommissionTeam: 'currency',
  netToCompanyTeam: 'currency',
  toCompanyBeforeOverride: 'currency',
  bonusOrFlatRate: 'currency',
  otherDebitCredit: 'currency',
  referralCommission: 'currency',
  referralFlatRate: 'currency',
  listVolume: 'currency',
  teamPercent: 'percentage',
  agentSplit: 'percentage',
  period: 'date',
  closeDate: 'date',
  closedApproved: 'checkbox',
};

export const CommissionSummary = ({ commission }) => {
  const [panelAttributes, setPanelAttributes] = React.useState([]);

  useEffect(() => {
    let newPanelAttributes = [
      'agentSplit',
      'contractVolume',
      'netToAgent',
      'closedApproved',
    ];
    if (parseInt(commission.teamPercent) !== 100) {
      newPanelAttributes = [
        'agentSplit',
        'teamPercent',
        'grossCommissionTeam',
        'contractVolume',
        'netToAgent',
      ];
    }
    if (commission.status === STATUS_OPTIONS.PROPERTY_MGMT.value) {
      newPanelAttributes = ['grossCommission', 'netToAgent'];
      if (parseInt(commission.teamPercent) !== 100) {
        newPanelAttributes.push('teamPercent');
      }
    }

    setPanelAttributes(newPanelAttributes);
  }, []);

  return (
    <CardContentRow>
      <Box display={'flex'} flexDirection={'column'}>
        <DetailsPanels
          data={commission}
          formatTypes={formatTypes}
          translatedLabels={{
            contractPrice:
              commission.status === STATUS_OPTIONS.PROPERTY_MGMT.value
                ? 'Rent'
                : 'Contract Price',
          }}
          panels={{
            [conversion.getName(commission.modelAccess.owner)]: {
              attributes: panelAttributes,
              icon: <FontAwesomeIconComp icon={faGauge} />,
            },
          }}
        />
      </Box>
    </CardContentRow>
  );
};

CommissionSummary.propTypes = {
  commission: PropTypes.object,
  showRelatedModel: PropTypes.bool,
};
