import { Avatar, Box, Typography } from '@mui/material';
import { conversion } from 'helpers';
import useGetMediaUrl from 'hooks/useGetMediaUrl';
import PropTypes from 'prop-types';
import React from 'react';
import { useGetProfileQuery } from 'redux/rtk-query';
import commentStyles from './commentStyles';

export const Comment = ({ comment: { createdBy, createdAt, message } }) => {
  const { data: userData } = useGetProfileQuery();

  const userName =
    userData?.data?.id === createdBy.id ? 'You' : conversion.getName(createdBy);
  const date = new Date(createdAt);
  const formattedDate = date.toLocaleString();

  const { data: fileData } = useGetMediaUrl(
    createdBy?.setting?.headshotFile,
    'id',
    createdBy?.setting?.id,
  );

  return (
    <Box sx={commentStyles.container}>
      <Avatar src={fileData?.data?.url} sx={commentStyles.avatar} />
      <Box sx={commentStyles.commentContainer}>
        <Box sx={commentStyles.header}>
          <Typography sx={commentStyles.authorName} variant="subtitle1">
            {userName}
          </Typography>
          <Typography sx={commentStyles.timestamp} variant="body2">
            {formattedDate}
          </Typography>
        </Box>
        <Typography sx={commentStyles.message} variant="body2">
          {message}
        </Typography>
      </Box>
    </Box>
  );
};

Comment.propTypes = {
  comment: PropTypes.shape({
    createdBy: PropTypes.shape({
      id: PropTypes.string,
      setting: PropTypes.shape({
        id: PropTypes.string,
        headshotFile: PropTypes.string,
      }),
    }),
    createdAt: PropTypes.instanceOf(Date),
    message: PropTypes.string,
  }),
};
