import { styled } from "@mui/system";
import { Box, Typography } from "@mui/material";

export const UserBox = styled(Box)({
  background: "#969696",
  borderRadius: "96px",
  width: "fit-content",
  fontWeight: "500",
  fontSize: "16px",
  lineHeight: "28px",
  textAlign: "center",
  color: "#FFFFFF",
  minWidth: "28px",
  padding: "0 8px",
  height: "28px",
  cursor: "pointer",
});

export const ActiveUserBox = styled(UserBox)({
  background: "#0085FF",
});

export const EmptyUserBox = styled(Typography)({
  fontWeight: "500",
  fontSize: "16px",
  lineHeight: "28px",
  padding: "0 8px",
  color: "#969696",
});

export const EmptyActiveUserBox = styled(Typography)({
  padding: "0 8px",
  color: "#0085FF",
});

export const ActiveStatusBox = styled(Box)({
  background:
    "linear-gradient(0deg, rgba(0, 133, 255, 0.1), rgba(0, 133, 255, 0.1)), #FFFFFF",
  borderRadius: "6px",
  width: "fit-content",
  fontWeight: "500",
  fontSize: "13px",
  lineHeight: "20px",
  textAlign: "center",
  color: "#0085FF",
  padding: "0 6px",
});

export const InactiveStatusBox = styled(ActiveStatusBox)({
  background: "#FFFFFF",
  border: "1px solid #E8E8E8",
  color: "#1C1C1C",
});

export const AuditText = styled("span")({
  fontWeight: "400",
  fontSize: "16px",
  lineHeight: "24px",
  color: "#565656",
});
