import React from 'react';
import * as AdminPages from 'pages/AdminPages';
import {
  CompanyUnitsPage,
  CreateCompanyUnitPage,
  CreateGroupPage,
  CreateRolePage,
  CreateUserPage,
  EditCompanyUnitPage,
  EditGroupPage,
  EditRolePage,
  EditUserPage,
  GroupsPage,
  RolesPage,
  UsersPage,
  ViewUserPage,
} from 'pages/AdminPages';
import { CreateReportPage } from 'pages/ReportPages/CreateReportPage';
import { ReportsListPage } from 'pages/ReportPages/ReportsListPage';
import { AdminLayout, MainLayout } from '../components/Layouts';
import { ProtectedRoute } from '../components/ProtectedRoute';
import { ActivityPage } from '../pages/ActivityPages';
import { CappingsPage } from '../pages/CappingPages/CappingsPage';
import { DetailsCappingPage } from '../pages/CappingPages/DetailsCappingPage/DetailsCappingPage';
import { CommissionsPage } from '../pages/CommissionPages/CommissionsPage';
import { ContactsPage } from '../pages/ContactsPages';
import { DetailsContactPage } from '../pages/ContactsPages/DetailsContactPage';
import { DetailsDocumentPage } from '../pages/DocumentPages/DetailsDocumentPage';
import { DocumentsPage } from '../pages/DocumentPages/DocumentsPage';
import { DocumentTemplatesPage } from '../pages/DocumentTemplatePages/DocumentTemplatesPage';
import { HomePage } from '../pages/HomePages';
import { InvoicesPage } from '../pages/InvoicePages/InvoicesPage';
import { InvoiceTemplatesPage } from '../pages/InvoiceTemplatePages/InvoiceTemplatesPage';
import { LoginPage } from '../pages/LoginPage';
import { MarketingPage } from '../pages/MarketingPages';
import { ReportPage } from '../pages/ReportPages';
import { DetailsSettingPage } from '../pages/SettingsPages/DetailsSettingPage';
import { SettingsPage } from '../pages/SettingsPages/SettingsPage';
import { CommissionOverviewPage } from '../pages/TransactionsPages/CommissionOverviewPage';
import { DetailsTransactionPage } from '../pages/TransactionsPages/DetailsTransactionPage';
import { TransactionsPage } from '../pages/TransactionsPages/TransactionsPage';

export const HOME_ROUTE = '/';
export const LOGIN = '/login';
export const ADMIN = '/admin';

export const USERS = 'users';
export const USERS_CREATE = 'users/create';
export const USERS_EDIT = 'users/edit/:userId';
export const USERS_DETAILS = 'users/:userId';

export const ROLES = 'roles';
export const ROLES_CREATE = 'roles/create';
export const ROLES_EDIT = 'roles/edit/:roleId';

export const GROUPS = 'groups';
export const GROUPS_CREATE = 'groups/create';
export const GROUPS_EDIT = 'groups/edit/:groupId';

export const COMPANY_UNITS = 'company-units';
export const COMPANY_UNITS_CREATE = 'company-units/create';
export const COMPANY_UNITS_EDIT = 'company-units/edit/:structureId';

export const ACTIVITY_CENTER = 'activityCenter/';
export const MARKETING_CENTER = 'marketingCenter/';

export const REPORT_PAGE = 'reports/';
export const REPORT_CREATE = 'reports/create';
export const REPORT_UPDATE = (id = ':reportId') => `reports/edit/${id}`;
export const REPORT_LIST = 'reports/list';

export const AUDIT_LOG = (uuid = ':uuid', modelType = ':modelType') =>
  `auditLog/${uuid}/${modelType}`;

///Contacts route consts
export const CONTACTS = 'contacts';

export const CREATE_CONTACT = 'contactCreate';
export const UPDATE_CONTACT = (id = ':contactEditId') => `contactEdit/${id}`;

export const DETAILS_CONTACT = (id = ':contactDetailsId?/*') =>
  `contacts/details/${id}/`;
export const LIST_CONTACTS = 'contacts/list/:listId?/*';

///Transaction route consts
export const DETAILS_TRANSACTION = (id = ':transactionDetailsId?/*') =>
  `transactions/details/${id}/`;
export const CREATE_OFFER = 'offerCreate/';
export const VIEW_OFFER = (id = ':offerViewId') => `offerView/${id}/`;
export const UPDATE_OFFER = (id = ':offerEditId') => `offerEdit/${id}/`;
export const COMMISSION_OVERVIEW = (id = ':transactionDetailsId?/*') =>
  `transactions/commissionOverview/${id}/`;

export const CREATE_TRANSACTION = 'transactionCreate/';
export const UPDATE_TRANSACTION = (id = ':transactionId') =>
  `transactionEdit/${id}/`;
export const LIST_TRANSACTIONS = 'transactions/list/*';

///Task route consts
export const CREATE_TASK = (
  relatedType = ':relatedType',
  relatedTypeId = ':relatedTypeId',
) => `taskCreate/${relatedType}/${relatedTypeId}`;
export const CREATE_TIMEFRAME = (
  relatedType = ':relatedType',
  relatedTypeId = ':relatedTypeId',
) => `timeframeCreate/${relatedType}/${relatedTypeId}`;
export const UPDATE_TASK = (id = ':taskEditId') => `taskEdit/${id}`;

///Note route consts
export const CREATE_NOTE = (
  relatedType = ':relatedType',
  relatedTypeId = ':relatedTypeId',
) => `noteCreate/${relatedType}/${relatedTypeId}`;
export const UPDATE_NOTE = (id = ':noteEditId') => `noteEdit/${id}`;

///Setting route consts
export const CREATE_SETTING = 'settingCreate/';
export const UPDATE_SETTING = (id = ':settingEditId', panel = ':panelEdit') =>
  `settingEdit/${id}/${panel}/`;
export const DETAILS_SETTING = (id = ':settingDetailsId?/*') =>
  `settings/details/${id}/`;
export const LIST_SETTINGS = 'settings/list/*';

///Capping route consts
export const CREATE_CAPPING = 'cappingCreate/';
export const UPDATE_CAPPING = (id = ':cappingEditId') => `cappingEdit/${id}/`;
export const DETAILS_CAPPING = (id = ':cappingDetailsId?/*') =>
  `cappings/details/${id}/`;
export const LIST_CAPPINGS = 'cappings/list/*';

///Commission route consts
export const UPDATE_COMMISSION = (id = ':commissionEditId') =>
  `commissionEdit/${id}/`;
export const DETAILS_COMMISSION = (id = ':commissionDetailsId?/*') =>
  `commissions/details/${id}/`;
export const LIST_COMMISSION = 'commissions/list/*';

///Document route consts
export const CREATE_DOCUMENT = 'documentCreate/';
export const UPDATE_DOCUMENT = (id = ':documentEditId') =>
  `documentEdit/${id}/`;
export const DETAILS_DOCUMENT = (id = ':documentDetailsId?/*') =>
  `documents/details/${id}/`;
export const LIST_DOCUMENT = 'documents/list/';
export const PREVIEW_DOCUMENT = (id = ':documentPreviewId') =>
  `documentPreview/${id}/`;
export const EDIT_DOCUMENT = (id = ':documentEditId') => `documentEdit/${id}/`;
export const SELECT_DOCUMENT = (id = ':documentSelectId') =>
  `documentSelect/${id}/`;

///Document Template route consts
export const CREATE_CLICK_MARKETING = 'createClickMarketing/';
export const CREATE_DOCUMENT_TEMPLATE = (
  parentId = ':parentId',
  type = ':type',
) => `createEsign/${parentId}/${type}`;
export const TEMPLATE_MANAGER = (tab = ':tab', parentId = ':parentId?') =>
  `documentTemplates/manager/${tab}/${parentId ? `${parentId}/` : ''}`;
export const EDIT_DOCUMENT_TEMPLATE = (id = ':documentTemplateEditId') =>
  `documentTemplateEdit/${id}/`;
export const DETAILS_DOCUMENT_TEMPLATE = (id = ':documentTemplateDetailsId') =>
  `documentTemplatesDetails/${id}/`;

export const TEMPLATE_LIST = 'documentTemplates/list/';

///Document Category route consts
export const DOCUMENT_CATEGORY_CREATE = 'documentCategoryCreate/';
export const DOCUMENT_CATEGORY_UPDATE = (id = ':categoryId') =>
  `documentCategoryEdit/${id}/`;

///Invoice route consts
export const LIST_INVOICES = 'invoices/list/';
export const CREATE_INVOICE = (invoiceTemplateId = ':invoiceTemplateId') =>
  `invoiceCreate/${invoiceTemplateId}/`;
export const UPDATE_INVOICE = (id = ':invoiceEditId') => `invoiceEdit/${id}/`;

///Invoice Template route consts
export const LIST_INVOICE_TEMPLATE = 'invoiceTemplates/list/';
export const CREATE_INVOICE_TEMPLATE = 'invoiceTemplateCreate';
export const UPDATE_INVOICE_TEMPLATE = (id = ':invoiceTemplateEditId') =>
  `invoiceTemplateEdit/${id}/`;
export const DETAILS_INVOICE_TEMPLATE = (id = ':invoiceTemplateDetailsId') =>
  `invoiceTemplateDetails/${id}/`;
export const CREATE_INVOICE_TEMPLATE_CATEGORY =
  'invoiceTemplateCategoryCreate/';
export const UPDATE_INVOICE_TEMPLATE_CATEGORY = (
  id = ':invoiceTemplateCategoryId',
) => `invoiceTemplateCategoryEdit/${id}/`;

////////////////////////////////////////////////////////////////////////
// Begin Routes
////////////////////////////////////////////////////////////////////////

// Activity routes
const ACTIVITY_ROUTES = [
  { path: `${ACTIVITY_CENTER}*`, element: <ActivityPage /> },
];

// Marketing routes
const MARKETING_ROUTES = [
  { path: `${MARKETING_CENTER}*`, element: <MarketingPage /> },
];

// Report routes
const REPORT_ROUTES = [
  { path: `${REPORT_PAGE}*`, element: <ReportPage /> },
  {
    path: `${REPORT_CREATE}`,
    element: <CreateReportPage />,
  },
  {
    path: `${REPORT_UPDATE()}`,
    element: <CreateReportPage />,
  },
  {
    path: `${REPORT_LIST}`,
    element: <ReportsListPage />,
  },
];

// Contacts routes
const CONTACT_ROUTES = [
  { path: LIST_CONTACTS, element: <ContactsPage /> },
  { path: DETAILS_CONTACT(), element: <DetailsContactPage /> },
];

// Transactions routes
const TRANSACTION_ROUTES = [
  { path: LIST_TRANSACTIONS, element: <TransactionsPage /> },
  { path: DETAILS_TRANSACTION(), element: <DetailsTransactionPage /> },
  { path: COMMISSION_OVERVIEW(), element: <CommissionOverviewPage /> },
];

// Documents routes
const DOCUMENT_ROUTES = [
  { path: `${LIST_DOCUMENT}*`, element: <DocumentsPage /> },
  { path: DETAILS_DOCUMENT(), element: <DetailsDocumentPage /> },
];

// Document Templates routes
const DOCUMENT_TEMPLATE_ROUTES = [
  { path: `${TEMPLATE_MANAGER()}*`, element: <DocumentTemplatesPage /> },
  { path: `${TEMPLATE_LIST}*`, element: <DocumentTemplatesPage /> },
];

// Settings routes
const SETTING_ROUTES = [
  { path: LIST_SETTINGS, element: <SettingsPage /> },
  { path: DETAILS_SETTING(), element: <DetailsSettingPage /> },
];

// Cappings routes
const CAPPING_ROUTES = [
  { path: LIST_CAPPINGS, element: <CappingsPage /> },
  { path: DETAILS_CAPPING(), element: <DetailsCappingPage /> },
];

// Commissions routes
const COMMISSION_ROUTES = [
  { path: LIST_COMMISSION, element: <CommissionsPage /> },
];

const INVOICE_ROUTES = [
  { path: `${LIST_INVOICES}*`, element: <InvoicesPage /> },
];

const INVOICE_TEMPLATE_ROUTES = [
  { path: `${LIST_INVOICE_TEMPLATE}*`, element: <InvoiceTemplatesPage /> },
];

const MAIN_CHILD_ROUTES = [
  ...ACTIVITY_ROUTES,
  ...REPORT_ROUTES,
  ...MARKETING_ROUTES,
  ...CONTACT_ROUTES,
  ...TRANSACTION_ROUTES,
  ...SETTING_ROUTES,
  ...CAPPING_ROUTES,
  ...COMMISSION_ROUTES,
  ...DOCUMENT_ROUTES,
  ...DOCUMENT_TEMPLATE_ROUTES,
  ...INVOICE_ROUTES,
  ...INVOICE_TEMPLATE_ROUTES,
  // ... include other module routes
  { path: '*', element: <HomePage /> }, // catch-all route at the end
];

const MAIN_ROUTE = {
  path: '/*',
  element: (
    <ProtectedRoute>
      <MainLayout />
    </ProtectedRoute>
  ),
  children: MAIN_CHILD_ROUTES,
};

export const routesConfig = [
  {
    path: LOGIN,
    element: <LoginPage />,
  },
  {
    path: ADMIN,
    element: (
      <ProtectedRoute>
        <AdminLayout />
      </ProtectedRoute>
    ),
    children: [
      {
        path: USERS,
        element: <UsersPage />,
      },
      {
        path: USERS_CREATE,
        element: <CreateUserPage />,
      },
      {
        path: USERS_EDIT,
        element: <EditUserPage />,
      },
      {
        path: USERS_DETAILS,
        element: <ViewUserPage />,
      },
      {
        path: ROLES,
        element: <RolesPage />,
      },
      {
        path: ROLES_CREATE,
        element: <CreateRolePage />,
      },
      {
        path: ROLES_EDIT,
        element: <EditRolePage />,
      },
      {
        path: GROUPS,
        element: <GroupsPage />,
      },
      {
        path: GROUPS_CREATE,
        element: <CreateGroupPage />,
      },
      {
        path: GROUPS_EDIT,
        element: <EditGroupPage />,
      },
      {
        path: COMPANY_UNITS,
        element: <CompanyUnitsPage />,
      },
      {
        path: COMPANY_UNITS_CREATE,
        element: <CreateCompanyUnitPage />,
      },
      {
        path: COMPANY_UNITS_EDIT,
        element: <EditCompanyUnitPage />,
      },
    ],
  },
  MAIN_ROUTE,
];

export const ADMIN_ROUTES = {
  USERS: {
    exact: true,
    path: '/admin/users',
    component: AdminPages.UsersPage,
  },
  CREATE_USER: {
    exact: true,
    path: '/admin/users/create',
    component: AdminPages.CreateUserPage,
  },
  EDIT_USER: {
    exact: true,
    path: '/admin/users/edit/:userId',
    component: AdminPages.EditUserPage,
  },
  VIEW_USER: {
    exact: true,
    path: '/admin/users/:userId',
    component: AdminPages.ViewUserPage,
  },
  ROLES: {
    exact: true,
    path: '/admin/roles',
    component: AdminPages.RolesPage,
  },
  CREATE_ROLE: {
    exact: true,
    path: '/admin/roles/create',
    component: AdminPages.CreateRolePage,
  },
  EDIT_ROLE: {
    exact: true,
    path: '/admin/roles/edit/:roleId',
    component: AdminPages.EditRolePage,
  },
  GROUPS: {
    exact: true,
    path: '/admin/groups',
    component: AdminPages.GroupsPage,
  },
  CREATE_GROUP: {
    exact: true,
    path: '/admin/groups/create',
    component: AdminPages.CreateGroupPage,
  },
  EDIT_GROUP: {
    exact: true,
    path: '/admin/groups/edit/:groupId',
    component: AdminPages.EditGroupPage,
  },
  COMPANY_UNITS: {
    exact: true,
    path: '/admin/company-units',
    component: AdminPages.CompanyUnitsPage,
  },
  CREATE_COMPANY_UNIT: {
    exact: true,
    path: '/admin/company-units/create',
    component: AdminPages.CreateCompanyUnitPage,
  },
  EDIT_COMPANY_UNIT: {
    exact: true,
    path: '/admin/company-units/edit/:structureId',
    component: AdminPages.EditCompanyUnitPage,
  },
  WORKFLOW: {
    exact: true,
    path: '/admin/workflow',
    component: AdminPages.RolesPage,
  },
  JOB_MANAGER: {
    exact: true,
    path: '/admin/job-manager',
    component: AdminPages.RolesPage,
  },
  ADMINISTRATORS: {
    exact: true,
    path: '/admin/administrators',
    component: AdminPages.RolesPage,
  },
};
