import { Grid } from '@mui/material';

import { FormPanel } from 'components/Common';
import { Select, TextInput, UserAutocomplete } from 'components/Fields';
import { FormField } from 'components/Forms';

import {
  CONTACT_STATUSES,
  CONTACT_TYPES,
  VENDORS_1099,
  VENDORS_APPROVED,
} from 'consts/contacts';
import PropTypes from 'prop-types';
import React from 'react';
import { Field, useForm } from 'react-final-form';

export const OfficeUseOnlyBox = ({ showAllFields }) => {
  const { getState } = useForm();

  return showAllFields ||
    [
      CONTACT_TYPES.COMPANY_AGENT.value,
      CONTACT_TYPES.COMPANY_ADMIN.value,
      CONTACT_TYPES.COOPERATING_AGENT.value,
      CONTACT_TYPES.VENDOR.value,
    ].includes(getState().values.type) ? (
    <FormPanel label={'Office Use Only'}>
      <Grid container>
        <Grid item xs={6}>
          <Field
            name="contactStatus"
            label="Contact Status"
            options={CONTACT_STATUSES}
            component={Select}
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            name="vendorApproved"
            label="Vendor Approved"
            options={VENDORS_APPROVED}
            component={Select}
          />
        </Grid>
        <Grid item xs={6}>
          <Field name="mlsId" label="MLS ID" component={TextInput} />
        </Grid>
        <Grid item xs={6}>
          <Field
            name="licenseNumber"
            label="License Number"
            component={TextInput}
          />
        </Grid>
        <FormField
          name="abeUser"
          label="ABE User"
          size={6}
          component={UserAutocomplete}
        />
        <Grid item xs={6}>
          <Field
            name="vendor1099"
            label="1099 Vendor"
            allowNull
            options={VENDORS_1099}
            component={Select}
          />
        </Grid>
      </Grid>
    </FormPanel>
  ) : null;
};

OfficeUseOnlyBox.propTypes = {
  showAllFields: PropTypes.bool,
};
