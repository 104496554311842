export const RIGHT_OPTIONS = {
  agentOnly: {
    value: "agentOnly",
    label: "Agent Only",
  },
  sharedWithUsers: {
    value: "sharedWithUsers",
    label: "Shared with Agents",
  },
  sharedWithUnits: {
    value: "sharedWitUnits",
    label: "Shared with Units",
  },
  everyone: {
    value: "everyone",
    label: "Everyone",
  },
};
