import React, { useEffect } from 'react';
import { Field } from 'react-final-form';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/system';
import { AbeCard } from 'components/Common';
import { Wizard } from 'components/Common/Wizard/Wizard';
import { TextInput, Select, CheckboxField } from 'components/Fields';
import { LayoutHeader } from 'components/Layouts';
import { PageMainContent } from 'components/Styled';
import { CATEGORY_STATUS } from 'consts';
import { getSelectOptions } from 'helpers';
import {
  useGetReportModulesQuery,
  useCreateReportMutation,
  useUpdateReportMutation,
  useGetReportQuery,
  useCreateFiltersMutation,
} from 'redux/rtk-query';
import { ReportFiltersPage } from './ReportFiltersPage';

export const CreateReportPage = () => {
  const [createReport] = useCreateReportMutation();
  const [updateReport] = useUpdateReportMutation();
  const [createFilters] = useCreateFiltersMutation();

  const { reportId } = useParams();

  const [initialValues, setInitialValues] = React.useState({
    name: '',
    module: '',
    description: '',
    systemDescription: '',
    testMode: true,
    filters: [{}],
    ownerRecord: 'All',
  });

  const { data: reportData } = useGetReportQuery(reportId, { skip: !reportId });

  const handleReportSubmit = async (values) => {
    console.log('Report submitted:', values);
  };

  const { data: moduleData } = useGetReportModulesQuery();

  const moduleOptions = moduleData?.data
    ? Object.keys(moduleData?.data).map((module) => ({
        label: module,
        value: module,
      }))
    : [];

  const mapFormValuesToDTO = (values) => {
    return {
      name: values.name,
      systemDescription: values.systemDescription,
      description: values.description,
      status: values.status,
      testMode: values.testMode,
      allowMassUpdate: values.allowMassUpdate,
      unrestrictedReport: values.unrestrictedReport,
      limit: values.limit,
      module: values.module,
      charts: values.charts || [],
      ownerRecord: values.ownerRecord,
      ownerRecordSelectedUserId: values.ownerRecordSelectedUserId,
      isOwnerRecordFilterEditable: values.isOwnerRecordFilterEditable,
      unitRecord: values.unitRecord,
      iUnitRecordFilterEditable: values.iUnitRecordFilterEditable,
      whoCanReadIds: values.whoCanReadIds || [],
      whoCanWriteIds: values.whoCanWriteIds || [],
      // Include any other fields required by your DTO
    };
  };

  const mapFiltersToDTO = (filters) => {
    console.log('filters:', filters);
    return filters.map((group) => ({
      name: group.name,
      groupingCondition: group.groupingCondition,
      isEditable: group.isEditable,
      childGroups: group.childGroups ? mapFiltersToDTO(group.childGroups) : [],
      filters: group.filters
        ? group.filters.map((filter) => ({
            name: filter.name,
            path: filter.path,
            condition: filter.condition,
            value: filter.value,
            isEditable: filter.isEditable,
          }))
        : [],
    }));
  };

  const steps = [
    {
      label: 'General',
      onNext: async (values) => {
        console.log('General values:', values);

        // Map form values to DTO
        const dtoData = mapFormValuesToDTO(values);

        // Call the createReport mutation
        try {
          if (values.id) {
            const response = await updateReport({
              id: values.id,
              ...dtoData,
            }).unwrap();
            console.log('Report updated:', response);
            return true;
          } else {
            const response = await createReport(dtoData).unwrap();
            console.log('Report created:', response);
            // Update formValues with data returned from the server
            setInitialValues((prevValues) => ({
              ...prevValues,
              id: response?.data?.id,
            }));
          }

          // Proceed to the next step
          return true;
        } catch (error) {
          console.error('Failed to create report:', error);

          // Optionally show an error message to the user

          // Prevent proceeding to the next step
          return false;
        }
      },
      render: () => (
        <>
          <Field
            name="module"
            label="Module"
            component={Select}
            options={moduleOptions}
            required
          />
          <Field
            name="name"
            label="Report Name"
            component={TextInput}
            required
          />
          <Field
            name="systemDescription"
            label="System Description"
            component={TextInput}
          />
          <Field
            name="description"
            label="Public Description"
            component={TextInput}
            required
          />
          <Field
            name="status"
            label="Status"
            component={Select}
            required
            options={getSelectOptions(CATEGORY_STATUS)}
          />
          <Field
            name="testMode"
            label={'Test Mode'}
            component={CheckboxField}
          />
        </>
      ),
    },
    {
      label: 'Filters ',
      onNext: async (values) => {
        const reportId = values.id;
        if (!reportId) {
          console.error('No report ID found.');
          return false;
        }

        // Map filters to DTO structure
        const filtersDTO = {
          name: values.filters[0].name || 'Default Filter Group Name',
          groupingCondition: values.filters[0].groupingCondition || 'And',
          isEditable: values.filters[0].isEditable || false,
          childGroups: mapFiltersToDTO(values.filters[0].childGroups || []),
          filters: values.filters[0].filters || [],
        };

        // Call the createFilters mutation
        try {
          const response = await createFilters({
            reportId,
            ...filtersDTO,
            groupingCondition: 'And',
          }).unwrap();
          console.log('Filters saved:', response);

          // Optionally update form values or state

          // Proceed to the next step
          return true;
        } catch (error) {
          console.error('Failed to save filters:', error);

          // Optionally show an error message to the user

          // Prevent proceeding to the next step
          return false;
        }
      },
      render: () => (
        <Field name="filters" label="Filters" component={ReportFiltersPage} />
      ),
    },
    {
      label: 'Groupings',
      render: (form, values) => (
        <div>
          <p>Review your details before submitting:</p>
          <ul>
            <li>Name: {values.name}</li>
            <li>Status: {values.status}</li>
          </ul>
        </div>
      ),
    },
    {
      label: 'Columns',
      render: (form, values) => (
        <div>
          <p>Review your details before submitting:</p>
          <ul>
            <li>Name: {values.name}</li>
            <li>Status: {values.status}</li>
          </ul>
        </div>
      ),
    },
    {
      label: 'Finish and Save',
      render: (form, values) => (
        <div>
          <p>Review your details before submitting:</p>
          <ul>
            <li>Name: {values.name}</li>
            <li>Status: {values.status}</li>
          </ul>
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (reportData?.data) {
      setInitialValues({
        ...reportData?.data,
        filters: reportData?.data?.filters || [{}],
      });
    }
  }, [reportData]);

  console.log('initialValues:', initialValues);
  return (
    <>
      <LayoutHeader title="Create Report" />
      <PageMainContent maxWidth={false} component="main" disableGutters>
        <AbeCard sx={{ width: '100%' }} noTitle>
          <Box>
            <Wizard
              steps={steps}
              onSubmit={handleReportSubmit}
              initialValues={initialValues}
              setInitialValues={setInitialValues}
            />
          </Box>
        </AbeCard>
      </PageMainContent>
    </>
  );
};
