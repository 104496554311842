import { faCaretDown, faCaretUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, TableBody } from '@mui/material';
import { TableRow } from 'components/Common';
import {
  StyledBadge,
  TableCell,
  TableCellLoader,
  TablePagination,
} from 'components/Styled';
import { colors } from 'consts';
import { conversion, taskColor } from 'helpers';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useGetTasksQuery, useUpdateTaskMutation } from 'redux/rtk-query';
import { activityFiltersSelector } from 'redux/selectors';
import { Cells } from '../../config';

export const TaskSectionTable = ({ search, sectionType }) => {
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [collapse, setCollapse] = React.useState(true);

  const activityFilters = useSelector(activityFiltersSelector);

  const listFilterValues =
    activityFilters.filterType === 'lists'
      ? activityFilters?.filterValues?.map((list) => list.value).join(',')
      : '';

  const { data, isFetching } = useGetTasksQuery({
    taskType: 'Task',
    type: sectionType,
    search: search,
    page: page,
    limit: rowsPerPage,
    listIds: listFilterValues,
    status: sectionType !== 'COMPLETED' ? ['New', 'In Progress'] : null,
    sortAttribute: sectionType === 'COMPLETED' ? 'updatedAt' : 'dueOn',
    sortOrder: sectionType === 'COMPLETED' ? 'DESC' : 'ASC',
  });
  const [updateTask] = useUpdateTaskMutation();

  const [loading, setLoading] = React.useState(true);
  const totalPages = data?.data?.meta.totalPages;
  const rows = data?.data?.items;
  const totalCount = data?.data?.meta.total;

  useEffect(() => {
    setLoading(isFetching);
  }, [isFetching]);

  const toggleCollapse = () => {
    setCollapse(!collapse);
  };

  const handleChangePage = (even, newPage) => {
    setPage(newPage);
  };
  // eslint-disable-next-line no-unused-vars
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleCheckClick = (id, row) => async () => {
    let status = row.status === 'Completed' ? 'New' : 'Completed';
    await updateTask({
      status,
      taskId: id,
    });
  };

  return (
    <>
      {loading || totalCount ? (
        <TableBody sx={{ borderLeft: `4px solid ${taskColor(sectionType)}` }}>
          <tr>
            <TableCell sx={{ p: 0, pl: 1 }} colSpan={Cells.length + 1}>
              <Box
                fontSize={13}
                fontWeight={'bold'}
                p={0}
                textTransform={'uppercase'}
              >
                <StyledBadge badgeContent={totalCount} color="secondary">
                  <>
                    <Button onClick={toggleCollapse} size={'x-small'}>
                      <FontAwesomeIcon
                        icon={collapse ? faCaretDown : faCaretUp}
                        fontSize={'lg'}
                        color={colors.BLACK}
                      />
                    </Button>
                    <Box component={'span'} ml={0.5}>
                      {conversion.titleCase(sectionType)}
                    </Box>
                  </>
                </StyledBadge>
                <Box p={0.5} display={'inline-block'}>
                  {totalPages > 0 && (
                    <TablePagination
                      count={totalPages}
                      size="large"
                      page={page}
                      variant="outlined"
                      shape="rounded"
                      color="primary"
                      onChange={handleChangePage}
                    />
                  )}
                </Box>
              </Box>
            </TableCell>
          </tr>

          {!!collapse && (
            <>
              {loading ? (
                <tr>
                  <TableCell />
                  {Cells.map((cell, index) => {
                    return (
                      <TableCell key={index}>
                        <TableCellLoader />
                      </TableCell>
                    );
                  })}
                </tr>
              ) : (
                rows.map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      setSelected={handleCheckClick}
                      selected={[]}
                      search={search}
                      key={labelId}
                      row={row}
                      cells={Cells}
                      labelId={labelId}
                    />
                  );
                })
              )}
            </>
          )}
        </TableBody>
      ) : (
        ''
      )}
    </>
  );
};

TaskSectionTable.propTypes = {
  search: PropTypes.string,
  sectionType: PropTypes.string,
};
