import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import DesignItems from 'components/ModelViews/Documents/ClickMarketingViewer/DesignItems';
import { pixelSizeSelector, activePageCM } from 'redux/selectors';
import { setActivePageCM } from 'redux/slices';

const PREVIEW_SCALE = 0.2;

export const PageTab = ({ pageId }) => {
  const dispatch = useDispatch();
  const activePageId = useSelector(activePageCM);
  const [width, height] = useSelector(pixelSizeSelector);
  const [isVisible, setIsVisible] = useState(false); // Track if the element is in the viewport
  const [isLoaded, setIsLoaded] = useState(false); // Track if the element has been loaded once
  const boxRef = useRef(null);

  let pageScale = {
    x: PREVIEW_SCALE,
    y: PREVIEW_SCALE,
  };
  let pageWidth = width * pageScale.x;
  let pageHeight = height * pageScale.y;

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true); // Set visibility to true when in viewport
          setIsLoaded(true); // Mark as loaded once it has been seen
        } else {
          setIsVisible(false); // Set visibility to false when out of viewport
        }
      },
      {
        rootMargin: '0px',
        threshold: 0.1, // Trigger when 10% of the component is visible
      },
    );

    if (boxRef.current) {
      observer.observe(boxRef.current);
    }

    return () => {
      if (boxRef.current) {
        observer.unobserve(boxRef.current);
      }
    };
  }, []);

  return (
    <Grid
      py={1}
      sx={{
        display: 'flex',
        justifyContent: 'center',
      }}
      onClick={() => dispatch(setActivePageCM(pageId))}
      item
    >
      <Box
        ref={boxRef} // Attach the ref to the Box element
        position="relative"
        sx={{
          pointerEvents: 'none',
          width: `${pageWidth}px`,
          height: `${pageHeight}px`,
          background: 'white',
          outline: activePageId === pageId ? '4px solid #097CC7' : 'none',
        }}
      >
        {(isVisible || isLoaded) && ( // Only load DesignItems if it's visible or already loaded once
          <Box sx={{ transform: 'scale(.2)', transformOrigin: 'left' }}>
            <DesignItems pageId={pageId} preview scale={PREVIEW_SCALE} />
          </Box>
        )}
      </Box>
    </Grid>
  );
};

PageTab.propTypes = {
  pageId: PropTypes.string.isRequired,
};
