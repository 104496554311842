import React from "react";
import { useSelector } from "react-redux";

import { LEFT_MENU_ITEM_ADMIN } from "consts";
import { isAuthenticatedSelector } from "redux/selectors";

import {
  PageContent,
  TopNavbar,
  LeftMenuDrawer,
} from "../components";
import {Box} from "@mui/material";
import {Outlet} from "react-router-dom";

export const AdminLayout = () => {
  const isAuthenticated = useSelector(state => isAuthenticatedSelector(state));

  return isAuthenticated ? (
          <Box>
            <TopNavbar />
            <LeftMenuDrawer menuItems={LEFT_MENU_ITEM_ADMIN} />
            <PageContent>
                <Outlet/>
            </PageContent>
          </Box>
  ) : null;
};
