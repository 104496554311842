import React from "react";

import { RightSideContainer } from "./styled";

export const RightSideContent = props => (
  <RightSideContainer
    disableGutters
    component="section"
    {...props}
    maxWidth={false}
  />
);
