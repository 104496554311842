import React from "react";
import PropTypes from "prop-types";

import {
  FooterWrapper,
  TablePagination,
  TablePaginationSelect,
} from "components/Styled";

export const TableFooter = ({
  rowsPerPageOptions,
  count,
  page,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
}) => (
  <FooterWrapper>
    {count > 0 && (
        <>
          <TablePaginationSelect
              component="div"
              count={count}
              page={page > 0 ? page - 1 : 0}
              onPageChange={handleChangePage}
              labelRowsPerPage="Rows per page"
              labelDisplayedRows={() =>{}}
              rowsPerPageOptions={rowsPerPageOptions}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              SelectProps={{
                variant: "standard"
              }}
          />
          <TablePagination
              count={count}
              size="large"
              page={page}
              variant="outlined"
              shape="rounded"
              color="primary"
              onChange={handleChangePage}
          />
        </>
    )}
  </FooterWrapper>
);


TableFooter.propTypes = {
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  count: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
};
