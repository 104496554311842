import React from 'react';
import PropTypes from 'prop-types';
import { Autocomplete, Snackbar, TextField } from '@mui/material';
import { useLoadMoreOptions } from 'hooks';
import { useGetStateListQuery, useSetStateMutation } from 'redux/rtk-query';

const getUserOption = ({ id, name }) => ({
  value: id,
  label: name,
  key: id,
});

export const SwitchStateAutocomplete = ({ onSuccess }) => {
  const { handleOpen, options, handleSearch } = useLoadMoreOptions(
    useGetStateListQuery,
    getUserOption,
  );
  const [switchState] = useSetStateMutation();

  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState('');

  async function handleSwitchState(userId) {
    console.log(userId);
    return switchState(userId);
  }

  const handleUserSwitch = async (value) => {
    try {
      const response = await handleSwitchState({ stateId: value.value });
      console.log(response);
      onSuccess();
      setSnackbarMessage(`Switched to ${value.label}`);
      // invalidateAllTags(dispatch);

      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      console.log('Failed to switch user', error);
      setSnackbarMessage('Failed to switch user');
    } finally {
      setSnackbarOpen(true);
    }
  };

  return (
    <div>
      <Autocomplete
        onOpen={handleOpen}
        options={options}
        getOptionLabel={(option) => option.label}
        onChange={(e, value) => handleUserSwitch(value)}
        renderInput={(params) => (
          <TextField
            {...params}
            autoFocus
            onChange={(e) => handleSearch(e.target.value)}
          />
        )}
      />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
    </div>
  );
};

SwitchStateAutocomplete.propTypes = {
  onSuccess: PropTypes.func,
};
