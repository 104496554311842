import { ConfirmMenuItem } from 'components/Common';
import PropTypes from 'prop-types';
import React from 'react';

export const DeleteComponent = ({ onBackToMenu }) => {
  return (
    <ConfirmMenuItem
      title="Delete"
      message={'Are you sure you want to permanently this document?'}
      onConfirm={onBackToMenu}
      onCancel={onBackToMenu}
      confirmText={'Confirm'}
      confirmColor={'error'}
      cancelText={'Go Back'}
    />
  );
};

DeleteComponent.propTypes = {
  onBackToMenu: PropTypes.func,
};
