import { styled } from "@mui/system";
import { List, ListItem, ListItemText, Box } from "@mui/material";

export const Lists = styled(List)({
  width: "100%",
  padding: "8px 0 0",
  overflowY: "scroll",
});

export const ListsItems = styled(ListItem)({
  margin: "10px 20px",
});

export const ListsItemsText = styled(ListItemText)({
  padding: "0 15px"
});

export const PrimaryTextStyles = {
  fontWeight: "400",
  fontSize: "16px",
  lineHeight: "24px",
  color: "#565656",
};

export const SecondaryTextStyles = {
  fontWeight: "400",
  fontSize: "13px",
  lineHeight: "20px",
  color: "#969696",
};

export const CheckboxIcon = styled(Box)({
  width: "28px",
  height: "48px",
  display: "flex",
  alignItems: "start",
  paddingTop: "2px",
});
