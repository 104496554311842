import { faUserPlus } from '@fortawesome/pro-light-svg-icons';
import { IconButton } from '@mui/material';
import { FontAwesomeIconComp, FormPanel } from 'components/Common';
import { FormCondition } from 'components/Forms';
import { CONTACT_TYPES, REPRESENTING_OPTIONS, STATUS_OPTIONS } from 'consts';
import React from 'react';
import { Field, useField } from 'react-final-form';
import { TeamMembers } from '../TeamShareAndReferralPanel/TeamMembers';
import {
  CustomerRolesField,
  OpposingCustomerRolesField,
  RolesField,
} from './components';

export const RolesPanel = () => {
  let representingValue = useField('representing').input.value;
  let statusValue = useField('state').input.value;

  const shouldShowCoopAndLender =
    representingValue !== REPRESENTING_OPTIONS.SELLER.value ||
    (representingValue === REPRESENTING_OPTIONS.SELLER.value &&
      statusValue !== STATUS_OPTIONS.DRAFT.value);

  return (
    <FormCondition when="representing" operator="isNotEmpty" is={false}>
      <FormPanel
        startOpen
        label={'Roles'}
        toolbar={
          <IconButton
            size={'small'}
            style={{ minWidth: 26, padding: '4px' }}
            color={'primary'}
            variant={'outlined'}
            // onClick={addExtraRole}
          >
            <FontAwesomeIconComp icon={faUserPlus} />
          </IconButton>
        }
      >
        <Field label="Roles" name={'roles'}>
          {(props) => (
            <>
              <CustomerRolesField
                {...props}
                customerType={'customersType1'}
                createType={'Customer'}
              />
              <CustomerRolesField
                {...props}
                customerType={'customersType2'}
                createType={'Customer'}
              />
            </>
          )}
        </Field>
        <TeamMembers />
        <Field label="Roles" name={'rolesStr'}>
          {(props) => (
            <>
              <OpposingCustomerRolesField {...props} />
            </>
          )}
        </Field>
        <Field label="Roles" name={'roles'}>
          {(props) => (
            <>
              {shouldShowCoopAndLender && (
                <>
                  <RolesField
                    {...props}
                    role={'Cooperating Agent'}
                    contactType={CONTACT_TYPES.COOPERATING_AGENT.value}
                    createType={CONTACT_TYPES.COOPERATING_AGENT.value}
                  />
                  <FormCondition
                    when="financingType"
                    operator="doesNotEqual"
                    is={'CASH'}
                  >
                    <RolesField
                      {...props}
                      role={'Lender'}
                      contactType={CONTACT_TYPES.LENDER.value}
                      createType={CONTACT_TYPES.LENDER.value}
                    />
                  </FormCondition>
                </>
              )}
              <FormCondition
                when="representing"
                operator="oneOf"
                is={[
                  REPRESENTING_OPTIONS.SELLER.value,
                  REPRESENTING_OPTIONS.BUYER.value,
                  REPRESENTING_OPTIONS.SELLER_AND_BUYER.value,
                ]}
              >
                <RolesField
                  {...props}
                  role={'Escrow Agent'}
                  contactType={CONTACT_TYPES.CLOSING_ESCROW_AGENT.value}
                  createType={CONTACT_TYPES.CLOSING_ESCROW_AGENT.value}
                />
                <RolesField
                  {...props}
                  role={'Closing Agent'}
                  contactType={CONTACT_TYPES.CLOSING_ESCROW_AGENT.value}
                  createType={CONTACT_TYPES.CLOSING_ESCROW_AGENT.value}
                />
              </FormCondition>
              {/*<OtherRoles {...props}/>*/}
            </>
          )}
        </Field>
      </FormPanel>
    </FormCondition>
  );
};

RolesPanel.propTypes = {};
