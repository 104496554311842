export const listedData = {
    "totalItemCount": 2,
    "items": [
        {
            "id": 9222,
            "name": "260 Spring Forest Drive New Smyrna Beach",
            "pmStatus": "",
            "status": "Listed",
            "period": "11/30/00-1",
            "netCommission": "27000",
            "documentProgress": {
                "Draft": 2,
                "In Progress": 2,
                "Completed": 2,
                "Revise": 0
            },
            "agent": "Kylo Holmes"
        },
        {
            "id": 12581,
            "name": "111 North 12th Street 1605 Tampa",
            "pmStatus": "",
            "status": "Listed",
            "period": "11/30/00-1",
            "netCommission": "13000",
            "documentProgress": {
                "Draft": 3,
                "In Progress": 1,
                "Completed": 1,
                "Revise": 0
            },
            "agent": "Kylo Holmes"
        }
    ],
    "total": "40000"
}

export const pendingData = {
    "totalItemCount": 6,
    "items": [
        {
            "id": 8434,
            "name": "1835 2nd Street South St. Petersburg",
            "pmStatus": "",
            "status": "Pending",
            "period": "11/30/00-1",
            "netCommission": "10320",
            "documentProgress": {
                "Draft": 10,
                "In Progress": 2,
                "Completed": 0,
                "Revise": 0
            },
            "agent": "Kylo Holmes"
        },
        {
            "id": 12582,
            "name": "10636 Gretna Green Drive Tampa",
            "pmStatus": "",
            "status": "Pending",
            "period": "11/30/00-1",
            "netCommission": "17700",
            "documentProgress": {
                "Draft": 0,
                "In Progress": 1,
                "Completed": 6,
                "Revise": 0
            },
            "agent": "Kylo Holmes"
        },
        {
            "id": 12599,
            "name": "5229 Butler Ridge Drive Windermere",
            "pmStatus": "",
            "status": "Pending",
            "period": "11/30/00-1",
            "netCommission": "22800",
            "documentProgress": {
                "Draft": 3,
                "In Progress": 2,
                "Completed": 3,
                "Revise": 0
            },
            "agent": "Kylo Holmes"
        },
        {
            "id": 7676,
            "name": "705 East Pine Street Orlando",
            "pmStatus": "",
            "status": "Pending",
            "period": "11/30/00-1",
            "netCommission": "11720",
            "documentProgress": {
                "Draft": 7,
                "In Progress": 2,
                "Completed": 5,
                "Revise": 0
            },
            "agent": "Kylo Holmes"
        },
        {
            "id": 13363,
            "name": "8704 Pinter Street Orlando",
            "pmStatus": "",
            "status": "Pending",
            "period": "11/30/00-1",
            "netCommission": "18000",
            "documentProgress": {
                "Draft": 5,
                "In Progress": 2,
                "Completed": 1,
                "Revise": 0
            },
            "agent": "Kylo Holmes"
        }
    ],
    "total": "80540"
}

export const draftData = {
    "totalItemCount": 10,
    "items": [
        {
            "id": 13648,
            "name": "1125 West Yale Street Orlando",
            "pmStatus": null,
            "status": "Draft",
            "period": null,
            "netCommission": "0",
            "documentProgress": {
                "Draft": 4,
                "In Progress": 0,
                "Completed": 0,
                "Revise": 0
            },
            "agent": "Kylo Holmes"
        },
        {
            "id": 13607,
            "name": "1811 Antilles Place Orlando",
            "pmStatus": "",
            "status": "Draft",
            "period": "11/30/00-1",
            "netCommission": "0",
            "documentProgress": {
                "Draft": 5,
                "In Progress": 0,
                "Completed": 0,
                "Revise": 0
            },
            "agent": "Kylo Holmes"
        },
        {
            "id": 13301,
            "name": "1522 East Jefferson Street Orlando",
            "pmStatus": null,
            "status": "Draft",
            "period": null,
            "netCommission": "800",
            "documentProgress": {
                "Draft": 5,
                "In Progress": 0,
                "Completed": 0,
                "Revise": 0
            },
            "agent": "Kylo Holmes"
        },
        {
            "id": 11942,
            "name": "24 Minnehaha Circle Maitland",
            "pmStatus": "",
            "status": "Draft",
            "period": "11/30/00-1",
            "netCommission": "16680",
            "documentProgress": {
                "Draft": 0,
                "In Progress": 0,
                "Completed": 0,
                "Revise": 0
            },
            "agent": "Kylo Holmes"
        },
        {
            "id": 12637,
            "name": "1234 North Orange Avenue Winter Park",
            "pmStatus": "",
            "status": "Draft",
            "period": "11/30/00-1",
            "netCommission": "7200",
            "documentProgress": {
                "Draft": 4,
                "In Progress": 1,
                "Completed": 0,
                "Revise": 0
            },
            "agent": "Kylo Holmes"
        }
    ],
    "total": "80340"
}

export const soldData = {
    "totalItemCount": 0,
    "items": [],
    "total": 0
}