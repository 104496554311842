import { Grid } from '@mui/material';
import { LayoutHeader } from 'components/Layouts';
import { PageMainContent } from 'components/Styled';
import React from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { selectedActivitiesSelector } from 'redux/selectors';
import {
  ActivityCenterToolbar,
  EmailsActivityTable,
  NotesActivityTable,
  TasksActivityTable,
} from './components';

export const ActivityPage = () => {
  const selectedActivity = useSelector(selectedActivitiesSelector);

  return (
    <>
      <LayoutHeader title={'Activity Center'}></LayoutHeader>
      <PageMainContent maxWidth={false} component="main" disableGutters>
        <Grid container flexDirection={'column'}>
          <ActivityCenterToolbar />
          {selectedActivity === 'Tasks' && <TasksActivityTable />}
          {selectedActivity === 'Notes' && <NotesActivityTable />}
          {selectedActivity === 'Emails' && <EmailsActivityTable />}
        </Grid>
      </PageMainContent>
      <Outlet />
    </>
  );
};
