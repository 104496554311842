import React from "react";
import PropTypes from "prop-types";
import { OutlinedInput } from "@mui/material";

import { Label, FormControlStyled } from "./styled";

export const EmailInput = props => {
  return (
    <FormControlStyled variant="outlined">
      <Label htmlFor="outlined-adornment-email">Email</Label>
      <OutlinedInput
        sx={{ borderRadius: "8px" }}
        id="outlined-adornment-email"
        type="text"
        label="Email"
        {...props.input}
      />
    </FormControlStyled>
  );
};

EmailInput.propTypes = {
  input: PropTypes.object,
};
