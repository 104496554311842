import { Box, Grid } from '@mui/material';
import { CardWithEdit, DetailsPanels } from 'components/Common';
import { DetailsBox } from 'components/Styled';
import { UPDATE_SETTING } from 'consts';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetSettingQuery } from 'redux/rtk-query';

export const SettingCapping = () => {
  let { settingDetailsId } = useParams();
  const { data: settingData } = useGetSettingQuery({ id: settingDetailsId });

  return (
    <Grid item xs={6}>
      <CardWithEdit link={UPDATE_SETTING(settingDetailsId, 'Capping')}>
        <DetailsBox>
          <Box width={'100%'}>
            <DetailsPanels
              data={settingData?.data}
              panels={{
                Capping: [
                  'rankAs',
                  'toAgentAmount',
                  'toCompanyAmount',
                  'salesCapping.name',
                  'salesSplit',
                  'leaseCapping.name',
                  'leaseSplit',
                  'propertyMgmtCapping.name',
                  'propertyMgmtSplit',
                  'referralCapping.name',
                  'referralSplit',
                  'commercialCapping.name',
                  'commercialSplit',
                ],
              }}
            />
          </Box>
        </DetailsBox>
      </CardWithEdit>
    </Grid>
  );
};
