import { ACTIVITY_CENTER, MARKETING_CENTER, REPORT_PAGE } from './routes'

export const LEFT_MENU_ITEM_INFO = [
  {
    module: "Home",
    refLink: "/",
  },
  {
    module: "Transactions",
    refLink: "/transactions/list/",
  },
  {
    module: "Marketing",
    refLink: MARKETING_CENTER,
  },
  {
    module: "Contacts",
    refLink: "/contacts/list/contact/",
  },
  {
    module: "Activities",
    refLink: ACTIVITY_CENTER,
  },
  {
    module: "Reports",
    refLink: REPORT_PAGE,
  },
  {
    module: "Invoices",
    refLink: "/invoices/list/",
  },
  {
    module: "Documents",
    refLink: "/documents/list/",
  },
  {
    module: "Capping",
    refLink: "/cappings/list/",
  },
  {
    module: "Settings",
    refLink: "/settings/list/",
  },
];
