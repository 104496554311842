import { SideDrawer } from 'components/Common';
import { NoteForm } from 'components/ModelViews/Notes';
import { defaultValues } from 'components/ModelViews/Notes/NoteForm/defaultValues';
import { RIGHT_OPTIONS } from 'consts';
import { conversion } from 'helpers';
import { useDrawer, useFetchRelatedModel } from 'hooks';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  contactsAPI,
  transactionsAPI,
  useCreateNoteMutation,
} from 'redux/rtk-query';

export const CreateNoteModal = ({ params, path }) => {
  const [initialValues, setInitialValues] = useState({});

  const [createNote] = useCreateNoteMutation();
  const dispatch = useDispatch();
  const { isOpen, openDrawer, closeDrawer } = useDrawer(false, path);

  const response = useFetchRelatedModel(
    params['relatedType'],
    params['relatedTypeId'],
  );
  const relatedModel = response.data?.data;

  const handleClose = () => {
    closeDrawer();
  };

  const afterSave = () => {
    handleClose();
  };

  useEffect(() => {
    if (relatedModel) {
      setInitialValues({
        ...defaultValues,
        agent: null,
        shareWithOption: RIGHT_OPTIONS.agentOnly.value,
        [`${params['relatedType']}Id`]: relatedModel.id,
      });
      openDrawer();
    }
  }, [relatedModel]);

  const handleSubmitFunc = async ({
    abeUser,
    agent,
    shareWith,
    companyStructureUnits,
    ...values
  }) => {
    const note = await createNote({
      ...values,
      abeUserId: abeUser?.value,
      agentId: agent?.value,
      shareWithIds: shareWith?.map(({ value }) => value),
      companyStructureUnitsIds:
        companyStructureUnits && companyStructureUnits[0] !== null
          ? companyStructureUnits?.map(({ value }) => value)
          : null,
    });
    if (params['relatedType'] === 'contact') {
      dispatch(contactsAPI.util.invalidateTags(['Contacts']));
    }
    if (params['relatedType'] === 'transaction') {
      dispatch(transactionsAPI.util.invalidateTags(['Transactions']));
    }
    return note;
  };

  return (
    <SideDrawer width={'40%'} isOpen={isOpen}>
      <NoteForm
        title={'Create Note'}
        subTitle={conversion.getName(relatedModel)}
        initialValues={initialValues}
        afterSave={afterSave}
        handleSubmitFunc={handleSubmitFunc}
      />
    </SideDrawer>
  );
};

CreateNoteModal.propTypes = {
  path: PropTypes.string,
  params: PropTypes.object,
};
