import { styled } from "@mui/system";
import { Box } from "@mui/material";

import { CreateBox, BoxName as Name } from "components/Styled";

export const CreateUserBox = styled(CreateBox)({
  display: "grid",
  gridTemplateColumns: "repeat(2, 1fr)",
  gap: "24px",
});

export const GridBox = styled(Box)({
  display: "grid",
  gridTemplateColumns: "repeat(2, 1fr)",
  gap: "24px",
});

export const BoxName = styled(Name)({
  gridColumn: "1 / 3",
  alignSelf: "center",
  margin: "4px 0",
});
