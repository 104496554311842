import { createApi } from '@reduxjs/toolkit/query/react';
import { searchUrlBuilder } from './urlBuilder/search';
import axiosBaseQuery from '../../utils/baseQuery';

export const searchAPI = createApi({
  reducerPath: 'searchAPI',
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  endpoints: (build) => ({
    globalSearch: build.query({
      query: (args) => ({
        url: searchUrlBuilder.globalSearch(args),
        method: 'GET',
      }),
      providesTags: ['Transactions', 'Contacts'],
    }),
  }),
});

export const { useGlobalSearchQuery } = searchAPI;
