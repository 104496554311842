export const UNIT_TYPES = [
  {
    value: "Country",
    label: "Country",
  },
  {
    value: "State",
    label: "State",
  },
  {
    value: "Region",
    label: "Region",
  },
  {
    value: "Office",
    label: "Office",
  },
  {
    value: "Other",
    label: "Other",
  },
];
