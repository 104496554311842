import { styled } from "@mui/system";
import { Box, Typography, Button, Popper as MuiPoper } from "@mui/material";

export const ListSectionWrapper = styled(Box)({
  height: "fit-content",
  width: "244px",
});

export const ListSectionHead = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "10px 5px 6px 5px",
});

export const ListTab = styled(Button, {
  shouldForwardProp: prop => prop !== "isSelected",
})(({ isSelected, theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  height: "40px",
  width: "100%",
  borderTopLeftRadius: "8px",
  borderBottomLeftRadius: "8px",
  borderTopRightRadius: "0",
  borderBottomRightRadius: "0",
  padding: "0 15px",
  cursor: "pointer",
  margin: "1px 0 1px",
  textTransform: "none",
  ...(isSelected && {
    color: "#FFFFFF",
    "& h6":{
      color: "#FFFFFF",
    },
    background: theme.palette.primary.main,
    "& span.MuiBox-root": {
      color: theme.palette.primary.grey,
      background: "#FFFFFF",
    },
    "& div.MuiBox-root > div > svg": {
      color: "#FFFFFF",
    },
    "&:hover": {
      color: "#FFFFFF",
      background: theme.palette.primary.main,
    },
  }),
  "& > *": {
    margin: 0,
  },
}));

export const ListTabText = styled(Typography)({
  fontWeight: "500",
  fontSize: "13px",
  lineHeight: "20px",
  textAlign: "left",
  width: "150px",
  margin: 0
});

export const ListTabCount = styled(Box)({
  height: "17px",
  borderRadius: "96px",
  fontWeight: "600",
  fontSize: "10px",
  textAlign: "center",
  letterSpacing: "0.04em",
  padding: "0 5px",
  color: "#FFFFFF",
  background: "#969696",
});

export const ListTabMenu = styled(Box)({
  marginRight: -6,
    "& > div": {
      margin: 0,
      padding: 0,
      minWidth: 24,
      height: 24,
      borderRadius: "50%"
    }
});

export const Popper = styled(MuiPoper)`
  z-index: 10;
  border-radius: "8px";
`;
