import { CreateButton } from 'components/Common';
import { LayoutHeader } from 'components/Layouts';
import { PageMainContent } from 'components/Styled';
import { LIST_INVOICE_TEMPLATE } from 'consts';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { InvoicesTable } from './components';

export const InvoicesPage = () => {
  return (
    <>
      <LayoutHeader title={'Invoices'}>
        <CreateButton
          path={`/${LIST_INVOICE_TEMPLATE}`}
          title={'Invoice Templates'}
          icon={null}
        />
      </LayoutHeader>
      <PageMainContent maxWidth={false} component="main" disableGutters>
        <InvoicesTable />
      </PageMainContent>
      <Outlet />
    </>
  );
};
