import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import DesignItems from 'components/ModelViews/Documents/ClickMarketingViewer/DesignItems';
import {
  scaleSelectorCM as scaleSelector,
  pixelSizeSelector,
} from 'redux/selectors';
import {
  redo,
  setActiveFieldCM as setActiveField,
  setScaleCM as setScale,
  undo,
  setDocumentDataCM as setDocumentData,
} from 'redux/slices';

const debounce = (func, delay) => {
  let inDebounce;
  return function () {
    const context = this;
    const args = arguments;
    clearTimeout(inDebounce);
    inDebounce = setTimeout(() => func.apply(context, args), delay);
  };
};

export function ClickMarketingViewer({ documentData, templateView = false }) {
  const containerRef = useRef(null);

  const dispatch = useDispatch();
  const scale = useSelector(scaleSelector);
  const [width, height] = useSelector(pixelSizeSelector);

  const [isDragging, setIsDragging] = useState(false);
  const [selectionPage, setSelectionPage] = useState(null);
  const [selectionStart, setSelectionStart] = useState(null);
  const [selectionEnd, setSelectionEnd] = useState(null);

  const doesIntersect = (selectionBox, adjustedRect) => {
    return !(
      selectionBox.right < adjustedRect.left ||
      selectionBox.left > adjustedRect.right ||
      selectionBox.bottom < adjustedRect.top ||
      selectionBox.top > adjustedRect.bottom
    );
  };

  const handleMouseDown = (e) => {
    if (
      e.button === 2 ||
      e.target.closest('.moveHandle') ||
      e.target.closest('.resizeHandleWrapper') ||
      e.target.closest('.textIndentResizeHandle')
    ) {
      setIsDragging(true);
      return;
    }

    if (e.key === 'Shift') {
      e.preventDefault();
    }

    if (e.target.closest('.page-wrapper')) {
      setSelectionPage(
        e.target.closest('.page-wrapper').getAttribute('data-page-number'),
      );
    }
    const rect = containerRef.current.getBoundingClientRect();
    setSelectionStart({ x: e.clientX - rect.left, y: e.clientY - rect.top });
    setSelectionEnd({ x: e.clientX - rect.left, y: e.clientY - rect.top });
    setIsDragging(true);
  };

  const handleMouseMove = (e) => {
    if (
      isDragging &&
      selectionStart &&
      containerRef.current.contains(e.target)
    ) {
      const rect = containerRef.current.getBoundingClientRect();
      setSelectionEnd({ x: e.clientX - rect.left, y: e.clientY - rect.top });
    }
  };

  const handleMouseUp = (e) => {
    if (
      !e.shiftKey &&
      isDragging &&
      selectionStart &&
      selectionEnd &&
      selectionStart.x === selectionEnd.x &&
      selectionStart.y === selectionEnd.y
    ) {
      // It's just a click, not a drag, so deselect all fields
      dispatch(setActiveField([]));
    } else if (
      !e.shiftKey &&
      isDragging &&
      selectionStart &&
      selectionEnd &&
      selectionPage
    ) {
      const selectionBox = {
        left: Math.min(selectionStart.x, selectionEnd.x),
        right: Math.max(selectionStart.x, selectionEnd.x),
        top: Math.min(selectionStart.y, selectionEnd.y),
        bottom: Math.max(selectionStart.y, selectionEnd.y),
      };

      // Get all field elements within the current page
      const pageElement = document.querySelector(
        `[data-page-number="${selectionPage}"]`,
      );
      const fieldElements = pageElement
        ? pageElement.querySelectorAll('[data-field-id]')
        : [];

      const selectedFieldIds = Array.from(fieldElements)
        .filter((el) => {
          const rect = el.getBoundingClientRect();
          // Calculate the adjusted position of the element relative to the selection area
          // Assuming the containerRef's scroll and scale are considered in these calculations
          const adjustedRect = {
            left:
              (rect.left -
                containerRef.current.getBoundingClientRect().left +
                containerRef.current.scrollLeft) /
              scale,
            right:
              (rect.right -
                containerRef.current.getBoundingClientRect().left +
                containerRef.current.scrollLeft) /
              scale,
            top:
              (rect.top -
                containerRef.current.getBoundingClientRect().top +
                containerRef.current.scrollTop) /
              scale,
            bottom:
              (rect.bottom -
                containerRef.current.getBoundingClientRect().top +
                containerRef.current.scrollTop) /
              scale,
          };

          Object.keys(adjustedRect).forEach(
            (key) => (adjustedRect[key] /= scale),
          );

          // Check intersection with the selection box
          return doesIntersect(selectionBox, adjustedRect);
        })
        .map((el) => el.getAttribute('data-field-id'));

      dispatch(setActiveField(selectedFieldIds));
    }

    // Regardless of whether it was a click or drag, reset all states
    setSelectionStart(null);
    setSelectionEnd(null);
    setSelectionPage(null);
    setIsDragging(false);
  };

  // Attach mouse event listeners to the container
  useEffect(() => {
    const container = containerRef.current;
    if (container && container.closest('.react-pdf__Document')) {
      container
        .closest('.react-pdf__Document')
        .addEventListener('mousedown', handleMouseDown);
      container
        .closest('.react-pdf__Document')
        .addEventListener('mousemove', handleMouseMove);
      container
        .closest('.react-pdf__Document')
        .addEventListener('mouseup', handleMouseUp);
    }

    return () => {
      if (container && container.closest('.react-pdf__Document')) {
        container
          .closest('.react-pdf__Document')
          .removeEventListener('mousedown', handleMouseDown);
        container
          .closest('.react-pdf__Document')
          .removeEventListener('mousemove', handleMouseMove);
        container
          .closest('.react-pdf__Document')
          .removeEventListener('mouseup', handleMouseUp);
      }
    };
  }, [selectionStart, selectionEnd, containerRef.current]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      // Check for Mac platform using navigator.platform
      const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;

      // Use event.metaKey for Command on Mac and event.ctrlKey for Control on others
      const undoPressed =
        !event.shiftKey &&
        ((isMac && event.metaKey && event.key === 'z') ||
          (!isMac && event.ctrlKey && event.key === 'z'));
      const redoPressed =
        (isMac && event.metaKey && event.shiftKey && event.key === 'z') ||
        (!isMac && event.ctrlKey && event.shiftKey && event.key === 'z');
      if (undoPressed) {
        event.preventDefault();
        dispatch(undo());
      } else if (redoPressed) {
        event.preventDefault();
        dispatch(redo());
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    // Cleanup
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [dispatch]);

  // Render selection box if dragging
  const renderSelectionBox = () => {
    if (!selectionStart || !selectionEnd || !isDragging) return null;

    const containerRect = containerRef.current.getBoundingClientRect();
    const startX = Math.min(selectionStart.x, selectionEnd.x);
    const startY = Math.min(selectionStart.y, selectionEnd.y);
    const endX = Math.max(selectionStart.x, selectionEnd.x);
    const endY = Math.max(selectionStart.y, selectionEnd.y);

    // Constrain the selection box to the container's dimensions
    const width = Math.min(endX - startX, containerRect.width - startX);
    const height = Math.min(endY - startY, containerRect.height - startY);

    return (
      <Box
        sx={{
          position: 'absolute',
          left: `${startX}px`,
          top: `${startY}px`,
          width: `${width}px`,
          height: `${height}px`,
          backgroundColor: 'rgba(0, 0, 255, 0.2)',
          border: '2px dashed blue',
          pointerEvents: 'none',
          zIndex: 100,
        }}
      />
    );
  };

  useEffect(() => {
    const handleResize = debounce(() => {
      const container = containerRef.current;
      if (container) {
        // Subtract 40px for 20px buffer on left and right
        const containerWidth = container.clientWidth - 40;
        // Subtract 40px for 20px buffer on top and bottom
        const containerHeight = container.clientHeight - 40;

        // Calculate scaling factors for width and height
        const widthScale = containerWidth / width;
        const heightScale = containerHeight / height;

        // Use the smaller scale factor to fit content within container
        const newScale = Math.min(widthScale, heightScale);

        dispatch(setScale(newScale));
      }
    }, 250);

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, [width, height, dispatch]);

  useEffect(() => {
    if (documentData) {
      let documentDataCopy = { ...documentData };
      documentDataCopy.templateView = templateView;
      dispatch(setDocumentData(documentDataCopy));
    }
    return () => {
      dispatch(setDocumentData(null));
    };
  }, []);

  return (
    <Grid
      item
      ref={containerRef}
      xs={8}
      sx={{
        height: '100%',
        width: '100%',
      }}
    >
      <Box
        flex={2}
        p={0}
        sx={{
          backgroundColor: '#6c6a6a',
          maxWidth: '100%',
          height: '100%',
          position: 'relative',
          padding: '20px', // Add 20px padding to get the buffer
          boxSizing: 'border-box', // Include padding in width and height calculations
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          position="relative"
          sx={{
            transform: `scale(${scale})`,
            width: `${width}px`,
            height: `${height}px`,
            background: 'white',
            '&:hover .react-draggable': {
              outlineWidth: '2px',
              outlineColor: '#097CC7',
              outlineStyle: 'dashed',
              outlineOffset: '-1px',
            },
            '& .show-on-hover': {
              visibility: 'hidden',
              position: 'absolute',
            },
            '&:hover .show-on-hover': {
              visibility: 'visible',
              position: 'absolute',
            },
            '& .text-label': {
              top: '-20px',
              left: '0',
              padding: '0 3px',
              backgroundColor: '#ffff78',
              fontSize: '12px',
              zIndex: 999,
            },
            '&:hover .text-label.insideLabel': {
              top: '0',
              left: '0',
              padding: '0 3px',
              backgroundColor: '#ffff78',
            },
          }}
        >
          {renderSelectionBox()}
          <Box className="checkered-background"></Box>
          <DesignItems />
        </Box>
      </Box>
    </Grid>
  );
}

ClickMarketingViewer.propTypes = {
  documentData: PropTypes.object.isRequired,
  templateView: PropTypes.bool,
  preview: PropTypes.bool,
};
