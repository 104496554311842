import {styled} from "@mui/system";
import {Table} from "@mui/material";

export const PipelineTable = styled(Table)({
  tableLayout:"fixed",
  "& .MuiTableCell-root:not(.noPadding)": {
    padding: 8
  },
  "& .table-header .MuiTableCell-root": {
  },
  "& .data-rows .MuiTableCell-root": {
    border: 0
  },
  "& .MuiTableRow-head th:first-of-type": {
    width: "60%"
  },
  "& .MuiTableRow-head th": {
    width: "20%"
  }
})

