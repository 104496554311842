import { MenuItem, Select } from '@mui/material';
import { SelectPlaceholderText } from 'components/Styled';
import PropTypes from 'prop-types';
import React from 'react';

export const DayPickerField = ({ input }) => {
  // create day options array as objects with value and label 1-31
  const dayOptions = Array.from(Array(31), (_, i) => {
    return { value: i + 1, label: i + 1 };
  });

  const handleDayChange = (event) => {
    input.onChange({
      ...input.value,
      day: event.target.value,
    });
  };

  return (
    <>
      <Select
        {...input}
        size={'small'}
        IconComponent={() => null}
        displayEmpty
        MenuProps={{
          sx: {
            maxHeight: 300,
          },
        }}
        label={'Day'}
        sx={{
          border: 0,
          '& .MuiOutlinedInput-notchedOutline': {
            border: 0,
          },
          '& .MuiOutlinedInput-input': {
            padding: '10px 0px',
            paddingRight: '5px !important',
            textAlign: 'center',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderWidth: 0,
          },
        }}
        onChange={handleDayChange}
        value={input.value?.day || ''}
      >
        <MenuItem value="">
          <SelectPlaceholderText>Day</SelectPlaceholderText>
        </MenuItem>
        {dayOptions.map(({ value, label }) => {
          return (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          );
        })}
      </Select>
    </>
  );
};

DayPickerField.propTypes = {
  input: PropTypes.object,
};
