import { faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons';
import { ClickAwayListener } from '@mui/material';
import AutocompleteField from '@mui/material/Autocomplete';
import { Icon } from 'components/Fields/TextInput/styled';
import { TextInputField } from 'components/Styled';
import { conversion } from 'helpers';
import { useLoadMoreOptions } from 'hooks';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAddToListMutation, useGetContactsQuery } from 'redux/rtk-query';
import {
  AutocompleteBox,
  PlusIcon,
  ToolbarAction,
  ToolbarButton,
} from '../styled';

const getContactOption = ({ id, ...rest }) => {
  return {
    id,
    value: id,
    label: conversion.getName(rest),
    key: id,
  };
};

export const AddExistingContact = () => {
  const [open, setOpen] = useState(false);
  const contacts = useLoadMoreOptions(useGetContactsQuery, getContactOption);
  const { listId: selectedListId } = useParams();

  const [addToList] = useAddToListMutation();

  const handleChange = (e, newValue) => {
    addToList({
      listsIds: [parseInt(selectedListId)],
      contactsIds: [newValue.id],
    });
    setOpen(false);
  };

  return (
    <>
      {!open ? (
        <ToolbarAction title="Add Existing" onClick={() => setOpen(true)}>
          <ToolbarButton endIcon={<PlusIcon />}>Add Existing</ToolbarButton>
        </ToolbarAction>
      ) : (
        <ClickAwayListener
          onClickAway={() => {
            setOpen(false);
          }}
        >
          <AutocompleteBox>
            <AutocompleteField
              autoFocus
              name="contact"
              placeholder={'Search for a Contact'}
              size={'small'}
              variant={'standard'}
              options={contacts.options}
              onOpen={contacts.handleOpen}
              loadMoreResults={contacts.handleMore}
              isLoading={contacts.isFetching}
              iconStart={<Icon icon={faMagnifyingGlass} />}
              onChange={handleChange}
              renderInput={(params) => {
                return (
                  <TextInputField
                    {...params}
                    onChange={(e) => {
                      contacts.handleSearch(e.target.value);
                    }}
                  />
                );
              }}
            />
          </AutocompleteBox>
        </ClickAwayListener>
      )}
    </>
  );
};
