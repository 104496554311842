import { styled } from "@mui/system";
import { Typography, Divider, Box } from "@mui/material";

export const BoxDivider = styled(Divider)({
  color: "#969696",
  marginBottom: "28px",
  borderColor: "#E8E8E8",
});

export const OptionBox = styled(Box)({
  display: "grid",
  gridTemplateColumns: "1fr",
  gap: "14px",
});

export const Label = styled(Typography)({
  fontWeight: "600",
  fontSize: "10px",
  lineHeight: "16px",
  letterSpacing: "0.04em",
  textTransform: "uppercase",
  color: "#565656",
});

