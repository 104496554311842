import { Grid } from '@mui/material';
import { FormPanel } from 'components/Common';
import {
  DateTimePicker,
  Select,
  TextInput,
  UserAutocomplete,
} from 'components/Fields';
import {
  AbeForm,
  FormCondition,
  FormValidationProvider,
} from 'components/Forms';
import { TASK_STATUSES, timeframeOptionsArray } from 'consts';
import { getSelectOptions } from 'helpers/form';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { Field, FormSpy } from 'react-final-form';
import { useValidateTaskMutation } from 'redux/rtk-query';
import {
  AssignToMeButton,
  CheckListItems,
  CommentsSection,
} from './components';
import { processFormData } from './formData';

export function TaskForm(props) {
  const previousTimeframeType = useRef();

  const renderFields = () => (
    <Grid container px={2}>
      <Grid item xs={12}>
        <FormPanel>
          <Grid container>
            <Grid item xs={12}>
              <Field name="name" label={'Name'} component={TextInput} />
              <Field
                name="modelAccess.owner"
                label="Assign To"
                emptyButton={<AssignToMeButton />}
                component={UserAutocomplete}
              />
              <FormCondition when="type" operator="equals" is="Timeframe">
                <Field
                  name="timeframeType"
                  label={'Timeframe Type'}
                  options={timeframeOptionsArray}
                  component={Select}
                />
              </FormCondition>
              <Grid container>
                <Grid item xs={6}>
                  <Field
                    name="status"
                    label={'Status'}
                    options={getSelectOptions(TASK_STATUSES)}
                    component={Select}
                    defaultValue={TASK_STATUSES.NEW}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    name="dueOn"
                    label={'Due On'}
                    size={'small'}
                    component={DateTimePicker}
                  />
                </Grid>
              </Grid>
              <Field
                name="description"
                label={'Description'}
                component={TextInput}
                multiline
              />
            </Grid>
          </Grid>
        </FormPanel>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormPanel startOpen noPadding label={'Checklist Items'}>
            <Field
              name="checklistItems"
              label={'Checklist Items'}
              component={CheckListItems}
            />
          </FormPanel>
        </Grid>
        <Grid item xs={6}>
          <FormPanel startOpen label={'Comments'}>
            <Field name="comments" component={CommentsSection} />
          </FormPanel>
        </Grid>
      </Grid>
      <FormSpy subscription={{ values: true }}>
        {({ values, form }) => {
          useEffect(() => {
            if (
              values.timeframeType &&
              values.timeframeType !== previousTimeframeType.current
            ) {
              form.change('name', values.timeframeType);
              previousTimeframeType.current = values.timeframeType;
            }
          }, [values.timeframeType, form]);

          return null;
        }}
      </FormSpy>
    </Grid>
  );

  const [validate] = useValidateTaskMutation();

  return (
    <FormValidationProvider value={{ validate, processFormData }}>
      <AbeForm {...props}>{renderFields}</AbeForm>
    </FormValidationProvider>
  );
}

TaskForm.propTypes = {
  afterSave: PropTypes.func,
  handleClose: PropTypes.func,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  handleSubmitFunc: PropTypes.func,
  initialValues: PropTypes.object,
};
