import { FormControl } from "@mui/material";
import { styled } from '@mui/system';

export const LabeledOutlinedWrapper = styled(FormControl)({
  position: 'relative',
  minHeight: 20,
  '& + &': {
    marginTop: 16,
  },
  '& MuiInput-root:before': {
    borderWidth: 0,
  },
});
