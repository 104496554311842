import { styled } from "@mui/system";
import { Box, Button } from "@mui/material";

export const AddingNewListBox = styled(Box)({
  padding: 10,
  width: "100%",
  borderTop: "1px solid #E8E8E8",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

export const AddListLink = styled(Button)({
  padding: "4px 8px",
  width: "129px",
  height: "28px",
  textTransform: "none",
  fontWeight: "500",
  fontSize: "13px",
  lineHeight: "20px",
  color: "#0085FF",
  borderRadius: "8px",
});
