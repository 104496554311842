import { faReceipt } from '@fortawesome/pro-solid-svg-icons';
import { AgentName } from 'components/CellComponents';
import { DETAILS_INVOICE_TEMPLATE } from 'consts';
import { columnTypes } from 'consts/columnTypes';
import { STATUSES } from 'consts/documentTemplate';
import { getSelectOptions } from 'helpers/form';
import { useNavigate } from 'react-router-dom';

export const Cells = [
  {
    id: 'name',
    value: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Name',
    isVisible: true,
  },
  {
    id: 'type',
    value: ['type'],
    filterable: true,
    filterType: columnTypes.DROP_DOWN,
    filterOptions: getSelectOptions(STATUSES),
    numeric: false,
    disablePadding: true,
    isVisible: true,
    label: 'Type',
  },
  {
    id: 'paymentType',
    value: ['paymentType'],
    filterable: true,
    filterType: columnTypes.DROP_DOWN,
    filterOptions: getSelectOptions(STATUSES),
    numeric: false,
    disablePadding: true,
    isVisible: true,
    label: 'Payment Type',
  },
  {
    id: 'sellPrice',
    value: ['sellPrice'],
    filterable: true,
    numeric: false,
    disablePadding: true,
    isVisible: true,
    label: 'Sell Price',
  },
  {
    id: 'modelAccess',
    value: 'modelAccess',
    label: 'Agent',
    isVisible: true,
    disableSort: true,
    Wrapper: AgentName,
  },
];

export const MenuItems = () => {
  let navigate = useNavigate();

  return [
    {
      name: 'Details',
      icon: faReceipt,
      onClick: (item) => {
        navigate(`${DETAILS_INVOICE_TEMPLATE(item.id)}`);
      },
      closeOnClick: true,
    },
  ];
};
