import {styled} from "@mui/system";
import {Popper as MuiPoper} from "@mui/material";
import {transactionColors} from "consts";
import hexRgb from "hex-rgb";

export const Popper = styled(MuiPoper)({
  zIndex: 10,
  borderRadius: "8px",
});

const getChipColorByType = (status, light) => {

  if(light){
    let rgb = hexRgb(transactionColors[status]);
    let rgba = `rgba(${rgb.red},${rgb.green},${rgb.blue},.15)`;
    return {
      color: transactionColors[status],
      backgroundColor:  rgba,
      fontWeight: 600,
    }
  }
  else {
    return {
      backgroundColor: transactionColors[status],
      color:  `white`,
      fontWeight: 600,
    }
  }
};

export const TransactionStatusChip = styled("span")(({ children  , light}) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  padding: "2px 6px",
  gap: "4px",
  height: "24px",
  borderRadius: "6px",
  flex: "none",
  order: "0",
  flexGrow: "0",
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "13px",
  lineHeight: "20px",
  textAlign: "center",
  width: "fit-content",
  ...getChipColorByType(children, light),
}));
