import React from 'react';
import { Field } from 'react-final-form';
import { Box, Grid } from '@mui/material';
import { FormPanel } from 'components/Common';
import {
  CategoriesAutocomplete,
  CheckboxField,
  Select,
  TextInput,
} from 'components/Fields';
import { AbeForm } from 'components/Forms';
import { ASPECT_RATIO, CM_TYPES, STATUSES } from 'consts';
import { getSelectOptions } from 'helpers/form';

export const ClickMarketingForm = ({ ...props }) => {
  const renderFields = () => (
    <Box p={2}>
      <FormPanel startOpen>
        <Grid item xs={12}>
          <Field label="Name" name={'name'} component={TextInput} />
          <Field
            name="status"
            label="Status"
            options={getSelectOptions(STATUSES)}
            component={Select}
          />
          <Field
            name="cmType"
            label="CM Type"
            options={getSelectOptions(CM_TYPES)}
            component={Select}
          />
          <Field
            name="aspectRatio"
            label="Aspect Ratio"
            options={ASPECT_RATIO}
            component={Select}
          />
          <Field
            name="categories"
            label="Categories"
            multiple
            component={CategoriesAutocomplete}
          />
          <Field
            name="closedApproved"
            label={'Closed Approved'}
            component={CheckboxField}
          />
        </Grid>
      </FormPanel>
    </Box>
  );

  return <AbeForm {...props}>{renderFields}</AbeForm>;
};
