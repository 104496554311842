import { createApi } from "@reduxjs/toolkit/query/react";

import { axiosBaseQuery } from "utils/baseQuery";

import {groupsUrlBuilder} from "./urlBuilder/groups";

export const groupsAPI = createApi({
  reducerPath: "groupsAPI",
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  tagTypes: ["Groups"],
  endpoints: build => ({
    getFunctionalActions: build.query({
      query: args => ({
        url: groupsUrlBuilder.getFunctionalActions(args),
        method: "GET",
      }),
      providesTags: ["Groups"],
    }),
    getGroups: build.query({
      query: args => ({
        url: groupsUrlBuilder.getGroups(args),
        method: "GET",
      }),
      providesTags: ["Groups"],
    }),
    getGroup: build.query({
      query: args => ({
        url: groupsUrlBuilder.getGroup(args),
        method: "GET",
      }),
      providesTags: ["Groups"],
    }),
    getGroupUsers: build.query({
      query: args => ({
        url: groupsUrlBuilder.getGroupUsers(args),
        method: "GET",
      }),
      invalidatesTags: ["RoleUsers"],
    }),
    createGroup: build.mutation({
      query: body => ({
        url: groupsUrlBuilder.createGroup(),
        method: "POST",
        data: body,
      }),
      invalidatesTags: ["Groups"],
    }),
    updateGroup: build.mutation({
      query: body => ({
        url: groupsUrlBuilder.updateGroup(),
        method: "PUT",
        data: body,
      }),
      invalidatesTags: ["Groups"],
    }),
  }),
});

export const {
  useGetFunctionalActionsQuery,
  useGetGroupsQuery,
  useGetGroupUsersQuery,
  useGetGroupQuery,
  useCreateGroupMutation,
  useUpdateGroupMutation,
} = groupsAPI;
