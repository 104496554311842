import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export function useDrawer(initialOpen = false, pathTemplate = null) {
  const [isOpen, setIsOpen] = useState(initialOpen);
  const navigate = useNavigate();
  const location = useLocation();

  const openDrawer = () => setIsOpen(true);
  const closeDrawer = (afterFunction) => {


    // When closing the drawer, modify the URL based on the pathTemplate
    if (
      pathTemplate &&
      location.pathname.includes(pathTemplate.split('/:')[0])
    ) {
      const basePath = location.pathname.split(pathTemplate.split('/:')[0])[0];
      setTimeout(() => {
        navigate(basePath || '/', { replace: true });
        afterFunction();
      }, 500);
    }
    // Close the drawer and navigate back
    setIsOpen(false);
  };

  useEffect(() => {
    if (initialOpen) {
      setTimeout(openDrawer, 200);
    }
  }, [initialOpen]);

  return { isOpen, openDrawer, closeDrawer };
}
