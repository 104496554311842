import React from 'react';
import PropTypes from 'prop-types';
import { faTimesCircle } from '@fortawesome/pro-light-svg-icons';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { FontAwesomeIconComp, TableFooter } from 'components/Common';
import AbeFileImage from 'components/Common/AbeFileImage/AbeFileImage';
import { ClickMarketingGalleryFilter } from 'components/ModelViews/Marketing/MarketingCreateView/components/ClickMarketingGallery/components/ClickMarketingGalleryFilter/ClickMarketingGalleryFilter';
import { ButtonSection, Header, HeadingSection } from 'components/Styled';
import { useTableData } from 'hooks';
import { useGetClickMarketingTemplatesQuery } from 'redux/rtk-query';

const StyledGridItem = styled(Grid)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  padding: theme.spacing(1),
  boxSizing: 'border-box',
}));

const ImageOuterWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  cursor: 'pointer',
  display: 'flex',
  paddingTop: '100%',
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  '& img': {
    maxWidth: '100%',
    maxHeight: '100%',
    objectFit: 'contain',
    height: 'auto',
    width: 'auto',
    boxShadow: '3px 3px 10px rgba(0, 0, 0, 0.3)',
  },
  '&:hover img': {
    boxShadow: '3px 3px 10px rgba(0, 0, 0, 0.6)',
  },
}));

const ImageInnerWrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  alignSelf: 'center',
  height: '100%',
  justifyContent: 'center',
  borderRadius: theme.shape.borderRadius,
}));

const FiltersContainer = styled(Box)(({ theme }) => ({
  position: 'sticky',
  top: 0,
  height: '100vh',
  overflowY: 'auto',
  padding: theme.spacing(2),
}));

export const ClickMarketingGallery = ({ handleClose }) => {
  const [selectedSizes, setSizes] = React.useState([]);
  const [selectedCategories, setCategories] = React.useState([]);
  const [featuredType, setFeaturedType] = React.useState(null);

  const { page, rowsPerPage, search, setPage, setSearch, totalPages, rows } =
    useTableData(useGetClickMarketingTemplatesQuery, [], 'name', 'asc', 10, {
      limit: 12,
      categoryIds: selectedCategories,
      featuredType: featuredType,
    });

  console.log(featuredType);
  const getFirstPageThumbnailFile = (item) => {
    return item.attachments.find(
      (file) => file.name === 'thumbnail-small_1.png',
    );
  };

  return (
    <>
      <HeadingSection>
        <Header variant="h4">Click Marketing Gallery</Header>
        <ButtonSection>
          <IconButton onClick={handleClose}>
            <FontAwesomeIconComp icon={faTimesCircle} />
          </IconButton>
        </ButtonSection>
      </HeadingSection>
      <Box sx={{ display: 'flex' }}>
        <FiltersContainer elevation={3}>
          <ClickMarketingGalleryFilter
            name={search}
            setName={setSearch}
            categories={selectedCategories}
            setCategories={setCategories}
            sizes={selectedSizes}
            setSizes={setSizes}
            featuredType={featuredType}
            setFeaturedType={setFeaturedType}
          />
        </FiltersContainer>
        <Box sx={{ flexGrow: 1, padding: 2 }}>
          <Grid container spacing={2}>
            {rows?.map((item) => (
              <StyledGridItem item key={item.id} xs={3}>
                <ImageOuterWrapper>
                  <ImageInnerWrapper>
                    <AbeFileImage
                      file={getFirstPageThumbnailFile(item)}
                      identifierValue={item.id}
                      identifier={'id'}
                    />
                    <Box
                      position="absolute"
                      bottom={0}
                      left={0}
                      width={'100%'}
                      textAlign="center"
                      sx={{ backgroundColor: 'rgba(0,0,0,.5)' }}
                      px={1}
                      py={0.5}
                    >
                      <Typography
                        color={'#ffffff'}
                        variant="body2"
                        fontSize={12}
                      >
                        {item.name}
                      </Typography>
                    </Box>
                  </ImageInnerWrapper>
                </ImageOuterWrapper>
              </StyledGridItem>
            ))}
          </Grid>
          {totalPages > 1 && (
            <Box width={'100%'}>
              <TableFooter
                count={totalPages}
                page={page}
                rowsPerPage={rowsPerPage}
                handleChangePage={(event, newPage) => {
                  setPage(newPage);
                }}
              />
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

ClickMarketingGallery.propTypes = {
  handleClose: PropTypes.func,
};
