import { createSlice } from '@reduxjs/toolkit';

export const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState: {
    open: false,
    message: '',
    severity: 'info',
  },
  reducers: {
    setSnackbarOpen: (state, action) => {
      state.open = true;
      state.message = action.payload.message;
      state.severity = action.payload.severity || 'info';
    },
    setSnackbarClose: (state) => {
      state.open = false;
    },
  },
});

export const { setSnackbarOpen, setSnackbarClose } = snackbarSlice.actions;

export const snackbarSliceReducer = snackbarSlice.reducer;