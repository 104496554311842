export const TYPES = {
  FILE: "File",
  FOLDER: "Folder",
  FOLDER_GROUP: "Folder Group",
  ARCHIVE_FOLDER: "Archive Folder",
};

export const STATUSES = {
  DRAFT: "Draft",
  IN_PROGRESS: "In Progress",
  COMPLETED: "Completed",
  REVISE: "Revise",
  VOIDED: "Voided",
};

export const CM_TYPES = {
  HEADER: "Header",
  BODY: "Body",
  FOOTER: "Footer",
  TEMPLATE: "Template",
};

export const MODULE_CLASS_NAMES = {
  TRANSACTION: "Transaction",
  CONTACT: "Contact",
};

export const ASPECT_RATIO = [
  { value: "1:1", label: "Square" },
  { value: "8.5:11", label: "Letter" },
  { value: "11:8.5", label: "Letter Landscape" },
  { value: "9:16", label: "Story" },
  { value: "42:20", label: "Cover Photo" },
]

// $values = array('++' =>  'No Name',
//   'documentTemplate->name' =>  'DocumentTemplate->Name',
//   'documentTemplate->name+-+contact->firstName+contact->lastName' =>  'DocumentTemplate->Name + Contact->Name',
//   'signedDateTime+-+documentTemplate' =>  'SignedDate + DocumentTemplate->Name',
//   'signedDateTime+-+account->name+-+account->customerName' =>  'SignedDate + Account->Name + Account->CustomerName',
//   'account->contractClosingDate+-+account->name+-+account->status->value+-+account->customerName' =>  'Closed Date + Account->Name + Account->Status + Account->CustomerName',
//   'signedDateTime+-+documentTemplate->name+-+contact->firstName+contact->lastName' =>  'SignedDate + DocumentTemplate->Name + Contact->Name',
//   'contact->firstName+contact->lastName' => 'Contact->Name',
//   'contact->firstName+contact->lastName+-+documentTemplate->documentTemplate->name' => 'Contact->Name - DocumentTemplate(parent)',
//   'contact->firstName+contact->lastName+documentTemplate->documentTemplate->name' => 'Contact->Name + DocumentTemplate(parent)',
//   'account->name' => 'Account->Name',
//   'account->name+-+contact->lastName' => 'Account->Name + Contact->LastName',
//   'account->status->value+-+account->name+-+contact->lastName' => 'Account->Status + Account->Name + Contact->LastName',
//   'customName' => 'Custom Name');

export const NAME_FORMATS = {
  NoName : "No name",
  DocumentTemplateName : "Document Template -> Name",
  DocumentTemplateNameContactName : "Document Template -> Name + Contact -> Name",
  SignedDateDocumentTemplateName : "Signed date + Document Template -> Name",
  SignedDateTransactionNameCustomerName : "Signed date + Transaction -> Name + Transaction -> Customer name",
  ClosedDateAccount : "Closed Date + Account -> Name +Account -> Status + Account -> Customer name",
  ContactName_DocumentTemplate : "Contact -> Name - DocumentTemplate (parent)",
  ContactNameDocumentTemplate : "Contact -> Name + DocumentTemplate (parent)",
};

export const CATEGORY_STATUS = {
  ACTIVE: "Active",
  INACTIVE: "Inactive",
  SYSTEM: "System",
}