
import queryString from "query-string";

export const  mlsUrlBuilder = {
  getParcels: function (params) {
    const urlParams = queryString.stringify(params, {skipNull: true})
    return `mls/parcels?${urlParams}`;
  },
  getParcel: function ({hjid}) {
    return `mls/parcel/${hjid}`;
  },
  getMlsListings: function (params) {
    const urlParams = queryString.stringify(params, {skipNull: true})
    return `mls/mlsListings?${urlParams}`;
  },
  getMlsListing: function ({id, withExtras = false}) {
    return `mls/mlsListing/${id}/${withExtras}`;
  },
};
