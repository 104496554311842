/* eslint-disable no-dupe-keys */
import { faCircleExclamation } from "@fortawesome/pro-regular-svg-icons";
import { Badge, Box, Divider } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { styled } from "@mui/system";
import { FontAwesomeIconComp } from "components/Common";
import { colors } from "consts/colors";
import PropTypes from "prop-types";

export const HeaderBox = styled(Box)({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '25px 18px',
});

export const ButtonGroup = styled(Box)({
  textAlign: 'center',
  '& > button': {
    // marginRight: 10
  },
});

export const ErrorMsg = ({ align, children }) => {
  const content =
    children.length < 10 ? (
      children
    ) : (
      <FontAwesomeIconComp icon={faCircleExclamation} color={colors.RED} />
    );
  const tooltipTitle = children.length < 10 ? '' : children;
  return (
    <Tooltip title={tooltipTitle}>
      <Box
        sx={{
          color: '#c9232d',
          fontSize: '13px',
          webkitFontSmoothing: 'antialiased',
          fontWeight: '400',
          lineHeight: '1.43',
          padding: '8px 0px',
          display: 'flex',
          position: 'absolute',
          top: 0,
          height: '100%',
          alignItems: 'center',
          right: align === 'right' ? "-20px" : '20px',

          '&.hideError': {
            display: 'none',
          },
        }}
      >
        {content}
      </Box>
    </Tooltip>
  );
};

ErrorMsg.propTypes = {
  children: PropTypes.string,
  align: PropTypes.string,
};

export const BoxInput = styled(Box)({
  flex: 1,
  position: 'relative',
  '.MuiFormControl-root': {
    width: '100%',
  },
});

export const TextBox = styled('span')({
  marginLeft: 20,
});

export const BoxName = styled(Divider)(({ textAlign, noGutter }) => ({
  fontWeight: '600',
  fontSize: '12px',
  lineHeight: '16px',
  letterSpacing: '0.04em',
  textTransform: 'uppercase',
  color: '#969696',
  textAlign: textAlign ? textAlign : 'center',
  borderStyle: 'dashed',
  marginBottom: noGutter ? 0 : 28,

  '&::before': {
    borderTop: '1px solid #c9c9c9',
  },
  '&::after': {
    borderTop: '1px solid #c9c9c9',
  },
}));

export const GreyBox = styled(Box)({
  backgroundColor: 'rgba(243,243,243,0.8)',
  padding: '8px 16px',
  borderRadius: 10,
  width: 'auto',
  gap: 8,

  position: 'relative',
});

export const StyledBadge = styled(Badge)({
  marginRight: 11,
  alignItems: 'center',
  '& .MuiBadge-badge': {
    position: 'relative',
    top: 'calc(50% - 0px)',
    border: `2px solid #fff`,
    padding: '0 4px',
    color: '#fff',
    alignSelf: 'center',
    transform: 'inherit',
    right: -5,
  },
});
