import { createApi } from '@reduxjs/toolkit/query/react';

import { axiosBaseQuery } from 'utils/baseQuery';
import { transformResponse } from 'helpers';

import { userUrlBuilder } from './urlBuilder/users';

export const usersAPI = createApi({
  reducerPath: 'usersAPI',
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  tagTypes: ['Users'],
  endpoints: (build) => ({
    getUsers: build.query({
      query: (args) => ({
        url: userUrlBuilder.getList(args),
        method: 'GET',
      }),
      providesTags: ['Users'],
    }),
    getUser: build.query({
      query: (args) => ({
        url: userUrlBuilder.getUser(args),
        method: 'GET',
      }),
      providesTags: ['Users'],
    }),
    getLanguagesList: build.query({
      query: (args) => ({
        url: userUrlBuilder.getLanguagesList(args),
        method: 'GET',
      }),
      transformResponse,
      providesTags: ['Languages'],
    }),
    getLocalesList: build.query({
      query: (args) => ({
        url: userUrlBuilder.getLocalesList(args),
        method: 'GET',
      }),
      transformResponse,
      providesTags: ['Locales'],
    }),
    getTimezonesList: build.query({
      query: (args) => ({
        url: userUrlBuilder.getTimezonesList(args),
        method: 'GET',
      }),
      transformResponse,
      providesTags: ['Timezones'],
    }),
    getCurrenciesList: build.query({
      query: (args) => ({
        url: userUrlBuilder.getCurrenciesList(args),
        method: 'GET',
      }),
      transformResponse,
      providesTags: ['Currencies'],
    }),
    getAuditTrail: build.query({
      query: (args) => ({
        url: userUrlBuilder.getAuditTrail(args),
        method: 'GET',
      }),
      providesTags: ['UserAuditTrail'],
    }),
    getGroupDetails: build.query({
      query: (args) => ({
        url: userUrlBuilder.getGroupDetails(args),
        method: 'GET',
      }),
      providesTags: ['GroupDetails'],
    }),
    createUser: build.mutation({
      query: (body) => ({
        url: userUrlBuilder.createUser(),
        method: 'POST',
        data: body,
      }),
      invalidatesTags: ['Users'],
    }),
    editUser: build.mutation({
      query: (body) => ({
        url: userUrlBuilder.editUser(),
        method: 'PUT',
        data: body,
      }),
      invalidatesTags: ['Users'],
    }),
    deleteUser: build.mutation({
      query: (args) => ({
        url: userUrlBuilder.deleteUser(args),
        method: 'DELETE',
      }),
      invalidatesTags: ['Users'],
    }),
    changePassword: build.mutation({
      query: (body) => ({
        url: userUrlBuilder.changePassword(),
        method: 'POST',
        data: body,
      }),
      invalidatesTags: ['Users'],
    }),
    getRecentlyViewed: build.query({
      query: () => ({
        url: userUrlBuilder.getRecentlyViewed(),
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetUsersQuery,
  useGetUserQuery,
  useGetLanguagesListQuery,
  useGetLocalesListQuery,
  useGetTimezonesListQuery,
  useGetCurrenciesListQuery,
  useGetAuditTrailQuery,
  useGetGroupDetailsQuery,
  useCreateUserMutation,
  useEditUserMutation,
  useDeleteUserMutation,
  useChangePasswordMutation,
  useGetRecentlyViewedQuery,
} = usersAPI;
