import {conversion} from "helpers";
import PropTypes from "prop-types";
import React from "react";

const FullName = ({ value }) => {
  return value ? conversion.getName(value): <></>
}

FullName.propTypes = {
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

export const Cells = [
  {
    id: "firstName",
    value: ['firstName', 'middleName', 'lastName', 'companyName', 'id'],
    numeric: false,
    disablePadding: true,
    label: "Name",
    Wrapper: FullName,
  },

];
