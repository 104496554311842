import { Box } from '@mui/material';

import { PageContent, TopNavbar } from 'components/Layouts/components';
import { MatchPath } from 'components/MatchPath';
import {
  DocumentEditorDrawer,
  DocumentPreviewDrawer,
  SelectDocumentDrawer,
} from 'components/ModelViews';
import {
  DocumentTemplateDetailsDrawer,
  DocumentTemplateEditorDrawer,
} from 'components/ModelViews/DocumentTemplates';
import { CreateInvoiceModal } from 'components/ModelViews/Invoices/CreateInvoiceModal';
import { EditInvoiceModal } from 'components/ModelViews/Invoices/EditInvoiceModal/EditInvoiceModal';
import { InvoiceTemplateDetailsDrawer } from 'components/ModelViews/InvoiceTemplates';
import { CreateNoteModal, EditNoteModal } from 'components/ModelViews/Notes';
import { CreateTaskModal, EditTaskModal } from 'components/ModelViews/Tasks';
import {
  CREATE_CAPPING,
  CREATE_CLICK_MARKETING,
  CREATE_CONTACT,
  CREATE_DOCUMENT_TEMPLATE,
  CREATE_INVOICE,
  CREATE_INVOICE_TEMPLATE_CATEGORY,
  CREATE_NOTE,
  CREATE_SETTING,
  CREATE_TASK,
  CREATE_TIMEFRAME,
  CREATE_TRANSACTION,
  DETAILS_DOCUMENT_TEMPLATE,
  DETAILS_INVOICE_TEMPLATE,
  DOCUMENT_CATEGORY_CREATE,
  DOCUMENT_CATEGORY_UPDATE,
  EDIT_DOCUMENT,
  EDIT_DOCUMENT_TEMPLATE,
  PREVIEW_DOCUMENT,
  SELECT_DOCUMENT,
  UPDATE_CAPPING,
  UPDATE_CONTACT,
  UPDATE_INVOICE, UPDATE_INVOICE_TEMPLATE_CATEGORY,
  UPDATE_NOTE,
  UPDATE_SETTING,
  UPDATE_TASK,
  UPDATE_TRANSACTION,
} from "consts";
import { CreateCappingPage, EditCappingPage } from 'pages/CappingPages';
import { CreateContactPage, EditContactPage } from 'pages/ContactsPages';
import {
  DocumentTemplateCreatePage,
  EditDocumentCategoryPage,
} from 'pages/DocumentTemplatePages';
import { CreateDocumentCategoryPage } from 'pages/DocumentTemplatePages/CreateDocumentCategoryPage';
import { CreateInvoiceCategoryPage } from 'pages/InvoiceTemplatePages/CreateInvoiceCategoryPage/CreateInvoiceCategoryPage';
import { EditInvoiceCategoryPage } from "pages/InvoiceTemplatePages/EditInvoiceCategoryPage";
import { CreateSettingPage, EditSettingPage } from 'pages/SettingsPages';
import {
  CreateTransactionPage,
  EditTransactionPage,
} from 'pages/TransactionsPages';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';

import { isAuthenticatedSelector } from 'redux/selectors';

import { LeftMenuDrawer } from './components';

export const MainLayout = () => {
  const MODAL_ROUTES = [
    {
      path: `${CREATE_SETTING}*`,
      component: CreateSettingPage,
    },
    {
      path: `${UPDATE_SETTING()}*`,
      component: EditSettingPage,
    },
    {
      path: `${CREATE_CAPPING}*`,
      component: CreateCappingPage,
    },
    {
      path: `${UPDATE_CAPPING()}*`,
      component: EditCappingPage,
    },
    {
      path: `${CREATE_TRANSACTION}*`,
      component: CreateTransactionPage,
    },
    {
      path: `${UPDATE_TRANSACTION()}*`,
      component: EditTransactionPage,
    },
    {
      path: CREATE_CONTACT,
      component: CreateContactPage,
    },
    {
      path: UPDATE_CONTACT(),
      component: EditContactPage,
    },
    {
      path: UPDATE_TASK(),
      component: EditTaskModal,
    },
    {
      path: CREATE_TASK(),
      component: CreateTaskModal,
    },
    {
      path: CREATE_TIMEFRAME(),
      component: CreateTaskModal,
    },
    {
      path: UPDATE_NOTE(),
      component: EditNoteModal,
    },
    {
      path: CREATE_NOTE(),
      component: CreateNoteModal,
    },
    {
      path: CREATE_INVOICE(),
      component: CreateInvoiceModal,
    },
    {
      path: UPDATE_INVOICE(),
      component: EditInvoiceModal,
    },
    {
      path: CREATE_CLICK_MARKETING,
      component: DocumentTemplateCreatePage,
      componentProps: {
        type: 'Click Marketing',
      },
    },
    {
      path: CREATE_DOCUMENT_TEMPLATE(),
      component: DocumentTemplateCreatePage,
    },
    {
      path: EDIT_DOCUMENT_TEMPLATE(),
      component: DocumentTemplateEditorDrawer,
    },
    {
      path: DETAILS_DOCUMENT_TEMPLATE(),
      component: DocumentTemplateDetailsDrawer,
    },
    {
      path: `${PREVIEW_DOCUMENT()}*`,
      component: DocumentPreviewDrawer,
    },
    {
      path: `${EDIT_DOCUMENT()}*`,
      component: DocumentEditorDrawer,
    },
    {
      path: `${SELECT_DOCUMENT()}*`,
      component: SelectDocumentDrawer,
    },
    {
      path: `${DOCUMENT_CATEGORY_UPDATE()}*`,
      component: EditDocumentCategoryPage,
    },
    {
      path: `${DOCUMENT_CATEGORY_CREATE}*`,
      component: CreateDocumentCategoryPage,
    },
    {
      path: `${DETAILS_INVOICE_TEMPLATE()}*`,
      component: InvoiceTemplateDetailsDrawer,
    },
    {
      path: `${CREATE_INVOICE_TEMPLATE_CATEGORY}*`,
      component: CreateInvoiceCategoryPage,
    },
    {
      path: `${UPDATE_INVOICE_TEMPLATE_CATEGORY()}*`,
      component: EditInvoiceCategoryPage,
    },
  ];

  const isAuthenticated = useSelector((state) =>
    isAuthenticatedSelector(state),
  );
  return isAuthenticated ? (
    <>
      <Box>
        <TopNavbar />
        <LeftMenuDrawer />
        <PageContent>
          <Outlet />
        </PageContent>
      </Box>
      {MODAL_ROUTES.map((route) => (
        <MatchPath
          key={route.path}
          path={route.path}
          Comp={route.component}
          componentProps={route.componentProps}
        />
      ))}
    </>
  ) : null;
};

MainLayout.propTypes = {
  isAdmin: PropTypes.bool,
};
