import { TextInput } from 'components/Fields';
import PropTypes from 'prop-types';

export const NumberInput = ({ input, numberType, min, max, ...props }) => {
  const handleChange = (e) => {
    let value = e.target.value;

    // Check if the input value is a valid number or a partially complete decimal
    if (/^\d*\.?\d*$/.test(value)) {
      // This regex matches an integer or a decimal number, including trailing dot
      // Parse the value only if it's a complete number (not ending with a dot)
      if (value !== '' && !value.endsWith('.')) {
        value =
          numberType === 'decimal' ? parseFloat(value) : parseInt(value, 10);
      }

      if (!isNaN(value)) {
        // Apply min and max constraints if the value is fully parsed
        if (typeof value === 'number') {
          if (min !== undefined && value < min) {
            value = min;
          } else if (max !== undefined && value > max) {
            value = max;
          }
        }

        input.onChange(value);
      }
    }
  };

  return (
    <TextInput
      {...props}
      size={'small'}
      input={{
        ...input,
        onChange: handleChange,
      }}
    />
  );
};

NumberInput.propTypes = {
  input: PropTypes.object,
  numberType: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
};
