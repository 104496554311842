import {styled} from "@mui/system";
import {Popper as MuiPoper} from "@mui/material";

export const Popper = styled(MuiPoper)({
    zIndex: 10,
    borderRadius: "8px",
});

export const RoleWrapper = styled("div")(() => ({
    padding: "3px 12px 3px 12px",
    display: "flex",
    alignItems: "center",
    gap: 12,
    minHeight: 40,
    "& .clear-button":{
      width: 25,
    },
    "& .clear-button button":{
        display: "inline-flex"
    },
    "&:not(:hover) .clear-button button":{
        display: "none",
    },
    "&:not(:last-child)":{
        borderBottom: "1px solid lightgrey",
    },
    "& .role-wrapper > span": {
        left: 10,
        position: "relative",
        fontSize: 14
    },
    "& .role-wrapper.text-only i": {
        width: 25,
    },
    "& .role-wrapper.text-only input": {
        width: "calc(100% - 70px)"
    },
    "& .role-wrapper .clear-button": {
        float: "right",
        top: -3
    },
    "& .role-wrapper.text-only.editing-name .clear-button": {
        top: 3
    },
}));

export const RoleNameWrapper = styled("span")({
    flexGrow: 1
})
