import { Grid } from '@mui/material';

import { RightsPermissions } from 'components/Fields';
import { ToggleFieldsButton } from 'components/Forms';
import { CreateLayoutHeader } from 'components/Layouts';
import { CreateBox } from 'components/Styled';
import { RIGHT_OPTIONS } from 'consts';
import arrayMutators from 'final-form-arrays';
import setFieldData from 'final-form-set-field-data';
import { useFormSubmitHandler } from 'hooks';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Form } from 'react-final-form';

import { useGetProfileQuery } from 'redux/rtk-query';
import {
  CappingPanel,
  HeadshotPanel,
  OverviewPanel,
  ProfilePanel,
} from './components';

const SettingForm = ({
  afterSave,
  handleSubmitFunc,
  initialValues,
  title,
  nameButton,
  isLoading,
  panelEdit,
}) => {
  const { data: { data: { permissions } = {} } = {} } = useGetProfileQuery();

  const [showAllFields, setShowAllFields] = useState(false);

  // const [validate] = useValidateTransactionMutation();
  //
  // const debouncedCheck = useDebouncedFormCheck(validate, processFormData);
  const onSubmit = useFormSubmitHandler(handleSubmitFunc, afterSave);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Form
          mutators={{
            setFieldData,
            ...arrayMutators,
          }}
          onSubmit={onSubmit}
          initialValues={initialValues}
          render={({ handleSubmit }) => (
            <CreateBox>
              <form
                id={'setting-form'}
                autoComplete="off"
                onSubmit={handleSubmit}
              >
                <CreateLayoutHeader
                  title={title}
                  nameButton={nameButton}
                  afterSave={afterSave}
                >
                  <ToggleFieldsButton
                    showAllFields={showAllFields}
                    setShowAllFields={setShowAllFields}
                    permissions={permissions}
                  />
                  <RightsPermissions
                    defaultValue={RIGHT_OPTIONS.agentOnly.value}
                  />
                </CreateLayoutHeader>
                {isLoading ? (
                  'Loading'
                ) : (
                  <Grid container spacing={2} style={{ padding: '0 16px' }}>
                    <Grid item xs={12}>
                      {(!panelEdit || panelEdit === 'Overview') && (
                        <OverviewPanel />
                      )}
                      {panelEdit === 'Headshot' && <HeadshotPanel />}
                      {panelEdit === 'Profile' && <ProfilePanel />}
                      {panelEdit === 'Capping' && <CappingPanel />}
                    </Grid>
                    <Grid item xs={12} md={4}></Grid>
                    <Grid item xs={12} md={4}></Grid>
                  </Grid>
                )}
              </form>
            </CreateBox>
          )}
        />
      </Grid>
    </Grid>
  );
};

SettingForm.propTypes = {
  afterSave: PropTypes.func,
  handleSubmitFunc: PropTypes.func,
  initialValues: PropTypes.object,
  title: PropTypes.string,
  nameButton: PropTypes.string,
  isLoading: PropTypes.bool,
  panelEdit: PropTypes.string,
};

export default SettingForm;
