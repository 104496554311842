export const Cells = [
  {
    id: "deliveryLine",
    value: "deliveryLine",
    disablePadding: true,
    label: "Delivery Line",
  },
  {
    id: "apn",
    value: "apn",
    disablePadding: false,
    label: "APN",
  },
];
