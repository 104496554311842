import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import ClickMarketingEditor from 'components/ModelViews/Documents/ClickMarketingEditorDrawer/components/ClickMarketingEditor';

export function ClickMarketingEditorDrawer({ documentData, headerHeight }) {
  return (
    <Box
      sx={{
        display: 'flex',
        height: '100%',
        maxHeight: `calc(100vh - ${headerHeight}px)`,
        position: 'relative',
        width: '100%',
      }}
    >
      {documentData && (
        <ClickMarketingEditor
          documentData={documentData}
          headerHeight={headerHeight}
        />
      )}
    </Box>
  );
}

ClickMarketingEditorDrawer.propTypes = {
  documentData: PropTypes.object.isRequired,
  headerHeight: PropTypes.number,
};
