import { colors } from 'consts';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import ContentEditable from 'react-contenteditable';
import { useDispatch, useSelector } from 'react-redux';
import { autofillDataSelector, isActiveFieldSelector } from 'redux/selectors';
import { updateField } from 'redux/slices';
import {
  adjustTextSizeAndCheckOverflow,
  isAutoFill,
  isFillNow,
} from '../../../../utils/field';
import { FieldAlert } from '../../../FieldAlert/FieldAlert';
import TextIndentMarker from './components/TextIndentMarker';

const autofillsToAdjust = [
  'accountId',
  'address',
  'county',
  'executedDate',
  'expectedCloseDate',
  'legalDescription',
  'parcelNumber',
];

const getAdjustedAutofill = (autoFill) => {
  return autofillsToAdjust.includes(autoFill)
    ? `property_${autoFill}`
    : autoFill;
};

const getAutofillValue = (data, autofill) => {
  if (!autofill) return null;
  const keys = autofill.split('_'); // Split the adjusted autofill into parts
  let value = data;
  for (const key of keys) {
    value = value[key]; // Dynamically access the nested property
    if (value === undefined) break; // If the path does not exist, stop the loop
  }
  return value;
};

export const Text = ({ field }) => {
  const isActive = useSelector(isActiveFieldSelector(field.id));
  const autofillData = useSelector(autofillDataSelector);
  const dispatch = useDispatch();
  const autoFill = getAdjustedAutofill(field.autoFill);
  const autofillValue = getAutofillValue(autofillData, autoFill);
  const [text, setText] = useState(
    isAutoFill(field) ? autofillValue || '' : field.value || '',
  );
  const [textFillAlert, setTextFillAlert] = useState(false);
  const textFillAlertRef = useRef(textFillAlert);
  const textRef = useRef(text);

  const contentRef = useRef();

  const handleChange = (event) => {
    const { adjustedContent, textFillAlert: newTextFillAlert } =
      adjustTextSizeAndCheckOverflow(
        event.target.value,
        {
          ...field,
          width: parseInt(field.width),
          fontSize: parseInt(field.fontSize),
        },
        dispatch,
      );

    setTextFillAlert(newTextFillAlert);
    if (!newTextFillAlert) {
      setText(adjustedContent);
    }
  };

  const handleBlur = useCallback(() => {
    dispatch(
      updateField({ id: field.id, properties: { value: textRef.current } }),
    );
  }, [dispatch, field.id, textRef.current]);

  const getDefaultLineHeight = () => {
    if (getIsMultiline(field)) {
      return '135%';
    } else {
      return parseFloat(field.height) + 'px';
    }
  };

  const getIsMultiline = (field) => {
    let fontSize = field.fontSize ? field.fontSize : '14px';
    return parseFloat(field.height) >= parseFloat(fontSize) * 2;
  };

  const handleKeyDown = useCallback(
    (e) => {
      if (
        textFillAlertRef.current &&
        !(e.key === 'Backspace' || e.key === 'Delete')
      ) {
        e.preventDefault();
        return false; // Stop the function here
      } else if (textFillAlertRef.current) {
        setTextFillAlert(false);
      }

      if (e.key === 'Enter') {
        e.preventDefault();

        // Get the current selection
        const selection = window.getSelection();
        if (!selection.rangeCount) return false; // Exit if no range is selected

        const range = selection.getRangeAt(0);
        range.deleteContents(); // Delete selected text if any

        // Create a break line element
        const lineBreak = document.createElement('br');
        range.insertNode(lineBreak);

        // Move the caret after the lineBreak
        range.setStartAfter(lineBreak);
        range.setEndAfter(lineBreak);
        selection.removeAllRanges(); // Clear the selection
        selection.addRange(range);

        return false;
      }
    },
    [textFillAlert],
  );

  useEffect(() => {
    if (isAutoFill(field)) {
      setText(autofillValue || '');
    } else if (field.role) {
      setText(field.value || '');
    } else {
      setText(field.value || '');
    }
  }, [field.role, field.autoFill, autofillValue]);

  useEffect(() => {
    textFillAlertRef.current = textFillAlert;
  }, [textFillAlert]);

  useEffect(() => {
    textRef.current = text;
  }, [text]);

  //if field.value changes, update the text
  useEffect(() => {
    if (isFillNow(field) && field.value !== text && field.value !== null) {
      setText(field.value);
    }
  }, [field.value]);

  return (
    <>
      {getIsMultiline(field) && <TextIndentMarker field={field} />}
      <FieldAlert
        open={!!(textFillAlert && isActive)}
        disableHoverListener={true}
        style={{ backgroundColor: colors.RED }}
        title={'Text is full'}
      >
        <span>
          <ContentEditable
            className={'content-editable'}
            innerRef={contentRef}
            disabled={!!field.role || !!field.autoFill}
            style={{
              userSelect: 'text',
              WebkitUserSelect: 'text',
              zIndex: 2,
              position: 'relative',
              width: parseFloat(field.width),
              height: '100%',
              maxHeight: parseFloat(field.height),
              overflow: 'hidden',
              maxWidth: '100%',
              wordWrap: 'break-word',
              overflowWrap: 'break-word',
              whiteSpace: 'break-spaces',
              fontSize: field.fontSize ? parseFloat(field.fontSize) : 14,
              fontWeight: 600,
              fontFamily: 'Arial',
              textAlign: field.textAlign,
              lineHeight:
                parseFloat(field['line-height']) > 0
                  ? field['line-height'] + '%'
                  : getDefaultLineHeight(),
              textIndent: field.textIndent ? parseFloat(field.textIndent) : 0,
              outline: 'none',
            }}
            onKeyDown={handleKeyDown}
            html={text}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </span>
      </FieldAlert>
    </>
  );
};

Text.propTypes = {
  field: PropTypes.object.isRequired,
};
