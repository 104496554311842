import React from 'react';
import { Rnd } from 'react-rnd';
import { useDispatch } from 'react-redux';
import { updateField } from "redux/slices";
import PropTypes from 'prop-types';
import { getClippingPath } from '../../../../../utils/field';

function TextIndentMarker({ field }) {
  let dispatch = useDispatch();

  const handleResizeStop = (e, d, ref) => {
    dispatch(
      updateField({
        fieldId: field.id,
        properties: { textIndent: ref.offsetWidth },
      }),
    );
  };

  let style = {
    // background: "rgba(125,204,255,.5)",
    position: 'absolute',
    top: '0',
    left: '0',
    zIndex: 6,
  };
  let width = field.textIndent ? parseFloat(field.textIndent) : 0;

  const textStyle = {
    margin: 0,
    width: '100%',
    maxHeight: field.height,
    fontSize: field.fontSize ? parseFloat(field.fontSize) : 14,
    fontWeight: 600,
    fontFamily: 'Arial',
    lineHeight:
      parseFloat(field['line-height']) > 0
        ? field['line-height'] + '%'
        : '135%',
  };
  return (
    <Rnd
      style={style}
      size={{
        width: width,
      }}
      resizeHandleWrapperClass={'textIndentResizeHandle'}
      minWidth={0}
      onResizeStop={handleResizeStop}
      onResize={(e, d, ref) => {
        const fieldElement = document.querySelector(
          '[data-field-id="' + field.id + '"]',
        );
        const textIndexClip = fieldElement.querySelector('.textIndexClip');
        const contentEditable = fieldElement.querySelector('.content-editable');
        const fieldCopy = { ...field };
        fieldCopy.textIndent = ref.offsetWidth;
        textIndexClip.style.clipPath = getClippingPath(fieldCopy);
        contentEditable.style.textIndent = ref.offsetWidth + 'px';
      }}
      disableDragging={true}
      enableResizing={{
        top: false,
        right: true,
        bottom: false,
        left: false,
        topRight: false,
        bottomRight: false,
        bottomLeft: false,
        topLeft: false,
      }}
    >
      <p style={textStyle}>&nbsp;</p>
    </Rnd>
  );
}

export default TextIndentMarker;

TextIndentMarker.propTypes = {
  field: PropTypes.object.isRequired,
};
