import { Box, Button, DialogActions, Divider, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

export const ConfirmMenuItem = ({
  title,
  message,
  cancelText = 'Cancel',
  confirmText = 'Confirm',
  onConfirm,
  onCancel,
}) => {
  return (
    <Box py={1} px={2}>
      <Typography sx={{ fontSize: '16px' }}>{title}</Typography>
      <Divider sx={{ my: 1 }} />
      <Box mt={2} mb={1}>
        <Typography component={'div'} variant={'body1'}>
          {message}
        </Typography>
      </Box>
      <DialogActions sx={{ width: '100%', justifyContent: 'space-between' }}>
        <Button variant="outlined" onClick={onCancel}>
          {cancelText}
        </Button>
        <Button variant="outlined" onClick={onConfirm}>
          {confirmText}
        </Button>
      </DialogActions>
    </Box>
  );
};

ConfirmMenuItem.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  cancelText: PropTypes.string,
  confirmText: PropTypes.string,
};
