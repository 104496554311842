import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  creating: false,
  editing: false,
};

export const noteSlice = createSlice({
  name: "notes",
  initialState,
  reducers: {
    setCreatingNote: (state, action) => {
      state.creating = action.payload;
    },
    setEditingNote: (state, action) => {
      state.editing = action.payload;
    },
  },
});

export const { setCreatingNote, setEditingNote } = noteSlice.actions;
export const notesSliceReducer = noteSlice.reducer;
