import { faPlus } from '@fortawesome/pro-light-svg-icons';
import {
  CollapsableMenuGroup,
  FontAwesomeIconComp,
  MenuItemButton,
} from 'components/Common';
import { iconAndLabel } from 'components/ModelViews/Documents/DocumentPdfViewer/utils/field';
import React from 'react';
import { useDispatch } from 'react-redux';
import { setPlacingItem } from 'redux/slices';

export const AddItemsMenuGroup = () => {
  const dispatch = useDispatch();
  return (
    <CollapsableMenuGroup
      label={'Add Item'}
      icon={<FontAwesomeIconComp fontSize={18} icon={faPlus} />}
    >
      <MenuItemButton
        icon={iconAndLabel.signatureField.icon}
        label={iconAndLabel.signatureField.label}
        onClick={() => {
          dispatch(setPlacingItem('signatureField'));
        }}
      />
      <MenuItemButton
        icon={iconAndLabel.signatureInitialField.icon}
        label={iconAndLabel.signatureInitialField.label}
        onClick={() => {
          dispatch(setPlacingItem('signatureInitialField'));
        }}
      />
      <MenuItemButton
        icon={iconAndLabel.printName.icon}
        label={iconAndLabel.printName.label}
        onClick={() => {
          dispatch(setPlacingItem('printName'));
        }}
      />
      <MenuItemButton
        icon={iconAndLabel.signDate.icon}
        label={iconAndLabel.signDate.label}
        onClick={() => {
          dispatch(setPlacingItem('signDate'));
        }}
      />
      <MenuItemButton
        icon={iconAndLabel.datePicker.icon}
        label={iconAndLabel.datePicker.label}
        onClick={() => {
          dispatch(setPlacingItem('datePicker'));
        }}
      />
      <MenuItemButton
        icon={iconAndLabel.text.icon}
        label={iconAndLabel.text.label}
        onClick={() => {
          dispatch(setPlacingItem('text'));
        }}
      />
      <MenuItemButton
        icon={iconAndLabel.checkbox.icon}
        label={iconAndLabel.checkbox.label}
        onClick={() => {
          dispatch(setPlacingItem('checkbox'));
        }}
      />
      <MenuItemButton
        icon={iconAndLabel.strikethrough.icon}
        label={iconAndLabel.strikethrough.label}
        onClick={() => {
          dispatch(setPlacingItem('strikethrough'));
        }}
      />
    </CollapsableMenuGroup>
  );
};
