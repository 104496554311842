export const defaultValues = {
   name: "",
   cappingType: "",
   // agentContact: null,
   cappingLevels: [],


   companyStructureUnits: [],
   shareWith: [],
   agentId: null,
}
