import { createApi } from '@reduxjs/toolkit/query/react';
import { profileUrlBuilder } from 'redux/rtk-query/urlBuilder/profile';
import { axiosBaseQuery } from 'utils/baseQuery';

export const serviceAPI = createApi({
  reducerPath: 'serviceAPI',
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  tagTypes: ['Profile'],
  endpoints: (build) => ({
    getSvgContent: build.query({
      query: (args) => ({
        url: profileUrlBuilder.getSvgContent(args),
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetSvgContentQuery } = serviceAPI;
