import React from 'react';
import PropTypes from 'prop-types';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, Card } from '@mui/material';
import { Box } from '@mui/system';
import HoverReveal from 'components/Common/HoverReveal/HoverReveal';
import { Path, Condition, Value, FilterCompareCondition } from './';

const Trigger = ({ name, showCondition, onRemove }) => {
  return (
    <>
      <Box>{showCondition && <FilterCompareCondition name={name} />}</Box>
      <HoverReveal
        hoverContent={
          <IconButton color="error" onClick={onRemove}>
            <FontAwesomeIcon icon={faTimes} />
          </IconButton>
        }
        sx={{
          marginBottom: 2,
          flexDirection: 'column',
        }}
      >
        <Card sx={{ padding: 3 }}>
          <Path name={name} />
          <Box>
            <Condition name={name} />
            <Value name={name} />
          </Box>
        </Card>
      </HoverReveal>
    </>
  );
};

export default Trigger;

Trigger.propTypes = {
  name: PropTypes.string.isRequired,
  showCondition: PropTypes.bool.isRequired,
  onRemove: PropTypes.func.isRequired,
};
