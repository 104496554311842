import { faCheck, faPaperPlane } from '@fortawesome/pro-light-svg-icons';
import { Box, Button, ButtonGroup, Tooltip, Typography } from '@mui/material';
import { FontAwesomeIconComp } from 'components/Common';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { signedStatusSelector } from 'redux/selectors';
import { setSignedStatus } from 'redux/slices';

const ButtonWithTooltip = ({ title, status, label, icon, color }) => {
  const dispatch = useDispatch();
  const signedStatus = useSelector(signedStatusSelector);

  return (
    <Tooltip title={title} placement={'top'}>
      <Button
        style={{ padding: 10 }}
        onClick={() => dispatch(setSignedStatus(status))}
        variant={signedStatus === status ? 'contained' : 'outlined'}
        size="medium"
        color="primary"
      >
        {label}
        <FontAwesomeIconComp
          style={{ marginLeft: 10 }}
          icon={icon}
          color={signedStatus === status ? 'white' : color}
        />
      </Button>
    </Tooltip>
  );
};

ButtonWithTooltip.propTypes = {
  title: PropTypes.string,
  status: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.object,
  color: PropTypes.string,
};

export function SignedStatusSelection() {
  return (
    <Box sx={{ marginBottom: '12px', textAlign: 'center', marginTop: 0 }}>
      <Typography variant="subtitle1" sx={{ marginBottom: '12px' }}>
        Once signed, this document will be:
      </Typography>
      <ButtonGroup>
        <ButtonWithTooltip
          title="Select this option if the document will NOT be fully signed by all parties and still needs signatures after this e-signing session."
          status="In Progress"
          label="Partially Signed"
          icon={faPaperPlane}
          color="orange"
        />
        <ButtonWithTooltip
          title="Select this option if the document will be fully signed by all parties after this e-signing session."
          status="Completed"
          label="Fully Signed"
          icon={faCheck}
          color="green"
        />
      </ButtonGroup>
    </Box>
  );
}
