import { Button } from '@mui/material';
import React from 'react';
import { useForm } from 'react-final-form';
import { useGetProfileQuery } from 'redux/rtk-query';

export const AssignToMeButton = () => {
  const { data: currentUser } = useGetProfileQuery();
  const form = useForm();

  const assignToMe = () => {
    form.change('agent', currentUser.data);
  };

  return (
    <Button onClick={assignToMe} size={'small'}>
      Assign to me
    </Button>
  );
};
