import { Grid } from '@mui/material';
import { LayoutHeader } from 'components/Layouts';
import { PageMainContent } from 'components/Styled';
import React from 'react';
import { Outlet } from 'react-router-dom';

export const MarketingPage = () => {
  return (
    <>
      <LayoutHeader title={'Marketing Center'}></LayoutHeader>
      <PageMainContent maxWidth={false} component="main" disableGutters>
        <Grid container flexDirection={'column'}></Grid>
      </PageMainContent>
      <Outlet />
    </>
  );
};
