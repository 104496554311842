import React from "react";
import { faContactCard } from "@fortawesome/pro-solid-svg-icons";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { PageLink } from "components/Styled";
import { DETAILS_CAPPING } from "consts";

const CommissionName = ({ value }) => {
  return value ? (
    <PageLink to={`/${DETAILS_CAPPING(value.id)}`}>
      <div>{value.name} </div>
    </PageLink>
  ) : (
    <></>
  );
};

CommissionName.propTypes = {
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

export const Cells = [
  {
    id: "name",
    value: ['name', 'id'],
    numeric: false,
    disablePadding: true,
    label: "Name",
    Wrapper: CommissionName,
  },
];

export const MenuItems = () => {

  let navigate = useNavigate();


  return [
    {
      name: "Details",
      icon: faContactCard,
      onClick: (item)=>{
        navigate(`/${DETAILS_CAPPING(item.id)}`)
      }
    },
  ];
}
