import { SideDrawer } from 'components/Common';
import { omitNull } from 'helpers';

import { usePrevious } from 'hooks';
import { CappingForm } from 'pages/CappingPages';
import { defaultValues } from 'pages/CappingPages/CappingForm/defaultValues';
import { processFormData } from 'pages/CappingPages/CappingForm/formData';
import PropTypes from 'prop-types';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGetCappingQuery, useUpdateCappingMutation } from 'redux/rtk-query';

export const EditCappingPage = ({ params, path }) => {
  const paramName = Object.keys(params)[0];

  const [initialValues, setInitialValues] = useState({});
  const [isOpen, setIsOpen] = useState(false);

  const [updateCapping] = useUpdateCappingMutation();
  const { data: cappingData, isSuccess } = useGetCappingQuery({
    id: params[paramName],
  });

  const location = useLocation();
  const navigate = useNavigate();
  const prevIsOpen = usePrevious(isOpen);

  useEffect(() => {
    if (isSuccess) {
      let data = { ...cappingData.data };
      data.agent = data.modelAccess?.owner;
      data.shareWithOption;
      data.shareWith = data.modelAccess?.sharedWithUsers;
      data.companyStructureUnits = data.modelAccess?.sharedWithUnits;
      setInitialValues({
        ...omitNull({ ...defaultValues }),
        ...omitNull(data),
      });
      setIsOpen(true);
    }
  }, [isSuccess]);

  const handleSubmitFunc = async (values) => {
    let formData = processFormData(values);
    const capping = await updateCapping(formData);
    return capping;
  };

  const afterSave = () => {
    setIsOpen(false);
  };

  useLayoutEffect(() => {
    if (prevIsOpen && !isOpen) {
      const pathArray = location.pathname.split('/');
      const pathIndex = pathArray.indexOf(path.split('/')[0]);
      const newPath = pathArray.slice(0, pathIndex).join('/');
      setTimeout(() => {
        navigate(newPath + (newPath[newPath.length - 1] === '/' ? '' : '/'));
      }, 500);
    }
  }, [isOpen]);

  return (
    <SideDrawer width={'65%'} isOpen={isOpen}>
      <CappingForm
        panelEdit={params['panelEdit']}
        title={'Edit Capping'}
        initialValues={initialValues}
        afterSave={afterSave}
        handleSubmitFunc={handleSubmitFunc}
      />
    </SideDrawer>
  );
};

EditCappingPage.propTypes = {
  params: PropTypes.object,
  path: PropTypes.string,
};
