export const ADMIN_NAME = "SUPER_ADMIN";

export const USER_STATUSES = [
  {
    value: "Active",
    label: "Active",
  },
  {
    value: "Inactive",
    label: "Inactive",
  },
];
