import { Required } from 'components/Common';
import { BoxInput, ErrorMsg, TextInputField } from "components/Styled";
import { getError, hasValue } from 'helpers';
import { useIsRequired } from 'hooks';
import PropTypes from 'prop-types';
import React from 'react';
import { usePlacesWidget } from 'react-google-autocomplete';

const componentForm = {
  street_number: 'short_name',
  route: 'long_name',
  locality: 'long_name',
  neighborhood: 'long_name',
  administrative_area_level_1: 'short_name',
  country: 'long_name',
  postal_code: 'short_name',
};

export const GoogleAddressLookup = ({
  onPlaceSelected,
  input,
  meta,
  readOnly,
  label,
}) => {
  const error = getError(meta);
  const isFilled = hasValue(input.value);
  const isRequired = useIsRequired(input.name) && !isFilled;

  const { ref: materialRef } = usePlacesWidget({
    apiKey: 'AIzaSyCK5S1Csbj316TYab3Kqdflh8RVHQ4azVw',
    onPlaceSelected: (place) => {
      let placeAddress = {};
      for (let i = 0; i < place.address_components.length; i++) {
        let addressType = place.address_components[i].types[0];
        if (componentForm[addressType]) {
          let val = place.address_components[i][componentForm[addressType]];
          placeAddress[addressType] = val;
        }
      }
      let city = placeAddress['locality']
        ? placeAddress['locality']
        : placeAddress['neighborhood'];
      let address = {
        city: `${city}`,
        country: `${placeAddress['country']}`,
        zipCode: `${placeAddress['postal_code']}`,
        street1: `${placeAddress['street_number']} ${placeAddress['route']}`,
        street2: '',
        state: `${placeAddress['administrative_area_level_1']}`,
        latitude: `${place['geometry']['location']['lat']().toFixed(8)}`,
        longitude: `${place['geometry']['location']['lng']().toFixed(8)}`,
      };
      onPlaceSelected(address);
    },
    componentRestrictions: { country: 'us' },
    options: {
      types: ['address'],
    },
  });

  return (
    <BoxInput>
      <div style={{ pointerEvents: readOnly ? 'none' : 'all' }}>
        <TextInputField
          id={input.name}
          label={
            <>
              {label}
              {isRequired ? <Required /> : ''}
            </>
          }
          size={'small'}
          fullWidth
          color="primary"
          variant="outlined"
          inputRef={materialRef}
          inputProps={{
            'data-lpignore': 'true',
            readOnly: readOnly,
          }}
          placeholder="Enter Address..."
          onChange={() => {
            input.onChange(materialRef.current.value);
          }}
          value={input.value}
          error={!!error}
        />
        {error && <ErrorMsg component={'span'}>{error}</ErrorMsg>}
      </div>
    </BoxInput>
  );
};

GoogleAddressLookup.propTypes = {
  onPlaceSelected: PropTypes.func,
  label: PropTypes.string,
  input: PropTypes.object,
  meta: PropTypes.object,
  readOnly: PropTypes.bool,
};
