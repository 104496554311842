import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useGetSettingQuery } from 'redux/rtk-query';
import { isTemplateViewSelectorCM as isTemplateViewSelector } from 'redux/selectors';
import { ReactComponent as MainframeLogo } from '../assets/MainframeLogo.svg';
import { ReactComponent as Micon } from '../assets/Micon.svg';
import propertyPhoto1 from '../assets/propertyPhoto1.png';
import propertyPhoto10 from '../assets/propertyPhoto10.png';
import propertyPhoto2 from '../assets/propertyPhoto2.png';
import propertyPhoto3 from '../assets/propertyPhoto3.png';
import propertyPhoto4 from '../assets/propertyPhoto4.png';
import propertyPhoto5 from '../assets/propertyPhoto5.png';
import propertyPhoto6 from '../assets/propertyPhoto6.png';
import propertyPhoto7 from '../assets/propertyPhoto7.png';
import propertyPhoto8 from '../assets/propertyPhoto8.png';
import propertyPhoto9 from '../assets/propertyPhoto9.png';
import { ReactComponent as SvgImage } from '../assets/SvgImage.svg';

export const useAutofillData = () => {
  const isTemplateView = useSelector(isTemplateViewSelector);

  // Fetch settings for agent 1 and agent 2
  const { data: settingData } = useGetSettingQuery({ id: 45 }); // Agent 1
  const { data: setting2Data } = useGetSettingQuery({ id: 29 }); // Agent 2

  const [autofillData, setAutofillData] = useState({
    fullAddress: '',
    streetAddress: '',
    cityStateZip: '',
    beds: '',
    baths: '',
    sqft: '',
    listPrice: '',
    status: '',
    defaultColor: '',
    customColor: '',
    themeFonts: {
      headerFont: '',
      bodyFont: '',
      scriptFont: '',
    },
    defaultThemeFonts: '',
    agentName: '',
    agentTitle: '',
    agentPhone: '',
    agentEmail: '',
    agentTeam: '',
    agentHeadshot: null,
    agentSettingId: '',
    agent2Name: '',
    agent2Title: '',
    agent2Phone: '',
    agent2Email: '',
    agent2Team: '',
    agent2Headshot: null,
    agent2SettingId: '',
    logos: {
      svgImage: null,
      svgLogo: null,
      svgIcon: null,
    },
  });

  useEffect(() => {
    if (isTemplateView) {
      let agent1AutoFillData = {
        themeFonts: {},
      };
      let agent2AutoFillData = {};

      // Extract agent 1 data if available
      if (settingData?.data) {
        agent1AutoFillData = {
          agentName: settingData.data.agentName,
          agentTitle: settingData.data.title,
          agentPhone: settingData.data.phoneNumber,
          agentEmail: settingData.data.emailAddress,
          agentTeam: settingData.data.teamName,
          agentHeadshot: settingData.data.headshotFile,
          agentIcon: settingData.data.iconFile,
          agentSettingId: settingData.data.id,
          themeFonts: {
            headerFont: settingData.data.headerFont,
            bodyFont: settingData.data.bodyFont,
            scriptFont: settingData.data.scriptFont,
          },
        };
      }

      // Extract agent 2 data if available
      if (setting2Data?.data) {
        agent2AutoFillData = {
          agent2Name: setting2Data.data.agentName,
          agent2Title: setting2Data.data.title,
          agent2Phone: setting2Data.data.phoneNumber,
          agent2Email: setting2Data.data.emailAddress,
          agent2Team: setting2Data.data.teamName,
          agent2Headshot: setting2Data.data.headshotFile,
          agent2SettingId: setting2Data.data.id,
        };
      }

      // Update the autofillData state with combined data
      setAutofillData({
        fullAddress: '1010 Circuitry Springs St, Orlando FL 32801',
        streetAddress: '1010 Circuitry Springs St',
        cityStateZip: 'Orlando FL 32801',
        beds: '3',
        baths: '2/1',
        sqft: '2,200',
        listPrice: '$529,000',
        status: 'LISTED',
        defaultColor: '#4a494b',
        customColor: '#4a494b',
        defaultThemeFonts: 'Roboto, sans-serif',
        logos: {
          svgImage: SvgImage,
          svgLogo: MainframeLogo,
          svgIcon: Micon,
        },
        propertyPhotos: [
          propertyPhoto1,
          propertyPhoto2,
          propertyPhoto3,
          propertyPhoto4,
          propertyPhoto5,
          propertyPhoto6,
          propertyPhoto7,
          propertyPhoto8,
          propertyPhoto9,
          propertyPhoto10,
        ],
        ...agent1AutoFillData,
        ...agent2AutoFillData,
      });
    }
  }, [isTemplateView, settingData, setting2Data]);

  return autofillData;
};
