import { INVOICE_STATUS, PRICE_FREQUENCY } from "consts/invoices";
import {RIGHT_OPTIONS} from "consts/rightsPermissions";

export const defaultValues = {
    name: 'Name',
    description: null,
    type: null,
    status: INVOICE_STATUS.CURRENT,
    priceFrequency: PRICE_FREQUENCY.ONE_TIME,
    price: null,
    companyStructureUnits: [],
    shareWithOption: RIGHT_OPTIONS.agentOnly.value,
    modelAccess: {
        shareWithOption: RIGHT_OPTIONS.agentOnly.value,
        sharedWithUsers: [],
        sharedWithUnits: [],
        owner: null,
        everyone: false,
    }
}