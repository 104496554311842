import { Checkbox, Divider, Grid } from '@mui/material';
import { Box } from '@mui/system';
import AbeFileImage from 'components/Common/AbeFileImage/AbeFileImage';
import { conversion } from 'helpers';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { AttributeName, AttributeValue, InfoBox, PanelName } from './styled';

export const DetailsPanels = ({
  panels,
  data,
  formatTypes = {},
  translatedLabels = {},
}) => {
  const panelNames = useMemo(() => Object.keys(panels), [panels]);

  const isAttributeValueEmpty = (value) => {
    return (
      !value ||
      value === '$0.00' ||
      value === 0 ||
      value === '0' ||
      value === '0.00'
    );
  };

  const formatTeamMembers = (teamMembers) => {
    const sortedTeamMembers = [...teamMembers].sort(
      (a, b) => a.order - b.order,
    );

    return sortedTeamMembers
      .map((member) => {
        const name = conversion.getName(member.teamMember);
        const percent = parseFloat(member.percent);
        const formattedPercent =
          percent % 1 === 0 ? percent : percent.toFixed(2).replace(/\.00$/, '');
        return `${name} (${formattedPercent}%)`;
      })
      .join('\n');
  };

  const formatValue = (attributeName, value) => {
    const formatType = formatTypes[attributeName] || attributeName;
    const identifier = 'id';
    const identifierValue = data[identifier];
    switch (formatType) {
      case 'attachmentArray':
        return value.map((item) => (
          <Box key={item.id} width={"100px"} height={"100px"}>
            {item.name}
            <AbeFileImage
              file={item}
              identifierValue={identifierValue}
              identifier={identifier}
            />
          </Box>
        ));
      case 'modelArray':
        value = value.map((item) => conversion.getName(item));
        return value.join(', ');
      case 'model':
        return conversion.getName(value);
      case 'team':
        return <Box whiteSpace={'pre-line'}>{formatTeamMembers(value)}</Box>;
      case 'currency':
        return conversion.formatCurrency(value);
      case 'percentage':
        return conversion.formatPercentage(value);
      case 'date':
        return conversion.formatDate(value);
      case 'boolean':
        return value ? 'Yes' : 'No';
      case 'checkbox':
        return value ? (
          <Checkbox sx={{ p: 0 }} size="small" checked disabled />
        ) : (
          <Checkbox sx={{ p: 0 }} size="small" disabled />
        );
      default:
        return typeof value === 'object' ? value : value;
    }
  };

  const renderPanelAttributes = (panelAttributes, panelName) => {
    return panelAttributes.map((attributeName) => {
      const attributeValue = attributeName.includes('.')
        ? conversion.getNestedValue(data, attributeName)
        : data[attributeName];
      const attributeLabel = attributeName.includes('.')
        ? attributeName.split('.')[0]
        : attributeName;
      if (!isAttributeValueEmpty(attributeValue)) {
        const formattedValue = formatValue(attributeName, attributeValue);
        return (
          <React.Fragment key={`${panelName}-${attributeName}`}>
            <Grid container wrap="nowrap" justifyContent={'flex-end'} mb={1}>
              {translatedLabels[attributeLabel] !== 'none' && (
                <Grid
                  item
                  style={{
                    display: 'flex',
                    flex: '1 1 auto', // Allow flex item to grow and shrink
                    minWidth: 0, // Prevents overflow beyond container
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap', // Ensures text does not wrap
                  }}
                >
                  <AttributeName noWrap>
                    {translatedLabels[attributeLabel] ||
                      conversion.camelCaseToWords(attributeLabel)}
                  </AttributeName>
                </Grid>
              )}
              <Grid
                item
                style={{
                  display: 'flex',
                  flex:
                    translatedLabels[attributeLabel] === 'none'
                      ? '1 0 auto'
                      : '0 0 auto',
                  minWidth: 'fit-content',
                  textAlign: 'right',
                }}
              >
                <AttributeValue>{formattedValue}</AttributeValue>
              </Grid>
            </Grid>
          </React.Fragment>
        );
      }
      return null;
    });
  };

  const renderPanels = () => {
    return panelNames.map((panelName, index) => {
      const panelData = panels[panelName];
      const panelAttributes = panelData.attributes || panelData;
      const panelIcon = panelData.icon;
      const areAllAttributesEmpty = panelAttributes.every((attributeName) =>
        isAttributeValueEmpty(data[attributeName]),
      );

      if (!areAllAttributesEmpty) {
        return (
          <React.Fragment key={`panel-${panelName}`}>
            {index > 0 && <Divider />}
            <Box px={2} pt={1}>
              {panelName && (
                <PanelName>
                  {panelName}
                  {panelIcon && (
                    <Box display={'inline-block'} ml={1}>
                      {panelIcon}
                    </Box>
                  )}
                </PanelName>
              )}
              <InfoBox>
                {renderPanelAttributes(panelAttributes, panelName)}
              </InfoBox>
            </Box>
          </React.Fragment>
        );
      }
      return null;
    });
  };

  return data && <Box width={'100%'}>{renderPanels()}</Box>;
};

DetailsPanels.propTypes = {
  panels: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  formatTypes: PropTypes.object,
  translatedLabels: PropTypes.object,
};
