import {
  ConfirmDialog,
  HideableTooltip,
  MenuItemButton,
} from 'components/Common';
import { RoleHeadIcon } from 'components/Icons';
import { getRoleNameFromWords } from 'components/ModelViews/Documents/DocumentPdfViewer/utils/roles';
import { conversion } from 'helpers';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { activeFieldsSelector } from 'redux/selectors';
import { updateField, updateRoleFiller } from 'redux/slices';

function RoleAssignmentOption({ role }) {
  const dispatch = useDispatch();
  const activeFields = useSelector(activeFieldsSelector);
  const [roleWithFields, setRoleWithFields] = useState(null);

  const disableRoleAssignments = (label) => {
    if (
      activeFields[0].role &&
      activeFields[0].type !== 'signatureInitialField' &&
      activeFields[0].type !== 'signatureField' &&
      activeFields[0].type !== 'signDate' &&
      activeFields[0].type !== 'printName'
    ) {
      if (label !== activeFields[0].role) {
        return true;
      }
    }
    return false;
  };

  const roleName = role.role && getRoleNameFromWords(role);

  return (
    <>
      <HideableTooltip
        title={
          disableRoleAssignments(roleName)
            ? 'Fields assigned to ' +
              conversion.camelCaseToWords(activeFields[0].role) +
              '. Changing this will assign the new role to all fields except signatures'
            : ''
        }
      >
        <MenuItemButton
          dense
          isSelected={activeFields[0].role === roleName}
          onClick={() => {
            if (disableRoleAssignments(roleName)) {
              setRoleWithFields(roleName);
            } else {
              dispatch(
                updateField({
                  properties: { role: roleName },
                }),
              );
            }
          }}
          label={`${conversion.camelCaseToWords(role.role)} ${role.order}`}
          icon={<RoleHeadIcon solid role={role} />}
        />
      </HideableTooltip>
      <ConfirmDialog
        open={!!roleWithFields}
        setOpen={setRoleWithFields}
        title="Change Filler Fields?"
        message={`Are you sure you want to change the filler fields to ${conversion.camelCaseToWords(
          roleName,
        )}? Do you want to continue?`}
        onConfirm={() => {
          dispatch(updateRoleFiller(roleWithFields));
          setRoleWithFields(null);
        }}
      />
    </>
  );
}

export default RoleAssignmentOption;

RoleAssignmentOption.propTypes = {
  role: PropTypes.object,
};
