import { faTrash } from '@fortawesome/pro-regular-svg-icons';
import {
  ConfirmDialog,
  FontAwesomeIconComp,
  MenuItemButton,
} from 'components/Common';
import React from 'react';
import { useDispatch } from 'react-redux';
import { deleteSelectedFields } from 'redux/slices';

export function DeleteField() {
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const dispatch = useDispatch();

  const handleConfirm = () => {
    dispatch(deleteSelectedFields());
    setConfirmOpen(false);
  };

  return (
    <>
      <MenuItemButton
        label={'Delete Field'}
        icon={<FontAwesomeIconComp fontSize={18} icon={faTrash} />}
        onClick={() => {
          setConfirmOpen(true);
        }}
      />
      <ConfirmDialog
        title="Delete Field?"
        message="This will permanently delete this field. Do you want to continue?"
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={handleConfirm}
      />
    </>
  );
}
