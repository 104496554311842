import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Box } from '@mui/system';
import { useAutofillData } from 'components/ModelViews/Documents/ClickMarketingViewer/hooks/useAutofillData';
import { useGetSvgContentQuery } from 'redux/rtk-query';
import { activePageCM } from 'redux/selectors';

export const SvgImageComponent = ({ SvgComponent, fillColor }) => {
  const svgRef = useRef(null);
  const autoFillData = useAutofillData();
  const activePageId = useSelector(activePageCM);

  const isUrl = typeof SvgComponent === 'string';

  const getFillColor = () => {
    if (
      fillColor === 'light' &&
      autoFillData.customColor === 'mainframeTheme'
    ) {
      return null;
    }
    switch (fillColor) {
      case 'dark':
        return '#231F20';
      case 'light':
        return '#fff';
      case 'themeColor':
        return autoFillData.customColor;
      case 'color':
        return null;
      default:
        return fillColor;
    }
  };

  const fillColorHex = getFillColor();

  // Use RTK Query to fetch SVG content when SvgComponent is a URL
  const {
    data: svgContent,
    error,
    isLoading,
  } = useGetSvgContentQuery(SvgComponent, { skip: !isUrl });

  useEffect(() => {
    if (svgRef.current) {
      const svgElement = svgRef.current;

      // Get all elements that can be filled in the SVG
      const elements = svgElement.querySelectorAll(
        'path, rect, circle, polygon, polyline, line, ellipse',
      );

      elements.forEach((element) => {
        // Only override fill if fillColorHex is present
        if (fillColorHex) {
          if (!element.getAttribute('data-original-fill')) {
            element.setAttribute(
              'data-original-fill',
              element.getAttribute('fill') || 'none',
            );
          }
          // Set new fill color
          element.setAttribute('fill', fillColorHex);
        }
      });
    }
  }, [fillColorHex, svgContent, activePageId]);

  if (isUrl && isLoading) {
    return <div>Loading...</div>;
  }

  if (isUrl && error) {
    return <div>Error loading SVG</div>;
  }

  return (
    <>
      {isUrl ? (
        svgContent && (
          <Box
            sx={{ width: '100%', height: '100%', display: 'flex' }}
            ref={svgRef}
            dangerouslySetInnerHTML={{ __html: svgContent }}
          />
        )
      ) : (
        <SvgComponent
          ref={svgRef}
          style={{ width: '100%', height: '100%', display: 'flex' }}
        />
      )}
    </>
  );
};

SvgImageComponent.propTypes = {
  SvgComponent: PropTypes.oneOfType([PropTypes.elementType, PropTypes.string])
    .isRequired,
  fillColor: PropTypes.string,
};
