import { Divider } from '@mui/material';
import { AbeCard } from 'components/Common';
import { ItemCountProvider } from 'contexts';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetTransactionQuery } from 'redux/rtk-query';
import { TimeframesList } from './components/TimeframesList';

export const TimeframeCard = (props) => {
  let { transactionDetailsId } = useParams();
  const {
    data: { data: transaction },
  } = useGetTransactionQuery({ id: transactionDetailsId });

  return (
    <ItemCountProvider>
      <AbeCard {...props} title={'Timeframes'}>
        <Divider />
        <TimeframesList transaction={transaction} />
      </AbeCard>
    </ItemCountProvider>
  );
};

TimeframeCard.propTypes = {};
