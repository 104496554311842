import { faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons';
import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { styled } from '@mui/system';
import { FontAwesomeIconComp } from 'components/Common';
import { colors } from 'consts';
import PropTypes from 'prop-types';
import React from 'react';

export const MenuItemIcon = styled(ListItemIcon)(({ theme }) => ({
  justifyContent: 'center',
  color: theme.palette.primary.main,
  fontSize: 18,
  '& span': {
    borderColor: theme.palette.primary.main,
  },
}));

const MenuItem = styled(ListItemButton, {
  shouldForwardProp: (prop) => prop !== 'selectedColor',
})(({ selected, selectedColor = colors.DARK_BLUE_1 }) => ({
  backgroundColor: selected ? selectedColor : '#fff',
  color: '#fff',
  '&.Mui-selected': {
    backgroundColor: selectedColor,
  },
  '&.Mui-selected:hover': {
    backgroundColor: selectedColor,
    color: '#fff',
  },
  '&:hover': {
    backgroundColor: selectedColor,
    color: '#fff',
  },
  '& i': {
    color: selectedColor,
  },
  '& .MuiListItemIcon-root': {
    color: selected ? '#fff' : selectedColor,
  },
  '& .MuiTypography-root': {
    color: selected ? '#fff' : selectedColor,
  },
  '&:hover .MuiListItemIcon-root': {
    color: '#fff',
  },
  '&:hover .MuiTypography-root': {
    color: '#fff',
  },
}));

export const MenuItemButton = ({
  icon,
  label,
  secondaryLabel,
  onClick = () => {},
  isSelected = false,
  isGroup = false,
  rightAction,
  dense = false,
  selectedColor,
  ...other
}) => {
  return (
    <>
      {dense ? (
        <MenuItem
          {...other}
          dense
          selected={isSelected}
          onClick={() => {
            onClick();
          }}
          selectedColor={selectedColor}
        >
          {icon && <MenuItemIcon>{icon}</MenuItemIcon>}
          <ListItemText primary={label} secondary={secondaryLabel} />
          {isGroup && (
            <MenuItemIcon>
              <FontAwesomeIconComp
                icon={isSelected ? faChevronUp : faChevronDown}
              />
            </MenuItemIcon>
          )}
        </MenuItem>
      ) : (
        <>
          <MenuItem
            {...other}
            selected={isSelected}
            onClick={() => {
              onClick();
            }}
          >
            {icon && <MenuItemIcon>{icon}</MenuItemIcon>}
            <ListItemText
              color={'primary'}
              primary={label}
              sx={{
                ml: 2,
                '& span': {
                  color: isSelected ? '#fff' : selectedColor,
                },
              }}
            />
            {isGroup && (
              <MenuItemIcon>
                <FontAwesomeIconComp
                  icon={isSelected ? faChevronUp : faChevronDown}
                />
              </MenuItemIcon>
            )}
            {rightAction && rightAction}
          </MenuItem>
        </>
      )}
    </>
  );
};

MenuItemButton.propTypes = {
  icon: PropTypes.object,
  label: PropTypes.string.isRequired,
  secondaryLabel: PropTypes.string,
  onClick: PropTypes.func,
  isSelected: PropTypes.bool,
  dense: PropTypes.bool,
  isGroup: PropTypes.bool,
  rightAction: PropTypes.object,
  selectedColor: PropTypes.string,
};
