import queryString from 'query-string';
import { endpoints } from 'consts';

export const documentTemplateUrlBuilder = {
  getCategoryList: function (params) {
    const urlParams = queryString.stringify(params, { skipNull: true });
    return `${endpoints.categories}/list?${urlParams}`;
  },
  createCategory: function () {
    return endpoints.categories;
  },
  getCategory: function ({ id }) {
    return `${endpoints.categories}/${id}`;
  },
  getList: function (params) {
    const urlParams = queryString.stringify(params, { skipNull: true });
    return `${endpoints.documentTemplates}?${urlParams}`;
  },
  getClickMarketingTemplates: function (params) {
    const urlParams = queryString.stringify(params, {
      skipNull: true,
      arrayFormat: 'bracket',
    });
    return `${endpoints.documentTemplates}/click-marketing?${urlParams}`;
  },
  createDocumentTemplate: function () {
    return endpoints.documentTemplates;
  },
  getDocumentTemplate: ({ id }) => {
    return `${endpoints.documentTemplates}/${id}`;
  },
  getDocumentTemplatePdfUrl: ({ id }) => {
    return `${endpoints.documentTemplates}/${id}/media/urls`;
  },
  getDocumentTemplateMediaUrl: function ({ id, uuid }) {
    return `${endpoints.documentTemplates}/${id}/media/${uuid}`;
  },
};
