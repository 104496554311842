import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

export const ScaleText = ({ children, maxFontSize, minFontSize = 2 }) => {
  const containerRef = useRef(null);
  const textRef = useRef(null);
  const [fontSize, setFontSize] = useState(maxFontSize);

  const adjustFontSize = () => {
    const container = containerRef.current.parentElement; // Use the parent element of the container
    const text = textRef.current;
    if (container && text) {
      let newFontSize = maxFontSize;
      // Reset font size to max before adjustment
      text.style.fontSize = `${newFontSize}px`;
      // Reduce font size until the text fits within the container
      while (
        (text.scrollWidth > container.clientWidth ||
          text.scrollHeight > container.clientHeight) &&
        newFontSize > minFontSize
      ) {
        newFontSize -= 0.5;
        text.style.fontSize = `${newFontSize}px`;
      }
      setFontSize(newFontSize);
    }
  };

  useEffect(() => {
    const container = containerRef.current.parentElement; // Observe the parent element
    const resizeObserver = new ResizeObserver(adjustFontSize);

    if (container) {
      resizeObserver.observe(container);
    }

    // Initial adjustment
    adjustFontSize();

    return () => {
      resizeObserver.disconnect();
    };
  }, [maxFontSize, minFontSize]); // Dependencies to re-run if these props change

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        alignItems: "flex-end",
        height: "100%"
      }}
      ref={containerRef}
    >
      <div
        ref={textRef}
        style={{
          fontSize: `${fontSize}px`,
          fontWeight: "normal",
          whiteSpace: "nowrap",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        {children}
      </div>
    </div>
  );
};

ScaleText.propTypes = {
  children: PropTypes.node.isRequired,
  maxFontSize: PropTypes.number.isRequired,
  minFontSize: PropTypes.number,
};
