import { Box, FormControl, Grid } from '@mui/material';
import { LabeledOutline } from 'components/Common';
import PropTypes from 'prop-types';
import React from 'react';
import { FieldArray } from 'react-final-form-arrays';
import { SocialMediaLink } from './components/SocialMediaLink';

export const SocialMediaLinks = ({ input }) => {
  return (
    <FieldArray name={input.name}>
      {({ fields }) => (
        <LabeledOutline label={'Social Media Links'}>
          <Box px={2}>
            <FormControl fullWidth>
              <Grid container spacing={2} p={1}>
                {fields.map((name, index) => {
                  const socialLink = fields.value[index];
                  return (
                    <Grid item xs={6} key={index} marginBottom={2}>
                      <SocialMediaLink socialLink={socialLink} />
                    </Grid>
                  );
                })}
              </Grid>
              {/*<Box*/}
              {/*  sx={{*/}
              {/*    display: 'flex',*/}
              {/*    justifyContent: 'center',*/}
              {/*    marginTop: 2,*/}
              {/*  }}*/}
              {/*>*/}
              {/*  <Tooltip*/}
              {/*    title={*/}
              {/*      fields.length >= 4*/}
              {/*        ? 'Maximum of 4 testimonials allowed'*/}
              {/*        : ''*/}
              {/*    }*/}
              {/*    arrow*/}
              {/*  >*/}
              {/*    <span>*/}
              {/*      <Button*/}
              {/*        variant="outlined"*/}
              {/*        onClick={() =>*/}
              {/*          fields.push({*/}
              {/*            customerName: '',*/}
              {/*            description: '',*/}
              {/*            id: uuid(),*/}
              {/*          })*/}
              {/*        }*/}
              {/*        startIcon={<AddIcon />}*/}
              {/*        disabled={fields.length >= 4} // Disable the button if there are 4 or more testimonials*/}
              {/*      >*/}
              {/*        Add SocialMediaLink*/}
              {/*      </Button>*/}
              {/*    </span>*/}
              {/*  </Tooltip>*/}
              {/*</Box>*/}
            </FormControl>
          </Box>
        </LabeledOutline>
      )}
    </FieldArray>
  );
};

SocialMediaLinks.propTypes = {
  input: PropTypes.object,
};
