import React from 'react';
import { faBullseyePointer } from '@fortawesome/pro-solid-svg-icons';
import { Outlet } from 'react-router-dom';
import { Grid } from '@mui/material';
import {
  CreateButton,
  MenuButton,
  FontAwesomeIconComp,
  MenuItemButton,
} from 'components/Common';
import { LayoutHeader } from 'components/Layouts';
import { MarketingCreateView } from 'components/ModelViews/Marketing/MarketingCreateView';
import { PageMainContent } from 'components/Styled';

export const MarketingPage = () => {
  const [createType, setCreateType] = React.useState(null);
  return (
    <>
      <LayoutHeader
        title={
          <>
            Marketing Center
            <MenuButton
              closeOnClick
              title={<CreateButton sx={{ ml: 2 }} title={'New'} />}
            >
              <MenuItemButton
                dense
                sx={{ padding: 0.5, paddingRight: 3 }}
                icon={
                  <FontAwesomeIconComp fontSize={18} icon={faBullseyePointer} />
                }
                label={'Click Marketing'}
                onClick={() => setCreateType('Click Marketing')}
              />
            </MenuButton>
          </>
        }
      ></LayoutHeader>
      <PageMainContent maxWidth={false} component="main" disableGutters>
        <Grid container flexDirection="column"></Grid>
      </PageMainContent>
      <Outlet />
      {createType && (
        <MarketingCreateView
          onClose={() => setCreateType(null)}
          createType={createType}
        />
      )}
    </>
  );
};
