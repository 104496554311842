import { Box } from '@mui/system';
import { Table } from 'components/Common';
import { ColumnManager } from 'components/Common/Table/components/ColumnManager';
import { STATUSES, TEMPLATE_MANAGER, TYPES } from 'consts';
import { useTableData } from 'hooks';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useGetDocumentTemplateQuery,
  useGetDocumentTemplatesListQuery,
} from 'redux/rtk-query';
import AddDocumentTemplateButton from './components/AddDocumentTemplateButton';
import { CustomBreadcrumbs } from './components/CustomBreadcrumbs';
import { Cells, MenuItems } from './config';
import { useBreadcrumbs } from './hooks/useBreadcrumbs';

const initialColumns = Cells.map((cell) => ({ ...cell, isVisible: true }));

export const DocumentTemplatesTable = () => {
  const navigate = useNavigate();
  const {
    tab,
    parentId = tab === 'clickMarketing' ? 'CLICK_MARKETING' : 'home',
  } = useParams();
  // should skip if parentId is not a number or "CLICK_MARKETING" or "home"

  const shouldSkipQuery =
    parentId !== 'home' &&
    parentId !== 'CLICK_MARKETING' &&
    isNaN(parseInt(parentId));
  const {
    breadcrumbs,
    updateBreadcrumbs,
    handleBreadcrumbNavigate,
    resetBreadcrumbs,
  } = useBreadcrumbs(parentId);

  const {
    order,
    orderBy,
    filterValues,
    page,
    rowsPerPage,
    search,
    loading,
    setFilterValues,
    setPage,
    setRowsPerPage,
    setSearch,
    handleRequestSort,
    totalPages,
    totalCount,
    rows,
    visibleColumns,
    resetColumns,
    toggleColumnVisibility,
  } = useTableData(
    useGetDocumentTemplatesListQuery,
    initialColumns,
    'name',
    'asc',
    10,
    { parentId: parentId, status: [STATUSES.COMPLETED] },
  );

  const { data: documentData } = useGetDocumentTemplateQuery(
    { id: parentId },
    {
      skip: shouldSkipQuery || breadcrumbs.find((bc) => bc.id === parentId),
    },
  );

  const onRowClick = (row) => {
    if (
      row.type === TYPES.FOLDER ||
      row.type === TYPES.FOLDER_GROUP ||
      row.type === TYPES.ARCHIVE_FOLDER
    ) {
      updateBreadcrumbs(row);
      navigate(`/${TEMPLATE_MANAGER(tab, row.id)}`);
    }
  };

  useEffect(() => {
    setPage(1);
  }, [parentId]);

  useEffect(() => {
    if (
      documentData?.data &&
      parentId !== 'home' &&
      documentData?.data?.id === parseInt(parentId) &&
      !breadcrumbs.find((bc) => bc.id === parseInt(parentId))
    ) {
      updateBreadcrumbs(documentData?.data);
    }
  }, [parentId, breadcrumbs, documentData, updateBreadcrumbs]);

  return (
    <>
      <Table
        setFilterValues={setFilterValues}
        filterValues={filterValues}
        onRowClick={onRowClick}
        title={
          parentId !== 'CLICK_MARKETING' && (
            <CustomBreadcrumbs
              path={breadcrumbs}
              onNavigate={handleBreadcrumbNavigate}
              onHomeClick={resetBreadcrumbs}
            />
          )
        }
        handleSearch={setSearch}
        search={search}
        afterSearch={
          <Box>
            <ColumnManager
              columns={visibleColumns}
              setColumns={toggleColumnVisibility}
              resetColumns={resetColumns}
            />
            <AddDocumentTemplateButton viewingId={parentId} />
          </Box>
        }
        isLoading={loading}
        rows={rows}
        totalPages={totalPages}
        totalCount={totalCount}
        order={order}
        menuItems={MenuItems()}
        cells={visibleColumns.filter((col) => col.isVisible)}
        orderBy={orderBy}
        page={page}
        rowsPerPage={rowsPerPage}
        handleRequestSort={handleRequestSort}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
      />
    </>
  );
};
