import { defaultValues } from './defaultValues';
import { addModelAccessValues, omitNull } from "helpers";

export const processFormData = (values) => {
  let formData = values.values ? values.values : values;
  let allValues = { ...defaultValues, ...omitNull(formData) };
  let brokerage = allValues?.brokerage;
  let companyName = allValues?.companyName;
  let referralContact = allValues?.referralContact;
  let lists = allValues?.lists;
  let tags = allValues?.tags;
  let abeUser = allValues?.abeUser;

  const relations = allValues?.relations?.map((relation) => {
    return {
      ...relation,
      contactId: relation.contact?.id,
      relationTypeId: relation.type?.id,
      relationType: relation.type?.id ? null : relation.type?.name,
    };
  });
  return {
    ...allValues,
    ...(companyName
      ? {
          companyName: companyName.value,
        }
      : null),
    vendor1099: allValues?.vendor1099 === 'Yes' ? true : false,
    referralContactId: referralContact?.id ? referralContact.id : null,
    brokerageId: brokerage?.id
      ? brokerage.id
      : brokerage?.value
      ? brokerage.value
      : null,
    contactListsIds: lists?.map((contactList) =>
      contactList.id ? contactList.id : contactList.value,
    ),
    tags: tags?.map((tag) => (tag.inputValue ? tag.inputValue : tag.value)),
    abeUserId: abeUser?.value,
    relations,
    ...addModelAccessValues(values.modelAccess),
  };
};
