import { Grid } from '@mui/material';
import { FormPanel } from 'components/Common';
import { DatePicker, Select, Setting, TextInput } from 'components/Fields';
import { FormCondition } from 'components/Forms';
import {
  AgentStatus,
  CommercialServices,
  ResidentialServices,
  SettingType,
} from 'consts';
import { getSelectOptions } from 'helpers/form';
import React from 'react';
import { Field } from 'react-final-form';

export const OverviewPanel = () => {
  return (
    <FormPanel label="Overview" hideArrow>
      <Grid container>
        <Grid item xs={12}>
          <Field name="name" label={'Name'} component={TextInput} />
          <Field
            name="settingType"
            label={'Setting Type'}
            options={getSelectOptions(SettingType)}
            component={Select}
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            name="residentialServices"
            label={'Residential Services'}
            component={Select}
            options={getSelectOptions(ResidentialServices)}
            multiple
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            name="commercialServices"
            label={'Commercial Services'}
            component={Select}
            options={getSelectOptions(CommercialServices)}
            multiple
          />
        </Grid>
        <Grid item xs={12}>
          <Field name="officeSetting" label="Office" component={Setting} />
        </Grid>
        <FormCondition when="settingType" operator="equals" is="Office">
          <Grid item xs={12}>
            <Field
              name="officeLocation"
              label="Office Location"
              component={TextInput}
            />
          </Grid>
        </FormCondition>
        <Grid item xs={6}>
          <Field
            name="agentStatus"
            label={'Agent Status'}
            options={getSelectOptions(AgentStatus)}
            component={Select}
          />
          <Field name="hireDate" label={'Hire Date'} component={DatePicker} />
          <Field
            name="licenseDate"
            label={'License Date'}
            component={DatePicker}
          />
          <Field
            name="assistantSetting"
            label="Assistant"
            component={Setting}
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            name="dehireDate"
            label={'Dehire Date'}
            component={DatePicker}
          />
          <Field
            name="anniversaryDate"
            label={'Anniversary Date'}
            component={DatePicker}
          />
          <Field
            name="licenseNumber"
            label={'License Number'}
            component={TextInput}
          />
        </Grid>
        <Grid item xs={6}>
          <Field name="brokerSetting" label="Broker" component={Setting} />
          <Field
            name="complianceCoordinatorSetting"
            label="Compliance Coordinator"
            component={Setting}
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            name="officeAdminSetting"
            label="Office Admin"
            component={Setting}
          />
          <Field
            name="marketingCoordinatorSetting"
            label="Marketing Coordinator"
            component={Setting}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            name={'officeLocation'}
            label={'Office Location'}
            component={TextInput}
          />
        </Grid>
      </Grid>
    </FormPanel>
  );
};
