import { Grid } from '@mui/material';
import { FilePreview } from 'components/Common';
import { FileInputBox } from 'components/Fields/FileInput/styled';
import { FileInput } from 'components/Fields/index';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-final-form';

export const ButtonFileInput = ({
  dropZoneId,
  input,
  useGetMediaQuery = () => ({
    data: null,
  }),
  identifier = 'uuid',
}) => {
  let startingValue;

  if (input.value) {
    if (Array.isArray(input.value)) {
      startingValue = input.value;
    } else if (typeof input.value === 'object') {
      startingValue = [input.value];
    } else {
      startingValue = input.value;
    }
  } else {
    startingValue = [];
  }
  const [filesValues, setFilesValues] = useState(startingValue);
  const form = useForm();
  const identifierValue = form.getState().values[identifier];
  const { data: files } = useGetMediaQuery(identifierValue, {
    skip: !identifier,
  });

  const removeFile = (file) => () => {
    let filesCopy = [...filesValues];
    let newFiles = filesCopy.map((fileCopy) => {
      if (fileCopy === file) {
        if (Object.isExtensible(fileCopy)) {
          fileCopy.removed = fileCopy.removed ? !fileCopy.removed : true;
          return fileCopy;
        } else {
          return {
            ...fileCopy,
            removed: fileCopy.removed ? !fileCopy.removed : true,
          };
        }
      }
      return fileCopy;
    });
    setFilesValues(newFiles);
  };

  useEffect(() => {
    if (identifierValue && files && files.data) {
      setFilesValues(files.data);
    }
  }, [files]);

  useEffect(() => {
    input.onChange(filesValues);
  }, [filesValues]);

  return (
    <FileInputBox>
      <FileInput
        setFilesValues={setFilesValues}
        dropZoneId={dropZoneId}
        name={input.name}
      />
      {filesValues.length > 0 && (
        <Grid style={{ paddingTop: 20 }} container>
          {filesValues.map((file, index) => {
            console.log(file);
            return (
              <FilePreview
                key={index}
                file={file}
                identifier={identifier}
                identifierValue={identifierValue}
                removeFile={removeFile}
              />
            );
          })}
        </Grid>
      )}
    </FileInputBox>
  );
};

ButtonFileInput.propTypes = {
  dropZoneId: PropTypes.string,
  input: PropTypes.object,
  useGetMediaQuery: PropTypes.func,
  identifier: PropTypes.string,
};
