import React from "react";
import PropTypes from "prop-types";
import {List, ListItem, ListItemText, useTheme} from "@mui/material";
import {PageLink} from "components/Styled";
import {FullMenuButton, UpperListItemButton, UpperListItemIcon} from "../../styled";
import {FontAwesomeIconComp} from "components/Common";
import {faEllipsis} from "@fortawesome/pro-solid-svg-icons";
import {LEFT_MENU_ITEM_INFO} from "consts";
import {getModuleIcon} from "helpers";

export const ModuleMenu = ({fullMenu, setFullMenu, open}) => {
    const theme = useTheme();
    const moduleMenuItems = LEFT_MENU_ITEM_INFO.map(item => ({...item, icon: getModuleIcon(item.module)}));
    const permLeftMenuItemInfo = moduleMenuItems.slice(0, 6);
    const menuToDisplay = fullMenu ? moduleMenuItems : permLeftMenuItemInfo;
    return <List sx={{padding: "10px 4px" }}>
        {menuToDisplay.map(item => (
            <ListItem key={item.module} disablePadding component={PageLink} to={item.refLink} sx={{
                "& .MuiTouchRipple-child": {
                    backgroundColor: theme.palette.primary.main
                }
            }}>
                <UpperListItemButton>
                    <UpperListItemIcon sx={{margin : open ? "0 10px 0 5px" : null}}>
                        <FontAwesomeIconComp icon={item.icon.icon} color={item.icon.color} />
                    </UpperListItemIcon>
                    <ListItemText
                        primary={item.module}
                        sx={{ display: open ? "block" : "none", width: "10px" }}
                    />
                </UpperListItemButton>
            </ListItem>
        ))}
        <ListItem disablePadding sx={{ display: open ? "block" : "none" }}>
            <FullMenuButton
                onClick={() => setFullMenu(!fullMenu)}
            >
                <FontAwesomeIconComp
                    icon={faEllipsis}
                    color={"#0085FF"}
                    size="lg"
                />
            </FullMenuButton>
        </ListItem>
    </List>;
}

ModuleMenu.propTypes = {
    fullMenu: PropTypes.bool,
    setFullMenu: PropTypes.func,
    open: PropTypes.bool
};


