import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";

import { ListInput, CreateCancelBtns, CancelBtn, CreateBtn } from "./styled";
import {useCreateListMutation} from "redux/rtk-query";

export const CreateNewListInput = ({ showListHandler, setCheckedLists, checkedLists }) => {

    const [listName, setListName] = useState("");
    const [createList,{ isLoading: isCreating, isSuccess: isSuccess }] = useCreateListMutation();

    const createListHandler = async () => {
        const newList = await createList({
            name: listName
        });
        let checkedListsCopy = [...checkedLists]
        checkedListsCopy.push(newList.data.data.id)
        setCheckedLists(checkedListsCopy)
    }

    useEffect(() => {
        if(isSuccess) {
            showListHandler(false)
        }
    }, [isSuccess]);


  return (
    <>
      <ListInput onChange={e=>setListName(e.target.value)} size="small" placeholder="List Name" />
      <CreateCancelBtns>
        <CancelBtn variant="outlined" onClick={() => showListHandler(false)}>
          Cancel
        </CancelBtn>
        <CreateBtn disabled={isCreating} onClick={createListHandler} variant="contained">
            {isCreating ? "Saving" :"Create"}
        </CreateBtn>
      </CreateCancelBtns>
    </>
  );
};

CreateNewListInput.propTypes = {
  showListHandler: PropTypes.func,
    setCheckedLists: PropTypes.func,
    checkedLists: PropTypes.array
};
