import {defaultValues} from "./defaultValues";
import {omitNull} from "helpers";

export const processFormData = values => {
  let formData = values.values ? values.values : values;
  let allValues = { ...defaultValues, ...omitNull(formData) };

  const cappingLevels = allValues.cappingLevels.map(item => {
    if (isNaN(Number(item.id))) {
      // eslint-disable-next-line no-unused-vars
      const { id, ...rest } = item;
       return {
        ...rest,
        amount: parseFloat(rest.amount),
        percentage: parseInt(rest.percentage),
      };
    }
    return {
      ...item,
      amount: parseFloat(item.amount),
      percentage: parseInt(item.percentage),
    };
  });


  return {
    ...allValues,
    cappingLevels: cappingLevels,
  }
};