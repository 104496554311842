import React from 'react';
import PropTypes from 'prop-types';
import { Chip as MaterialChip } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledChip = styled(MaterialChip)(({ theme, chipColor = theme.palette.grey, variant }) => ({
  color: 'white',
  backgroundColor: `${chipColor} !important`,
  '&:hover': {
    backgroundColor: chipColor,
    filter: 'brightness(120%)',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: chipColor,
    borderColor: chipColor,
  },
  ...(variant === 'outlined' && {
    color: chipColor,
    border: `1px solid ${chipColor}`,
    backgroundColor: 'transparent !important',
    '& .MuiChip-icon': {
      color: chipColor,
    },
    '& .MuiChip-deleteIcon': {
      color: chipColor,
    },
  }),
  ...(variant !== 'outlined' && {
    '& .MuiChip-icon': {
      color: 'white',
    },
    '& .MuiChip-deleteIcon': {
      color: 'white',
    },
  }),
}));

export const ColorChip = (props) => {
  return <StyledChip {...props} />;
};

ColorChip.propTypes = {
  color: PropTypes.string,
  variant: PropTypes.string,
};
