import { styled } from "@mui/system";
import { Box, Container, Typography } from "@mui/material";

export const SignInWithGoogleText = styled(Typography)({
  fontSize: "16px",
  color: "#0085FF",
  cursor: "pointer",
  fontWeight: "500",
  lineHeight: "24px",
  margin: "24px 0 0px",

  "&:hover": {
    color: "#1f82c9",
  },
});

export const SignInContainer = styled(Container)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: "456px",
  height: "560px",
  boxShadow: `0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)`,
  borderRadius: "8px",
  backgroundColor: "#fff",
  padding: "50px 70px",
  margin: 0,
});

export const LoginForm = styled("form")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  width: "320px",
  height: "324px",
});

export const FormButton = styled(Box)({
  color: "#FFFFFF",
  height: "52px",
  width: "320px",
  marginTop: "24px",
  backgroundColor: "#0085FF",
  fontWeight: "500",
  fontSize: "20px",
  lineHeight: "28px",
  cursor: "pointer",
  boxShadow: `0px 3px 1px -2px rgb(0 0 0 / 20%),
  0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)`,
  borderRadius: "8px",
  border: "none",
  textTransform: "none",
  transition: "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1)",
  "&:hover": {
    backgroundColor: "#04568b",
    transition: "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1)",
  },
});
