import { styled } from "@mui/system";
import { Typography, Divider, Button } from "@mui/material";

import { BoxStyled } from "../styled";

export const BoxDivider = styled(Divider)({
  color: "#969696",
  marginBottom: "28px",
  borderColor: "#E8E8E8",
});

export const BoxTitleContainer = styled(BoxStyled)({
  gridTemplateColumns: "1fr auto auto",
});

export const BoxTitle = styled(Typography)({
  fontWeight: "500",
  fontSize: "20px",
  lineHeight: "28px",
});

export const BoxDescription = styled(Typography)({
  fontWeight: "400",
  fontSize: "13px",
  lineHeight: "20px",
  color: "#969696",
});

export const AddButton = styled(Button)({
  border: "1px solid #E8E8E8",
  boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.06)",
  borderRadius: "8px",
  background: "#FFFFFF",
  padding: "4px 8px",
  fontWeight: "500",
  fontSize: "13px",
  lineHeight: "20px",
  textAlign: "center",
  textTransform: "none",
  height: "fit-content",

  span: {
    marginRight: "6px",
    marginLeft: "0",
  },
  svg: {
    color: "#969696",
    width: "13px",
    height: "13px",
  },
});

export const BoxDetails = styled(BoxStyled)({
  padding: "16px",
  background: "#F7F7F7",
  borderRadius: "8px",
  gridTemplateColumns: "1fr auto",
  ".MuiInputBase-root": {
    height: "28px",
  },
  ".MuiAutocomplete-root .MuiInputBase-root": {
    padding: "2px",
    ".MuiChip-root": {
      marginTop: 0,
      marginBottom: "4px",
      height: "20px",
      background: "#F7F7F7",
      fontWeight: "400",
      fontSize: "13px",
      lineHeight: "20px",
      color: "#565656",

      ".MuiSvgIcon-root": {
        fill: "#969696",
      },
    },
  },
});

export const BoxLine = styled(BoxStyled)({
  gridTemplateColumns: "100px 1fr",
  marginBottom: "8px",
  "&:last-child": {
    marginBottom: 0,
  },
});

export const BoxBirthdayLine = styled(BoxStyled)({
  gridTemplateColumns: "100px 1fr 80px auto auto",
  marginBottom: "8px",
});

export const RemoveButton = styled(Button)({
  fontWeight: "500",
  fontSize: "13px",
  lineHeight: "20px",
  textTransform: "none",
  color: "#E92C2C",
  alignSelf: "start",
});

export const Label = styled(Typography)({
  fontWeight: "600",
  fontSize: "12px",
  lineHeight: "16px",
  letterSpacing: "0.04em",
  textTransform: "uppercase",
  color: "#969696",
  alignSelf: "center",
});

export const YearsText = styled(Typography)({
  fontWeight: "400",
  fontSize: "13px",
  lineHeight: "20px",
  color: "#969696",
  alignSelf: "center",
});
