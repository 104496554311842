import React, { useMemo, useState } from "react";
import { faPen, faTrashCan } from "@fortawesome/pro-solid-svg-icons";
import { useNavigate } from "react-router-dom";

import {
  useGetRolesQuery,
  useDeleteRoleMutation,
  useGetRoleUsersQuery,
} from "redux/rtk-query";
import { Table } from 'components/Common';
import { ConfirmDialog } from "components/Common/ConfirmDialog";
import { UsersModal } from "components/ModelViews/Users/UsersModal";
import { filterChildren } from "helpers";

import { getCells } from "./config";

export const RolesTable = () => {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [currentRole, setCurrentRole] = useState(false);
  const [deleteRole] = useDeleteRoleMutation();

  const { data, isLoading } = useGetRolesQuery();
  const navigate = useNavigate();

  const totalRows = useMemo(
    () => filterChildren(data?.data || [], search),
    [data?.data, search],
  );
  const rows = useMemo(
    () => totalRows.slice((page - 1) * rowsPerPage, page * rowsPerPage),
    [totalRows, rowsPerPage, page],
  );
  const totalPages = useMemo(
    () => Math.ceil(totalRows.length / rowsPerPage),
    [totalRows.length, rowsPerPage],
  );
  const MenuItems = useMemo(
    () => [
      {
        name: "Edit",
        icon: faPen,
        onClick: ({ id }) => navigate(`/admin/roles/edit/${id}`),
      },
      {
        name: "Delete",
        icon: faTrashCan,
        onClick: ({ id }) => setConfirmOpen(id),
      },
    ],
    [navigate],
  );

  const handleConfirm = () => deleteRole(confirmOpen);
  const getClickHandler = role => () => setCurrentRole(role);

  return (
    <>
      <Table
        handleSearch={setSearch}
        search={search}
        isLoading={isLoading}
        rows={rows}
        totalPages={totalPages}
        menuItems={MenuItems}
        cells={getCells(getClickHandler)}
        page={page}
        rowsPerPage={rowsPerPage}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
        isCollapsible
      />
      {currentRole ? (
        <UsersModal
          currentItem={currentRole}
          setCurrentItem={setCurrentRole}
          useGetList={useGetRoleUsersQuery}
        />
      ) : null}
      <ConfirmDialog
        title="Delete Role?"
        message="Are you sure you want to permanently delete this role?"
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={handleConfirm}
      />
    </>
  );
};

RolesTable.propTypes = {};
