import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types'
import { colors } from "consts"

export function FieldAlert({showRedOutline, ...props}) {
  return (
    <Tooltip
      {...props}
      arrow
      title={props.title}
      PopperProps={{
        popperOptions: {
          modifiers: {
            offset: {
              enabled: true,
              offset: "0, 5px",
            },
          },
        },
      }}
      componentsProps={{
        arrow: {
          sx: {
            color: colors.DARK_RED, // For the arrow itself
            // Additional arrow styling can go here
          },
        },
        tooltip: {
          sx: {
            backgroundColor: colors.DARK_RED, // Or any specific color
            color: 'white',
            fontSize: '11px',
            marginRight: "-1px",
            marginTop: "5px",
            padding: '5px',
          },
        },
      }}
    >
      <span style={{
        width: "100%",
        height: "100%",
        position: "relative",
        display: "block",
      }}>
        {props.children}
        {showRedOutline && props.open && (
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              top: 0,
              outline: "4px solid #c9232d",
            }}
          />
        )}
      </span>
    </Tooltip>
  );
}

FieldAlert.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  children: PropTypes.node.isRequired,
  backgroundColor: PropTypes.string,
  showRedOutline: PropTypes.bool,
};

