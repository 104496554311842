import { Grid, useMediaQuery } from '@mui/material';
import {
  CommissionCard,
  DocumentCard,
  EmailCard,
  MarketingCard,
  NoteCard,
  RolesCard,
  TaskCard,
  TimeframeCard,
} from 'components/ModelViews';
import { RelatedTransactionsCard } from 'components/ModelViews/Transactions/RelatedTransactionsCard';
import { PM_FILE_OPTIONS, STATUS_OPTIONS } from 'consts';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetTransactionQuery } from 'redux/rtk-query';
import PropertyMgmtInvoicesCards from './components/PropertyMgmtInvoicesCards/PropertyMgmtInvoicesCards';

export const TransactionDashboard = () => {
  const isSm = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  let { transactionDetailsId } = useParams();
  const { data: transactionData } = useGetTransactionQuery(
    { id: transactionDetailsId },
    { skip: !transactionDetailsId },
  );

  const transaction = transactionData?.data || {};

  return (
    <Grid container spacing={2}>
      <Grid
        item
        xs={12}
        sm={6}
        sx={{ '& .MuiCard-root:not(:first-of-type)': { marginTop: 2 } }}
      >
        <TimeframeCard startClosed />
        <TaskCard startClosed />
        <MarketingCard startClosed />
        <NoteCard startClosed />
        <EmailCard startClosed />
        <CommissionCard startClosed={isSm} />
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        sx={{ '& .MuiCard-root:not(:first-of-type)': { marginTop: 2 } }}
      >
        {transaction.status === STATUS_OPTIONS.PROPERTY_MGMT.value && (
          <PropertyMgmtInvoicesCards />
        )}
        {(transaction.pmFile ===
          PM_FILE_OPTIONS.LEASE_FOR_PROPERTY_MGMT.value ||
          transaction.status === STATUS_OPTIONS.PROPERTY_MGMT.value) && (
          <RelatedTransactionsCard />
        )}
        <DocumentCard />
        <RolesCard />
      </Grid>
    </Grid>
  );
};
