import { CreateButton } from 'components/Common';
import { LayoutHeader } from 'components/Layouts';
import { ButtonSection, PageMainContent } from 'components/Styled';
import { CREATE_SETTING } from 'consts';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { SettingsTable } from './components';

export const SettingsPage = () => {
  return (
    <>
      <LayoutHeader title={'Settings'}>
        <ButtonSection>
          <CreateButton path={CREATE_SETTING} title="Create Setting" />
        </ButtonSection>
      </LayoutHeader>
      <PageMainContent maxWidth={false} component="main" disableGutters>
        <SettingsTable />
      </PageMainContent>
      <Outlet />
    </>
  );
};
