/* eslint-disable no-dupe-keys */
import { faEye } from "@fortawesome/pro-light-svg-icons";
import { Box } from "@mui/material";
import { styled } from "@mui/system";
import { FontAwesomeIconComp } from "components/Common";

import { HeaderBox } from "components/Styled";
import React from "react";

export const FeatureButtons = styled(HeaderBox)({
  padding: '16px 10px 16px 24px',
  width: 'calc(100% - 16px)',
  zIndex: 2,
  position: 'sticky',
  top: 0,
  backgroundColor: '#F7F7F7',
});

export const CreateContainer = styled('form')({});

export const CreateBox = styled(Box)({
  fontFamily: 'Open Sans',
  '& > form .MuiBox-root .MuiFormControl-root': {
    margin: '8px 0',
  },
  '& > form .MuiGrid-item:not(.MuiGrid-grid-xs-12):not(:last-child) > .MuiBox-root':
    {
      marginRight: 10,
    },
  '& > form .MuiGrid-item.MuiGrid-grid-xs-6:nth-of-type(2n) > .MuiBox-root': {
    // marginRight: 0,
  },
  '& > form .MuiBox-root .MuiFormControl-root .MuiBox-root .MuiFormControl-root':
    {
      margin: 0,
    },
  '& > form .MuiGrid-grid-xs-6 + .MuiGrid-grid-xs-6:not(:first-of-type) .MuiFormControl-root':
    {},
});

export const GridBox = styled(Box)({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
});
export const EyeIcon = () => (
  <FontAwesomeIconComp icon={faEye} style={{ width: '20px', height: '20px' }} />
);
