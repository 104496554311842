import React from "react";

import { ADMIN_ROUTES } from "consts/routes";
import {LayoutHeader} from "components/Layouts";
import { CreateButton } from "components/Common";
import {ButtonSection, PageMainContent} from "components/Styled";

import { UsersTable } from "./UsersTable";

export const UsersPage = () => (
  <>
      <LayoutHeader
          title={"Users"}
      >
          <ButtonSection>
              <CreateButton path={ADMIN_ROUTES.CREATE_USER.path} title="New User" />
          </ButtonSection>
      </LayoutHeader>
      <PageMainContent maxWidth={false} component="main" disableGutters>
      <UsersTable />
    </PageMainContent>
  </>
);
