import { faContactCard } from '@fortawesome/pro-solid-svg-icons';
import { Box } from '@mui/system';
import { PageLink } from 'components/Styled';
import { DETAILS_CAPPING, UPDATE_INVOICE_TEMPLATE_CATEGORY } from 'consts';
import PropTypes from 'prop-types';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const DocumentTemplateName = ({ value }) => {
  return (
    value && (
      <Box display={'inline-flex'}>
        <PageLink to={`/${DETAILS_CAPPING(value.id)}`}>
          <div>{value.name} </div>
        </PageLink>
      </Box>
    )
  );
};

DocumentTemplateName.propTypes = {
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

export const Cells = [
  {
    id: 'name',
    label: 'Name',
    value: ['name', 'id'],
    isCollapsibleCell: true,
    numeric: false,
    disablePadding: true,
    static: true,
    Wrapper: DocumentTemplateName,
  },
];

export const MenuItems = () => {
  const navigate = useNavigate();
  return [
    {
      name: 'Edit',
      icon: faContactCard,
      onClick: (item) => {
        navigate(UPDATE_INVOICE_TEMPLATE_CATEGORY(item.id));
      },
      closeOnClick: true,
    },
  ];
};
