import React from "react";
import PropTypes from "prop-types";
import { Radio } from "@mui/material";

export const RadioFormField = ({
  input: { checked, value, name, onChange, ...otherInput },
  ...other
}) => (
  <Radio
    {...other}
    name={name}
    value={value}
    checked={checked}
    onChange={onChange}
    inputProps={otherInput}
  />
);

RadioFormField.propTypes = {
  input: PropTypes.object,
};
