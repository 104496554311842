import { faCalendar, faTimes } from '@fortawesome/pro-light-svg-icons';
import { faHistory } from '@fortawesome/pro-regular-svg-icons';
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import {
  Box,
  ClickAwayListener,
  Grid,
  IconButton,
  InputAdornment,
  Tooltip,
} from '@mui/material';
import { MobileDatePicker, TimePicker } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {
  FontAwesomeIconComp,
  LabeledOutline,
  Required,
} from 'components/Common';
import { ErrorMsg, TextInputField } from "components/Styled";
import { colors, TASK_STATUSES } from 'consts';
import { addDays, checkForBusinessDay } from 'helpers';
import { usePrevious } from 'hooks';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-final-form';

const getTimeframe = (timeframes, type) => {
  const timeframe = timeframes.filter((timeframe) => timeframe.type === type);
  if (timeframe.length === 0) {
    return { type: type, dateTime: null };
  }
  return timeframe[0];
};

export const TimeframeField = ({
  type,
  required,
  dateOnly,
  noDeadline,
  updateField,
  updatePeriodField,
}) => {
  let form = useForm();

  const timeframes = form.getState().values.timeframes;
  const isRequired = form.getState().submitErrors?.timeframes?.[type] === 'required';
  const timeframe = getTimeframe(timeframes, type);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [period, setPeriod] = useState(
    timeframe.numberOfDays ? timeframe.numberOfDays : null,
  );
  const [selectedDate, setSelectedDate] = useState(
    timeframe.dueOn ? timeframe.dueOn : '',
  );
  const [selectedTime, setSelectedTime] = useState(
    timeframe.dueOn ? timeframe.dueOn : '',
  );
  const [skippedDays, setSkippedDays] = useState(null);

  const prevPeriod = usePrevious(period);
  const prevSelectedDate = usePrevious(selectedDate);

  const contractExecutionDate = form.getState().values.contractExecutionDate;
  const timeframeCalculation = form.getState().values.timeframeCalculation;
  const doNotCalculate =
    timeframeCalculation &&
    (timeframeCalculation.timeframeCalculation === 'Custom' ||
      !timeframeCalculation.timeframeCalculation);

  const removeTimeframeType = (type) => {
    let timeframesCopy = [...timeframes];
    const checkForType = timeframesCopy.filter(
      (timeframe) => timeframe.type !== type,
    );
    if (timeframe.taskId) {
      // TODO: DELETE TIMEFRAMES ON SAVE
    }
    form.change('timeframes', checkForType);
  };

  const handlePeriodChange = useCallback(
    (e) => {
      if (e.target.value >= 0) {
        setPeriod(e.target.value);
        if (updatePeriodField) {
          form.change(updatePeriodField, e.target.value);
        }
        if (skippedDays?.length >= 0) {
          setTooltipOpen(true);
        }
      }
    },
    [skippedDays],
  );

  const handleDateChange = (e) => {
    if (e) {
      const dateTime = DateTime.fromMillis(e.ts);
      const dateTimeValue = dateTime.toFormat('yyyy-MM-dd HH:mm');
      setSelectedDate(dateTimeValue + ':00');
      if (doNotCalculate === false) {
        let daysAlert = checkForBusinessDay(dateTimeValue + ':00');
        setSkippedDays(daysAlert);
        if (daysAlert.length >= 0) {
          setTooltipOpen(true);
        }
      }
    } else {
      setSelectedDate(null);
    }
    if (doNotCalculate === false) {
      setPeriod(null);
    }
  };
  useEffect(() => {
    if (timeframe.dueOn && timeframe.dueOn !== selectedDate) {
      setSelectedDate(timeframe.dueOn);
    }
    if (timeframe.dueOn && timeframe.dueOn !== selectedTime) {
      setSelectedTime(timeframe.dueOn);
    }
  }, [timeframe.dueOn]);

  useEffect(() => {
    if (
      parseInt(period) >= 0 &&
      (timeframe.numberOfDays !== period || !skippedDays)
    ) {
      if (doNotCalculate === false && contractExecutionDate) {
        let periodNum = parseInt(period);
        const dateTime = DateTime.fromISO(contractExecutionDate).setZone();
        let formatted = contractExecutionDate;
        if (!dateTime.invalid) {
          formatted = dateTime.toFormat('MM/dd/yyyy');
        }
        let endDate = addDays(
          formatted,
          periodNum,
          timeframeCalculation.timeframeCalculation,
        );
        let newDate = endDate.newDate
          ? endDate.newDate.toISOString()
          : endDate.toISOString();
        let skippedDays = endDate.skippedDays ? endDate.skippedDays : [];
        newDate = newDate.slice(0, 16).replace('T', ' ');
        setSelectedDate(newDate + ':00');
        setSkippedDays(skippedDays);
      }
    }
  }, [period, contractExecutionDate]);

  useEffect(() => {
    if (prevPeriod !== period) {
      let timeframesCopy = [...timeframes];
      timeframesCopy.map((timeframeCopy) => {
        if (timeframeCopy.type === timeframe.type) {
          timeframeCopy.numberOfDays = parseInt(period);
          timeframeCopy.noDeadline = noDeadline;
        }
      });
      // form.change('timeframes', timeframesCopy);
    }
  }, [period, timeframes]);

  // useEffect(() => {
  //
  // }, [selectedDate]);

  const prevSelectedTime = usePrevious(selectedTime);

  // useEffect(() => {
  //     if(selectedTime && prevSelectedTime !== selectedTime && selectedTime !== "Invalid DateTime"){
  //         let timeframesCopy = [...timeframes]
  //         timeframesCopy.map((timeframeCopy) => {
  //             if (selectedDate && timeframeCopy.type === timeframe.type) {
  //                 let replacedDate = selectedDate.split(" ");
  //                 let replacedTime = selectedTime.split(" ");
  //                 let newDateTime = replacedDate[0] + " " + replacedTime[1];
  //                 timeframeCopy.dueOn = newDateTime;
  //                 form.change("timeframes",timeframesCopy)
  //             }
  //         })
  //     }
  // }, [selectedTime, selectedDate, prevSelectedTime]);

  useEffect(() => {
    if (prevSelectedDate !== selectedDate) {
      // ...

      if (prevSelectedDate !== selectedDate) {
        let timeframesCopy = [...timeframes];
        timeframesCopy.map((timeframeCopy) => {
          if (timeframeCopy.type === timeframe.type) {
            timeframeCopy.dueOn = selectedDate;
            timeframeCopy.noDeadline = noDeadline;
            if (updateField) {
              if (selectedDate) {
                let replacedDate = selectedDate.split(' ');
                replacedDate = replacedDate[0].replaceAll('-', '/');
                let timeframeDateTime = new Date(replacedDate);
                const year = timeframeDateTime.getFullYear();
                const month = String(timeframeDateTime.getMonth() + 1);
                const day = String(timeframeDateTime.getDate());
                const joined = [month, day, year].join('/');
                form.change(updateField, joined);
              } else {
                form.change(updateField, null);
              }
            }
            if (!prevSelectedDate && selectedDate && !selectedTime) {
              let now = DateTime.now();
              let nowValue = now.toFormat('yyyy-MM-dd HH:mm:ss');
              let nowDate = nowValue.split(' ');
              let newTime = nowDate[0] + '11:59:00';
              setSelectedTime(newTime);
            } else {
              if (!selectedDate) {
                setSelectedTime(null);
              }
            }
            form.change('timeframes', timeframesCopy);
          }
        });
      } else {
        if (
          selectedTime &&
          prevSelectedTime !== selectedTime &&
          selectedTime !== 'Invalid DateTime'
        ) {
          let timeframesCopy = [...timeframes];
          timeframesCopy.map((timeframeCopy) => {
            if (selectedDate && timeframeCopy.type === timeframe.type) {
              let replacedDate = selectedDate.split(' ');
              let replacedTime = selectedTime.split(' ');
              let newDateTime = replacedDate[0] + ' ' + replacedTime[1];
              timeframeCopy.dueOn = newDateTime;
              form.change('timeframes', timeframesCopy);
            }
          });
        }
      }
    }
  }, [selectedDate, prevSelectedDate, selectedTime, prevSelectedTime]);

  const hasError = () => {
    if (form.getState().hasSubmitErrors && form.getState().submitErrors) {
      return form.getState().submitErrors[`timeframes.${timeframe.type}`];
    }
    return false;
  };
  const dateTimeValue = selectedDate ? selectedDate : null;
  const initialDateTime = new Date().setHours(23, 59, 59, 0);
  const isCompleted = timeframe.status === TASK_STATUSES.COMPLETED;

  const LastColumn = () => {
    const color = isCompleted ? 'green' : '#c9232d';
    const fontSize = isCompleted ? 18 : required ? 10 : 14;

    return (
      <span
        style={{
          marginRight: 10,
          alignItems: 'center',
          textAlign: 'center',
          display: 'flex',
          color: color,
          fontSize: fontSize,
        }}
      >
        {isCompleted ? (
          <FontAwesomeIconComp color={colors.GREEN} icon={faCircleCheck} />
        ) : required ? (
          <></>
        ) : (
          <IconButton
            size={'small'}
            onClick={() => removeTimeframeType(type)}
            style={{ color: colors.RED }}
          >
            <FontAwesomeIconComp icon={faTimes} />
          </IconButton>
        )}
      </span>
    );
  };
  const timeframeTooltip = useMemo(() => {
    if (skippedDays?.length > 0) {
      const handleClose = () => {
        setTooltipOpen(false);
      };

      const handleOpen = () => {
        setTooltipOpen(true);
      };

      const getMessage = () => {
        const getFirstChunk = () => {
          if (parseInt(period) > 0 && firstDay[0] !== 'Business') {
            return (
              <li>
                {period} Calendar days ends on {firstDay[0]}
              </li>
            );
          } else {
            return <></>;
          }
        };

        const getMiddleChunk = () => {
          return skippedDays?.map((skippedDay, index) => {
            return <li key={index}>{skippedDay}</li>;
          });
        };

        const getLastChunk = () => {
          if (parseInt(period) > 0) {
            return (
              <li>
                Adjusted to{' '}
                {`${
                  timeframeDateTime.getMonth() + 1
                }/${timeframeDateTime.getDate()}`}
              </li>
            );
          } else {
            return <></>;
          }
        };

        return (
          <>
            {getFirstChunk()}
            {getMiddleChunk()}
            {getLastChunk()}
          </>
        );
      };

      let timeframeDateTime = new Date(dateTimeValue);
      let firstDay = skippedDays[0].split(' ');
      let tooltipText = (
        <div>
          <ul
            style={{
              paddingInlineStart: 15,
              padding: 0,
              margin: '2px 0px 2px 10px',
            }}
          >
            {getMessage()}
          </ul>
        </div>
      );
      return (
        <>
          {skippedDays?.length > 0 && (
            <ClickAwayListener
              onClickAway={handleClose}
              mouseEvent={'onMouseDown'}
            >
              <Tooltip
                placement={'bottom-end'}
                title={tooltipText}
                open={tooltipOpen}
                onClose={handleClose}
                onOpen={handleOpen}
              >
                <span
                  style={{
                    position: 'absolute',
                    right: 10,
                    top: 8,
                    fontSize: 17,
                    zIndex: 9,
                    color: '#f6c244',
                  }}
                >
                  <div>
                    <span
                      onMouseDown={(event) => {
                        event.preventDefault();
                      }}
                    >
                      <FontAwesomeIconComp icon={faHistory} />
                    </span>
                  </div>
                </span>
              </Tooltip>
            </ClickAwayListener>
          )}
        </>
      );
    }
  }, [timeframe.dueOn, skippedDays, setTooltipOpen, tooltipOpen]);
  const handleTimeChange = (value) => {
    if (value) {
      const formatted = value.toFormat('yyyy-MM-dd HH:mm:ss');
      setSelectedTime(formatted);
    }
  };

  return (
    <>
      {(contractExecutionDate || dateOnly) && (
        <Grid
          className={`${isCompleted ? 'completedTimeframe' : ''}`}
          container
        >
          <Grid item xs={12}>
            <LabeledOutline
              error={isRequired}
              label={
                <>
                  {type} {required && !timeframe.dueOn ? <Required /> : ''}
                </>
              }
            >
              <Box display={'flex'}>
                <div
                  style={{
                    width: 70,
                    alignSelf: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    opacity: isCompleted ? 0.5 : 1,
                    pointerEvents: isCompleted ? 'none' : 'auto',
                  }}
                >
                  {!dateOnly ? (
                    <>
                      <TextInputField
                        value={period || ''}
                        variant={'standard'}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        style={{
                          display: 'inline-block',
                          margin: 0,
                        }}
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                        readOnly={isCompleted}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position={'end'}
                              disableTypography
                              style={{ marginLeft: 3, fontSize: 12 }}
                            >
                              days
                            </InputAdornment>
                          ),
                          readOnly: isCompleted,
                          style: {
                            height: 43,
                            marginLeft: 8,
                            marginRight: 5,
                          },
                          inputProps: {
                            style: {
                              fontSize: 12,
                              padding: '10px 0',
                              textAlign: 'right',
                            },
                          },
                        }}
                        onChange={handlePeriodChange}
                      />
                    </>
                  ) : (
                    <IconButton
                      onClick={() => {
                        setIsCalendarOpen(true);
                      }}
                      size={'small'}
                    >
                      <FontAwesomeIconComp icon={faCalendar} />
                    </IconButton>
                  )}
                </div>
                <LocalizationProvider dateAdapter={AdapterLuxon}>
                  <div
                    style={{
                      position: 'relative',
                      width: '90%',
                      display: 'inline-block',
                      opacity: isCompleted ? 0.5 : 1,
                      pointerEvents: isCompleted ? 'none' : 'auto',
                    }}
                  >
                    <MobileDatePicker
                      DialogProps={{
                        style: { zIndex: 1505 },
                      }}
                      open={isCalendarOpen}
                      onOpen={() => {
                        setIsCalendarOpen(true);
                      }}
                      onClose={() => {
                        setIsCalendarOpen(false);
                      }}
                      showTodayButton
                      clearable
                      className={'date-time-picker'}
                      // value={selectedDate}
                      onKeyPress={(event) => {
                        if (event.key === 'Enter') {
                          event.preventDefault();
                        }
                      }}
                      fullWidth
                      readOnly={
                        (doNotCalculate === false &&
                          !!period &&
                          !parseInt(timeframe.taskId) > 0) ||
                        isCompleted
                      }
                      initialFocusedDate={initialDateTime}
                      format={dateOnly ? 'MM/dd/yyyy' : 'MM/dd/yyyy'}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={dateTimeValue}
                      onChange={handleDateChange}
                      error={hasError()}
                      renderInput={(params) => (
                        <TextInputField
                          {...params}
                          placeholder={'Due On'}
                          variant={'standard'}
                          style={{ margin: 0, display: 'inline-block' }}
                          inputProps={{
                            ...params.inputProps,
                            style: {
                              width: 90,
                              fontSize: 16,
                              padding: '0px 15px',
                              margin: '10px 0',
                              borderLeft: '1px solid lightgray',
                            },
                          }}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: null,
                          }}
                        />
                      )}
                    />
                    {!dateOnly && (
                      <TimePicker
                        disableOpenPicker
                        value={selectedTime}
                        onChange={handleTimeChange}
                        renderInput={(params) => (
                          <TextInputField
                            {...params}
                            style={{
                              display: 'inline-block',
                              right: 8,
                              margin: 0,
                              width: 100,
                            }}
                            variant={'standard'}
                            inputProps={{
                              ...params.inputProps,
                              style: {
                                fontSize: 16,
                                paddingTop: 5,
                                paddingBottom: 5,
                                paddingLeft: 10,
                                margin: '5px 0',
                              },
                            }}
                          />
                        )}
                      />
                    )}
                    {skippedDays?.length > 0 && timeframeTooltip}
                  </div>
                </LocalizationProvider>
                <LastColumn />
                {isRequired && <ErrorMsg component={'span'}>Required</ErrorMsg>}
              </Box>
            </LabeledOutline>
          </Grid>
        </Grid>
      )}
    </>
  );
};

TimeframeField.propTypes = {
  type: PropTypes.string,
  required: PropTypes.bool,
  dateOnly: PropTypes.bool,
  noDeadline: PropTypes.bool,
  updateField: PropTypes.string,
  updatePeriodField: PropTypes.string,
};
