import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { IconButton } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { FontAwesomeIconComp, Required, SideDrawer } from 'components/Common';
import { FieldTooltip } from 'components/Fields';
import { ErrorMsg, TextInputField } from 'components/Styled';
import { conversion, getError, hasValue } from 'helpers';
import { useIsRequired } from 'hooks';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Field, useForm } from 'react-final-form';

export const ModalSelectField = ({
  readOnly,
  notClearable,
  name,
  ModalContent,
  modalProps = {},
  label,
  startAdornment,
  endAdornment,
  onTableRowClick,
  tooltip,
}) => {
  const [showModalList, setShowModalList] = useState(false);
  const form = useForm();
  const getStartAdornment = () => {
    return startAdornment ? (
      <InputAdornment position="start">{startAdornment}</InputAdornment>
    ) : (
      ''
    );
  };
  const getEndAdornment = (input) => {
    let hasValue =
      input.value && input.value.id && input.value.id.toString().length > 0;
    let clearIcon =
      hasValue && !notClearable ? (
        <span>
          <IconButton
            onClick={() => {
              input.onChange({ id: '', name: '' });
              form.mutators.setFieldData(input.name, { tooltip: false });
            }}
            onMouseDown={(event) => {
              event.preventDefault();
            }}
            edge="end"
          >
            <FontAwesomeIconComp icon={faTimes} color={'#c9232d'} size={'xs'} />
          </IconButton>
        </span>
      ) : (
        <></>
      );
    return (
      <InputAdornment position="end">
        {endAdornment ? (
          <>
            {endAdornment}
            {clearIcon}
          </>
        ) : (
          <>{clearIcon}</>
        )}
      </InputAdornment>
    );
  };

  const onRowClick = (row) => {
    onTableRowClick(row);
    setShowModalList(false);
  };

  const fieldLabel = (input) => {
    let isFilled = hasValue(input.value);
    let required = useIsRequired(input.name) && !isFilled;
    return (
      <>
        {label}
        {required ? <Required /> : ''}
      </>
    );
  };

  return (
    <>
      <Field name={name} label={label}>
        {(props) => {
          const error = getError(props.meta);
          return (
            <div style={{ position: 'relative' }}>
              <TextInputField
                {...props.input}
                label={fieldLabel(props.input)}
                inputProps={{ 'data-lpignore': 'true' }}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    event.preventDefault();
                  }
                }}
                InputProps={{
                  readOnly: true,
                  endAdornment: getEndAdornment(props.input),
                  inputProps: {
                    onClick: (e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      !readOnly ? setShowModalList(true) : '';
                    },
                  },
                }}
                // InputLabelProps={{ shrink: props.input.value.name && props.input.value.name !== "(Unnamed)" }}
                fullWidth
                size={'small'}
                value={
                  props.input.value.name ||
                  conversion.getName(props.input.value)
                }
                variant={'outlined'}
                error={Boolean(error && error.length > 0)}
              />
              {getStartAdornment()}
              {tooltip && <FieldTooltip tooltip={tooltip} />}
              {error && <ErrorMsg component={'span'}>{error}</ErrorMsg>}
            </div>
          );
        }}
      </Field>
      <SideDrawer
        width={'700px'}
        isOpen={showModalList}
        closeOnClickAway={() => {
          setShowModalList(!showModalList);
        }}
      >
        <ModalContent {...modalProps} onRowClick={onRowClick} />
      </SideDrawer>
    </>
  );
};

ModalSelectField.propTypes = {
  readOnly: PropTypes.bool,
  notClearable: PropTypes.bool,
  title: PropTypes.string,

  name: PropTypes.string,
  emptyText: PropTypes.string,
  startText: PropTypes.string,
  ModalContent: PropTypes.elementType,
  label: PropTypes.string,
  meta: PropTypes.object,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  startAdornment: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  input: PropTypes.object,
  endAdornment: PropTypes.object,
  handleCreateClick: PropTypes.func,
  onTableRowClick: PropTypes.func,
  modalProps: PropTypes.object,
  tooltip: PropTypes.object,
};
