export const endpoints = {
  contactList: "contact-list",
  contacts: "contact",
  documents: "documents",
  documentTemplates: "document-templates",
  categories: "document-templates/category",
  notes: "note",
  tasks: "task",
  transactions: "transaction",
  companies: "contact/company",
  tag: "tag",
  vendorType: "vendor-type",
  settings: "setting",
  cappings: "capping",
  commissions: "commission",
  invoices: "invoices",
  invoiceTemplates: "invoice-templates",
  invoiceCategories: "invoice-templates/category",

  roles: "role",
  rolesList: "role/hierarchy",

  users: "user",
  groups: "group",
  profile: "user/profile",
  languagesList: "user/language/list",
  localesList: "user/locale/list",
  timezonesList: "user/timezone/list",
  currenciesList: "user/currency/list",
  changePassword: "user/change-password",
  recentlyViewed: "recently-viewed",

  companyUnitsList: "company-structure/hierarchy",
  companyUnit: "company-structure/unit",
};
