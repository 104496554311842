import React from 'react';
import { useLocation } from 'react-router-dom';

function camelToSentenceCase(str) {
  return str
    // Insert a space before all found capital letters
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    // Capitalize the first character of the resulting string
    .replace(/^./, str => str.toUpperCase());
}

function generateTitleFromPath(path) {
  return "ABE - " + path.split('/')
    .filter(segment => segment) // Filter out any empty segments
    .map(segment => camelToSentenceCase(segment)) // Convert camelCase to Sentence case
    .join(' - '); // Join them with ' - '
}

export default function usePageTitle() {
  const location = useLocation();

  React.useEffect(() => {
    document.title = generateTitleFromPath(location.pathname);
  }, [location]);
}
