import { styled } from "@mui/system";
import { FormControl, InputLabel } from "@mui/material";

export const FormControlStyled = styled(FormControl)({
  marginBottom: "24px",
  width: "100%",
  background: "#FFFFFF",
  border: "1px solid #E8E8E8",
  borderRadius: "8px",
});

export const Label = styled(InputLabel)({
  fontWeight: "500",
  color: "#565656",
});
