import React from "react";
import {useField} from "react-final-form";
import PropTypes from "prop-types";
import {RolesField} from "../RolesField";
import {REPRESENTING_OPTIONS, STATUS_OPTIONS} from "consts";


export const OpposingCustomerRolesField = ({
                                       ...props
                                   }) => {
    let representing = useField("representing");
    let status = useField("status");
    let mgmtFile = useField("pmFile");
    let representingValue = representing.input.value ? representing.input.value : "";
    let statusValue = status.input.value ? status.input.value : "";
    let mgmtFileValue = mgmtFile.input.value ? mgmtFile.input.value : "";

    const getActualRole = () => {
        if(statusValue !== STATUS_OPTIONS.PROPERTY_MGMT.value) {
            if (representingValue === REPRESENTING_OPTIONS.BUYER.value) {
                return "Seller"
            } else if (representingValue ===  REPRESENTING_OPTIONS.SELLER.value) {
                return "Buyer"
            } else if (representingValue === REPRESENTING_OPTIONS.LANDLORD.value && mgmtFileValue !== "Property Mgmt" && mgmtFileValue !== "") {
                return "Tenant"
            } else if (representingValue === REPRESENTING_OPTIONS.TENANT.value) {
                return "Landlord"
            }
        }
        return false;
    }


    return (
        <>
            {getActualRole() && <RolesField
                {...props}
                textOnly
                role={getActualRole()}
            />}
        </>
    );
}

OpposingCustomerRolesField.propTypes = {
    customerType: PropTypes.string,
    createType: PropTypes.string,
};


