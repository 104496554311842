import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";

import { Table } from 'components/Common';
import { useGetAuditTrailQuery } from "redux/rtk-query";

import { Cells } from "./config";

const RowsPerPageOptions = [15, 25, 50];

export const AuditLog = ({ userId }) => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(15);

  const { data, isLoading } = useGetAuditTrailQuery({
    uuid: userId,
    page,
    limit,
  });

  const rows = useMemo(() => data?.data?.items ?? [], [data?.data]);
  const totalPages = useMemo(() => data?.data?.meta?.totalPages, [data?.data]);

  return (
    <Table
      withSearch={false}
      isLoading={isLoading}
      rows={rows}
      totalPages={totalPages}
      menuItems={null}
      cells={Cells}
      order={"asc"}
      orderBy={""}
      page={page}
      rowsPerPage={limit}
      rowsPerPageOptions={RowsPerPageOptions}
      setPage={setPage}
      setRowsPerPage={setLimit}
    />
  );
};

AuditLog.propTypes = {
  userId: PropTypes.string,
};
