import React from 'react';
import { FileMenuGroup, AddItemsMenuGroup } from '../menuItems';
import { MenuItemButton } from "components/Common";
import {
  faAlignCenter,
  faFile,
  faHistory,
  faUser,
} from '@fortawesome/pro-light-svg-icons';
import { Divider } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setMenu } from "redux/slices";
import { FontAwesomeIconComp } from "components/Common";
import { isTemplateViewSelector } from "redux/selectors";

export const PrimaryMenu = () => {
  const dispatch = useDispatch();
  const isTemplateView = useSelector(isTemplateViewSelector);

  return (
    <>
      <FileMenuGroup />
      <Divider />
      <AddItemsMenuGroup />
      <Divider />
      <MenuItemButton
        icon={<FontAwesomeIconComp fontSize={18} icon={faUser} />}
        label={'Roles'}
        onClick={() => {
          dispatch(setMenu('roles'));
        }}
      />
      <Divider />
      {!isTemplateView && (
        <>
          <MenuItemButton
            icon={<FontAwesomeIconComp fontSize={18} icon={faAlignCenter} />}
            label={'Field Manager'}
            onClick={() => {
              dispatch(setMenu('fieldManager'));
            }}
          />
          <Divider />
          <MenuItemButton
            icon={<FontAwesomeIconComp fontSize={18} icon={faFile} />}
            label={'Page Manager'}
            onClick={() => {}}
          />
          <Divider />
          <MenuItemButton
            icon={<FontAwesomeIconComp fontSize={18} icon={faHistory} />}
            label={'Revision History'}
            onClick={() => {}}
          />
          <Divider />
        </>
      )}
    </>
  );
};
