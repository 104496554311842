import React from 'react';
import PropTypes from 'prop-types';
import { MainContent } from './styled';
import { Skeleton } from '@mui/material';
import { Box } from '@mui/system';

export const SkeletonSummary = () => {
  return (
    <MainContent>
      <Box>
        <Skeleton width={200} />
      </Box>
      <Skeleton width={'100%'} />
      <Skeleton width={'100%'} />
    </MainContent>
  );
};

SkeletonSummary.propTypes = {
  note: PropTypes.object,
};
