import React from "react";
import { Field, useForm } from "react-final-form";
import { Grid } from "@mui/material";
import { FormPanel } from "components/Common";
import { STATUS_OPTIONS } from "consts";
import { CurrencyInput } from 'components/Fields';

export const ListingPanel = () => {
  let form = useForm();
  let formValues = form.getState();

  const shouldShow = () => {
    if (
      formValues.values.status !== "Property Mgmt" &&
      formValues.values.pmFile !== "Property Mgmt" &&
      (formValues.values.representing?.includes("Seller") ||
        formValues.values.representing?.includes("Landlord"))
    ) {
      return true;
    }
    return false;
  };

  return (
    <>
      {shouldShow() && (
        <FormPanel label={"Listing"}>
          <Grid item xs={12}>
            <Field
              label={
                form.getState().values.status === STATUS_OPTIONS.DRAFT.value
                  ? "Est. List Price"
                  : "List Price"
              }
              name={"listPrice"}
              component={CurrencyInput}
            />
          </Grid>
          {form.getState().values.origListPrice !==
            form.getState().values.listPrice && (
            <>
              <Grid item xs={12}>
                <Field
                  label="Orig List Price"
                  name={"origListPrice"}
                  component={CurrencyInput}
                />
              </Grid>
            </>
          )}
        </FormPanel>
      )}
    </>
  );
};

ListingPanel.propTypes = {
    
};


