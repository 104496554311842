import _ from "lodash";

const filter = (array, search) => {
  return _.filter(array, o => {
    if (o.children.length) {
      const filteredItems = filter(o.children, search);
      return filteredItems.length
        ? filteredItems
        : o.name.toLowerCase().includes(search.toLowerCase());
    } else {
      return o.name.toLowerCase().includes(search.toLowerCase());
    }
  });
};

export const filterMap = (array, search, first = false) => {
  return filter(array, search, first).flatMap(item => {
    return { ...item, children: filterMap(item.children, search) };
  });
};

export const filterChildren = (array, search) => {
  return filterMap(array, search, true);
};
