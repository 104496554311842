import { Autocomplete } from 'components/Fields/Autocomplete';
import { conversion } from 'helpers';
import { useLoadMoreOptions } from 'hooks';
import PropTypes from 'prop-types';
import React from 'react';
import { useGetUsersQuery } from 'redux/rtk-query';

const getUserOption = ({ id, ...rest }) => ({
  value: id,
  label: conversion.getName(rest),
  id: id,
  key: id,
});

export const UserAutocomplete = (props) => {
  const users = useLoadMoreOptions(useGetUsersQuery, getUserOption);

  return (
    <Autocomplete
      {...props}
      options={users.options}
      size={'small'}
      onOpen={users.handleOpen}
      loadMoreResults={users.handleMore}
      isLoading={users.isFetching}
      handleSearch={users.handleSearch}
    />
  );
};

UserAutocomplete.defaultProps = {
  freeSolo: false,
  onOpen: null,
  isLoading: false,
};

UserAutocomplete.propTypes = {
  label: PropTypes.string,
  size: PropTypes.string,
  freeSolo: PropTypes.bool,
  isLoading: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    }),
  ),
  iconStart: PropTypes.node,
  input: PropTypes.object,
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
  }),
  loadMoreResults: PropTypes.func,
  onOpen: PropTypes.func,
  handleSearch: PropTypes.func,
};
