import { FormPanel } from 'components/Common';
import { AddressBox } from 'components/Fields';
import React from 'react';
import { Field } from 'react-final-form';

export const PrimaryAddressBox = () => (
  <FormPanel label={'Primary Address'}>
    <Field name={'primaryAddress'} component={AddressBox} />
  </FormPanel>
);

PrimaryAddressBox.propTypes = {};
