import { Grid } from '@mui/material';
import { LayoutHeader } from 'components/Layouts';
import PropTypes from 'prop-types';
import React from 'react';

export const DetailsLayout = ({
  header,
  leftContent,
  rightContent,
  equalWidth = false,
}) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <LayoutHeader title={header} />
      </Grid>
      <Grid
        item
        sx={{
          paddingRight: { xs: 0, sm: 2 },
          paddingBottom: { xs: 2, sm: 0 },
          position: 'relative',
        }}
        xs={12}
        sm={equalWidth ? 4 : 3}
        position={'relative'}
      >
        {leftContent}
      </Grid>
      <Grid item xs={12} sm={equalWidth ? 8 : 9}>
        {rightContent}
      </Grid>
    </Grid>
  );
};

DetailsLayout.propTypes = {
  header: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  leftContent: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  rightContent: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  equalWidth: PropTypes.bool,
};
