import React, { memo } from "react";
import { Field } from "react-final-form";
import PropTypes from "prop-types";

import { ErrorMsg, CreateContainer } from "components/Styled";
import { PasswordInput } from "components/Fields";

import { PasswordBox } from "./styled";
import {Button} from "@mui/material";

const PasswordForm = ({ handleSubmit, submitError, isLoading }) => {
  return (
    <CreateContainer action="submit" onSubmit={handleSubmit}>
      <PasswordBox>
        <Field name="password" label="Password" component={PasswordInput} />
        <Field
          name="confirm"
          label="Confirm Password"
          component={PasswordInput}
        />
      </PasswordBox>
      <Button variant="contained" type="submit" disabled={isLoading}>
        Change Password
      </Button>
      {submitError && <ErrorMsg component={"span"}>{submitError}</ErrorMsg>}
    </CreateContainer>
  );
};

PasswordForm.propTypes = {
  submitError: PropTypes.string,
  handleSubmit: PropTypes.func,
  dirty: PropTypes.bool,
  isLoading: PropTypes.bool,
};

const propsAreEqual = (prevProps, nextProps) => {
  const prevState = JSON.stringify(prevProps.form?.getState());
  const nextState = JSON.stringify(nextProps.form?.getState());
  const prevValues = JSON.stringify(prevProps.values);
  const nextValues = JSON.stringify(nextProps.values);

  return prevState === nextState && prevValues === nextValues;
};

export default memo(PasswordForm, propsAreEqual);
