import { Box } from '@mui/system';
import { STATUSES } from 'consts';
import { nanoid } from 'nanoid';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { useDispatch, useSelector } from 'react-redux';
import {
  useGetDocumentPdfUrlQuery,
  useGetDocumentTemplatePdfUrlQuery,
  useGetTransactionQuery,
} from 'redux/rtk-query';
import {
  currentDocumentSelector,
  isPreviewModeSelector,
  placingItemSelector,
  scaleSelector,
} from 'redux/selectors';
import {
  redo,
  setActiveField,
  setAutofillData,
  setDocumentData,
  setNumberOfPages,
  setScale,
  undo,
} from 'redux/slices';
import { DocumentItem, LoadingOverlay, PlacingField } from './components';
import { useAutofillData } from './hooks/useAutofillData';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url,
).toString();

const debounce = (func, delay) => {
  let inDebounce;
  return function () {
    const context = this;
    const args = arguments;
    clearTimeout(inDebounce);
    inDebounce = setTimeout(() => func.apply(context, args), delay);
  };
};

export function DocumentPdfViewer({
  documentData,
  templateView = false,
  preview = false,
}) {
  const [pdfUrl, setPdfUrl] = useState(null);

  const scale = useSelector(scaleSelector);
  const isPreviewMode = useSelector(isPreviewModeSelector) || preview;
  const currentDocument = useSelector(currentDocumentSelector);
  const placingItem = useSelector(placingItemSelector);

  const documentPdfUrlQuery = useGetDocumentPdfUrlQuery(
    {
      id: documentData.id,
    },
    {
      skip: templateView,
    },
  );

  const { data: transactionData } = useGetTransactionQuery(
    { id: documentData?.transaction?.id },
    { skip: !documentData?.transaction?.id },
  );

  const templatePdfUrlQuery = useGetDocumentTemplatePdfUrlQuery(
    {
      id: documentData.id,
    },
    {
      skip: !templateView,
    },
  );

  const autofillData = useAutofillData(currentDocument);

  useEffect(() => {
    if (documentPdfUrlQuery?.data?.data) {
      const url =
        documentPdfUrlQuery?.data?.data?.files?.[0]?.url ||
        documentPdfUrlQuery?.data?.data?.documentTemplateFiles?.[0]?.url ||
        documentPdfUrlQuery?.data?.data?.aliasFiles?.[0]?.url;

      setPdfUrl(url);
    }
    if (templatePdfUrlQuery?.data?.data) {
      const url =
        templatePdfUrlQuery?.data?.data?.files?.[0]?.url ||
        templatePdfUrlQuery?.data?.data?.aliasFiles?.[0]?.url;

      setPdfUrl(url);
    }
  }, [documentPdfUrlQuery, templatePdfUrlQuery]);

  const containerRef = useRef(null);

  const [numPages, setNumPages] = useState(null);
  const dispatch = useDispatch();

  const [isDragging, setIsDragging] = useState(false);
  const [selectionPage, setSelectionPage] = useState(null);
  const [selectionStart, setSelectionStart] = useState(null);
  const [selectionEnd, setSelectionEnd] = useState(null);

  const doesIntersect = (selectionBox, adjustedRect) => {
    return !(
      selectionBox.right < adjustedRect.left ||
      selectionBox.left > adjustedRect.right ||
      selectionBox.bottom < adjustedRect.top ||
      selectionBox.top > adjustedRect.bottom
    );
  };

  const handleMouseDown = (e) => {
    if (
      e.button === 2 ||
      e.target.closest('.moveHandle') ||
      e.target.closest('.resizeHandleWrapper') ||
      e.target.closest('.textIndentResizeHandle')
    ) {
      setIsDragging(true);
      return;
    }

    if (e.key === 'Shift') {
      e.preventDefault();
    }

    if (e.target.closest('.page-wrapper')) {
      setSelectionPage(
        e.target.closest('.page-wrapper').getAttribute('data-page-number'),
      );
    }
    const rect = containerRef.current.getBoundingClientRect();
    setSelectionStart({ x: e.clientX - rect.left, y: e.clientY - rect.top });
    setSelectionEnd({ x: e.clientX - rect.left, y: e.clientY - rect.top });
    setIsDragging(true);
  };

  const handleMouseMove = (e) => {
    if (
      isDragging &&
      selectionStart &&
      containerRef.current.contains(e.target)
    ) {
      const rect = containerRef.current.getBoundingClientRect();
      setSelectionEnd({ x: e.clientX - rect.left, y: e.clientY - rect.top });
    }
  };

  const handleMouseUp = (e) => {
    if (
      !e.shiftKey &&
      isDragging &&
      selectionStart &&
      selectionEnd &&
      selectionStart.x === selectionEnd.x &&
      selectionStart.y === selectionEnd.y
    ) {
      // It's just a click, not a drag, so deselect all fields
      dispatch(setActiveField([]));
    } else if (
      !e.shiftKey &&
      isDragging &&
      selectionStart &&
      selectionEnd &&
      selectionPage
    ) {
      const selectionBox = {
        left: Math.min(selectionStart.x, selectionEnd.x),
        right: Math.max(selectionStart.x, selectionEnd.x),
        top: Math.min(selectionStart.y, selectionEnd.y),
        bottom: Math.max(selectionStart.y, selectionEnd.y),
      };

      // Get all field elements within the current page
      const pageElement = document.querySelector(
        `[data-page-number="${selectionPage}"]`,
      );
      const fieldElements = pageElement
        ? pageElement.querySelectorAll('[data-field-id]')
        : [];

      const selectedFieldIds = Array.from(fieldElements)
        .filter((el) => {
          const rect = el.getBoundingClientRect();
          // Calculate the adjusted position of the element relative to the selection area
          // Assuming the containerRef's scroll and scale are considered in these calculations
          const adjustedRect = {
            left:
              (rect.left -
                containerRef.current.getBoundingClientRect().left +
                containerRef.current.scrollLeft) /
              scale,
            right:
              (rect.right -
                containerRef.current.getBoundingClientRect().left +
                containerRef.current.scrollLeft) /
              scale,
            top:
              (rect.top -
                containerRef.current.getBoundingClientRect().top +
                containerRef.current.scrollTop) /
              scale,
            bottom:
              (rect.bottom -
                containerRef.current.getBoundingClientRect().top +
                containerRef.current.scrollTop) /
              scale,
          };

          Object.keys(adjustedRect).forEach(
            (key) => (adjustedRect[key] /= scale),
          );

          // Check intersection with the selection box
          return doesIntersect(selectionBox, adjustedRect);
        })
        .map((el) => el.getAttribute('data-field-id'));

      dispatch(setActiveField(selectedFieldIds));
    }

    // Regardless of whether it was a click or drag, reset all states
    setSelectionStart(null);
    setSelectionEnd(null);
    setSelectionPage(null);
    setIsDragging(false);
  };

  // Attach mouse event listeners to the container
  useEffect(() => {
    const container = containerRef.current;
    if (container && container.closest('.react-pdf__Document')) {
      container
        .closest('.react-pdf__Document')
        .addEventListener('mousedown', handleMouseDown);
      container
        .closest('.react-pdf__Document')
        .addEventListener('mousemove', handleMouseMove);
      container
        .closest('.react-pdf__Document')
        .addEventListener('mouseup', handleMouseUp);
    }

    return () => {
      if (container && container.closest('.react-pdf__Document')) {
        container
          .closest('.react-pdf__Document')
          .removeEventListener('mousedown', handleMouseDown);
        container
          .closest('.react-pdf__Document')
          .removeEventListener('mousemove', handleMouseMove);
        container
          .closest('.react-pdf__Document')
          .removeEventListener('mouseup', handleMouseUp);
      }
    };
  }, [selectionStart, selectionEnd, containerRef.current]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      // Check for Mac platform using navigator.platform
      const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;

      // Use event.metaKey for Command on Mac and event.ctrlKey for Control on others
      const undoPressed =
        !event.shiftKey &&
        ((isMac && event.metaKey && event.key === 'z') ||
          (!isMac && event.ctrlKey && event.key === 'z'));
      const redoPressed =
        (isMac && event.metaKey && event.shiftKey && event.key === 'z') ||
        (!isMac && event.ctrlKey && event.shiftKey && event.key === 'z');
      if (undoPressed) {
        event.preventDefault();
        dispatch(undo());
      } else if (redoPressed) {
        event.preventDefault();
        dispatch(redo());
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    // Cleanup
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [dispatch]);

  // Render selection box if dragging
  const renderSelectionBox = () => {
    if (!selectionStart || !selectionEnd || !isDragging || placingItem)
      return null;

    const containerRect = containerRef.current.getBoundingClientRect();
    const startX = Math.min(selectionStart.x, selectionEnd.x);
    const startY = Math.min(selectionStart.y, selectionEnd.y);
    const endX = Math.max(selectionStart.x, selectionEnd.x);
    const endY = Math.max(selectionStart.y, selectionEnd.y);

    // Constrain the selection box to the container's dimensions
    const width = Math.min(endX - startX, containerRect.width - startX);
    const height = Math.min(endY - startY, containerRect.height - startY);

    return (
      <Box
        sx={{
          position: 'absolute',
          left: `${startX}px`,
          top: `${startY}px`,
          width: `${width}px`,
          height: `${height}px`,
          backgroundColor: 'rgba(0, 0, 255, 0.2)',
          border: '2px dashed blue',
          pointerEvents: 'none',
          zIndex: 100,
        }}
      />
    );
  };

  useEffect(() => {
    if (autofillData) {
      dispatch(setAutofillData(autofillData));
    }
  }, [autofillData, dispatch]);

  useEffect(() => {
    if (documentData && !templateView) {
      let documentDataCopy = { ...documentData };
      //TODO: Implement later IF MLS Approval Document and Transaction has an MLS linked then create
      console.log('documentDataCopy', documentDataCopy);
      if (documentDataCopy.status === STATUSES.DRAFT) {
        if (
          !documentDataCopy.htmlTemplate ||
          documentDataCopy.htmlTemplate.length === 0
        ) {
          documentDataCopy.htmlTemplate =
            documentDataCopy?.defaultTemplate?.htmlTemplate.map((field) => {
              return {
                ...field,
                id: nanoid(),
              };
            }) || [];
        }
        if (!documentDataCopy.roles || documentDataCopy.roles.length === 0) {
          let defaultRoles = [];
          transactionData?.data?.roles.map((role) => {
            if (
              documentDataCopy.defaultTemplate.roles.find(
                (r) => r.role === role.role && r.order === role.order,
              )
            ) {
              defaultRoles.push({
                ...role,
                type: 'signer',
              });
            }
          });
          documentDataCopy.roles = defaultRoles;
        }
      }

      dispatch(setDocumentData(documentDataCopy));
    } else if (documentData && templateView) {
      let documentDataCopy = { ...documentData };
      dispatch(setDocumentData(documentDataCopy));
    }
  }, [documentData, transactionData, templateView, dispatch]);

  useEffect(() => {
    const handleResize = debounce(() => {
      const containerWidth = containerRef.current?.clientWidth;
      const newScale = containerWidth ? containerWidth / 816 : 1;
      dispatch(setScale(newScale));
    }, 250);

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const onDocumentLoadSuccess = ({ numPages }) => setNumPages(numPages);

  const renderHtmlFields = (fields, pageNumber) => {
    return fields
      .filter((field) => {
        // Extract the page number from the field's pageContainer property
        const fieldPageNumber = parseInt(
          field.pageContainer.replace('pageContainer', ''),
        );
        return fieldPageNumber === pageNumber;
      })
      .map((field, index) => <DocumentItem key={index} field={field} />);
  };

  useEffect(() => {
    dispatch(setNumberOfPages(numPages));
  }, [numPages]);

  return (
    <Box
      flex={2}
      p={2}
      sx={{
        backgroundColor: 'grey',
        maxWidth: '100%',
        pointerEvents: isPreviewMode ? 'none' : 'auto',
        position: 'relative',
      }}
    >
      {placingItem && <PlacingField />}
      {pdfUrl !== null && (
        <Document
          file={pdfUrl}
          onLoadSuccess={onDocumentLoadSuccess}
          loading={<LoadingOverlay page loading />}
          error={<Box>Error loading PDF</Box>}
        >
          <Box
            ref={containerRef}
            position="relative"
            sx={{
              transform: `scale(${scale})`,
              transformOrigin: 'top center',
              maxWidth: '816px',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          >
            {renderSelectionBox()}
            {Array.from(new Array(numPages), (el, index) => (
              <Box
                key={`page_${index + 1}`}
                className={'page-wrapper'}
                data-page-number={`${index + 1}`}
                position="relative"
                sx={{ pb: 2, width: 'min-content' }}
              >
                <Page
                  pageNumber={index + 1}
                  width={816}
                  renderTextLayer={false}
                  renderAnnotationLayer={false}
                  loading={<LoadingOverlay page loading />}
                />
                {currentDocument?.htmlTemplate &&
                  renderHtmlFields(currentDocument.htmlTemplate, index + 1)}
              </Box>
            ))}
          </Box>
        </Document>
      )}
    </Box>
  );
}

DocumentPdfViewer.propTypes = {
  documentData: PropTypes.object.isRequired,
  templateView: PropTypes.bool,
  preview: PropTypes.bool,
};
