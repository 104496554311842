import { Box } from '@mui/material';
import { DetailsPanels } from 'components/Common';
import ModelDetailsCollapse from 'components/ModelDetailsCollapse';
import PropTypes from 'prop-types';
import React from 'react';

export const BottomCard = ({ contactInfo }) => {
  return (
    <Box width={'100%'}>
      <DetailsPanels
        data={contactInfo}
        panels={{
          Overview: ['companyName', 'jobTitle', 'primaryAddress'],
        }}
      />
      <ModelDetailsCollapse data={contactInfo} />
    </Box>
  );
};

BottomCard.propTypes = {
  contactInfo: PropTypes.object,
};
