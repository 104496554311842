import { createApi } from '@reduxjs/toolkit/query/react';
import { transformResponse } from 'helpers';
import { conditionalInvalidateTags } from 'redux/rtk-query/utils/conditionalInvalidateTags';

import { axiosBaseQuery } from 'utils/baseQuery';

import { invoiceTemplateUrlBuilder } from './urlBuilder/invoiceTemplates';

export const invoiceTemplatesAPI = createApi({
  reducerPath: 'invoiceTemplatesAPI',
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  tagTypes: [
    'InvoiceTemplates',
    'Tasks',
    'Commissions',
    'Categories',
    'Notes',
    'Files',
  ],
  endpoints: (build) => ({
    validateInvoiceTemplate: build.mutation({
      query: (body) => ({
        url: invoiceTemplateUrlBuilder.validateInvoiceTemplate(),
        method: 'POST',
        data: body,
      }),
      invalidatesTags: [],
    }),
    createInvoiceTemplate: build.mutation({
      query: (body) => ({
        url: invoiceTemplateUrlBuilder.createInvoiceTemplate(),
        method: 'POST',
        data: body,
      }),
      invalidatesTags: ['InvoiceTemplates'],
    }),
    updateInvoiceTemplate: build.mutation({
      query: (body) => ({
        url: invoiceTemplateUrlBuilder.updateInvoiceTemplate(body),
        method: 'PUT',
        data: {
          data: body,
        },
      }),
      invalidatesTags: ['InvoiceTemplates', 'Tasks', 'Commissions'],
    }),
    getInvoiceTemplate: build.query({
      query: (args) => ({
        url: invoiceTemplateUrlBuilder.getInvoiceTemplate(args),
        method: 'GET',
      }),
      providesTags: ['InvoiceTemplates'],
    }),
    getInvoiceTemplates: build.query({
      query: (args) => ({
        url: invoiceTemplateUrlBuilder.getInvoiceTemplates(args),
        method: 'GET',
      }),
      providesTags: ['InvoiceTemplates'],
    }),
    getInvoiceCategoriesList: build.query({
      query: (args) => ({
        url: invoiceTemplateUrlBuilder.getCategoryList(args),
        method: 'GET',
      }),
      transformResponse,
      providesTags: ['Categories'],
    }),
    getInvoiceTemplateMedia: build.query({
      query: (args) => ({
        url: invoiceTemplateUrlBuilder.getInvoiceTemplateMedia(args),
        method: 'GET',
      }),
      providesTags: ['Notes'],
    }),
    getInvoiceTemplateMediaUrl: build.query({
      query: (args) => ({
        url: invoiceTemplateUrlBuilder.getInvoiceTemplateMediaUrl(args),
        method: 'GET',
      }),
      providesTags: ['Files'],
    }),
    getInvoiceCategory: build.query({
      query: (args) => ({
        url: invoiceTemplateUrlBuilder.getCategory(args),
        method: 'GET',
      }),
      }
    ),
    createInvoiceCategory: build.mutation({
      query: (body) => ({
        url: invoiceTemplateUrlBuilder.createCategory(),
        method: 'POST',
        data: {
          data: body,
        },
      }),
      onQueryStarted(arg, { dispatch, queryFulfilled }) {
        conditionalInvalidateTags(
          queryFulfilled,
          dispatch,
          invoiceTemplatesAPI,
          ['Categories'],
        );
      },
    }),
    updateInvoiceCategory: build.mutation({
      query: (body) => ({
        url: invoiceTemplateUrlBuilder.getCategory(body),
        method: 'PUT',
        data: {
          data: body,
        },
      }),
      invalidatesTags: ['Categories'],
    }),
  }),
});

export const {
  useCreateInvoiceTemplateMutation,
  useUpdateInvoiceTemplateMutation,
  useGetInvoiceTemplatesQuery,
  useGetInvoiceTemplateQuery,
  useGetInvoiceCategoriesListQuery,
  useLazyGetInvoiceCategoriesListQuery,
  useGetInvoiceTemplateMediaQuery,
  useGetInvoiceTemplateMediaUrlQuery,
  useCreateInvoiceCategoryMutation,
  useUpdateInvoiceCategoryMutation,
  useGetInvoiceCategoryQuery,
} = invoiceTemplatesAPI;
