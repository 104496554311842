import React, { useEffect } from "react";
import { Cells, MenuItems } from "./config";
import { Table } from 'components/Common';
import {useGetSettingsListQuery} from "redux/rtk-query";

export const SettingsTable = () => {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("");
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [search, setSearch] = React.useState("");
  const orderByKey = orderBy
    .split(/\.?(?=[A-Z])/)
    .join("_")
    .toLowerCase();
  const { data, isFetching } = useGetSettingsListQuery({
    page: page,
    limit: rowsPerPage,
    search: search,
    // column: [ 'lists', 'notes', 'tasks'],
    order: { [`sort_${orderByKey}`]: order.toUpperCase() },
  });
  const [loading, setLoading] = React.useState(true);
  const totalPages = data?.data?.meta.totalPages;
  const totalCount = data?.data?.meta.total;
  const rows = data?.data?.items;

  useEffect(() => {
    setLoading(isFetching);
  }, [isFetching]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <>
      <Table
        handleSearch={setSearch}
        search={search}
        isLoading={loading}
        rows={rows}
        totalPages={totalPages}
        totalCount={totalCount}
        order={order}
        menuItems={MenuItems()}
        cells={Cells}
        orderBy={orderBy}
        page={page}
        rowsPerPage={rowsPerPage}
        handleRequestSort={handleRequestSort}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
      />
    </>
  );
};

