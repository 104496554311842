import { Card } from '@mui/material';
import { DetailsBox, DetailsWrapper } from 'components/Styled';
import PropTypes from 'prop-types';
import React from 'react';
import { BottomCard, TopCard } from './components';

export const ContactDetails = ({ contactInfo }) => {
  return (
    <DetailsWrapper>
      <Card>
        {contactInfo && (
          <DetailsBox>
            <TopCard contactInfo={contactInfo} />
          </DetailsBox>
        )}
      </Card>
      <Card>
        {contactInfo && (
          <DetailsBox style={{ alignItems: 'flex-start' }}>
            <BottomCard contactInfo={contactInfo} />
          </DetailsBox>
        )}
      </Card>
    </DetailsWrapper>
  );
};

ContactDetails.propTypes = {
  contactInfo: PropTypes.object,
};
