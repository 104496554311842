import React, { useMemo, useState } from "react";
import { faPen, faTrashCan } from "@fortawesome/pro-solid-svg-icons";
import { useNavigate } from "react-router-dom";

import {
  useGetCompanyUnitsQuery,
  useDeleteCompanyUnitMutation,
  useGetCompanyUnitUsersQuery,
} from "redux/rtk-query";
import { Table } from 'components/Common';
import { ConfirmDialog } from "components/Common/ConfirmDialog";
import { UsersModal } from "components/ModelViews/Users/UsersModal";
import { filterChildren } from "helpers";

import { getCells } from "./config";

export const CompanyUnitsTable = () => {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [currentCompanyUnit, setCurrentCompanyUnit] = useState(false);
  const [deleteCompanyUnit] = useDeleteCompanyUnitMutation();

  const { data, isLoading } = useGetCompanyUnitsQuery();
  const navigate = useNavigate();

  const totalRows = useMemo(
    () => filterChildren(data?.data || [], search),
    [data?.data, search],
  );
  const rows = useMemo(
    () => totalRows.slice((page - 1) * rowsPerPage, page * rowsPerPage),
    [totalRows, rowsPerPage, page],
  );
  const totalPages = useMemo(
    () => Math.ceil(totalRows.length / rowsPerPage),
    [totalRows.length, rowsPerPage],
  );
  const MenuItems = useMemo(
    () => [
      {
        name: "Edit",
        icon: faPen,
        onClick: ({ uuid }) => navigate(`/admin/company-units/edit/${uuid}`),
      },
      {
        name: "Delete",
        icon: faTrashCan,
        onClick: ({ uuid }) => setConfirmOpen(uuid),
      },
    ],
    [navigate],
  );

  const handleConfirm = () => deleteCompanyUnit(confirmOpen);
  const getClickHandler = companyUnit => () =>
    setCurrentCompanyUnit(companyUnit);

  return (
    <>
      <Table
        handleSearch={setSearch}
        search={search}
        isLoading={isLoading}
        rows={rows}
        totalPages={totalPages}
        menuItems={MenuItems}
        cells={getCells(getClickHandler)}
        page={page}
        rowsPerPage={rowsPerPage}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
        isCollapsible
      />
      {currentCompanyUnit ? (
        <UsersModal
          currentItem={currentCompanyUnit}
          setCurrentItem={setCurrentCompanyUnit}
          useGetList={useGetCompanyUnitUsersQuery}
        />
      ) : null}
      <ConfirmDialog
        title="Delete Company Unit?"
        message="Are you sure you want to permanently delete this company unit?"
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={handleConfirm}
      />
    </>
  );
};

CompanyUnitsTable.propTypes = {};
