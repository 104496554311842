import { Divider, Grid } from '@mui/material';
import { Box } from '@mui/system';
import { AbeCard, ModuleIcon } from 'components/Common';
import { LayoutHeader } from 'components/Layouts';
import { PageMainContent } from 'components/Styled';
import PropTypes from 'prop-types';
import React from 'react';
import { Outlet } from 'react-router-dom';
import Report1 from './demoAssests/Report1.png';
import Report10 from './demoAssests/Report10.png';
import Report11 from './demoAssests/Report11.png';
import Report2 from './demoAssests/Report2.png';
import Report4 from './demoAssests/Report4.png';
import Report5 from './demoAssests/Report5.png';
import Report7 from './demoAssests/Report7.png';
import Report8 from './demoAssests/Report8.png';

const ImageComponent = ({ reportImage }) => {
  return (
    <Box
      component="div"
      sx={{
        position: 'relative',
        overflow: 'hidden',
        height: 'auto', // Adjust height to fit your layout
      }}
    >
      <Box
        component="img"
        src={reportImage}
        alt="placeholder"
        sx={{
          position: 'relative',
          top: '-23px',
          width: '100%',
          height: 'auto',
          marginBottom: '-23px',
        }}
      />
    </Box>
  );
};

ImageComponent.propTypes = {
  reportImage: PropTypes.string.isRequired,
};

const ReportCard = ({ title, reportImage }) => {
  return (
    <AbeCard
      elevation={2}
      icon={
        <ModuleIcon
          maxWidth={'20px'}
          module={'Reports'}
          marginRight={0}
          fontSize={20}
        />
      }
      title={title}
    >
      <Divider />
      <ImageComponent reportImage={reportImage} />
    </AbeCard>
  );
};

ReportCard.propTypes = {
  title: PropTypes.string,
  reportImage: PropTypes.string,
};

export const ReportPage = () => {
  return (
    <>
      <LayoutHeader title={'Reports'}></LayoutHeader>
      <PageMainContent maxWidth={false} component="main" disableGutters>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <ReportCard
              title={'Sales Modes Distribution'}
              reportImage={Report1}
            />
          </Grid>
          <Grid item xs={4}>
            <ReportCard
              title={'Closed Commission Sales Activity'}
              reportImage={Report2}
            />
          </Grid>
          <Grid item xs={4}>
            <ReportCard
              title={'Real Estate Sales And Listings Activity'}
              reportImage={Report11}
            />
          </Grid>
          <Grid item xs={4}>
            <ReportCard
              title={'Pending Sales Activity'}
              reportImage={Report4}
            />
          </Grid>
          <Grid item xs={4}>
            <ReportCard title={'New Leads Activity'} reportImage={Report5} />
          </Grid>
          <Grid item xs={4}>
            <ReportCard
              title={'Sales Distribution Over The Months'}
              reportImage={Report10}
            />
          </Grid>
          <Grid item xs={6}>
            <ReportCard
              title={'Sales Distribution By Property Type'}
              reportImage={Report7}
            />
          </Grid>
          <Grid item xs={6}>
            <ReportCard title={'Customer Lead Sources'} reportImage={Report8} />
          </Grid>
        </Grid>
      </PageMainContent>
      <Outlet />
    </>
  );
};
