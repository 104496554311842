import { styled } from "@mui/system";
import { Box } from "@mui/material";

export const PasswordBox = styled(Box)({
  marginBottom: "40px",
  maxWidth: "464px",
  display: "flex",
  flexDirection: "column",
  gap: "24px",
});
