import { faLightbulbOn } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Divider, IconButton, Tooltip } from "@mui/material";
import { AbeCard } from 'components/Common';
import { COMMISSION_OVERVIEW } from 'consts';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useGetTransactionQuery } from 'redux/rtk-query';
import { CommissionsList } from '../CommissionsList';

const CommissionAfterTitle = ({ relatedModelType, relatedModelId }) => {
  if (relatedModelType === 'transaction') {
    return (
      <>
        <Tooltip title={'Commission Overview'} placement="top">
          <Link to={`/${COMMISSION_OVERVIEW(relatedModelId)}`}>
            <IconButton size={'small'}>
              <FontAwesomeIcon
                size={'xs'}
                icon={faLightbulbOn}
                style={{ marginLeft: '0.5rem' }}
              />
            </IconButton>
          </Link>
        </Tooltip>
      </>
    );
  }
  return <> </>;
};

CommissionAfterTitle.propTypes = {
  relatedModelType: PropTypes.string.isRequired,
  relatedModelId: PropTypes.string.isRequired,
};

export const CommissionCard = (props) => {
  const { contactDetailsId, transactionDetailsId } = useParams();
  const relatedModelId = contactDetailsId || transactionDetailsId;
  const relatedModelType = contactDetailsId ? 'contact' : 'transaction';

  const { data: transactionData } = useGetTransactionQuery({
    id: relatedModelId,
  });

  const afterTitle = useMemo(
    () => (
      <CommissionAfterTitle
        relatedModelType={relatedModelType}
        relatedModelId={relatedModelId}
      />
    ),
    [relatedModelType, relatedModelId],
  );

  return (
    <AbeCard title={'Commissions'} afterTitle={afterTitle} {...props}>
      <Divider/>
      {transactionData && (
        <CommissionsList
          relatedModelType={relatedModelType}
          relatedModel={transactionData.data}
        />
      )}
    </AbeCard>
  );
};
