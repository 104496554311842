import AddIcon from '@mui/icons-material/Add';
import { Box, Button, FormControl, Grid } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { LabeledOutline } from 'components/Common';
import React, { useCallback } from 'react';
import { useForm } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import uuid from 'react-uuid';
import { CommissionLevel } from './components/CommissionLevel';

const renderCommissionLevels = (fields, handleUpdate, handleDelete) =>
  fields.map((name, index) => {
    const level = fields.value[index];
    return (
      <Grid item xs={12} key={level.id} marginBottom={2}>
        <CommissionLevel
          level={level}
          onUpdate={handleUpdate}
          onDelete={handleDelete}
        />
      </Grid>
    );
  });

export const CommissionLevels = () => {
  const form = useForm();

  const handleUpdate = useCallback(
    (id, field, value) => {
      const updatedCommissionLevels = form
        .getState()
        .values.cappingLevels.map((level) => {
          if (level.id === id) {
            return { ...level, [field]: value };
          }
          return level;
        });
      form.change('cappingLevels', updatedCommissionLevels);
    },
    [form],
  );

  const handleDelete = useCallback(
    (id) => {
      const updatedCommissionLevels = form
        .getState()
        .values.cappingLevels.filter((level) => level.id !== id);
      form.change('cappingLevels', updatedCommissionLevels);
    },
    [form],
  );

  return (
    <FieldArray name="cappingLevels">
      {({ fields }) => (
        <LabeledOutline label={'Commission Levels'}>
          <Box px={2}>
            <FormControl fullWidth>
              <Grid container spacing={2} p={1}>
                {renderCommissionLevels(fields, handleUpdate, handleDelete)}
              </Grid>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: 2,
                }}
              >
                <Tooltip title={'Add another commission level'} arrow>
                  <Button
                    variant="outlined"
                    onClick={() =>
                      fields.push({
                        amount: '',
                        percentage: '',
                        id: uuid(),
                      })
                    }
                    startIcon={<AddIcon />}
                  >
                    Add Commission Level
                  </Button>
                </Tooltip>
              </Box>
            </FormControl>
          </Box>
        </LabeledOutline>
      )}
    </FieldArray>
  );
};

CommissionLevels.propTypes = {};
