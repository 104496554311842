import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { Select, MenuItem } from '@mui/material';

export const FilterCompareCondition = ({ name }) => (
  <Field name={`${name}.conditionType`} defaultValue="And">
    {({ input }) => (
      <Select
        {...input}
        IconComponent={() => null}
        sx={{
          top: '50%',
          transform: 'translateY(-50%)',
          background: 'white',
          borderRadius: 8,
          borderWidth: 2,
          mx: -1,
          zIndex: 7,
          minWidth: '56px',
          textAlign: 'center',
          '& .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input': {
            padding: '2px',
            paddingRight: '2px',
          },
        }}
      >
        <MenuItem value="And">AND</MenuItem>
        <MenuItem value="Or">OR</MenuItem>
        <MenuItem value="And Not">AND NOT</MenuItem>
      </Select>
    )}
  </Field>
);

FilterCompareCondition.propTypes = {
  name: PropTypes.string.isRequired,
};
