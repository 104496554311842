import { createApi } from "@reduxjs/toolkit/query/react";

import { axiosBaseQuery } from "utils/baseQuery";

import { companyUnitUrlBuilder } from "./urlBuilder/companyUnits";

export const companyUnitsAPI = createApi({
  reducerPath: "companyUnitsAPI",
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  tagTypes: ["CompanyUnits"],
  endpoints: build => ({
    getCompanyUnits: build.query({
      query: args => ({
        url: companyUnitUrlBuilder.getCompanyUnits(args),
        method: "GET",
      }),
      providesTags: ["CompanyUnits"],
    }),
    getCompanyUnit: build.query({
      query: args => ({
        url: companyUnitUrlBuilder.getCompanyUnit(args),
        method: "GET",
      }),
      providesTags: ["CompanyUnits"],
    }),
    getCompanyUnitUsers: build.query({
      query: args => ({
        url: companyUnitUrlBuilder.getCompanyUnitUsers(args),
        method: "GET",
      }),
      invalidatesTags: ["CompanyUnitUsers"],
    }),
    createCompanyUnit: build.mutation({
      query: body => ({
        url: companyUnitUrlBuilder.createCompanyUnit(),
        method: "POST",
        data: body,
      }),
      invalidatesTags: ["CompanyUnits"],
    }),
    editCompanyUnit: build.mutation({
      query: body => ({
        url: companyUnitUrlBuilder.editCompanyUnit(),
        method: "PUT",
        data: body,
      }),
      invalidatesTags: ["CompanyUnits"],
    }),
    deleteCompanyUnit: build.mutation({
      query: args => ({
        url: companyUnitUrlBuilder.deleteCompanyUnit(args),
        method: "DELETE",
      }),
      invalidatesTags: ["CompanyUnits"],
    }),
  }),
});

export const {
  useGetCompanyUnitsQuery,
  useGetCompanyUnitQuery,
  useGetCompanyUnitUsersQuery,
  useCreateCompanyUnitMutation,
  useEditCompanyUnitMutation,
  useDeleteCompanyUnitMutation,
} = companyUnitsAPI;
