import { useState, useEffect, useMemo } from "react";
import { isArray } from 'lodash'

const PAGE = 1;
const getOptionDefaults = ({ id, name }) => ({
  value: id,
  label: name,
  key: id,
});

export function useLoadMoreOptions(useGetListQuery, getOption, queryOptions = {}) {
  const [page, setPage] = useState(PAGE);
  const [search, setSearch] = useState("");
  const [fullList, setFullList] = useState([]);
  const list = useGetListQuery({ page, search, ...queryOptions }, {skip: !queryOptions.selectField && search.length === 0});

  const handleOpen = () => {
    setPage(PAGE);
    setSearch("");
  };
  const handleMore = list?.data?.hasNextPage ? () => setPage(page + 1) : null;
  const handleSearch = value => setSearch(value);

  useEffect(() => {
    const data = list?.data?.data || list?.data || list;
    const dataItems = data?.items || data?.data || data || [];
    if(!isArray(dataItems)) return setFullList([]);
    if (data?.meta?.page === 1 || !data?.meta?.page) {
      setFullList(dataItems);
    } else {
      setFullList([...fullList, ...dataItems]);
    }
  }, [list?.data]);

  const options = useMemo(
    () => fullList.map(getOption ? getOption : getOptionDefaults),
    [fullList],
  );

  return {
    ...list,
    handleOpen,
    handleMore,
    options,
    handleSearch,
  };
}
