import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  useCreateTransactionMutation,
  useGetProfileQuery,
} from 'redux/rtk-query';

import { usePrevious } from 'hooks';
import { SideDrawer } from 'components/Common';
import TransactionForm from '../TransactionForm/TransactionForm';
import { defaultValues } from '../TransactionForm/defaultValues';
import { processFormData } from '../TransactionForm/formData';

export const CreateTransactionPage = () => {
  const [initialValues, setInitialValues] = useState({});
  const [isOpen, setIsOpen] = useState(false);

  const [createTransaction] = useCreateTransactionMutation();
  const { data } = useGetProfileQuery();

  const navigate = useNavigate();
  const prevIsOpen = usePrevious(isOpen);

  useEffect(() => {
    setInitialValues({
      ...defaultValues,
      agent: data?.data,
      team: [
        {
          teamMember: data?.data?.contact,
          percent: 100,
          order: 1,
        },
      ],
    });
    setIsOpen(true);
  }, [0, data]);

  const handleSubmitFunc = async (values) => {
    let formData = processFormData(values);
    const transaction = await createTransaction(formData);
    return transaction;
  };

  const afterSave = () => {
    setIsOpen(false);
  };

  useLayoutEffect(() => {
    if (prevIsOpen && !isOpen) {
      setTimeout(function () {
        navigate(-1);
      }, 500);
    }
  }, [isOpen]);

  return (
    <SideDrawer width={'95%'} isOpen={isOpen}>
      <TransactionForm
        title={'Create Transaction'}
        initialValues={initialValues}
        afterSave={afterSave}
        handleSubmitFunc={handleSubmitFunc}
      />
    </SideDrawer>
  );
};
