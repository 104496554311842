import _ from "lodash";
import {useGetProfileQuery} from "../redux/rtk-query";

// import { ADMIN_NAME } from "constants";

export const isAdmin = () => {
    const { data: { data: { permissions } = {} } = {} } = useGetProfileQuery();

    return permissions?.length
        ? Boolean(_.find(permissions, ({ name }) => name === "ASSIGN_ROLE"))
        : true;
}
