import { faClock } from '@fortawesome/pro-light-svg-icons';
import { faCaretDown, faCaretUp } from '@fortawesome/pro-solid-svg-icons';
import { Box, Button, Divider, IconButton } from '@mui/material';
import { SkeletonSummary } from 'components/Common';
import * as helpers from 'helpers';
import { conversion } from 'helpers';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
  useGetContactTasksQuery,
  useGetMyTasksQuery,
  useGetTransactionTasksQuery,
} from 'redux/rtk-query';
import { useItemCount } from "contexts";
import { FontAwesomeIconComp } from "components/Common";
import { ListPagination } from "components/ListPagination";

import { StyledBadge } from "components/Styled";
import { TaskSummary } from '../TaskSummary';
import { TaskSectionHeader } from './styled';

export const TaskSection = ({
  relatedModelType,
  uuid,
  type,
  searchValue,
  startClosed,
}) => {
  const [isOpen, setIsOpen] = useState(!startClosed);
  const [page, setPage] = React.useState(1);
  const [showCompleted, setShowCompleted] = useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const { setItemCount } = useItemCount();
  const { data: contactTaskData } = useGetContactTasksQuery(
    {
      uuid: uuid,
      taskType: 'Task',
      type: type,
      status: showCompleted
        ? ['New', 'In Progress', 'Completed']
        : type !== 'COMPLETED'
        ? ['New', 'In Progress']
        : null,
      search: searchValue,
      page: page,
      limit: rowsPerPage,
    },
    { skip: !uuid || relatedModelType !== 'contact' },
  );
  const { data: transactionTaskData } = useGetTransactionTasksQuery(
    {
      uuid: uuid,
      taskType: 'Task',
      type: type,
      status: showCompleted
        ? ['New', 'In Progress', 'Completed']
        : type !== 'COMPLETED'
        ? ['New', 'In Progress']
        : null,
      search: searchValue,
      page: page,
      limit: rowsPerPage,
    },
    { skip: !uuid || relatedModelType !== 'transaction' },
  );
  const { data: myTaskData } = useGetMyTasksQuery(
    {
      type: type,
      taskType: 'Task',
      status: showCompleted
        ? ['New', 'In Progress', 'Completed']
        : type !== 'COMPLETED'
        ? ['New', 'In Progress']
        : null,
      search: searchValue,
      page: page,
      limit: rowsPerPage,
    },
    {
      skip: !(
        relatedModelType !== 'contact' && relatedModelType !== 'transaction'
      ),
    },
  );

  const taskData = contactTaskData || transactionTaskData || myTaskData;

  const AllTasks = taskData?.data?.items;
  const totalPages = taskData?.data?.meta.totalPages;
  const totalCount = taskData?.data?.meta.total;
  const hasCompleted = taskData?.data?.meta?.otherData?.hasCompleted;

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  useEffect(() => {
    if (!type && setItemCount) {
      setItemCount(totalCount);
    }
  }, [setItemCount, totalCount, type]);

  return (
    <div
      style={{
        borderLeft: `4px solid ${helpers.taskColor(type)}`,
      }}
    >
      {AllTasks && (
        <>
          {type && (
            <TaskSectionHeader>
              <Box
                onClick={() => {
                  setIsOpen(!isOpen);
                }}
              >
                <IconButton
                  onClick={() => {
                    setIsOpen(!isOpen);
                  }}
                  size={'small'}
                >
                  <FontAwesomeIconComp
                    icon={isOpen ? faCaretDown : faCaretUp}
                    fontSize={12}
                  />
                </IconButton>
                <span>{conversion.titleCase(type)}</span>
                {!isOpen && (
                  <StyledBadge badgeContent={totalCount} color="secondary" />
                )}
              </Box>
            </TaskSectionHeader>
          )}
          {isOpen && (
            <>
              {!taskData ? (
                <SkeletonSummary />
              ) : (
                AllTasks &&
                AllTasks.length > 0 &&
                AllTasks.map((task) => (
                  <React.Fragment key={task.id}>
                    <TaskSummary task={task} />
                  </React.Fragment>
                ))
              )}
              <Box style={{ textAlign: 'center' }}>
                {hasCompleted && (
                  <Button
                    fullWidth
                    size='small'
                    color='secondary'
                    onClick={() => {
                      setShowCompleted(!showCompleted);
                    }}
                  >
                    <FontAwesomeIconComp
                      icon={faClock}
                      style={{ marginRight: 10 }}
                    />
                    {showCompleted ? 'Hide' : 'Show'} Completed
                  </Button>
                )}
                {totalPages > 1 && (
                  <ListPagination
                    totalCount={totalCount}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    rowsPerPageOptions={[5]}
                  />
                )}
              </Box>
              <Divider />
            </>
          )}
        </>
      )}
    </div>
  );
};

TaskSection.propTypes = {
  uuid: PropTypes.string,
  type: PropTypes.string,
  searchValue: PropTypes.string,
  startClosed: PropTypes.bool,
  relatedModelType: PropTypes.string,
};
