import { useForm } from 'react-final-form';

export function useIsRequired(name) {
  let required = false;
  if (name) {
    let form = useForm();
    const field = form.getFieldState(name);
    if (field && field.data && field.data.required) {
      required = true;
    }
  }
  return required;
}
