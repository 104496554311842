import { Grid } from '@mui/material';
import { FormPanel } from 'components/Common';
import {
  CheckboxField,
  DatePicker,
  MlsListing,
  Parcel,
  ParcelLookupAddress,
  Select,
  TextInput,
} from 'components/Fields';
import { FormCondition } from 'components/Forms';
import { PM_FILE_OPTIONS, REPRESENTING_OPTIONS, STATUS_OPTIONS } from 'consts';
import { useTransactionOptions } from 'hooks';
import React from 'react';
import { Field, useForm } from 'react-final-form';

export const OverviewPanel = () => {
  const form = useForm();
  const { saleTypeOptions, pmFileOptions, statusOptions, representingOptions } =
    useTransactionOptions(form);

  return (
    <FormPanel label={'Overview'}>
      <Grid item xs={12}>
        <Field
          label="Status"
          name={'status'}
          component={Select}
          options={statusOptions}
          tooltip={{
            title: `Choose the status of the account you are entering. Each status requires different documents (except Referrals do not require documents).`,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <FormCondition
          when="status"
          operator="oneOf"
          is={[
            STATUS_OPTIONS.LEASED.value,
            STATUS_OPTIONS.SOLD.value,
            STATUS_OPTIONS.REFERRAL.value,
            STATUS_OPTIONS.EXPIRED_WITHDRAWN.value,
            STATUS_OPTIONS.CANCELLED_CONTRACT.value,
          ]}
        >
          <Field
            label="Close Date"
            name={'contractClosingDate'}
            component={DatePicker}
          />
        </FormCondition>
      </Grid>
      <Grid item xs={12}>
        <Field
          label="Representing"
          name={'representing'}
          component={Select}
          options={representingOptions}
          tooltip={{
            title: (
              <>
                The customer type represented <br /> on the transaction.
              </>
            ),
          }}
        />
      </Grid>
      <FormCondition when="representing" operator="isNotEmpty" is={false}>
        <Grid item xs={12}>
          <FormCondition
            when="status"
            operator="doesNotEqual"
            is={STATUS_OPTIONS.PROPERTY_MGMT.value}
          >
            <FormCondition
              when="representing"
              operator="contains"
              is={'Landlord'}
            >
              <Field
                label="Mgmt File?"
                name={'pmFile'}
                component={Select}
                options={pmFileOptions}
                tooltip={{
                  title: (
                    <>
                      <strong>Property Mgmt:</strong> The account is managed on
                      a monthly basis. <br />{' '}
                      <strong> Lease for Property Mgmt:</strong> The lease
                      account is related to a property under management. <br />{' '}
                      <strong>Lease Only:</strong> The account is not managed
                      monthly by the company.
                    </>
                  ),
                }}
              />
            </FormCondition>
          </FormCondition>
        </Grid>
        <Grid item xs={12}>
          <FormCondition
            when="pmFile"
            operator="equals"
            is={PM_FILE_OPTIONS.LEASE_FOR_PROPERTY_MGMT.value}
          >
            <Field
              label="Transaction"
              name={'account'}
              component={TextInput}
              tooltip={{
                title:
                  'Select the address of the management account. This will link this lease to the management account.',
              }}
            />
          </FormCondition>
        </Grid>
        <Grid item xs={12}>
          <Field
            label={'Address'}
            name={'address'}
            component={ParcelLookupAddress}
          />
        </Grid>
      </FormCondition>
      <FormCondition when="addressConfirmed" operator="equals" is={true}>
        <Grid item xs={12}>
          <Field label="Parcel" name={'parcel'} component={Parcel} />
        </Grid>
        <FormCondition
          when="status"
          operator="doesNotEqual"
          is={STATUS_OPTIONS.PROPERTY_MGMT.value}
        >
          <FormCondition
            when="pmFile"
            operator="doesNotEqual"
            is={STATUS_OPTIONS.PROPERTY_MGMT.value}
          >
            <Grid item xs={12}>
              <Field
                label="MLS Listing"
                name={'mlsListing'}
                component={MlsListing}
              />
            </Grid>
          </FormCondition>
        </FormCondition>
        <Grid item xs={12}>
          <FormCondition
            when="representing"
            operator="oneOf"
            is={[
              REPRESENTING_OPTIONS.SELLER.value,
              REPRESENTING_OPTIONS.BUYER.value,
              REPRESENTING_OPTIONS.SELLER_AND_BUYER.value,
            ]}
          >
            <Field
              label="Sale Type"
              name={'saleType'}
              component={Select}
              options={saleTypeOptions}
            />
          </FormCondition>
        </Grid>
        <Grid item xs={12}>
          <Field
            name="closedApproved"
            label={'Closed Approved'}
            component={CheckboxField}
          />
        </Grid>
      </FormCondition>
    </FormPanel>
  );
};
