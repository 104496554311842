import {styled} from "@mui/system";
import {Box, Typography} from "@mui/material";
import { TreeItem } from '@mui/x-tree-view'

export const DocumentTreeItemBox = styled(TreeItem)({
  "& .MuiTreeItem-iconContainer": {
    marginRight: 5,
    marginLeft: 5,
  },
  "$ .css-mopuop-MuiTreeItem-content .MuiTreeItem-label":{
    paddingLeft: 10,
  },
  "& .MuiTreeItem-content": {
    padding: "2px 9px",
    // Directly target the svg inside the icon container
    "& .MuiTreeItem-iconContainer svg": {
      fontSize: '15px', // Set the desired font size for the icon
    },
  }
});

export const DocumentItemBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  "& .document-name": {
    paddingRight: 100,
  },
  "&:hover .document-row-menu": {
    visibility: "visible",
  }
});

export const DocumentItemName = styled(Typography)({
  fontWeight: 'inherit',
  flexGrow: 1,
});

DocumentItemName.defaultProps = {
  display: "flex",
  variant: "body2",
  noWrap: true,
}

export const DocumentRowMenuWrapper = styled("div")({
  position: "absolute",
  right: 0,
  alignItems: "center",
  display: "flex",
  visibility: "hidden",
});