import { createApi } from "@reduxjs/toolkit/query/react";

import { axiosBaseQuery } from "utils/baseQuery";
import {taskUrlBuilder} from "./urlBuilder/tasks";
import {contactUrlBuilder} from "./urlBuilder/contacts";
import {transactionUrlBuilder} from "./urlBuilder/transactions";

export const tasksAPI = createApi({
  reducerPath: "tasksAPI",
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  tagTypes: ["Tasks", "Checklists"],
  endpoints: build => ({
    validateTask: build.mutation({
      query: body => ({
        url: taskUrlBuilder.validate(),
        method: "POST",
        data: body,
      }),
      invalidatesTags: [],
    }),
    createTask: build.mutation({
      query: body => ({
        url: taskUrlBuilder.createTask(),
        method: "POST",
        data: body,
      }),
      invalidatesTags: ["Tasks"],
    }),
    updateTask: build.mutation({
      query: body => ({
        url: taskUrlBuilder.updateTask(),
        method: "PUT",
        data: body,
      }),
      invalidatesTags: ["Tasks"],
    }),
    getTask: build.query({
      query: args => ({
        url: taskUrlBuilder.getTask(args),
        method: "GET",
      }),
      providesTags: ["Tasks"],
    }),
    getTasks: build.query({
      query: args => ({
        url: taskUrlBuilder.getTasks(args),
        method: "GET",
      }),
      providesTags: ["Tasks"],
    }),
    getContactTasks: build.query({
      query: args => ({
        url: contactUrlBuilder.getTasks(args),
        method: "GET",
      }),
      providesTags: ["Tasks"],
    }),
    getTransactionTasks: build.query({
      query: args => ({
        url: transactionUrlBuilder.getTasks(args),
        method: "GET",
      }),
      providesTags: ["Tasks"],
    }),
    getTransactionsTimeframes: build.query({
      query: args => ({
        url: transactionUrlBuilder.getTransactionTimeframes(args),
        method: "GET",
      }),
      providesTags: ["Tasks"],
    }),
    getMyTasks: build.query({
      query: args => ({
        url: taskUrlBuilder.getMyTasks(args),
        method: "GET",
      }),
      providesTags: ["Tasks"],
    }),
    getChecklistForTask: build.query({
      query: args => ({
        url: taskUrlBuilder.getChecklistForTask(args),
        method: "GET",
      }),
      providesTags: ["Checklists"],
    }),
    saveChecklistItems: build.mutation({
      query: body => ({
        url: taskUrlBuilder.saveChecklistItems(),
        method: "POST",
        data: body,
      }),
      invalidatesTags: ["Checklists"],
    }),
    getTaskComments: build.query({
      query: args => ({
        url: taskUrlBuilder.getTaskComments(args),
        method: "GET",
      }),
      providesTags: ["Tasks"],
    }),
  }),
});

export const {
  useCreateTaskMutation,
  useUpdateTaskMutation,
  useValidateTaskMutation,
  useGetContactTasksQuery,
  useGetTransactionTasksQuery,
  useGetTransactionsTimeframesQuery,
  useGetTaskQuery,
  useGetTasksQuery,
  useGetTaskCommentsQuery,
  useGetChecklistForTaskQuery,
  useSaveChecklistItemsMutation,
    useGetMyTasksQuery,
} = tasksAPI;
