import React from "react";
import PropTypes from "prop-types";
import { ConfirmMenuItem } from "components/Common";
import { useUpdateDocumentMutation } from "redux/rtk-query"
import { STATUSES } from "consts"

export const VoidComponent = ({ onBackToMenu, handleCloseMenu, item }) => {

  const [updateDocument] = useUpdateDocumentMutation();

  const onConfirm = () => {
    updateDocument({ id: item.id, status: STATUSES.VOIDED });
    handleCloseMenu(true);
  };

  return (
    <ConfirmMenuItem
      title="Void"
      message={"Are you sure you want to void this document?"}
      onConfirm={onConfirm}
      onCancel={onBackToMenu}
      confirmText={"Confirm"}
      confirmColor={"error"}
      cancelText={"Go Back"}
    />
  );
};

VoidComponent.propTypes = {
  onBackToMenu: PropTypes.func,
  handleCloseMenu: PropTypes.func,
  item: PropTypes.object,
};