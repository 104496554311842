import { conversion } from "helpers";
import { roleColors } from "consts";

export const getRoleIndex = (role) => {
  switch (role) {
    case 'seller':
    case 'landlord':
    case 'seller1':
    case 'landlord1':
      return 0;
    case 'buyer':
    case 'tenant':
    case 'buyer1':
    case 'tenant1':
      return 1;
    case 'seller2':
    case 'landlord2':
      return 2;
    case 'buyer2':
    case 'tenant2':
      return 3;
    case 'seller3':
    case 'landlord3':
      return 4;
    case 'buyer3':
    case 'tenant3':
      return 5;
    case 'seller4':
    case 'landlord4':
      return 6;
    case 'buyer4':
    case 'tenant4':
      return 7;
    case 'broker':
      return 8;
    case 'teamMember1':
      return 9;
    case 'teamMember2':
      return 10;
    case 'teamMember3':
      return 11;
    case 'teamMember4':
      return 12;
    case 'coopAgent':
      return 13;
  }
};

export const getFirstOfRole = (roles, role) => {
  for (const r of roles) {
    if (r.role === role && r.order === 1) {
      return r;
    }
  }
};

export function partyTypeCount(type, roles) {
  let count = 0;
  roles.map((role) => {
    if (role.type === type) {
      count++;
    }
  });

  return count;
}

export const getNextRoleOrder = (allRoles, roleName) => {
  const roleOrders = allRoles
    .filter((role) => role.role === roleName)
    .map((role) => role.order)
    .sort((a, b) => a - b);

  let nextOrder = 1;
  for (const order of roleOrders) {
    if (order === nextOrder) nextOrder++;
    else break;
  }

  return nextOrder;
};

export const sortRoles = (originalRoles) => {
  const rolePriority = {
    Seller: 1,
    Buyer: 2,
    Landlord: 3,
    Tenant: 4,
    Default: 5,
  };

  const roles = [...originalRoles];

  roles.sort((a, b) => {
    const roleOrderA = rolePriority[a.role] || rolePriority.Default;
    const roleOrderB = rolePriority[b.role] || rolePriority.Default;

    if (roleOrderA === roleOrderB) {
      return (a.order || Infinity) - (b.order || Infinity);
    }
    return roleOrderA - roleOrderB;
  });

  return roles;
};

export const getPossibleRoles = (documentData) => {
  const customerRoleTypes = documentData?.moduleRelationType ? getCustomerRolesTypes(
    documentData.moduleRelationType,
  ) : [];
  const allRoles = documentData.roles;
  const contactRoles = [
    'Cooperating Agent',
    'Lender',
    'Closing Agent',
    'Referral Agent',
    'Escrow Agent',
    'Team Member',
  ];
  const extraRoles = [
    'Seller Agent',
    'Buyer Agent',
    'Broker',
    'Team Leader',
    'Contact',
    'Assistant',
    'Compliance Coordinator',
  ];
  let possibleRoles = [];

  customerRoleTypes.forEach((role) => {
    const order = getNextRoleOrder(allRoles, role);
    if (order <= 10) {
      possibleRoles.push({ role, order, type: 'inactive' });
    }
  });

  // Adding contact roles with order checks
  contactRoles.forEach((role) => {
    const order = getNextRoleOrder(allRoles, role);
    if (order <= 10) {
      possibleRoles.push({ role, order, type: 'inactive' });
    }
  });

  // Adding extra roles if they are not present in allRoles and without order checks (order will be null)
  extraRoles.forEach((role) => {
    const isRolePresent = allRoles.some((allRole) => allRole.role === role);
    if (!isRolePresent) {
      possibleRoles.push({ role, order: null, type: 'inactive' });
    }
  });
  return possibleRoles;
};

export const getRoleNameFromWords = (role) => {
  const roleName = role.role === 'Cooperating Agent' ? 'Coop Agent' : role.role;
  return `${conversion.wordsToCamelCase(roleName)}${role.order}`;
};

export const getRoleAndOrderFromRoleName = (roleName) => {
  if(!roleName) return { role: null, order: 0 };
  const role = roleName.replace(/[0-9]/g, '');
  const order = roleName.replace(/[^0-9]/g, '');
  const roleWords = conversion.camelCaseToWords(role);
  return { role: roleWords, order: order || 1 };
};

export const getRoleLabel = (role) => {
  return `${role.role}${role.order ? ` ${role.order}` : ''}`;
};

export const getCustomerRolesTypes = (moduleRelationType) => {
  if (
    moduleRelationType.includes('Seller') ||
    moduleRelationType.includes('Buyer')
  ) {
    return ['Seller', 'Buyer'];
  } else if (
    moduleRelationType.includes('Landlord') ||
    moduleRelationType.includes('Tenant')
  ) {
    return ['Landlord', 'Tenant'];
  } else if (moduleRelationType.includes('Referral')) {
    return ['Referral', null];
  }
};


export const generateColorIndex = (role) => {
  const roleString = `${role.role}:${role.order}`;
  let hash = 0;
  for (let i = 0; i < roleString.length; i++) {
    const char = roleString.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash; // Convert to 32bit integer
  }

  // Spread out the hash values by introducing an offset
  const offset = role.order * 7; // Multiplying by a prime number to reduce collisions
  const colorIndex = (Math.abs(hash) + offset) % roleColors.length;

  return colorIndex;
};