import { styled } from "@mui/system";
import {Box, Typography} from "@mui/material";

export const MainContent = styled(Box)({
  width: "100%",
  padding: 15
});

export const MainSummary = styled(Typography)({
  width: "100%",
  textAlign: "left",
});

MainSummary.defaultProps = {
  variant: "body2",
};

const Info = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.lightgrey,
  marginTop: 5,
}));

export const LeftInfo = styled(Info)({
  width: "50%",
  textAlign: "left",
});

LeftInfo.defaultProps = {
  variant: "body2",
  display: "inline-block"
};

export const RightInfo = styled(Info)({
  width: "50%",
  textAlign: "right",
});

RightInfo.defaultProps = {
  variant: "body2",
  display: "inline-block"
};
