import { Action, Date } from 'components/Common/Table/components';

export const Cells = [
  {
    id: 'action',
    value: 'action',
    disablePadding: true,
    label: 'Agent Audit Event',
    Wrapper: Action,
  },
  {
    id: 'createdAt',
    value: 'createdAt',
    disablePadding: false,
    label: 'Date Time',
    width: 400,
    Wrapper: Date,
  },
];
