import React from "react";
import { Divider } from "@mui/material";
import { AbeCard } from "components/Common";
import { ListsList } from "../ListsList";

export const ListCard = () => {
  return (
    <AbeCard title={"Lists"}>
      <Divider />
      <ListsList />
    </AbeCard>
  );
};
