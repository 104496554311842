import { styled } from "@mui/system";
import {Box} from "@mui/material";

export const FileInputBox = styled(Box)({
  backgroundColor: "#faf6f6",
  padding: 10,
  margin: "10px 0",
  borderRadius: 10
});

export const FileInputText = styled("span")({
  color: "#797777",
  padding: 10,
  margin: "10px 0",
});

