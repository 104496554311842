import { Box } from '@mui/system';
import { DocumentPdfViewer } from 'components/ModelViews';
import ViewportBanner from 'components/ModelViews/Documents/DocumentPdfViewer/components/ViewportBanner';
import { ZoomControls } from 'components/ModelViews/Documents/DocumentPdfViewer/components/ZoomControls';
import PropTypes from 'prop-types';
import React from 'react';

function DocumentEsignEditor({ documentData, templateView = false }) {
  return (
    <Box
      sx={{
        flexGrow: 1, // allow the PDF viewer to grow and fill the remaining space
        overflowY: 'auto', // allow the PDF viewer to scroll
        backgroundColor: 'grey', // background color of the PDF viewer
      }}
    >
      <ZoomControls />
      <ViewportBanner />
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        sx={{ background: '#F7F7F7' }}
      >
        <Box display="flex" flexGrow={1} height="0px">
          <DocumentPdfViewer
            documentData={documentData}
            templateView={templateView}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default DocumentEsignEditor;

DocumentEsignEditor.propTypes = {
  documentData: PropTypes.object.isRequired,
  templateView: PropTypes.bool,
};
