import { styled } from "@mui/system";
import { Typography } from "@mui/material";

import { BoxStyled } from "../styled";

export const ImportantDatesBoxStyled = styled(BoxStyled)({
  // gridTemplateColumns: "repeat(4, 1fr)",
});

export const YearsText = styled(Typography)({
  fontWeight: "400",
  fontSize: "16px",
  lineHeight: "24px",
  color: "#969696",
  alignSelf: "center",
});
