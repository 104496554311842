import { faCirclePlus } from '@fortawesome/pro-regular-svg-icons';
import { Box, Button, Divider } from '@mui/material';
import {
  EmptyResults,
  FontAwesomeIconComp,
  SkeletonSummary,
} from 'components/Common';
import { ToolbarRow } from 'components/Common/Table/TableToolbar/styled';
import { ListPagination } from 'components/ListPagination';
import { SearchInput } from 'components/SearchInput';
import { ListBox, NewButtonBox } from 'components/Styled';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { MarketingItem } from './components/MarketingItem';

// eslint-disable-next-line no-unused-vars
export const MarketingList = ({ relatedModel, relatedModelType }) => {
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  // eslint-disable-next-line no-unused-vars
  const [searchValue, setSearchValue] = useState('');

  const data = {
    success: true,
    message: 'Transaction notes.',
    data: {
      items: [
        {
          id: 48598,
          name: 'Folders (9"x12" Standard)',
          modelClassName: 'Product',
          type: 'Marketing Promo Item',
          invoiceType: {
            value: 'Marketing Promo Item',
          },
          vendorName: 'Mainframe Real Estate LLC',
          templateDescription: '',
          isInstalled: true,
          hasPhotoInvoices: null,
          designPreviewLabel: 'Upload',
        },
        {
          id: 53842,
          name: 'Mailer - Postcards (8.5 x 5.5)',
          modelClassName: 'Product',
          type: 'Marketing Graphic Design',
          invoiceType: {
            value: 'Marketing Graphic Design',
          },
          vendorName: 'Mainframe Real Estate LLC',
          templateDescription:
            'First Class postage delivers within a week. \n\nThis 8.5" x 5.5" jumbo postcard is printed on 130# gloss paper stock with UV coating on both sides. A mailing list will be automatically generated for you from the subject property.\n\nYou can select 2 call-to-actions, one for each side of the postcard. If you have specific photographs you want to use, please notate the file names upon checkout.',
          isInstalled: true,
          hasPhotoInvoices: null,
          designPreviewLabel: 'Upload',
        },
        {
          id: 53843,
          name: 'Mailer - Farming Campaign – Postcards Jumbo',
          modelClassName: 'Product',
          type: 'Marketing Graphic Design',
          invoiceType: {
            value: 'Marketing Graphic Design',
          },
          vendorName: 'Mainframe Real Estate LLC',
          templateDescription:
            'This 8.5" x 5.5" jumbo postcard is printed on 130# gloss paper stock with UV coating on both sides. The mailing list will need to be provided. Must schedule a meeting before ordering by going to this link: https://calendly.com/kamran-mainframe\n\n* The price may vary based on total quantity of cards and contacts. Enter the number of cards times the number of contacts for your quantity. (i.e. 4 cards a year to 100 contacts = Quantity 400)',
          isInstalled: true,
          hasPhotoInvoices: null,
          designPreviewLabel: 'Upload',
        },
        {
          id: 151535,
          name: 'Photo Set',
          modelClassName: 'Document',
          type: 'Photo Set',
          invoiceType: {
            value: 'Photo Set',
          },
          vendorName: false,
          templateDescription: false,
          isInstalled: false,
          hasPhotoInvoices: false,
          designPreviewLabel: 'Upload',
        },
        {
          id: 187221,
          name: 'Property - Just Listed 03',
          modelClassName: 'Document',
          type: 'Click Marketing',
          invoiceType: {
            value: 'Click Marketing',
          },
          vendorName: false,
          templateDescription: false,
          isInstalled: false,
          hasPhotoInvoices: null,
          designPreviewLabel: 'Upload',
        },
        {
          id: 153312,
          name: 'Property Custom 01',
          modelClassName: 'Document',
          type: 'Click Marketing',
          invoiceType: {
            value: 'Click Marketing',
          },
          vendorName: false,
          templateDescription: false,
          isInstalled: false,
          hasPhotoInvoices: null,
          designPreviewLabel: 'Upload',
        },
      ],
      meta: {
        page: 1,
        limit: 5,
        total: 4,
        totalPages: 1,
        otherData: {},
      },
    },
  };
  const AllMarketing = data?.data?.items;
  const totalPages = data?.data?.meta.totalPages;
  const totalCount = data?.data?.meta.total;

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const createMarketing = useCallback(() => {}, [relatedModel]);

  return (
    <>
      <ToolbarRow width="100%" justifyContent="space-between">
        <SearchInput changeHandler={setSearchValue} />
        <NewButtonBox>
          <Button
            size="small"
            variant="text"
            onClick={createMarketing}
            endIcon={<FontAwesomeIconComp icon={faCirclePlus} />}
          >
            New
          </Button>
        </NewButtonBox>
      </ToolbarRow>
      <Divider />
      <ListBox>
        {!AllMarketing ? (
          <SkeletonSummary />
        ) : AllMarketing && AllMarketing.length ? (
          AllMarketing.map((marketing) => (
            <React.Fragment key={marketing.id}>
              <MarketingItem marketing={marketing} />
            </React.Fragment>
          ))
        ) : (
          <EmptyResults />
        )}
        <Box style={{ textAlign: 'center' }}>
          {totalPages > 0 && (
            <ListPagination
              totalCount={totalCount}
              page={page}
              rowsPerPage={rowsPerPage}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              rowsPerPageOptions={[5]}
            />
          )}
        </Box>
      </ListBox>
    </>
  );
};

MarketingList.propTypes = {
  closeHandler: PropTypes.func,
  open: PropTypes.bool,
  relatedModel: PropTypes.object,
  relatedModelType: PropTypes.string,
};
