export const taskColor = (type) => {
    switch (type){
        case "PAST_DUE":
            return "#C9232d";
        case "DUE_TODAY":
            return "red";
        case "UPCOMING":
            return "orange";
        case "NO_DUE_DATE":
            return "lightgrey";
        case "COMPLETED":
            return "green";
    }
}