// Use a custom hook or a utility function for breadcrumb handling
import { TEMPLATE_MANAGER } from 'consts';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export const useBreadcrumbs = (initialParentId = 'home') => {
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [viewingParentId, setViewingParentId] = useState(initialParentId);
  const { tab } = useParams();
  const navigate = useNavigate();

  const updateBreadcrumbs = (newRow) => {
    setViewingParentId(newRow.id);
    setBreadcrumbs((prev) => [...prev, { id: newRow.id, name: newRow.name }]);
  };

  const resetBreadcrumbs = () => {
    setViewingParentId('home');
    setBreadcrumbs([]);
    navigate(`/${TEMPLATE_MANAGER(tab, 'home')}`);
  };

  const handleBreadcrumbNavigate = (id, index) => {
    setViewingParentId(id);
    setBreadcrumbs(breadcrumbs.slice(0, index + 1));
    navigate(`/${TEMPLATE_MANAGER(tab, id)}`);
  };

  return {
    breadcrumbs,
    viewingParentId,
    setViewingParentId,
    updateBreadcrumbs,
    handleBreadcrumbNavigate,
    resetBreadcrumbs,
  };
};
