import React from "react";
import PropTypes from "prop-types";
import {DateTime} from "luxon";

import { AuditText } from "./styled";

const EVENT_TYPES = {
  USER_CREATED: "User Created",
  USER_UPDATE: "User Updated",
  USER_CHANGE_PASSWORD: "User Change Password",
  USER_LOGIN: "User Login",
  PASSWORD_CHANGED: "PASSWORD_CHANGED",
  EDITING: "EDITING",
  ITEM_CREATED: "ITEM_CREATED",
};

export const Date = ({ value }) => {
  // MM/dd/yyyy hh:mm a with no leading zeros
  let date = value ? DateTime.fromISO(value).toFormat("M/d/yyyy h:mm a") : "Invalid date";
  return <AuditText>{date}</AuditText>;
};

Date.propTypes = {
  value: PropTypes.string,
};

export const Action = ({ value, item }) => {
  let action = "";
  switch (value) {
    case EVENT_TYPES.USER_LOGIN:
      action = `User login from IP: ${item?.content?.ip ?? ""}, browser: ${
        item?.content?.browser?.name ?? ""
      }, system type ${item?.content?.system ?? ""}`;
      break;
    case EVENT_TYPES.PASSWORD_CHANGED:
      action = "User Password Changed";
      break;
    case EVENT_TYPES.EDITING:
      action = `${item?.content?.field ?? ""} from ${
        item?.content?.oldData ?? ""
      } to ${item?.content?.newData ?? ""}`;
      break;
    case EVENT_TYPES.ITEM_CREATED:
      action = "The user created";
      break;
    default:
        action = value;
      break;
  }
  return <AuditText>{action}</AuditText>;
};

Action.propTypes = {
  value: PropTypes.string,
  item: PropTypes.object,
};
