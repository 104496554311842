import React, { useCallback, useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { faPencil } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import debounce from 'lodash.debounce';
import ContentEditable from 'react-contenteditable';
import { useDispatch } from 'react-redux';
import { useAutofillData } from 'components/ModelViews/Documents/ClickMarketingViewer/hooks/useAutofillData';
import { useCurrentItem } from 'components/ModelViews/Documents/ClickMarketingViewer/hooks/useCurrentItem';
import { CM_ITEM_TYPES } from 'consts';
import { updateItemValue } from 'redux/slices';

const getFontFamily = (fontFamily, header, autoFillData) => {
  if (fontFamily === 'themeFont') {
    const themeFont =
      autoFillData.themeFonts[header ? 'headerFont' : 'bodyFont'];
    if (!themeFont || themeFont.includes('Quicksand')) {
      return 'Quicksand';
    }
    return themeFont;
  }
  if (fontFamily === 'scriptFont') {
    return autoFillData.themeFonts['scriptFont'];
  }
  if (fontFamily.includes('Lato')) {
    return 'Lato';
  }
  if (fontFamily.includes('Quicksand')) {
    return 'Quicksand';
  }
  return fontFamily;
};

const getFontWeight = (fontFamily, header, autoFillData) => {
  if (fontFamily === 'themeFont') {
    fontFamily = autoFillData.themeFonts[header ? 'headerFont' : 'bodyFont'];
  }
  if (!fontFamily) {
    return 400;
  }
  const font =
    fontFamily.includes('Lato') || fontFamily.includes('Quicksand')
      ? fontFamily.substring(fontFamily.indexOf(' ') + 1)
      : fontFamily;

  switch (font) {
    case 'Light':
      return 300;
    case 'Regular':
      return 400;
    case 'Medium':
      return 500;
    case 'SemiBold':
      return 600;
    case 'Bold':
      return 700;
    default:
      return 400;
  }
};

export const Text = React.memo(({ itemId, preview }) => {
  const currentItem = useCurrentItem(itemId);
  const autoFillData = useAutofillData();
  const dispatch = useDispatch();

  const [value, setValue] = useState('');

  // Initialize value based on item type and autofill data
  useEffect(() => {
    let initialValue = currentItem.value;
    if (currentItem.type === 'autofill') {
      if (!currentItem.value || currentItem.value === 'Select Auto-Fill') {
        initialValue = autoFillData[currentItem.autofill] || '';
      }
    }
    if (
      (currentItem.type === CM_ITEM_TYPES.text.fillnow && !initialValue) ||
      initialValue === '&nbsp;'
    ) {
      initialValue = 'Fill Now';
    }
    setValue(initialValue);
  }, [currentItem, autoFillData]);

  // Memoize text styles to prevent recalculation on each render
  const textStyle = useMemo(() => {
    const style = {
      fontSize: currentItem.style.fontSize,
      fontWeight: getFontWeight(
        currentItem.style.fontFamily,
        currentItem.header,
        autoFillData,
      ),
      color: currentItem.style.color,
      textAlign: currentItem.style.textAlign,
      lineHeight: currentItem.h,
      fontFamily: getFontFamily(
        currentItem.style.fontFamily,
        currentItem.header,
        autoFillData,
      ),
    };

    if (currentItem.multiline) {
      style.width = '100%';
      style.maxWidth = '100%';
      style.height = '100%';
      style.lineHeight = currentItem.style.lineHeight || 1;
      style.whiteSpace = 'break-spaces';
      style.display = 'flex';
      console.log(currentItem.style);
      style.alignItems = currentItem.style?.alignment
        ? currentItem.style?.alignment
        : 'flex-start';
      style.justifyContent =
        currentItem.style?.textAlign === 'left'
          ? 'flex-start'
          : currentItem.style?.textAlign === 'right'
            ? 'flex-end'
            : 'center';
    }

    return style;
  }, [currentItem.style, currentItem.header, currentItem.h, autoFillData]);

  // Debounced function to update item value in the store
  const debouncedUpdate = useMemo(() => {
    const handler = debounce((id, value) => {
      dispatch(updateItemValue(id, value));
    }, 300);
    return handler;
  }, [dispatch]);

  useEffect(() => {
    return () => {
      debouncedUpdate.cancel();
    };
  }, [debouncedUpdate]);

  // Handle content changes
  const handleChange = useCallback(
    (e) => {
      const newValue = e.target.value;
      setValue(newValue);
      debouncedUpdate(itemId, newValue);
    },
    [debouncedUpdate, itemId],
  );

  const handleKeyDown = useCallback((e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      return false;
    }
  }, []);

  return (
    <>
      <ContentEditable
        tabIndex="0"
        id={`contenteditable-${itemId}`}
        tagName="div"
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        style={textStyle}
        html={value}
      />
      {currentItem.type === CM_ITEM_TYPES.text.fillnow &&
        value === 'Fill Now' &&
        !preview && (
          <FontAwesomeIcon
            className={'show-on-hover'}
            icon={faPencil}
            style={{
              color: 'white',
              fontWeight: '400',
              backgroundColor: 'blue',
              borderRadius: 15,
              padding: 5,
              position: 'absolute',
              top: -5,
              right: -5,
            }}
          />
        )}
    </>
  );
});

Text.propTypes = {
  itemId: PropTypes.string.isRequired,
  preview: PropTypes.bool,
};

Text.displayName = 'Text';
