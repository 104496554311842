import React from "react";
import ReactDOM from "react-dom/client";
import {BrowserRouter} from "react-router-dom";
import {Provider} from "react-redux";
import {PersistGate} from "redux-persist/integration/react";
import {GoogleOAuthProvider} from '@react-oauth/google';

import {persistor, store} from "store";
import "index.scss";
import App from "./App";

ReactDOM.createRoot(document.querySelector("#root")).render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter>
                    <GoogleOAuthProvider clientId="854369361030-9bfqkoqgfn91fcfrpfjmpacj9iokfkfi.apps.googleusercontent.com">
                        <App/>
                    </GoogleOAuthProvider>
            </BrowserRouter>
        </PersistGate>
    </Provider>,
);
