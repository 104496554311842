import { createApi } from '@reduxjs/toolkit/query/react';
import { transformResponse } from 'helpers';
import { axiosBaseQuery } from 'utils/baseQuery';
import { documentTemplateUrlBuilder } from './urlBuilder/documentTemplates';
import { conditionalInvalidateTags } from './utils/conditionalInvalidateTags';

export const documentTemplatesAPI = createApi({
  reducerPath: 'documentTemplatesAPI',
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  tagTypes: ['DocumentTemplates', 'Categories'],
  endpoints: (build) => ({
    getDocumentTemplatePdfUrl: build.query({
      query: (args) => ({
        url: documentTemplateUrlBuilder.getDocumentTemplatePdfUrl(args),
        method: 'GET',
      }),
    }),
    getCategoriesList: build.query({
      query: (args) => ({
        url: documentTemplateUrlBuilder.getCategoryList(args),
        method: 'GET',
      }),
      transformResponse,
      providesTags: ['Categories'],
    }),
    getCategory: build.query({
      query: (args) => ({
        url: documentTemplateUrlBuilder.getCategory(args),
        method: 'GET',
      }),
      providesTags: ['Categories'],
    }),
    createCategory: build.mutation({
      query: (body) => ({
        url: documentTemplateUrlBuilder.createCategory(),
        method: 'POST',
        data: {
          data: body,
        },
      }),
      onQueryStarted(arg, { dispatch, queryFulfilled }) {
        conditionalInvalidateTags(
          queryFulfilled,
          dispatch,
          documentTemplatesAPI,
          ['Categories'],
        );
      },
    }),
    updateCategory: build.mutation({
      query: (body) => ({
        url: documentTemplateUrlBuilder.getCategory(body),
        method: 'PUT',
        data: {
          data: body,
        },
      }),
      invalidatesTags: ['Categories'],
    }),
    getDocumentTemplatesList: build.query({
      query: (args) => ({
        url: documentTemplateUrlBuilder.getList(args),
        method: 'GET',
      }),
      transformResponse,
      providesTags: ['DocumentTemplates'],
    }),
    createDocumentTemplate: build.mutation({
      query: (body) => ({
        url: documentTemplateUrlBuilder.createDocumentTemplate(),
        method: 'POST',
        data: {
          data: body,
        },
      }),
      onQueryStarted(arg, { dispatch, queryFulfilled }) {
        conditionalInvalidateTags(
          queryFulfilled,
          dispatch,
          documentTemplatesAPI,
          ['DocumentTemplates'],
        );
      },
    }),
    updateDocumentTemplate: build.mutation({
      query: (body) => ({
        url: documentTemplateUrlBuilder.getDocumentTemplate(body),
        method: 'PUT',
        data: {
          data: body,
        },
      }),
      invalidatesTags: ['DocumentTemplates'],
    }),
    getDocumentTemplate: build.query({
      query: (args) => ({
        url: documentTemplateUrlBuilder.getDocumentTemplate(args),
        method: 'GET',
      }),
      providesTags: ['DocumentTemplates'],
    }),
    getClickMarketingTemplates: build.query({
      query: (args) => ({
        url: documentTemplateUrlBuilder.getClickMarketingTemplates(args),
        method: 'GET',
      }),
      transformResponse,
    }),
    getDocumentTemplateMediaUrl: build.query({
      query: (args) => ({
        url: documentTemplateUrlBuilder.getDocumentTemplateMediaUrl(args),
        method: 'GET',
      }),
      providesTags: ['Files'],
    }),
  }),
});

export const {
  useGetDocumentTemplatePdfUrlQuery,
  useGetCategoriesListQuery,
  useGetCategoryQuery,
  useCreateCategoryMutation,
  useLazyGetCategoriesListQuery,
  useUpdateCategoryMutation,
  useGetDocumentTemplatesListQuery,
  useCreateDocumentTemplateMutation,
  useUpdateDocumentTemplateMutation,
  useGetDocumentTemplateQuery,
  useGetClickMarketingTemplatesQuery,
  useGetDocumentTemplateMediaUrlQuery,
} = documentTemplatesAPI;
