import { styled } from "@mui/system";
import {Box, Container} from "@mui/material";
import {HeaderBox} from "./Box";

export const PageContainer = styled(Container)({
  backgroundColor: "#F7F7F7",
});

export const PageMainContent = styled(Container)({
  height: "fit-content",
  width: "100%",
  display: "flex",
});

export const TableContent = styled(Container)({
  height: "fit-content",
  width: "100%",
  padding: "0 16px",
  display: "flex",
});

export const ListBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  // justifyContent: "center",
  // alignItems: "center",
});

export const LayoutHeaderWrapper = styled(HeaderBox)({
  padding: "0px 0px 8px 5px",
  width: "calc(100% - 16px)",
  zIndex: 2,
  top: 0,
});

export const CardContentRow = styled(Box)({
  width: "100%",
  position: "relative",
  "&:hover .MuiTypography-noWrap": {
    whiteSpace: "normal",
    paddingRight: 25,
  },
  "&:hover .showOnHover": {
    display: "block",
  },
});
