import React from "react";
import PropTypes from "prop-types";
import { ConfirmMenuItem } from "components/Common";
import { useUpdateDocumentMutation } from "redux/rtk-query";
import { STATUSES } from "consts";

export const CloseApproveComponent = ({ onBackToMenu, handleCloseMenu,  item }) => {
  const [updateDocument] = useUpdateDocumentMutation();

  const onConfirm = () => {
    updateDocument({ id: item.id, status: STATUSES.COMPLETED, closedApproved: true });
    handleCloseMenu(true);
  };

  return (
    <ConfirmMenuItem
      title="Close Approve"
      message={"Are you sure you want to Close Approve this document?"}
      onConfirm={onConfirm}
      onCancel={onBackToMenu}
      confirmText={"Confirm"}
      confirmColor={"success"}
      cancelText={"Go Back"}
    />
  );
};

CloseApproveComponent.propTypes = {
  onBackToMenu: PropTypes.func,
  handleCloseMenu: PropTypes.func,
  item: PropTypes.object,
};
