import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { ClickMarketingMenu } from 'components/ModelViews/Documents/ClickMarketingEditorDrawer/components/ClickMarketingMenu/ClickMarketingMenu';
import PagesNavigation from 'components/ModelViews/Documents/ClickMarketingEditorDrawer/components/PagesNavigation/PagesNavigation';
import { ClickMarketingViewer } from 'components/ModelViews/Documents/ClickMarketingViewer/ClickMarketingViewer';

function ClickMarketingEditor({
  documentData,
  templateView = false,
  headerHeight,
}) {
  return (
    <Grid alignContent={'flex-start'} container>
      <Grid
        item
        xs={2}
        sx={{
          height: 'auto',
          width: '100%',
        }}
      >
        <ClickMarketingMenu headerHeight={headerHeight} />
      </Grid>
      <Grid
        item
        xs={2}
        style={{
          height: '100%',
          width: '100%',
          overflowY: 'scroll',
        }}
        // onMouseDown={() => {
        //   dispatch(setCurrentItem(null));
        // }}
      >
        <PagesNavigation />
      </Grid>

      <ClickMarketingViewer
        documentData={documentData}
        templateView={templateView}
      />
    </Grid>
  );
}

export default ClickMarketingEditor;

ClickMarketingEditor.propTypes = {
  documentData: PropTypes.object.isRequired,
  templateView: PropTypes.bool,
  headerHeight: PropTypes.number,
};
