import { faFilePdf, faImage } from '@fortawesome/pro-light-svg-icons';
import React from 'react';
import { FontAwesomeIconComp } from "components/Common";

export const getFileThumbnail = (file) => {
  const mimetypeVar = file.mimetype ? 'mimetype' : 'type';
  return file[mimetypeVar].includes('pdf') ? (
    <FontAwesomeIconComp color={'#d50808'} icon={faFilePdf} fixedWidth />
  ) : (
    <FontAwesomeIconComp color={'#d50808'} icon={faImage} fixedWidth />
  );
};
