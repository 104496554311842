import { endpoints } from 'consts';
import queryString from 'query-string';

export const invoiceTemplateUrlBuilder = {
  validateInvoiceTemplate: function () {
    return `${endpoints.invoiceTemplates}/validate`;
  },
  createInvoiceTemplate: function () {
    return endpoints.invoiceTemplates;
  },
  updateInvoiceTemplate: function ({ id }) {
    return `${endpoints.invoiceTemplates}/${id}`;
  },
  getInvoiceTemplates: function (params) {
    const urlParams = queryString.stringify(params, { skipNull: true });
    return `${endpoints.invoiceTemplates}?${urlParams}`;
  },
  getInvoiceTemplate: function ({ id }) {
    return `${endpoints.invoiceTemplates}/${id}`;
  },
  getCategoryList: function (params) {
    const urlParams = queryString.stringify(params, { skipNull: true });
    return `${endpoints.invoiceCategories}/list?${urlParams}`;
  },
  createCategory: function () {
    return endpoints.invoiceCategories;
  },
  getCategory: function ({ id }) {
    return `${endpoints.invoiceCategories}/${id}`;
  },
  getInvoiceTemplateMedia: function (id) {
    return `${endpoints.invoiceTemplates}/${id}/media`;
  },
  getInvoiceTemplateMediaUrl: function ({ id, uuid }) {
    return `${endpoints.invoiceTemplates}/${id}/media/${uuid}`;
  },
};
