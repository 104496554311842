// ReportFiltersTree.js
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-final-form';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TreeView, TreeItem } from '@mui/lab';
import { useGetReportModulesQuery } from 'redux/rtk-query';

const ReportFiltersTree = ({ onSelect }) => {
  const { data: moduleData } = useGetReportModulesQuery();
  const form = useForm();
  const moduleField = form.getFieldState('module');
  const moduleValue = moduleField ? moduleField.value : null;

  const [expanded, setExpanded] = useState([]);
  const [loadedChildren, setLoadedChildren] = useState({});
  const [visitedPaths, setVisitedPaths] = useState(new Set());

  // Handle expand/collapse of tree nodes
  const handleToggle = (event, nodeIds) => {
    // Determine which nodes are newly expanded
    const newExpandedNodes = nodeIds.filter((id) => !expanded.includes(id));
    setExpanded(nodeIds);

    // When nodes are expanded, load their children
    newExpandedNodes.forEach((nodeId) => {
      handleNodeExpansion(nodeId);
    });
  };

  // Function to handle node selection
  const handleNodeSelect = (event, nodeId) => {
    event.preventDefault();

    const [fullPath, filterString] = nodeId.split('||');
    const selectedFilter = JSON.parse(filterString);

    if (selectedFilter.type !== 'relation') {
      // If it's a field, call onSelect
      if (onSelect) {
        onSelect({
          name: selectedFilter.name,
          path: [selectedFilter.key],
          label: fullPath,
          type: selectedFilter.type,
          enum: selectedFilter.enum,
        });
      }
    }
  };

  // Function to load children lazily when a node is expanded
  const handleNodeExpansion = (nodeId) => {
    const [fullPath, filterString] = nodeId.split('||');
    const filter = JSON.parse(filterString);

    if (filter.type === 'relation') {
      // Check for cycles
      if (visitedPaths.has(fullPath)) {
        console.warn('Cyclic relation detected for path:', fullPath);
        return;
      }

      // Mark this path as visited
      setVisitedPaths((prev) => new Set([...prev, fullPath]));

      // Load children if not already loaded
      if (!loadedChildren[fullPath]) {
        const relatedFields = moduleData?.data?.[filter.relatedModule] || [];
        setLoadedChildren((prev) => ({
          ...prev,
          [fullPath]: relatedFields,
        }));
      }
    }
  };

  // Recursive function to render tree items
  const renderTreeItems = (filter, path = '') => {
    const hasChildren = filter.type === 'relation';
    const fullPath = path ? `${path} -> ${filter.name}` : filter.name;

    // Unique nodeId combining fullPath and filter object
    const nodeId = `${fullPath}||${JSON.stringify(filter)}`;

    return (
      <TreeItem
        nodeId={nodeId}
        label={filter.name}
        key={nodeId}
        collapseIcon={hasChildren ? <ExpandMoreIcon /> : null}
        expandIcon={hasChildren ? <ChevronRightIcon /> : null}
      >
        {hasChildren &&
          (loadedChildren[fullPath]?.length > 0 ? (
            loadedChildren[fullPath].map((childFilter) =>
              renderTreeItems(childFilter, fullPath),
            )
          ) : (
            // Render a placeholder to ensure expand icon appears
            <TreeItem
              nodeId={`${nodeId}-placeholder`}
              label="Loading..."
              key={`${nodeId}-placeholder`}
            />
          ))}
      </TreeItem>
    );
  };

  if (!moduleValue || !moduleData) {
    return <div>Loading...</div>;
  }

  const moduleFilters = moduleData?.data?.[moduleValue] || [];

  return (
    <TreeView
      expanded={expanded}
      onNodeToggle={handleToggle} // Use handleToggle here
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      onNodeSelect={handleNodeSelect}
    >
      {moduleFilters.map((filter) => renderTreeItems(filter))}
    </TreeView>
  );
};

ReportFiltersTree.propTypes = {
  onSelect: PropTypes.func.isRequired,
};

export default ReportFiltersTree;
