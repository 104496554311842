import { CONTACT_TYPES } from 'consts';
import { useMemo } from 'react';

export function useContactOptions(form) {
  const typeOptions = useMemo(() => {
    if (
      form.getState().initialValues.type &&
      form.getState().initialValues.type.length > 0
    ) {
      return Object.keys(CONTACT_TYPES)
        .filter(
          (key) =>
            form.getState().initialValues.type === CONTACT_TYPES[key].value,
        )
        .map((key) => {
          if (form.getState().initialValues.type === CONTACT_TYPES[key].value) {
            return CONTACT_TYPES[key];
          }
        });
    } else {
      return Object.keys(CONTACT_TYPES).map((key) => {
        return CONTACT_TYPES[key];
      });
    }
  }, [form.getState().values.type]);

  return {
    typeOptions,
  };
}
