import React, { useState } from "react";
import PropTypes from "prop-types";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { InputAdornment, OutlinedInput } from "@mui/material";

import { FontAwesomeIconComp } from "components/Common";

import { Label, FormControlStyled } from "./styled";

export const PasswordInput = props => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <FormControlStyled variant="outlined" sx={{ marginBottom: "0" }}>
      <Label htmlFor="outlined-adornment-password">Password</Label>
      <OutlinedInput
        sx={{ borderRadius: "8px", paddingRight: "8px" }}
        id="outlined-adornment-password"
        type={showPassword ? "text" : "password"}
        endAdornment={
          <InputAdornment position="end">
            <FontAwesomeIconComp
              onClick={() => setShowPassword(!showPassword)}
              icon={showPassword ? faEyeSlash : faEye}
              style={{
                height: "24px",
                width: "24px",
                color: "#969696",
                cursor: "pointer",
              }}
            />
          </InputAdornment>
        }
        label="Password"
        {...props.input}
      />
    </FormControlStyled>
  );
};

PasswordInput.propTypes = {
  input: PropTypes.object,
};
