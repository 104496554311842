import React from "react";
import PropTypes from "prop-types";
import { ConfirmMenuItem } from "components/Common";
import { useUpdateDocumentMutation } from "redux/rtk-query"

export const UnApproveComponent = ({ onBackToMenu, handleCloseMenu, item }) => {

  const [updateDocument] = useUpdateDocumentMutation();

  const onConfirm = () => {
    updateDocument({ id: item.id, closedApproved: false });
    handleCloseMenu(true);
  };

  return (
    <ConfirmMenuItem
      title={"UnApprove"}
      message={"Are you sure you want to unapprove this document?"}
      onConfirm={onConfirm}
      onCancel={onBackToMenu}
      confirmText={"Confirm"}
      confirmColor={"error"}
      cancelText={"Go Back"}
    />
  );
};

UnApproveComponent.propTypes = {
  onBackToMenu: PropTypes.func,
  handleCloseMenu: PropTypes.func,
  item: PropTypes.object,
};