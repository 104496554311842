import { Button } from '@mui/material';
import { DetailsLayout } from 'components/Layouts';
import { UPDATE_TRANSACTION } from 'consts';
import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { useGetTransactionQuery } from 'redux/rtk-query';

//TODO Complete this page
export const CommissionOverviewPage = () => {
  let { transactionDetailsId } = useParams();
  const navigate = useNavigate();

  const [transactionInfo, setTransactionInfo] = useState(null);
  const {
    data: transactionData,
    isSuccess,
    isFetching,
  } = useGetTransactionQuery({ id: transactionDetailsId });

  useEffect(() => {
    if (isSuccess) {
      setTransactionInfo(transactionData.data);
    }
  }, [isFetching, isSuccess]);

  return (
    <>
      {transactionInfo && (
        <>
          <DetailsLayout
            header={
              <>
                <span>{transactionInfo.name}</span>
                <Button
                  size={'small'}
                  onClick={() => {
                    navigate(UPDATE_TRANSACTION(transactionDetailsId));
                  }}
                >
                  Edit
                </Button>
              </>
            }
            leftContent={<></>}
            rightContent={<></>}
          />
          <Outlet />
        </>
      )}
    </>
  );
};
