import { Button } from '@mui/material';
import { Box } from '@mui/system';
import { CreateButton } from 'components/Common';
import { LayoutHeader } from 'components/Layouts';
import { PageMainContent } from 'components/Styled';
import { LIST_INVOICES } from 'consts';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { InvoiceTemplatesTable } from './components';
import { CategoriesTable } from './components/CategoriesTable/CategoriesTable';

export const InvoiceTemplatesPage = () => {
  const [view, setView] = React.useState('templates');
  return (
    <>
      <LayoutHeader
        title={view === 'templates' ? 'Invoice Templates' : 'Categories'}
        afterTitle={
          <Box pl={4}>
            <Button
              variant={view === 'templates' ? 'contained' : ''}
              onClick={() => setView('templates')}
            >
              Templates
            </Button>
            <Button
              variant={view === 'categories' ? 'contained' : ''}
              onClick={() => setView('categories')}
            >
              Categories
            </Button>
          </Box>
        }
      >
        <CreateButton
          path={`/${LIST_INVOICES}`}
          title={'Invoices'}
          icon={null}
        />
      </LayoutHeader>
      <PageMainContent maxWidth={false} component="main" disableGutters>
        {view === 'templates' && <InvoiceTemplatesTable />}
        {view === 'categories' && <CategoriesTable />}
      </PageMainContent>
      <Outlet />
    </>
  );
};
