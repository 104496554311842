import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import { Divider } from '@mui/material';
import { FontAwesomeIconComp } from 'components/Common';
import { conversion } from 'helpers';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { useGetGroupDetailsQuery } from 'redux/rtk-query';

export const SecurityOverview = () => {
  const [schema, setSchema] = useState([]);

  const { data, isSuccess } = useGetGroupDetailsQuery({ id: 2 });

  useEffect(() => {
    if (isSuccess) {
      setSchema(data.data.schema);
    }
  }, [isSuccess]);

  return (
    <>
      {schema.map((module) => {
        let moduleName = conversion.titleCase(module.name);
        return (
          <div key={module.id}>
            <strong>{moduleName}</strong>
            <div>
              {module.actions.map((action) => {
                return (
                  <div
                    style={{ width: '33%', display: 'inline-block' }}
                    key={`action${action.id}`}
                  >
                    <span>
                      {conversion.titleCase(action.name)}
                      <FontAwesomeIconComp
                        color={action.enabled ? 'green' : 'grey'}
                        fontSize={12}
                        icon={faCheckCircle}
                      />
                    </span>
                  </div>
                );
              })}
            </div>
            <Divider />
          </div>
        );
      })}
    </>
  );
};

SecurityOverview.propTypes = {
  groupId: PropTypes.string,
};
