import { RIGHT_OPTIONS } from "consts/rightsPermissions";


export const defaultValues = {
  type: null,
  firstName: '',
  lastName: '',
  officePhone: '',
  mobilePhone: '',
  officeFax: '',
  email: null,
  secondaryEmail: null,
  birthday: {
    day: null,
    month: null,
    year: null,
  },
  source: '',
  sourceWebsite: '',
  anniversaryDate: null,
  homeAnniversary: null,
  description: '',
  company: null,
  companyName: '',
  communication: [],
  lists: [],
  tags: [],
  agent: {},
  shareWith: [],
  companyStructureUnits: [],
  shareWithOption: RIGHT_OPTIONS.agentOnly.value,
};
//
// export default {
//     type: "",
//     officePhone: "",
//     officeFax: "",
//     mobilePhone: "",
//     email: null,
//     secondaryEmail: null,
//     list: [],
//     birthday: null,
//     anniversaryDate: null,
//     homeAnniversary: null,
//     primaryAddress: {},
//     secondaryAddress: {},
//     description: "",
//     suffix: null,
//     firstName: null,
//     middleName: null,
//     lastName: null,
//     nickname: null,
//     communication: [],
//     contactClassification: [],
//     company: null,
//     jobTitle: "",
//     source: null,
//     tags: [],
//     relationships: [],
//     contactStatus: null,
//     vendorApproved: null,
//     mlsId: null,
//     licenseNumber: null,
//     agent: null,
//     vendorTypeId: null,
//     vendor1099: null,
//     agentId: null,
//     shareWith: [],
//     companyStructureUnits: [],
//     shareWithOption: null,
// };
