import { createApi } from '@reduxjs/toolkit/query/react';

import { axiosBaseQuery } from 'utils/baseQuery';

import { documentUrlBuilder } from './urlBuilder/documents';

export const documentsAPI = createApi({
  reducerPath: 'documentsAPI',
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  tagTypes: ['Documents'],
  endpoints: (build) => ({
    uploadFiles: build.mutation({
      query: (body) => {
        return {
          url: documentUrlBuilder.uploadFiles(body.get('id')),
          method: 'POST',
          data: body,
        };
      },
      invalidatesTags: ['Documents'],
    }),
    getDocumentPdfUrl: build.query({
      query: (args) => ({
        url: documentUrlBuilder.getDocumentPdfUrl(args),
        method: 'GET',
      }),
    }),
    getDocumentsList: build.query({
      query: (args) => ({
        url: documentUrlBuilder.getList(args),
        method: 'GET',
      }),
      providesTags: ['Documents'],
    }),
    createDocument: build.mutation({
      query: (body) => ({
        url: documentUrlBuilder.createDocument(),
        method: 'PUT',
        data: {
          data: body,
        },
      }),
      invalidatesTags: ['Documents'],
    }),
    createMultipleDocuments: build.mutation({
      query: (body) => ({
        url: documentUrlBuilder.createMultipleDocuments(),
        method: 'POST',
        data: {
          data: body,
        },
      }),
      invalidatesTags: ['Documents'],
    }),
    updateDocument: build.mutation({
      query: (body) => ({
        url: documentUrlBuilder.updateDocument(body),
        method: 'PUT',
        data: {
          data: body,
        },
      }),
      invalidatesTags: ['Documents'],
    }),
    getDocument: build.query({
      query: (args) => ({
        url: documentUrlBuilder.getDocument(args),
        method: 'GET',
      }),
      providesTags: ['Documents'],
    }),
  }),
});

export const {
  useUploadFilesMutation,
  useGetDocumentPdfUrlQuery,
  useGetDocumentsListQuery,
  useCreateDocumentMutation,
  useCreateMultipleDocumentsMutation,
  useUpdateDocumentMutation,
  useGetDocumentQuery,
} = documentsAPI;
