import { Grid } from '@mui/material';
import { AbeForm, FormValidationProvider } from 'components/Forms';
import PropTypes from 'prop-types';
import React from 'react';
import { useValidateContactMutation } from 'redux/rtk-query';

import {
  CategorizationBox,
  CompanyBox,
  ImportantDatesBox,
  OfficeUseOnlyBox,
  OverviewBox,
  PrimaryAddressBox,
  RelationshipBox,
  SecondaryAddressBox,
} from '../CreateContactPage/components';
import { processFormData } from './formData';

const ContactForm = (props) => {
  const renderFields = (form, values, showAllFields) => (
    <Grid container spacing={2} style={{ padding: '0 16px' }}>
      <Grid item xs={6}>
        <OverviewBox showAllFields={showAllFields} />
        {values.type && (
          <>
            <PrimaryAddressBox />
            <SecondaryAddressBox />
          </>
        )}
      </Grid>
      <Grid item xs={6}>
        {values.type && (
          <>
            {/* eslint-disable-next-line react/jsx-no-undef */}
            <CompanyBox showAllFields={showAllFields} />
            <ImportantDatesBox />
            <RelationshipBox />
            <CategorizationBox showAllFields={showAllFields} />
            <OfficeUseOnlyBox showAllFields={showAllFields} />
          </>
        )}
      </Grid>
    </Grid>
  );
  const [validate] = useValidateContactMutation();

  return (
    <FormValidationProvider value={{ validate, processFormData }}>
      <AbeForm {...props}>{renderFields}</AbeForm>
    </FormValidationProvider>
  );
};

ContactForm.propTypes = {
  afterSave: PropTypes.func,
  handleSubmitFunc: PropTypes.func,
  initialValues: PropTypes.object,
  title: PropTypes.string,
  nameButton: PropTypes.string,
  isLoading: PropTypes.bool,
};

export default ContactForm;
