import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  undoStack: [],
  redoStack: [],
  needsSaving: false,
  activeMenu: 'primary',
  activePage: null,
  bannerMessages: [],
  templateView: false,
  currentDocument: {
    htmlTemplate: [],
  },
  activeFieldIds: [],
  autofillData: {},
  scale: 1,
};

export const clickMarketingSlice = createSlice({
  name: 'clickMarketing',
  initialState,
  reducers: {
    setMenuCM: (state, action) => {
      state.activeMenu = action.payload;
    },
    setBannerMessageCM: (state, action) => {
      state.bannerMessages.push(action.payload);
    },
    removeBannerMessageCM: (state, action) => {
      state.bannerMessages = state.bannerMessages.filter(
        (message) => message.bannerMessage !== action.payload.bannerMessage,
      );
    },
    setDocumentDataCM: (state, action) => {
      state.currentDocument = action.payload;
      if (state.currentDocument?.parentTemplate) {
        state.templateView = true;
      }
      if (!state.currentDocument?.htmlTemplate) {
        state.currentDocument = {
          ...state.currentDocument,
          htmlTemplate: [],
        };
      }
      if (state.currentDocument?.htmlTemplate?.options?.pageOrder[0]) {
        state.activePage =
          state.currentDocument?.htmlTemplate?.options?.pageOrder[0];
      }
    },
    setAutofillDataCM: (state, action) => {
      state.autofillData = action.payload;
    },
    setScaleCM: (state, action) => {
      state.scale = action.payload;
    },
    setActiveFieldCM: () => {
      // const activeFieldIds = parseActiveFieldIds(action.payload);
      // const activeFields = collectActiveFields(
      //   state.currentDocument.htmlTemplate,
      //   activeFieldIds,
      // );
      // const groupIds = extractGroupIds(activeFields);
      // const groupFieldIds = collectGroupFieldIds(
      //   state.currentDocument.htmlTemplate,
      //   groupIds,
      // );
      // state.activeFieldIds = [...new Set([...activeFieldIds, ...groupFieldIds])];
      // setMenu(state, { payload: 'primary' });
    },
    sortPagesCM: (state, action) => {
      state.currentDocument.htmlTemplate.options.pageOrder = action.payload;
    },
    setActivePageCM: (state, action) => {
      state.activePage = action.payload;
    },
    updateItemValue: (state, action) => {
      const { id, value } = action.payload;
      const item = state.currentDocument.htmlTemplate.items.find(
        (item) => item.id === id,
      );
      if (item) {
        item.value = value;
        state.needsSaving = true; // Mark document as needing saving after the update
      }
    },
  },
});

export const {
  setMenuCM,
  setDocumentDataCM,
  setAutofillDataCM,
  setScaleCM,
  setBannerMessageCM,
  removeBannerMessageCM,
  arrangeGroupCM,
  setActiveFieldCM,
  sortPagesCM,
  setActivePageCM,
  updateItemValue,
} = clickMarketingSlice.actions;
export const clickMarketingSliceReducer = clickMarketingSlice.reducer;
