import { TableBody, TableContainer } from '@mui/material';

import {
  TableFooter,
  TableHead,
  TableRow,
  TableToolbar,
} from 'components/Common';
import {
  Table as MUITable,
  TableCell,
  TableCellLoader,
  TablePaper,
  TableWrapper,
} from 'components/Styled';
import PropTypes from 'prop-types';
import React from 'react';

export const Table = ({
  title,
  cells,
  menuItems,
  isLoading,
  isCollapsible,
  rows,
  rowsPerPageOptions,
  totalCount,
  totalPages,
  order,
  orderBy,
  page,
  rowsPerPage,
  search,
  afterSearch,
  handleRequestSort,
  setFilterValues,
  filterValues,
  selected,
  setSelected,
  setPage,
  setRowsPerPage,
  handleSearch,
  toolbarActions,
  withSearch,
  onRowClick,
  hideTableHeader = false,
  hideHeaderCheckbox = false,
  handleRowExpand,
  expandedRows,
}) => {
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  return (
    <TableWrapper>
      {withSearch ? (
        <TableToolbar
          title={title}
          handleSearch={handleSearch}
          toolbarActions={toolbarActions}
          totalCount={totalCount}
          afterSearch={afterSearch}
        />
      ) : null}
      <TablePaper
        sx={!title ? { borderTopRightRadius: 0, borderTopLeftRadius: 0 } : {}}
      >
        <TableContainer>
          <MUITable>
            {!hideTableHeader && (
              <TableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={rows?.length}
                cells={cells}
                setSelected={setSelected}
                selected={selected}
                menuItems={menuItems}
                hideHeaderCheckbox={hideHeaderCheckbox}
                setFilterValues={setFilterValues}
                filterValues={filterValues}
              />
            )}
            <TableBody>
              {isLoading ? (
                <tr>
                  {setSelected && <TableCell />}
                  {cells.map((cell, index) => {
                    return (
                      <TableCell key={index}>
                        <TableCellLoader />
                      </TableCell>
                    );
                  })}
                </tr>
              ) : (
                rows.map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      onRowClick={onRowClick}
                      setSelected={setSelected}
                      selected={selected}
                      search={search}
                      key={labelId}
                      row={row}
                      cells={cells}
                      labelId={labelId}
                      menuItems={menuItems}
                      isCollapsible={isCollapsible}
                      expandedRows={expandedRows}
                      handleRowExpand={handleRowExpand}
                    />
                  );
                })
              )}
            </TableBody>
          </MUITable>
        </TableContainer>
        {totalPages > 0 && (
          <TableFooter
            count={totalPages}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            rowsPerPageOptions={rowsPerPageOptions}
          />
        )}
      </TablePaper>
    </TableWrapper>
  );
};

Table.defaultProps = {
  withSearch: true,
};

Table.propTypes = {
  withSearch: PropTypes.bool,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  isLoading: PropTypes.bool,
  isCollapsible: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  rows: PropTypes.arrayOf(PropTypes.shape),
  totalPages: PropTypes.number,
  totalCount: PropTypes.number,
  order: PropTypes.string,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
      icon: PropTypes.object,
    }),
  ),
  cells: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      numeric: PropTypes.bool,
      disablePadding: PropTypes.bool,
      label: PropTypes.string,
    }),
  ),
  search: PropTypes.string,
  afterSearch: PropTypes.node,
  orderBy: PropTypes.string,
  page: PropTypes.number,
  selected: PropTypes.array,
  rowsPerPage: PropTypes.number,
  handleRequestSort: PropTypes.func,
  toolbarActions: PropTypes.node,
  setPage: PropTypes.func,
  setRowsPerPage: PropTypes.func,
  handleSearch: PropTypes.func,
  setSelected: PropTypes.func,
  onRowClick: PropTypes.func,
  hideHeaderCheckbox: PropTypes.bool,
  hideTableHeader: PropTypes.bool,
  setFilterValues: PropTypes.func,
  filterValues: PropTypes.object,
  handleRowExpand: PropTypes.func,
  expandedRows: PropTypes.object,
};
