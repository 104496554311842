import { Divider } from '@mui/material';
import { EmptyResults, SkeletonSummary } from 'components/Common';
import { ListPagination } from 'components/ListPagination';
import { ListBox } from 'components/Styled';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useGetContactListsQuery, useGetContactQuery } from 'redux/rtk-query';
import { ListRow } from './components/ListRow';

export const ListsList = () => {
  const [page, setPage] = React.useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [loading, setLoading] = useState(true);
  const [listItems, setListItems] = useState([]);
  const { contactDetailsId: id } = useParams();
  const { data: { data: contactData } = {} } = useGetContactQuery({ id: id });
  const { data: listData, isLoading } = useGetContactListsQuery(
    { uuid: contactData?.uuid, limit: rowsPerPage },
    {
      skip: !contactData,
    },
  );
  const totalCount = listData?.data.meta.totalCount;

  useEffect(() => {
    if (isLoading) {
      setLoading(true);
    } else if (listData) {
      setListItems(listData.data.items);
      setTotalPages(listData.data.meta.totalPages);
      setLoading(false);
    }
  }, [isLoading, listData]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  return (
    <>
      <ListBox>
        {loading ? (
          <SkeletonSummary />
        ) : listItems && listItems.length ? (
          listItems.map((list) => (
            <React.Fragment key={list.id}>
              <ListRow list={list} />
            </React.Fragment>
          ))
        ) : (
          <EmptyResults />
        )}
        <Divider />
        {totalPages > 1 && (
          <ListPagination
            totalCount={totalCount}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            rowsPerPageOptions={[5]}
          />
        )}
      </ListBox>
    </>
  );
};

ListsList.propTypes = {
  closeHandler: PropTypes.func,
  open: PropTypes.bool,
  relatedModel: PropTypes.object,
};
