import {
  faObjectGroup,
  faObjectUngroup,
} from '@fortawesome/pro-light-svg-icons';
import {
  faObjectsAlignBottom,
  faObjectsAlignCenterHorizontal,
  faObjectsAlignCenterVertical,
  faObjectsAlignLeft,
  faObjectsAlignRight,
  faObjectsAlignTop,
} from '@fortawesome/pro-solid-svg-icons';
import {
  Button,
  ButtonGroup,
  Collapse,
  Divider,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { FontAwesomeIconComp, MenuItemButton } from 'components/Common';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { activeFieldsSelector } from 'redux/selectors';
import {
  arrangeGroup,
  groupFields,
  unGroupFields,
  updateField,
} from 'redux/slices';

const GroupItemButton = () => {
  const dispatch = useDispatch();
  const activeFields = useSelector(activeFieldsSelector);
  const showGroupButton = activeFields[0] && activeFields[0].groupId;
  const allCheckboxes =
    activeFields.length > 1 &&
    activeFields.every((field) => field.type === 'checkbox');

  if (allCheckboxes) {
    return (
      <>
        <Divider />
        <MenuItemButton
          icon={
            <FontAwesomeIconComp
              icon={showGroupButton ? faObjectUngroup : faObjectGroup}
            />
          }
          label={
            showGroupButton
              ? 'Ungroup Selected Checkboxes'
              : 'Group Selected Checkboxes'
          }
          onClick={() => {
            if (showGroupButton) {
              dispatch(unGroupFields());
            } else {
              dispatch(groupFields());
            }
          }}
        />
        <Collapse in={showGroupButton}>
          <Box sx={{ m: 1, textAlign: 'center' }}>
            <ButtonGroup>
              <Button
                variant={
                  activeFields[0].groupType !== 'chooseAll'
                    ? 'contained'
                    : 'outlined'
                }
                onClick={() => {
                  dispatch(
                    updateField({ properties: { groupType: 'chooseOne' } }),
                  );
                }}
              >
                Choose One
              </Button>
              <Button
                variant={
                  activeFields[0].groupType === 'chooseAll'
                    ? 'contained'
                    : 'outlined'
                }
                onClick={() => {
                  dispatch(
                    updateField({ properties: { groupType: 'chooseAll' } }),
                  );
                }}
              >
                Choose All
              </Button>
            </ButtonGroup>
          </Box>
        </Collapse>
      </>
    );
  }
};

export function GroupToolbar() {
  const dispatch = useDispatch();
  return (
    <Box>
      <GroupItemButton />
      <Divider />
      <Typography pt={1} pl={3} variant="subtitle1" gutterBottom>
        Align Items
      </Typography>
      <MenuItemButton
        icon={<FontAwesomeIconComp icon={faObjectsAlignLeft} />}
        label={'Left'}
        onClick={() => {
          dispatch(arrangeGroup('left'));
        }}
      />
      <MenuItemButton
        icon={<FontAwesomeIconComp icon={faObjectsAlignCenterHorizontal} />}
        label={'Center'}
        onClick={() => {
          dispatch(arrangeGroup('center'));
        }}
      />
      <MenuItemButton
        icon={<FontAwesomeIconComp icon={faObjectsAlignRight} />}
        label={'Right'}
        onClick={() => {
          dispatch(arrangeGroup('right'));
        }}
      />
      <MenuItemButton
        icon={<FontAwesomeIconComp icon={faObjectsAlignTop} />}
        label={'Top'}
        onClick={() => {
          dispatch(arrangeGroup('top'));
        }}
      />
      <MenuItemButton
        icon={<FontAwesomeIconComp icon={faObjectsAlignCenterVertical} />}
        label={'Middle'}
        onClick={() => {
          dispatch(arrangeGroup('middle'));
        }}
      />
      <MenuItemButton
        icon={<FontAwesomeIconComp icon={faObjectsAlignBottom} />}
        label={'Bottom'}
        onClick={() => {
          dispatch(arrangeGroup('bottom'));
        }}
      />
    </Box>
  );
}
