import { Button } from '@mui/material';
import { NotesListModal } from 'components/ModelViews/Notes';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

export const NoteCount = ({ value }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <NotesListModal open={open} closeHandler={setOpen} relatedModel={value} />
      <Button
        style={{ minWidth: 20 }}
        fullWidth
        size={'small'}
        onClick={() => {
          setOpen(true);
        }}
      >
        {value.notes}
      </Button>
    </>
  );
};

NoteCount.propTypes = {
  value: PropTypes.shape({
    id: PropTypes.number,
    notes: PropTypes.number,
  }),
  id: PropTypes.number,
};
