import { Button } from '@mui/material';
import { Table } from 'components/Common';
import { ButtonSection, Header, HeadingSection } from 'components/Styled';
import { useTableData } from 'hooks';
import PropTypes from 'prop-types';
import React from 'react';
import { useGetInvoiceTemplatesQuery } from 'redux/rtk-query';

const Cells = [
  {
    id: 'name',
    value: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Name',
    isVisible: true,
  },
];

export const InvoiceTemplateSelection = ({ type, onRowClick, closeDrawer }) => {
  const {
    page,
    rowsPerPage,
    search,
    loading,
    setPage,
    setSearch,
    totalPages,
    rows,
  } = useTableData(useGetInvoiceTemplatesQuery, Cells, 'name', 'asc', 10, {
    type: type,
  });

  return (
    <>
      <HeadingSection>
        <Header variant="h4">Select {type ? type : 'Invoice Template'}</Header>
        <ButtonSection>
          <Button variant="outlined" onClick={closeDrawer}>
            Cancel
          </Button>
        </ButtonSection>
      </HeadingSection>
      <Table
        onRowClick={onRowClick}
        handleSearch={setSearch}
        search={search}
        isLoading={loading}
        rows={rows}
        totalPages={totalPages}
        cells={Cells}
        page={page}
        rowsPerPage={rowsPerPage}
        setPage={setPage}
      />
    </>
  );
};

InvoiceTemplateSelection.propTypes = {
  type: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  onRowClick: PropTypes.func,
  closeDrawer: PropTypes.func,
};
