import React from 'react';
import { useSelector } from 'react-redux';
import { activeFieldsSelector } from 'redux/selectors';
import { GroupToolbar, TextToolbar } from './components';

export const ActiveFieldMenu = () => {
  const activeFields = useSelector(activeFieldsSelector);
  const showTextToolbar =
    activeFields[0].type === 'text' ||
    activeFields[0].type === 'datePicker' ||
    activeFields[0].type === 'checkbox';

  return (
    <>
      {showTextToolbar && <TextToolbar />}
      {activeFields.length > 1 && <GroupToolbar />}
    </>
  );
};
