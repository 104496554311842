import { createApi } from "@reduxjs/toolkit/query/react";

import { axiosBaseQuery } from "utils/baseQuery";
import { transformResponse } from "helpers";

import { cappingUrlBuilder } from "./urlBuilder/cappings";

export const cappingsAPI = createApi({
  reducerPath: "cappingsAPI",
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  tagTypes: ["Cappings"],
  endpoints: build => ({
    getCappingsList: build.query({
      query: args => ({
        url: cappingUrlBuilder.getList(args),
        method: "GET",
      }),
      transformResponse,
      providesTags: ["Cappings"],
    }),
    createCapping: build.mutation({
      query: body => ({
        url: cappingUrlBuilder.createCapping(),
        method: "POST",
        data: body,
      }),
      invalidatesTags: ["Cappings"],
    }),
    updateCapping: build.mutation({
      query: body => ({
        url: cappingUrlBuilder.createCapping(),
        method: "PUT",
        data: body,
      }),
      invalidatesTags: ["Cappings"],
    }),
    getCapping: build.query({
      query: args => ({
        url: cappingUrlBuilder.getCapping(args),
        method: "GET",
      }),
      providesTags: ["Cappings"],
    }),
  }),
});

export const {
  useGetCappingsListQuery,
  useCreateCappingMutation,
  useUpdateCappingMutation,
  useGetCappingQuery,
} = cappingsAPI;
