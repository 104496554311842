import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { Box, Button } from "@mui/material";

export const CardButton = ({ link, label, ...other }) => {
  const navigate = useNavigate();
  return (
    <Box position={"absolute"} right={5} top={10} zIndex={100}>
      <Button
        size={"small"}
        onClick={() => {
          navigate(link);
        }}
        {...other}
      >
        {label}
      </Button>
    </Box>
  );
};

CardButton.propTypes = {
    link: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
};


