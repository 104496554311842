import { faTimesCircle } from '@fortawesome/pro-light-svg-icons';
import { Box, IconButton, Paper } from '@mui/material';
import { FontAwesomeIconComp, SideDrawer } from 'components/Common';
import {
  ButtonSection,
  Header,
  HeaderSubtitle,
  HeadingSection,
} from 'components/Styled';
import { conversion } from 'helpers';
import { useDrawer } from 'hooks';
import PropTypes from 'prop-types';
import React, { useLayoutEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useGetDocumentQuery } from 'redux/rtk-query';
import { DocumentEditorMenu } from './components/DocumentEditorMenu/DocumentEditorMenu';
import DocumentEsignEditor from './components/DocumentEsignEditor';

export function DocumentEditorDrawer({ params, path }) {
  const { isOpen, closeDrawer } = useDrawer(true, path);
  const [headerHeight, setHeaderHeight] = React.useState(0);
  const headerRef = React.useRef(null);
  const routeParams = useParams();
  const relatedModelType = routeParams.contactDetailsId
    ? 'contact'
    : 'transaction';

  const paramName = Object.keys(params)[0];
  const { data: documentData } = useGetDocumentQuery({
    id: params[paramName],
    defaults: true,
  });

  useLayoutEffect(() => {
    if (headerRef.current) {
      setHeaderHeight(headerRef.current.offsetHeight);
    }
  }, [headerRef.current]);

  return (
    <SideDrawer width={'95%'} isOpen={isOpen} closeDrawer={closeDrawer}>
      <Paper sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Box ref={headerRef}>
          <HeadingSection shadow backgroundColor={'#F7F7F7'}>
            <Box>
              <Header variant="h4">{documentData?.data?.name}</Header>
              <HeaderSubtitle>
                {conversion.getName(documentData?.data[relatedModelType])}
              </HeaderSubtitle>
            </Box>
            <ButtonSection>
              <IconButton onClick={closeDrawer}>
                <FontAwesomeIconComp icon={faTimesCircle} />
              </IconButton>
            </ButtonSection>
          </HeadingSection>
        </Box>
        <Box
          sx={{
            display: 'flex',
            height: '100%',
            maxHeight: `calc(100% - ${headerHeight}px)`,
            position: 'relative',
          }}
        >
          <DocumentEditorMenu headerHeight={headerHeight} />
          {documentData?.data && (
            <DocumentEsignEditor documentData={documentData?.data} />
          )}
        </Box>
      </Paper>
    </SideDrawer>
  );
}

DocumentEditorDrawer.propTypes = {
  params: PropTypes.object.isRequired,
  path: PropTypes.string,
};
