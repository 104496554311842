import { List } from '@mui/material';
import { CollapsableMenuGroup, MenuItemButton } from 'components/Common';
import { colors } from 'consts';
import { conversion } from 'helpers';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { activeFieldsSelector, autofillDataSelector } from 'redux/selectors';
import { updateField } from 'redux/slices';

const hiddenFields = [
  'id',
  'poaName',
  'roleType',
  'signedDateTime',
  'signature',
  'initial',
  'serializedData',
];

function AutofillItem({ label, item }) {
  const activeFields = useSelector(activeFieldsSelector);
  const dispatch = useDispatch();

  function isMenuItemSelected(parentName, label) {
    return (
      activeFields[0].autoFill === `${parentName}_${label}` ||
      activeFields[0].autoFill === label
    );
  }

  function getMenuFromItem(item, parentName) {
    let labels = Object.keys(item);
    return (
      <List>
        {labels.map((label) => {
          if (hiddenFields.indexOf(label) === -1) {
            let autoFill = `${parentName}_${label}`;
            return (
              <MenuItemButton
                dense
                isSelected={isMenuItemSelected(parentName, label)}
                key={`item-label-${label}`}
                label={conversion.camelCaseToWords(label)}
                secondaryLabel={item[label]}
                onClick={() =>
                  dispatch(updateField({ properties: { autoFill: autoFill } }))
                }
              />
            );
          }
          return null;
        })}
      </List>
    );
  }

  function isGroupSelected(item, label) {
    return Object.keys(item).some((key) => {
      if (label === 'property') {
        return isMenuItemSelected(label, key);
      }
      return activeFields[0].autoFill === `${label}_${key}`;
    });
  }

  return (
    <>
      {typeof item === 'object' ? (
        <CollapsableMenuGroup
          dense
          selectedColor={colors.DARK_BLUE_1}
          label={conversion.camelCaseToWords(label)}
          isSelected={isGroupSelected(item, label)}
        >
          {getMenuFromItem(item, label)}
        </CollapsableMenuGroup>
      ) : (
        <MenuItemButton
          dense
          isSelected={activeFields[0].autoFill === label}
          label={conversion.camelCaseToWords(label)}
          secondaryLabel={item}
          onClick={() =>
            dispatch(updateField({ properties: { autoFill: label } }))
          }
        />
      )}
    </>
  );
}

AutofillItem.propTypes = {
  label: PropTypes.string.isRequired,
  item: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export function AutofillAssignment() {
  const autofillData = useSelector(autofillDataSelector);

  return (
    <div>
      {Object.keys(autofillData).map((label) => {
        let item = autofillData[label];
        return (
          <React.Fragment key={`autofill-${label}`}>
            <AutofillItem label={label} item={item} />
          </React.Fragment>
        );
      })}
    </div>
  );
}
