import { faTextSize } from '@fortawesome/pro-light-svg-icons';
import { TextField } from '@mui/material';
import { FontAwesomeIconComp, MenuItemButton } from 'components/Common';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { activeFieldsSelector } from 'redux/selectors';
import { updateField } from 'redux/slices';

export function FontSize() {
  const dispatch = useDispatch();
  const activeFields = useSelector(activeFieldsSelector);
  const selectedField = activeFields[0];

  const value = selectedField?.fontSize?.replace('px', '') ?? 14;
  const max = parseInt(selectedField?.height);
  return (
    <MenuItemButton
      label={'Font Size'}
      icon={<FontAwesomeIconComp fontSize={18} icon={faTextSize} />}
      rightAction={
        <TextField
          size={'small'}
          sx={{ width: '70px', p: 0 }}
          type="number"
          value={value}
          inputProps={{ min: 10, max: max, step: 1 }}
          onChange={(e) => {
            dispatch(
              updateField({
                properties: { fontSize: `${e.target.value}px` },
              }),
            );
          }}
        />
      }
    />
  );
}
