import { Grid } from '@mui/material';
import {
  AbeForm,
  FormCondition,
  FormValidationProvider,
} from 'components/Forms';
import { PM_FILE_OPTIONS, REPRESENTING_OPTIONS, STATUS_OPTIONS } from 'consts';
import PropTypes from 'prop-types';
import React from 'react';
import { useValidateTransactionMutation } from 'redux/rtk-query';
import {
  BuyerAgentCommissionPanel,
  ContractPanel,
  LeasePanel,
  ListingPanel,
  MyCommissionPanel,
  OverviewPanel,
  PropertyMgmtAgreementPanel,
  PropertyMgmtPanel,
  RolesPanel,
  TeamShareAndReferralPanel,
  TimeframesPanel,
} from './components';
import { processFormData } from './formData';

const TransactionForm = (props) => {
  const removeTeamMember = (args, state, utils) => {
    utils.changeValue(state, 'team', (team) => {
      return team.filter((t) => t.order !== args[0]);
    });
  };

  const renderFields = (form, values) => (
    <Grid container spacing={2} style={{ padding: '0 16px' }}>
      <Grid item xs={12} md={4}>
        <OverviewPanel />
        <FormCondition when="addressConfirmed" operator="equals" is={true}>
          <MyCommissionPanel />
          {(values.representing === REPRESENTING_OPTIONS.SELLER.value ||
            values.representing === REPRESENTING_OPTIONS.LANDLORD.value) && (
            <BuyerAgentCommissionPanel />
          )}
        </FormCondition>
      </Grid>
      <Grid item xs={12} md={4}>
        <ListingPanel />
        <ContractPanel />
        {(values.status === STATUS_OPTIONS.LEASED.value ||
          values.representing?.includes('Tenant')) && <LeasePanel />}
        {(values.status === STATUS_OPTIONS.PROPERTY_MGMT.value ||
          values.pmFile === PM_FILE_OPTIONS.PROPERTY_MGMT.value) && (
          <>
            <PropertyMgmtPanel />
            <PropertyMgmtAgreementPanel />
          </>
        )}
        <TimeframesPanel />
      </Grid>
      <Grid item xs={12} md={4}>
        <RolesPanel />
        <TeamShareAndReferralPanel />
      </Grid>
    </Grid>
  );

  const [validate] = useValidateTransactionMutation();

  return (
    <FormValidationProvider value={{ validate, processFormData }}>
      <AbeForm {...props} mutators={{ removeTeamMember }}>
        {renderFields}
      </AbeForm>
    </FormValidationProvider>
  );
};

TransactionForm.propTypes = {
  afterSave: PropTypes.func,
  handleSubmitFunc: PropTypes.func,
  initialValues: PropTypes.object,
  title: PropTypes.string,
  nameButton: PropTypes.string,
  isLoading: PropTypes.bool,
};

export default TransactionForm;
