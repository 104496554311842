import { styled } from "@mui/system";
import { Dialog } from "@mui/material";

export const BaseDialogWrapper = styled(Dialog)({
  borderRadius: 10
});

BaseDialogWrapper.defaultProps = {
  fullWidth: false,
  maxWidth:  'sm',
};