import { Box } from '@mui/material';
import { SideDrawer } from 'components/Common';
import { TextInput } from 'components/Fields';
import { AbeForm } from 'components/Forms';
import { RIGHT_OPTIONS } from 'consts';
import { addModelAccessValues } from 'helpers';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Field } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';

import {
  useCreateListMutation,
  useGetListQuery,
  useGetProfileQuery,
  useUpdateListMutation,
} from 'redux/rtk-query';
import { editingListSelector } from 'redux/selectors';
import { setSelectedList } from 'redux/slices';

export const CreateListModal = ({ closeHandler, open }) => {
  const [createList] = useCreateListMutation();
  const [updateList] = useUpdateListMutation();
  const [initialValues, setInitialValues] = useState({});
  const dispatch = useDispatch();
  const { data: userData } = useGetProfileQuery();

  const editingList = useSelector(editingListSelector);
  const { data: listData } = useGetListQuery(
    { id: editingList },
    { skip: !editingList },
  );

  const renderFields = () => (
    <Box p={2}>
      <Field autoFocus name="name" label="List Name" component={TextInput} />
    </Box>
  );

  const onSubmit = async (values) => {
    if (editingList) {
      const list = await updateList({
        ...values,
        ...addModelAccessValues(values.modelAccess),
      });
      let listCopy = { ...list.data.data };
      listCopy.name = values.name;
      dispatch(setSelectedList(listCopy));
      return list;
    } else {
      const list = await createList({
        ...values,
        ...addModelAccessValues(values.modelAccess),
      });
      let listCopy = { ...list.data.data };
      listCopy.name = values.name;
      dispatch(setSelectedList(list.data.data));
      return list;
    }
  };

  useEffect(() => {
    if (listData && listData.data) {
      setInitialValues(listData.data);
    } else {
      setInitialValues({
        name: '',
        agent: userData?.data,
        companyStructureUnits: [],
        shareWithOption: RIGHT_OPTIONS.agentOnly.value,
      });
    }
  }, [listData, userData]);

  return (
    <SideDrawer isOpen={open}>
      <AbeForm
        initialValues={initialValues}
        afterSave={closeHandler}
        handleSubmitFunc={onSubmit}
        title={`${editingList ? 'Edit' : 'Create'} List`}
      >
        {renderFields}
      </AbeForm>
    </SideDrawer>
  );
};

CreateListModal.propTypes = {
  closeHandler: PropTypes.func,
  open: PropTypes.bool,
};
