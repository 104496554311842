import { Box } from '@mui/material';
import { FontAwesomeIconComp } from "components/Common";
import PropTypes from 'prop-types';
import { faFacebook, faInstagram, faLinkedin, faTwitter } from "@fortawesome/free-brands-svg-icons";

import React from 'react';

export const SocialMediaLink = ({ socialLink }) => {

  const icon = {
    "Facebook": faFacebook,
    "Twitter": faTwitter,
    "Instagram": faInstagram,
    "LinkedIn": faLinkedin,
  }

  return <Box>
    {socialLink.type}
    <FontAwesomeIconComp icon={icon[socialLink.type]}/>
    {socialLink.url}
  </Box>;
};

SocialMediaLink.propTypes = {
  socialLink: PropTypes.shape({
    url: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
};
